"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GLOBAL_VIRTUAL_GROUP_COMMON = exports.GLOBAL_VIRTUAL_GROUPS_COMMON = exports.GLOBAL_USER_COMMON = exports.GLOBAL_ORG_COMMON = exports.GLOBAL_NOTIFICATIONS_COMMON = void 0;
var GLOBAL_USER_COMMON = 'user';
exports.GLOBAL_USER_COMMON = GLOBAL_USER_COMMON;
var GLOBAL_ORG_COMMON = 'org';
exports.GLOBAL_ORG_COMMON = GLOBAL_ORG_COMMON;
var GLOBAL_VIRTUAL_GROUP_COMMON = 'virtualGroup';
exports.GLOBAL_VIRTUAL_GROUP_COMMON = GLOBAL_VIRTUAL_GROUP_COMMON;
var GLOBAL_VIRTUAL_GROUPS_COMMON = 'virtualGroups';
exports.GLOBAL_VIRTUAL_GROUPS_COMMON = GLOBAL_VIRTUAL_GROUPS_COMMON;
var GLOBAL_NOTIFICATIONS_COMMON = 'notifications';
exports.GLOBAL_NOTIFICATIONS_COMMON = GLOBAL_NOTIFICATIONS_COMMON;