import {
  Result,
  KaizenThemeContext,
  Textbox,
  Password,
  Button,
  ButtonGroup,
  Select
} from '@kaizen-ui/complete'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  FormikError,
  ModalButtons,
  ModalSelect,
  useAppNotifications,
  useFetch
} from 'common'
import { API_EMAIL_ALERTS_SMTP, SMTP_SECURITY_TYPES } from '../../../api'
import { SessionExpired } from '../../../Components'
import Text from '@kaizen-ui/text'
import Icon from '@kaizen-ui/icon'
import ReactTooltip from 'react-tooltip'

const Outer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.result.border};
  background: ${({ theme }) => theme.colors.result.background};
  display: flex;

  .form {
    background: ${({ theme }) =>
      theme.darkMode ? theme.colors.pageBackground : theme.colors.lightGray000};
    padding: 1rem;
    border-right: 1px solid ${({ theme }) => theme.colors.result.border};
    min-width: 25rem;
  }
`
const StyledResult = styled(Result)`
  border: none;
`
const StyledPassword = styled(Password)`
  svg {
    margin-top: -2rem;
    margin-right: 0.5rem;
  }
`
const StyledSelect = styled(Select)`
  width: 350px !important;
`
const ports = [587, 465, 25, 2525]
const availablePorts = ports.map(port => {
  return { label: `${port}`, value: port }
})

const validationSchema = Yup.object({
  host: Yup.string().required('You must enter the SMTP server hostname'),
  username: Yup.string(),
  password: Yup.string(),
  forcedSSL: Yup.string().required(
    'You must indicate security mechanism to use'
  ),
  port: Yup.number()
    .oneOf(ports, 'You must select a port for SSL communication')
    .required()
})

const securityOptions = [
  { label: 'Forced SSL', value: SMTP_SECURITY_TYPES.FORCED },
  { label: 'Opportunistic SSL', value: SMTP_SECURITY_TYPES.OPPORTUNISTIC },
  { label: 'No SSL', value: SMTP_SECURITY_TYPES.NONE }
]

export const ConfigureSmtp = ({ smtp, onUpdate, onCancel }) => {
  const theme = React.useContext(KaizenThemeContext)
  const { notify } = useAppNotifications()
  const { getData: sendRequest, loading } = useFetch({
    endpoint: API_EMAIL_ALERTS_SMTP(),
    actionLabel: 'Update SMTP configuration',
    SessionExpired: SessionExpired,
    method: 'PUT'
  })

  const initialValues = {
    host: smtp?.host || '',
    username: smtp?.username || '',
    password: '',
    forcedSSL: smtp?.securityMechanism || SMTP_SECURITY_TYPES.NONE,
    port: smtp?.port || 465
  }

  const onSubmit = async values => {
    const { host, username, password, forcedSSL, port } = values

    const body = {
      host,
      ...(username ? { username: username } : {}),
      ...(password ? { password: password } : {}),
      securityMechanism: forcedSSL,
      port
    }
    const result = await sendRequest({ body })

    if (result) {
      notify(
        `Successfully configured SMTP setting`,
        null,
        null,
        'Updated SMTP configuration'
      )
      onUpdate && onUpdate()
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit
  })

  return (
    <Outer theme={theme}>
      <ReactTooltip
        id='file-tooltip'
        multiline={true}
        style={{ overFlow: 'visible' }}
      />
      <div className='form'>
        <Textbox
          id='host'
          name='host'
          label='Host'
          className='nvl'
          placeholder='Enter the hostname of the SMTP server'
          value={formik.values.host}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          validationMessage={formik.touched.host && formik.errors.host}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '-1rem'
          }}
        >
          <Text
            textStyle='label'
            color={theme.colors.checkbox?.foreground}
            htmlFor='username'
          >
            Username &nbsp;
            <span
              data-for='file-tooltip'
              data-tip={`The License Service Instance only verifies the connectivity to the SMTP server.<br/> If an SMTP connection requires authentication but no password is provided, email alerts will fail to be sent as required by the SMTP server.`}
              style={{
                marginLeft: '4px',
                verticalAlign: 'sub'
              }}
            >
              <Icon name='StatusCircleInformation' color={theme.colors.brand} />
            </span>
          </Text>
        </div>
        <Textbox
          id='username'
          name='username'
          /* label='Username'*/
          className='nvl'
          placeholder='Enter the username for the SMTP server'
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          validationMessage={formik.touched.username && formik.errors.username}
        />

        <StyledPassword
          id='password'
          name='password'
          label='Password'
          className='nvl'
          inputMode=''
          placeholder='Enter the password for the SMTP server'
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          validationMessage={formik.touched.password && formik.errors.password}
          showVisibilityToggle={
            formik.values.password !== formik.initialValues.password
          }
        />

        {/* <StyledSwitch>
          <div key={`forcedSSL-${formik.values.forcedSSL}`}>
            <Switch
              id='forcedSSL'
              name='forcedSSL'
              checked={!!formik.values.forcedSSL}
              value={!!formik.values.forcedSSL}
              onChange={() => {
                formik.setFieldValue('forcedSSL', !formik.values.forcedSSL)
              }}
              label='Forced SSL'
            />
          </div>
        </StyledSwitch> */}
        <Text textStyle='label'>Security</Text>
        <StyledSelect
          options={securityOptions}
          value={securityOptions.find(o => o.value === formik.values.forcedSSL)}
          onChange={option => formik.setFieldValue('forcedSSL', option.value)}
        />
        <br />

        <div key={`port-selection-${formik.values.port}`}>
          <Text textStyle='label' color={theme.colors.checkbox?.foreground}>
            Port Number
          </Text>
          <ModalSelect
            className='nvl'
            id={'port'}
            name={'port'}
            options={availablePorts}
            value={
              availablePorts.find(port => port.value === formik.values.port) ||
              null
            }
            onChange={option => {
              formik.setFieldTouched('port', true)
              formik.setFieldValue('port', option?.value)
            }}
            onBlur={formik.handleBlur}
            validationMessage={formik.touched.port && formik.errors.port}
            valid={!formik.touched.port || !formik.errors.port}
            required
          />
          <FormikError>{formik.touched.port && formik.errors.port}</FormikError>
        </div>

        <ModalButtons>
          <ButtonGroup>
            <Button type='secondary' onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={formik.handleSubmit} disabled={loading}>
              Configure SMTP
            </Button>
          </ButtonGroup>
        </ModalButtons>
      </div>
      <StyledResult
        status={loading ? 'loading' : 'warning'}
        title={
          loading
            ? 'Updating SMTP configuration'
            : 'SMTP configuration has not been completed'
        }
      />
    </Outer>
  )
}
