"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "UsageIndicator", {
  enumerable: true,
  get: function get() {
    return _UsageIndicator.UsageIndicator;
  }
});

var _UsageIndicator = require("./UsageIndicator");