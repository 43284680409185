import { useEffect, useMemo, useGlobal, useState } from 'reactn'
import { useAppNotifications, useFetch } from 'common'
import {
  API_UPGRADE_FILE_INSTALL_STATUS,
  UPGRADE_FILE_INSTALL_STATUS
} from '../../api'
import { SessionExpired } from '../../Components'

import {
  GLOBAL_IS_CONTAINERIZED,
  GLOBAL_IS_USER_LOCKED,
  GLOBAL_UPGRADE_FILE_INSTALL_REFRESH,
  GLOBAL_UPGRADE_FILE_INSTALL_STATUS,
  GLOBAL_UPGRADE_FILE_INSTALL_TRIGGERED
} from '../../globalState'
import { toast } from 'react-toastify'
import { useAuth } from '../useAuth'
// const mock = {
//   //state: UPGRADE_STATUS.COMPLETED,
//   //message: 'Upgrade file installation in progress',
//   //message: 'Upgrade file installation failed',
//   //message: 'Upgrade file installed successfully'
// }
export function useUpgradeFileInstallStatus(user) {
  const { checkAuth } = useAuth()
  const [, setIsContainerized] = useGlobal(GLOBAL_IS_CONTAINERIZED)
  const [, setIsUserLocked] = useGlobal(GLOBAL_IS_USER_LOCKED)
  const [upgradeFileInstallStatusRefresh] = useGlobal(
    GLOBAL_UPGRADE_FILE_INSTALL_REFRESH
  )
  const [, setUpgradeFileInstallTriggered] = useGlobal(
    GLOBAL_UPGRADE_FILE_INSTALL_TRIGGERED
  )
  const [, setUpgradeFileInstallStatus] = useGlobal(
    GLOBAL_UPGRADE_FILE_INSTALL_STATUS
  )
  const { notify } = useAppNotifications()
  const [status, setStatus] = useState(undefined)

  const { getData, abort } = useFetch({
    endpoint: API_UPGRADE_FILE_INSTALL_STATUS,
    actionLabel: 'Check upgrade file installation status',
    SessionExpired: SessionExpired,
    supressToast: true
    // normalizer: () => mock
    /* normalizer: () => {
      return { ...mock, state: UPGRADE_STATUS.STARTED }
    }*/
    /* normalizer: () => {
      return { ...mock, state: UPGRADE_STATUS.FAILED }
    },
    normalizer: () => {
      return { ...mock, state: UPGRADE_STATUS.COMPLETED }
    }*/
  })

  useEffect(() => {
    /*if (user) {
      return
    }*/
    const interval = setInterval(() => {
      getStatus()
    }, 5000)
    const getStatus = async () => {
      if (user) {
        clearInterval(interval)
        return
      }
      const data = await getData()
      //no response
      if (!data || !data.state) {
        setUpgradeFileInstallTriggered(false)
        clearInterval(interval)
        return
      }

      //overloaded features
      if (data?.isPureContainer) {
        setIsContainerized(true)
      }
      if (data?.isUserLocked) {
        setIsUserLocked(true)
      }

      //extract and use state
      setStatus(data)
      const { state, message } = data
      setUpgradeFileInstallTriggered(
        data?.state === UPGRADE_FILE_INSTALL_STATUS.STARTED
      )
      setUpgradeFileInstallStatus(data)
      //notification
      if (
        toast.isActive('upgrade-file-install-progress') &&
        state === UPGRADE_FILE_INSTALL_STATUS.STARTED
      ) {
        notify(message, null, null, 'Upgrade file installation status', true, {
          toastId: 'upgrade-file-install-progress',
          toastOptions: { autoClose: false }
        })
      } else if (
        [
          UPGRADE_FILE_INSTALL_STATUS.STARTED,
          UPGRADE_FILE_INSTALL_STATUS.FAILED,
          UPGRADE_FILE_INSTALL_STATUS.COMPLETED
        ].includes(state)
      ) {
        toast.dismiss('upgrade-file-install-progress')
        notify(
          message,
          {
            toastId: 'upgrade-file-install-progress',
            autoClose: 5000
          },
          state === UPGRADE_FILE_INSTALL_STATUS.FAILED,
          'Upgrade file installation status'
        )
      }

      if (state === UPGRADE_FILE_INSTALL_STATUS.COMPLETED) {
        if (!user) {
          checkAuth(() => {})
        }
      }

      if (
        state === UPGRADE_FILE_INSTALL_STATUS.FAILED ||
        state === UPGRADE_FILE_INSTALL_STATUS.COMPLETED
      ) {
        clearInterval(interval)
      }
    }
    getStatus()

    return () => {
      clearInterval(interval)
      abort()
    }
  }, [user, upgradeFileInstallStatusRefresh]) // eslint-disable-line react-hooks/exhaustive-deps

  const beingInstalled = useMemo(
    () => status?.state === UPGRADE_FILE_INSTALL_STATUS.STARTED,
    [status]
  )

  return {
    installStatusObj: status,
    beingInstalled
  }
}
