import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Textbox from '@kaizen-ui/textbox'
import Password from '@kaizen-ui/password'
import Button from '@kaizen-ui/button'
import Icon from '@kaizen-ui/icon'
import { ModalSpinner, useFetch } from 'common'
import { useAuth } from '../../hooks'
import { API_USER_PW_CHANGE } from '../../api'

const PullRight = styled.div`
  display: flex;
  justify-content: flex-end;
`
const StyledSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > h2 {
    margin-left: 0.5rem;
    font-weight: 100;
  }

  b {
    margin-left: 0.5rem;
  }
`

export const ChangeUsername = ({
  setChangeUsernameOpen,
  setHasCredsChanged
}) => {
  const theme = useContext(KaizenThemeContext)
  const [localResetSecret, setLocalResetSecret] = useState('')
  const { user, serviceInstanceId, signout } = useAuth()

  const { getData: sendUserChange, loading: submitting } = useFetch({
    endpoint: API_USER_PW_CHANGE,
    actionLabel: `Change username for ${user?.clientId}`,
    method: 'POST'
  })

  const initialValues = {
    email: '',
    oldPassword: ''
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .notOneOf([user?.clientId], "Username can't be same as existing")
      .min(3, 'Must have at least 3 characters')
      .max(64, 'Must have fewer than 64 characters')
      .required('You must enter a username'),
    oldPassword: Yup.string().required('Current password is required')
  })

  const submit = async values => {
    ReactTooltip.hide()
    const headers = { 'x-nv-service-instance-id': serviceInstanceId }
    const body = {
      username: values.email,
      current: values.oldPassword
    }

    const data = await sendUserChange({ body, headers })
    if (data?.localResetSecret) {
      setLocalResetSecret(data?.localResetSecret)
      setHasCredsChanged(true)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: submit,
    validationSchema
  })

  return (
    <>
      {submitting && <ModalSpinner />}
      {localResetSecret ? (
        <>
          <StyledSuccess>
            <Flex>
              <Icon
                name='StatusCircleCheck2'
                variant='solid'
                color={theme.colors.brand}
                size='large'
              />
              <h2>Username has changed successfully!</h2>
            </Flex>
            <div>
              Your updated username is <b>{formik.values.email}</b>
            </div>
            <br />
            <Button
              primary
              onClick={() => {
                setLocalResetSecret('')
                setChangeUsernameOpen(false)
                signout()
              }}
            >
              Continue to Login
            </Button>
          </StyledSuccess>
        </>
      ) : (
        <>
          <form>
            <div>
              <div>
                <Textbox
                  label='Current username'
                  value={user?.clientId}
                  disabled={true}
                />
              </div>
            </div>
            <div>
              <div>
                <Textbox
                  name='email'
                  label='New username'
                  className='nvl'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  validationMessage={
                    formik.touched.email && formik.errors.email
                  }
                  valid={formik.touched.email && !formik.errors.email}
                />
              </div>
            </div>
            <div>
              <div data-tip='Enter the current password'>
                <Password
                  name='oldPassword'
                  label='Current password'
                  className='nvl'
                  showVisibilityToggle={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.oldPassword}
                  validationMessage={
                    formik.touched.oldPassword && formik.errors.oldPassword
                  }
                  valid={
                    formik.touched.oldPassword && !formik.errors.oldPassword
                  }
                />
              </div>
            </div>

            <PullRight>
              <Button
                tag='submit'
                onClick={formik.handleSubmit}
                disabled={submitting}
              >
                Change Username
              </Button>
            </PullRight>
          </form>
        </>
      )}
    </>
  )
}
