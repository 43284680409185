import React, { useState, useGlobal, useEffect, useContext } from 'reactn'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import {
  GLOBAL_IS_CONTAINERIZED,
  GLOBAL_IS_UNREGISTERED_USER,
  GLOBAL_UPGRADE_FILE_INSTALL_TRIGGERED
} from '../../globalState'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Block from '@kaizen-ui/block'
import Textbox from '@kaizen-ui/textbox'
import Password from '@kaizen-ui/password'
import Button from '@kaizen-ui/button'
import Icon from '@kaizen-ui/icon'
import { useHistory } from 'react-router'
import { ROUTE_DASHBOARD } from '../../Components'
import { useFetch } from 'common'
import { API_USER_REGISTRATION } from '../../api'
import { useAuth } from '../../hooks'
import { Result } from '@kaizen-ui/complete'

const LoginBox = styled.div`
  max-width: 500px;
  margin-top: 1rem;

  h1 {
    margin: 0;
    font-weight: 100;
    color: #76b900;
    margin-bottom: 2rem;
  }
`
const PullRight = styled.div`
  display: flex;
  justify-content: flex-end;
`
/*const StyledSwitch = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin: 0 0 0.5rem 0;

    .switch-display,
    label {
      margin-right: 0 !important;
    }
    .switch-text {
      margin-left: 0.5rem;
    }
  }
  > span {
    margin-left: 0.5rem;
  }
`*/
const StyledSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Flex = styled.div`
  display: flex;
  align-items: center;

  > h2 {
    margin-left: 0.5rem;
    font-weight: 100;
  }

  b {
    margin-left: 0.5rem;
  }
`

const initialValues = {
  email: 'dls_admin',
  password: '',
  passwordCopy: ''
}
const validationSchema = Yup.object({
  email: Yup.string()
    .min(3, 'Must have at least 3 characters')
    .max(64, 'Must have fewer than 64 characters')
    .required('You must enter a username'),
  password: Yup.string()
    .matches(/^\S*$/, 'Should not contain any whitespaces')
    .min(8, 'Must have at least 8 characters')
    .max(64, 'Must have fewer than 64 characters')
    .required('You must enter a password'),
  passwordCopy: Yup.string().when('password', {
    is: val => {
      return val?.length > 0
    },
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'Both passwords must match')
      .required('Both passwords must match'),
    otherwise: Yup.string().required('You must enter a password')
  })
})

export const SetPassword = () => {
  const theme = useContext(KaizenThemeContext)
  const [isUnregistered] = useGlobal(GLOBAL_IS_UNREGISTERED_USER)
  const [isContainerized] = useGlobal(GLOBAL_IS_CONTAINERIZED)
  const [upgradeFileInstallTriggered] = useGlobal(
    GLOBAL_UPGRADE_FILE_INSTALL_TRIGGERED
  )
  const [localResetSecret, setLocalResetSecret] = useState('')
  const [secretCopied, setSecretCopied] = useState(false)
  const history = useHistory()
  const { checkAuth } = useAuth()

  const { getData: sendRegistration, loading: submitting } = useFetch({
    endpoint: API_USER_REGISTRATION,
    actionLabel: 'Create DLS user',
    method: 'POST'
  })

  const submit = async values => {
    ReactTooltip.hide()
    const body = {
      username: values.email,
      pw: values.password,
      ...(!isContainerized
        ? {
            machineAccount: true
          }
        : {})
    }

    const data = await sendRegistration({ body })
    if (data?.localResetSecret) {
      setLocalResetSecret(data?.localResetSecret)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: submit,
    validationSchema
  })

  useEffect(() => {
    if (!isUnregistered) history.replace(ROUTE_DASHBOARD)
  }, [isUnregistered]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      ReactTooltip.hide()
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  if (upgradeFileInstallTriggered) {
    return <Result status='loading' title='Upgrade file is being installed' />
  }

  return isUnregistered ? (
    <>
      <LoginBox>
        <Block loading={submitting}>
          {localResetSecret ? (
            <>
              <h1>Register user account</h1>
              <StyledSuccess>
                <Flex>
                  <Icon
                    name='StatusCircleCheck2'
                    variant='solid'
                    color={theme.colors.brand}
                    size='large'
                  />
                  <h2>Account registered successfully!</h2>
                </Flex>
                <div>
                  Your username is <b>{formik.values.email}</b>
                </div>
                <Flex>
                  Your local reset secret is <b>{localResetSecret}</b>
                  <div data-tip='Copy secret to clipboard'>
                    <CopyToClipboard
                      text={localResetSecret}
                      onCopy={() => setSecretCopied(true)}
                    >
                      <Button
                        type='success'
                        variant='link'
                        icon={{
                          name: secretCopied ? 'StatusCircleCheck2' : 'FileCopy'
                        }}
                      />
                    </CopyToClipboard>
                  </div>
                </Flex>
                <br />
                <div>
                  Copy this local reset secret and store it in a secure
                  location. In case the password is forgotten, this value can be
                  used one time to reset the password.
                </div>
              </StyledSuccess>
              <br />
              <PullRight>
                <Button onClick={() => checkAuth(() => {})}>
                  Continue to login
                </Button>
              </PullRight>
            </>
          ) : (
            <>
              <h1>Register user account</h1>
              <form>
                <div>
                  <div>
                    <Textbox
                      name='email'
                      label='Username'
                      className='nvl'
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      validationMessage={
                        formik.touched.email && formik.errors.email
                      }
                      valid={formik.touched.email && !formik.errors.email}
                    />
                  </div>
                </div>
                <div>
                  <div data-tip='Enter the password'>
                    <Password
                      name='password'
                      label='Password'
                      className='nvl'
                      showVisibilityToggle={false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      validationMessage={
                        formik.touched.password && formik.errors.password
                      }
                      valid={formik.touched.password && !formik.errors.password}
                    />
                  </div>
                </div>
                <div>
                  <div data-tip='Enter the password again'>
                    <Password
                      name='passwordCopy'
                      label='Confirm password'
                      className='nvl'
                      showVisibilityToggle={false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.passwordCopy}
                      validationMessage={
                        formik.touched.passwordCopy &&
                        formik.errors.passwordCopy
                      }
                      valid={
                        formik.touched.passwordCopy &&
                        !formik.errors.passwordCopy
                      }
                    />
                  </div>
                </div>
                {/* {!isContainerized && (
                  <StyledSwitch data-tip='Creates user "dls_diagnostics", this can be used via the hypervisor console to access log files'>
                    <Switch
                      id={'dls_diagnostics'}
                      checked={formik.values.debugUser}
                      onChange={() =>
                        formik.setFieldValue(
                          'debugUser',
                          !formik.values.debugUser
                        )
                      }
                      label='Create a diagnostic user'
                    />
                    <span>
                      <Icon name='StatusCircleInformation' />
                    </span>
                  </StyledSwitch>
                )}*/}
                <PullRight>
                  <Button
                    tag='submit'
                    onClick={formik.handleSubmit}
                    disabled={submitting}
                  >
                    Register
                  </Button>
                </PullRight>
              </form>
            </>
          )}
        </Block>
      </LoginBox>
    </>
  ) : (
    /*no render, redirect*/
    <></>
  )
}
