import React, { useContext, useGlobal } from 'reactn'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Card from '@kaizen-ui/card'
import Icon from '@kaizen-ui/icon'
import Block from '@kaizen-ui/block'
import { PageHeader } from 'common'
import { DOCS_ROOT, RELEASE_NOTES } from '../../api'
import Tag from '@kaizen-ui/tag'
import { GLOBAL_VA_VERSION } from '../../globalState'

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};
  margin-bottom: ${props => props.theme.spacing.one};
  //margin-top: ${props => props.theme.spacing.one};

  > div {
    margin-right: ${props => props.theme.spacing.four};
    margin-top: ${props => props.theme.spacing.four};
    width: 400px;
    ${props => props?.nested && `&:first-of-type { margin-top: 0;}`};
    &:last-of-type {
      margin-right: 0;
    };
  }
`
const Hint = styled.div`
  color: #a5a5a5;
  font-size: 0.85rem;

  a {
    font-size: 0.85rem;
  }
`
const LineBreak = styled.div`
  height: 1px;
  background-color: #e2e2e2;
  margin: 1rem 0;
`
const StyledBlock = styled.div`
  align-self: flex-start;

  h3 {
    margin: 0;
    display: flex;
    align-items: center;

    > span {
      margin: 0 1rem 0 0;
    }
  }
  a {
    margin-top: 0.25rem;
    font-size: 0.85rem;
    ${({ multipleLinks }) => multipleLinks && `line-height: 1.5rem;`}
  }
`
const StyledLink = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`
const HelpLink = ({ href, text }) => {
  const theme = useContext(KaizenThemeContext)

  return (
    <StyledLink>
      <Icon name='ObjectsGlobe1' />
      <span style={{ marginLeft: theme.spacing.two }}>
        <a href={href} target='_blank' rel='noopener noreferrer'>
          {text}
        </a>
      </span>
    </StyledLink>
  )
}

export const Support = () => {
  const theme = useContext(KaizenThemeContext)
  const [version] = useGlobal(GLOBAL_VA_VERSION)

  React.useEffect(() => {
    document.title = 'DLS - Support'
  }, [])

  return (
    <>
      <PageHeader
        title='Support'
        subTitle={'Contact information and additional support'}
      />

      <FlexRow theme={theme}>
        <FlexRow theme={theme} flexDirection='column' nested={true}>
          <StyledBlock>
            <Block>
              <>
                <h3>
                  <span>NVIDIA Delegated License System:</span>
                  <Tag
                    clickable={false}
                    color='green'
                    variant={theme.darkMode ? 'outline' : 'solid'}
                  >
                    {version}
                  </Tag>
                </h3>
              </>
            </Block>
          </StyledBlock>
          <StyledBlock>
            <Block>
              <>
                <h3>
                  <span>Contact NVIDIA Enterprise Support</span>
                  <Icon
                    name='MessengerCircleDouble'
                    size='larger'
                    color='#c2c2c2'
                  />
                </h3>
                <a
                  href='https://www.nvidia.com/en-us/support/enterprise/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  https://www.nvidia.com/en-us/support/enterprise/
                </a>
              </>
            </Block>
          </StyledBlock>
          <StyledBlock multipleLinks>
            <Block>
              <>
                <h3>
                  <span>Support Documentation</span>
                  <Icon name='FileTextDocument' size='larger' color='#c2c2c2' />
                </h3>
                <div>
                  <a
                    href={RELEASE_NOTES(version)}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    NVIDIA DLS Release Notes
                  </a>
                </div>
                <div>
                  <a
                    href={DOCS_ROOT(version)}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    NVIDIA DLS User Guide
                  </a>
                </div>
              </>
            </Block>
          </StyledBlock>
        </FlexRow>
        <div>
          <Card
            childrenMaxLines={12}
            childrenMinLines={8}
            elevation='lowest'
            headerEnabled
            headerText='Virtual GPU'
          >
            <>
              <HelpLink
                href='https://docs.nvidia.com/grid/'
                text='NVIDIA Virtual GPU Documentation'
              />
              <HelpLink
                href='https://gridforums.nvidia.com/'
                text='NVIDIA Virtual GPU Forums'
              />
              <HelpLink
                href='https://www.nvidia.com/en-us/data-center/virtualization/resources/'
                text='NVIDIA Virtual GPU Resources'
              />
              <LineBreak />
              <Hint>
                If you are a GRID customer without a current SUMS or current
                Subscription{' '}
                <a
                  href='https://www.nvidia.com/en-us/data-center/buy-grid/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  click here
                </a>{' '}
                for support
              </Hint>
            </>
          </Card>
        </div>
      </FlexRow>
    </>
  )
}
