import React, { useContext, useGlobal } from 'reactn'
import { ContextNotifications, PageHeader } from 'common'
import { useHistory } from 'react-router'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
//import Button, { ButtonGroup } from '@kaizen-ui/button'
import Card from '@kaizen-ui/card'
import { ROUTE_SET_PASSWORD, ROUTE_UPGRADE } from '../../Components'
import {
  GLOBAL_UPGRADE_FILE_INSTALL_TRIGGERED,
  GLOBAL_VA_VERSION
} from '../../globalState'
import { DOCS_SETUP_ADMIN, DOCS_SETUP_GEN_UPGRADE } from '../../api'

const Row = styled.div`
  display: flex;

  .card-title {
    color: ${({ theme }) => theme.colors.textbox.normal.foreground};
  }
  > div + div {
    margin-left: 1rem;
  }
`
export const Setup = () => {
  const theme = useContext(KaizenThemeContext)
  const history = useHistory()
  const [upgradeFileInstallTriggered] = useGlobal(
    GLOBAL_UPGRADE_FILE_INSTALL_TRIGGERED
  )
  React.useEffect(() => {
    document.title = 'DLS - Setup'
  }, [])

  const [version] = useGlobal(GLOBAL_VA_VERSION)
  const helpRef1 = React.useRef(null)
  const [step, setStep] = React.useState(-1)
  const help = [
    {
      title: 'Registering the DLS Administrator User',
      text: `Each DLS virtual appliance is configured with a user account specifically for administering the DLS. This account provides access through a web-based management interface to the NVIDIA Licensing application on the appliance. Before administering a DLS virtual appliance, you must register this user to be able to access this management interface.`,
      link: DOCS_SETUP_ADMIN(version),
      ref: helpRef1,
      offsetY: 125
    },
    {
      title: 'Generating an Upgrade File for the DLS Instance',
      text: `After registering the DLS instance on an upgraded virtual appliance with the NVIDIA Licensing Portal, the downloaded upgrade file will be uploaded here.`,
      link: DOCS_SETUP_GEN_UPGRADE(version),
      ref: helpRef1,
      offsetY: 125,
      offsetX: 330
    }
  ]

  if (upgradeFileInstallTriggered) {
    return <Redirect to={ROUTE_UPGRADE} noThrow />
  }

  return (
    <>
      <ContextNotifications list={help} step={step} setStep={setStep} />
      <PageHeader
        title='Virtual Appliance Setup'
        //helpUrl={DOCS_LICENSE_SERVER}
        //tip='Get help with license servers'
        showHelp={() => setStep(0)}
        subTitle='Choose if this virtual appliance will be used as a new installation or to upgrade an existing one'
      />
      <Row theme={theme} ref={helpRef1}>
        <Card
          headerEnabled
          headerIcon={{ name: 'DatabaseAdd' }}
          title='First time setup'
          childrenMinLines={2}
          primaryFooterAction={{
            label: 'New Installation',
            onClick: () => history.push(ROUTE_SET_PASSWORD)
          }}
        >
          <span style={{ color: theme.colors.lightGray500 }}>
            Setting up the virtual appliance for the first time?
          </span>
        </Card>
        <Card
          headerEnabled
          headerIcon={{ name: 'DatabaseStar' }}
          title='Upgrade existing'
          childrenMinLines={2}
          primaryFooterAction={{
            label: 'Upgrade',
            onClick: () => history.push(ROUTE_UPGRADE)
          }}
        >
          <span style={{ color: theme.colors.lightGray500 }}>
            Upgrading from an older virtual appliance version?
          </span>
        </Card>
      </Row>
    </>
  )
}
