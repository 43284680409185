import React from 'reactn'
import { useEffect, useState } from 'react'
import { Table } from 'common'
import Text from '@kaizen-ui/text'
import moment from 'moment'

export const MetricChangesTable = ({ capacities = [], label = '', metricUnit = '' }) => {
  const [changes, setChanges] = useState([])
/*  useEffect(() => {
    console.log('changes : '
      , JSON.stringify(changes))
  }, [changes])*/

  useEffect(() => {
    const data = [...capacities].reverse() //ascending time order
    let prevValue = data[0][1] //metric value for first entry
    let changes = [data[0]] //list of table rows
    data.forEach(item => {
      if (item[1] !== prevValue) {
        changes.push(item)
        prevValue = item[1]
      }
    })
    setChanges(changes.reverse().map((change, index) => {
      return { id:index.toString(), time: change[0], metricMax: change[1] }
    }))
  }, [capacities])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'time',
        Cell: ({ row }) => <Text>{moment
          .utc(row.values.time, moment.ISO_8601)
          .local()
          .format('lll')}</Text>

      },
      {
        Header: 'Value',
        accessor: 'metricMax',
        Cell: ({ row }) => <Text>{row.values.metricMax} {metricUnit}</Text>
      }
    ],
    [metricUnit]
  )
  return (
    <div>
      <Table
        columns={columns}
        data={changes}
        /*   loading={pageLoading}
           fetching={loading}
           error={error}*/
        label={label}
        initialPageSize={5}
        totalResults={changes.length}
        disableSearch
        disableSorting
        disablePageSizeSelection
        disableExporting
        disableColumnHiding
      />
    </div>
  )
}
