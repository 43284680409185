import React, { useEffect, useState, useGlobal, useContext } from 'reactn'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Textbox from '@kaizen-ui/textbox'
import Checkbox from '@kaizen-ui/checkbox'
import Button from '@kaizen-ui/button'
import Spinner from '@kaizen-ui/spinner'
import Text from '@kaizen-ui/text'
import Result from '@kaizen-ui/result'
import Icon from '@kaizen-ui/icon'
import { useFetch, useAppNotifications, Pill } from 'common'
import { GLOBAL_SERVICE_INSTANCE_ID } from '../../globalState'
import { API_SERVICE_INSTANCE_SSL_CERT } from '../../api'
import { SessionExpired } from '../../Components'

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 0.5rem;
  }
`
const StyledResult = styled.div`
  > div {
    border: none;
  }
`
const StyledFileSelect = styled.div`
  margin-bottom: 1rem;
`
const StyledCheckbox = styled.label`
  .checkbox-text {
    font-size: 12px;
    font-weight: 700;
  }
`

const domainNameRegex =
  '^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\\-]*[A-Za-z0-9])$'
const validationSchema = yup.object({
  domainName: yup
    .string()
    .required('Domain name is required')
    .matches(domainNameRegex, 'Must be a valid domain name'),
  certFile: yup
    .mixed()
    .required('Certificate is required')
    .test('extension', 'The file must have a .pem extension', val => {
      let fileName = val && val.name
      let fileExt = fileName && fileName.split('.').pop()
      return fileExt === 'pem'
    }),
  certKeyFile: yup
    .mixed()
    .required('Certificate key is required')
    .test('extension', 'The file must have a .pem extension', val => {
      let fileName = val && val.name
      let fileExt = fileName && fileName.split('.').pop()
      return fileExt === 'pem'
    })
})
export const SSLConfiguration = ({ onUpdate }) => {
  const { notify } = useAppNotifications()
  const theme = useContext(KaizenThemeContext)
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)
  const [cert, setCert] = useState('')
  const [certKey, setCertKey] = useState('')
  const [isWildcardCert, setIsWildcardCert] = useState(false)
  const [updateMode, setUpdateMode] = useState(false)
  const [sslCertResp, setSSLCertResp] = useState(null)
  const [refreshToggle, setRefreshToggle] = useState(false)

  /* const [sslCertResp, sslCertLoading, sslCertError] = [null, false, null]*/
  const {
    getData,
    loading: sslCertLoading,
    abort,
    error: sslCertError
  } = useFetch({
    endpoint: API_SERVICE_INSTANCE_SSL_CERT,
    actionLabel: 'Get SSL configuration',
    SessionExpired: SessionExpired,
    supressToast: true
  })

  const { getData: installSSLCert, loading: installing } = useFetch({
    endpoint: API_SERVICE_INSTANCE_SSL_CERT,
    actionLabel: 'Configure SSL certificate',
    method: 'PUT'
  })

  useEffect(() => {
    const getSSLconfig = async () => {
      const data = await getData()
      if (data) {
        setSSLCertResp(data)
      }
    }
    getSSLconfig()
    return () => {
      abort()
    }
  }, [refreshToggle]) //eslint-disable-line react-hooks/exhaustive-deps

  const { domainName, sslCertsUploaded } = sslCertResp || {}
  /*const { domainName, sslCertsUploaded } = {
    domainName: 'ip-10-34-0-26.us-west-1.compute.internal',
    sslCertsUploaded: true
  }*/
  const initialValues = {
    domainName: domainName ? domainName : '',
    certFile: '',
    certKeyFile: ''
  }
  function getFileContents(files, e, setFieldValue) {
    if (!files || !files.length > 0) return

    const file = files[0]
    const fieldName = e.target.name
    if (fieldName === 'certFile') {
      setFieldValue('certFile', file)
    } else {
      setFieldValue('certKeyFile', file)
    }
    const fileName = file.name
    const fileExt = fileName.split('.').pop()

    if (fileExt === 'pem') {
      const reader = new FileReader()
      reader.onload = function(r) {
        if (fieldName === 'certFile') {
          setCert(r.target.result)
        } else {
          setCertKey(r.target.result)
        }
      }
      reader.readAsText(file)
      //for custom input validation, if formik validation doesn't work
      /*  e.target.setCustomValidity(
        'Invalid file. Please select a file with .pem extension.'
      )*/
    }
  }

  const resetSSLForm = ({ resetForm }) => {
    if (document.getElementById('cert-file')) {
      document.getElementById('cert-file').value = ''
    }
    if (document.getElementById('cert-key-file')) {
      document.getElementById('cert-key-file').value = ''
    }

    setCert('')
    setCertKey('')
    /* setIsWildcardCert(false)*/

    resetForm({
      values: {
        domainName: domainName ? domainName : '',
        certFile: '',
        certKeyFile: ''
      }
    })
  }

  const submit = async (values, actions) => {
    const { domainName } = values
    const body = {
      domainName: domainName,
      certificate: cert,
      certificateKey: certKey,
      isWildcardCert: isWildcardCert
    }
    const headers = { 'x-nv-service-instance-id': serviceInstanceId }
    installSSLCert({ body, headers }).then(data => {
      if (data) {
        const msg = `Certificate is validated successfully, you'll be able to access the virtual appliance securely via the configured domain name in few minutes.`
        notify(msg, null, null, 'SSL configuration triggered successfully')
        setRefreshToggle(val => !val)
        onUpdate && onUpdate()
      }
      resetSSLForm(actions)
    })
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: submit
  })

  if (sslCertLoading || sslCertError)
    return (
      <StyledResult>
        <Result
          status={sslCertError ? 'error' : 'loading'}
          subTitle={sslCertError}
        />
      </StyledResult>
    )

  return (
    <div>
      <ReactTooltip
        id='file-tooltip'
        multiline={true}
        style={{ overFlow: 'visible' }}
      />
      {sslCertsUploaded && !updateMode ? (
        <div>
          <div style={{ paddingBottom: '1rem' }}>
            <Pill label='Configured For Domain' value={domainName} />
          </div>
          <Buttons>
            <Button
              onClick={() => {
                setUpdateMode(true)
              }}
            >
              Upload new certificates
            </Button>
          </Buttons>
        </div>
      ) : (
        <>
          <StyledCheckbox
            data-for='file-tooltip'
            data-tip={
              !domainName
                ? `Make sure that a domain name is configured for this DLS.<br/> If applied from primary node, the same certificate will be applied to the secondary node(if any).`
                : ''
            }
          >
            <Checkbox
              name='isWildcardCert'
              id='isWildcardCert'
              label={'Apply Wildcard'}
              value={isWildcardCert}
              onChange={e => {
                setIsWildcardCert(e.target.checked)
                if (e.target.checked) {
                  formik.setFieldValue('domainName', domainName)
                }
              }}
              disabled={installing || !domainName}
            />
            <span
              data-for='file-tooltip'
              data-tip={`Make sure that a domain name is configured for this DLS.<br/> If applied from primary node, the same certificate will be applied to the secondary node(if any).`}
            >
              <Icon
                name='StatusCircleInformation'
                color={theme.colors?.brand}
              />
            </span>
            &nbsp;
          </StyledCheckbox>

          <Textbox
            id='domainName'
            name='domainName'
            label='Domain Name'
            placeholder='Domain name where the certificate will be installed'
            className='nvl'
            value={formik.values.domainName}
            disabled={installing || isWildcardCert}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            validationMessage={
              formik.touched.domainName && formik.errors.domainName
            }
          />

          <StyledFileSelect>
            <Text
              textStyle='label'
              color={theme.colors.checkbox?.foreground}
              htmlFor='cert-file'
            >
              Certificate
              <span
                data-for='file-tooltip'
                data-tip={`Specifies a file with the certificate in the PEM format for the given DLS server. <br/> If intermediate certificates should be specified in addition to a primary certificate, they should be specified in the same file in the following order: <br/> 1. The primary certificate for your domain name 2. Intermediate certificates 3. Root certificate.`}
                style={{
                  marginLeft: '4px',
                  verticalAlign: 'sub'
                }}
              >
                <Icon
                  name='StatusCircleInformation'
                  color={theme.colors.brand}
                />
              </span>
              &nbsp;
            </Text>
            <input
              type='file'
              id='cert-file'
              name='certFile'
              disabled={installing}
              accept='.pem'
              onChange={e => {
                getFileContents([...e.target.files], e, formik.setFieldValue)
              }}
              onBlur={formik.handleBlur}
              /* required*/
              /*value={values.certFile}*/
            />
            {formik.touched.certFile &&
              formik.errors &&
              formik.errors.certFile && (
                <div>
                  <Text
                    textStyle='label'
                    color={theme.colors.textbox?.validationMessage}
                  >
                    {formik.errors.certFile}
                  </Text>
                </div>
              )}
          </StyledFileSelect>

          <StyledFileSelect>
            <Text
              textStyle='label'
              color={theme.colors.checkbox?.foreground}
              htmlFor='cert-key-file'
            >
              Private Key
              <span
                data-for='file-tooltip'
                data-tip={`Specifies a file with the secret key in the PEM format for the given DLS server.`}
                style={{
                  marginLeft: '4px',
                  verticalAlign: 'sub'
                }}
              >
                <Icon
                  name='StatusCircleInformation'
                  color={theme.colors?.brand}
                />
              </span>
              &nbsp;
            </Text>
            <input
              type='file'
              id='cert-key-file'
              name='certKeyFile'
              disabled={installing}
              accept='.pem'
              onChange={e => {
                getFileContents([...e.target.files], e, formik.setFieldValue)
              }}
              onBlur={formik.handleBlur}
              /*  required*/
            />
            {formik.touched.certKeyFile && formik?.errors?.certKeyFile && (
              <div>
                <Text
                  textStyle='label'
                  color={theme.colors.textbox?.validationMessage}
                >
                  {formik.errors.certKeyFile}
                </Text>
              </div>
            )}
          </StyledFileSelect>

          <Buttons>
            {installing && (
              <span style={{ marginRight: '0.5rem' }}>
                <Spinner size='tiny' />
              </span>
            )}
            {sslCertsUploaded && (
              <Button
                onClick={() => {
                  setUpdateMode(false)
                }}
              >
                Cancel
              </Button>
            )}
            <Button
              icon={{ name: 'StatusShieldCheck' }}
              onClick={formik.handleSubmit}
              disabled={installing}
            >
              Configure
            </Button>
          </Buttons>
        </>
      )}
    </div>
  )
}
