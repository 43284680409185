import React from 'react'
import styled from 'styled-components'
import { Radio, Block, RadioGroup, Text } from '@kaizen-ui/complete'

const StyledBlock = styled.div`
  padding: 7px;
  > div {
    padding: 14px;
  }
`

export const LeasingPortSelector = ({
  leasingPortList,
  setSelectedLeasingPort,
  selectedLeasingPort,
  collapsible = false
}) => {
  return selectedLeasingPort ? (
    <StyledBlock>
      <Block
        title={`Port Number`}
        titleIcon={{
          name: 'ConnectionEthernetCable',
          size: 22
        }}
        onClick={e => {
          e.stopPropagation()
        }}
        collapsible={collapsible}
      >
        <Text textStyle={'p1'}>
          Selected port number will be used by the licensing clients to connect
          to the license server for leasing operations
        </Text>
        <div
          onClick={e => {
            e.stopPropagation()
          }}
          key={`sel-port-${selectedLeasingPort}`}
          style={{ marginTop: '1rem' }}
        >
          {/*RadioGroup requires id,name and selected as same names. Also, the parent of RadioGroup should have a key equal to selected radio value to be able to rerender when updated prop for selected arrives. Also, the value prop of RadioButtons needs to be a string to work as expected.*/}
          <RadioGroup
            inline={true}
            id={'selectedLeasingPort'}
            name='selectedLeasingPort'
            selected={selectedLeasingPort}
            onChange={e => {
              setSelectedLeasingPort(e.target.value)
            }}
          >
            {leasingPortList.map((portObj, index) => {
              return (
                <Radio
                  id={`port-number-${portObj.port}`}
                  label={portObj.port}
                  value={portObj.port}
                  key={index}
                />
              )
            })}
          </RadioGroup>
        </div>
      </Block>
    </StyledBlock>
  ) : null
}
