import React from 'react'
import { useAppNotifications, useFetch } from 'common'
import { API_CLUSTER_NODE_REMOVE } from '../../../api'
import Result from '@kaizen-ui/result'
import { SessionExpired } from '../../../Components'

export const MakeStandalone = ({ primaryNode, onUpdate }) => {
  const { notify } = useAppNotifications()
  const { fqdn, ip_v6_address, network_location } = primaryNode || {}
  const {
    getData: markStandaloneAPI,
    loading: markingStandalone,
    error: markStandaloneErr
  } = useFetch({
    endpoint: API_CLUSTER_NODE_REMOVE,
    actionLabel: 'Convert DLS to standalone setup',
    method: 'POST',
    responseOnly: true,
    SessionExpired: SessionExpired
  })

  const removeNode = async () => {
    const body = {
      nodeIp: '',
      retainQueue: false, //always to be passed as false from UI
      makeStandAlone: true // pass false for removing single node
    }
    const ok = await markStandaloneAPI({ body })
    if (ok) {
      notify(
        `All secondary nodes were successfully removed from the cluster!`,
        null,
        null,
        'Convert DLS setup to standalone mode'
      )
      onUpdate && onUpdate()
    }
  }

  return (
    <div>
      <Result
        status={
          markingStandalone ? 'loading' : markStandaloneErr ? 'error' : 'info'
        }
        title={
          markingStandalone
            ? 'Convert setup to standalone'
            : markStandaloneErr
            ? undefined
            : 'Are you sure?'
        }
        subTitle={
          markingStandalone
            ? ''
            : markStandaloneErr
            ? markStandaloneErr
            : `This will remove and cleanup all the secondary nodes from HA cluster. The primary node ${fqdn ||
                ip_v6_address ||
                network_location} would start functioning in standalone mode.`
        }
        actions={[
          {
            children: 'Make Standalone',
            onClick: removeNode,
            disabled: markingStandalone,
            type: 'critical',
            key: 0
          }
        ]}
      />
    </div>
  )
}
