import React, { useState, useGlobal, useContext } from 'reactn'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'

import Switch from '@kaizen-ui/switch'
import Button from '@kaizen-ui/button'
import Modal from '@kaizen-ui/modal'
import Text from '@kaizen-ui/text'
import Block from '@kaizen-ui/block'
import Textbox from '@kaizen-ui/textbox'
import Icon from '@kaizen-ui/icon'
import SidePanel from '@kaizen-ui/side-panel'
import { useHistory } from 'react-router'
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from '../AppRouter'
import { AppNotifications } from '../AppNotifications'
import { NvidiaLogo } from 'common'
import { useAuth } from '../../hooks'
import { USER_PERMISSIONS } from '../../api'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'
import { ChangeUsername } from './ChangeUsername'
import { ChangePassword } from './ChangePassword'
import { PendingReturnNotifier } from '..'

const StyledLogo = styled.div`
  padding: 14px;
  flex: 1;
  display: flex;

  > div {
    &:hover {
      cursor: pointer;
    }
  }
`
const Container = styled.div`
  background-color: ${props => props.theme.colors.componentBackground};
  display: flex;
  align-items: center;
  padding-right: 1rem;
  ${props =>
    !props.theme.darkMode &&
    `border-bottom: 1px solid ${props.theme.colors.lightGray300};`}
  box-shadow: ${props =>
    props.theme.darkMode
      ? 'rgb(0 0 0 / 40%) 0px 2px 3px 0px'
      : 'rgb(0 0 0 / 15%) 0px 2px 3px 0px'};
`
const Right = styled.div`
  display: flex;
  align-items: center;
`
const StyledSide = styled.div`
  .panel-content {
    flex: 1;
  }
`
const SideBack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(5, 5, 5, 0.2);
  backdrop-filter: blur(0.035rem);
  z-index: 101;
`
const SideSpacer = styled.div`
  background: ${props => props.theme.colors.brand};
  height: 1px;
  margin: 1rem 2rem;
`
/*
const StyledSwitch = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-top: 1rem;
    margin-left: 0;
  }
`
*/

export const Header = ({ setTheme, themeOptions, theme }) => {
  const history = useHistory()
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [changeUsernameOpen, setChangeUsernameOpen] = useState(false)
  const [changePasswordOpen, setChangePasswordOpen] = useState(false)
  const { user, signout } = useAuth()
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const [org] = useGlobal(GLOBAL_ORG)
  const [hasCredsChanged, setHasCredsChanged] = useState(false)

  return (
    <>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Change Username'
          subtitle={`Change the username used to login to this appliance`}
          open={changeUsernameOpen}
          onClose={() => {
            if (hasCredsChanged) {
              signout()
            }
            setChangeUsernameOpen(false)
          }}
        >
          {changeUsernameOpen && (
            <ChangeUsername
              setChangeUsernameOpen={setChangeUsernameOpen}
              setHasCredsChanged={setHasCredsChanged}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Change Password'
          subtitle={`Change the password used to login to this appliance`}
          open={changePasswordOpen}
          onClose={() => {
            if (hasCredsChanged) {
              signout()
            }
            setChangePasswordOpen(false)
          }}
        >
          {changePasswordOpen && (
            <ChangePassword
              setChangePasswordOpen={setChangePasswordOpen}
              setHasCredsChanged={setHasCredsChanged}
            />
          )}
        </Modal>
      </div>
      {settingsOpen && (
        <SideBack
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setSettingsOpen(false)
          }}
        />
      )}
      <StyledSide>
        <SidePanel
          onClose={() => setSettingsOpen(false)}
          title='Settings'
          open={settingsOpen}
          zIndex={102}
        >
          {user && (
            <>
              <UserPermissions
                openUsernameChange={() => {
                  setSettingsOpen(false)
                  setChangeUsernameOpen(true)
                }}
                openPasswordChange={() => {
                  setSettingsOpen(false)
                  setChangePasswordOpen(true)
                }}
              />
              <SideSpacer />
            </>
          )}
          <Switch
            id={'themeToggle'}
            label='Dark mode'
            onChange={e => {
              const { checked } = e.target
              if (checked) {
                setTheme(themeOptions[0])
                localStorage.setItem('nvl::theme', themeOptions[0].label)
              } else {
                setTheme(themeOptions[1])
                localStorage.setItem('nvl::theme', themeOptions[1].label)
              }
            }}
            checked={theme === themeOptions[0]}
          />
        </SidePanel>
      </StyledSide>
      <Container theme={theme.value}>
        <StyledLogo>
          <div onClick={() => history.push(ROUTE_DASHBOARD)}>
            <NvidiaLogo small light={theme.label === 'Dark'} />
          </div>
        </StyledLogo>
        <Right>
          <PendingReturnNotifier />
          <AppNotifications />
          <div data-tip='View settings'>
            <Button
              variant='link'
              type='secondary'
              icon={{ name: 'SettingsCogDouble1' }}
              onClick={() => setSettingsOpen(true)}
            >
              {user && (
                <UserContext
                  user={user}
                  org={org}
                  virtualGroup={virtualGroup}
                />
              )}
            </Button>
          </div>
          {user && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                icon={{
                  name: 'AccountCircleGenericUser'
                }}
                type='secondary'
                variant='link'
                onClick={() => {
                  history.push(ROUTE_LOGIN)
                  signout(() => {})
                }}
              >
                logout
              </Button>
            </div>
          )}
        </Right>
      </Container>
    </>
  )
}

const UserContext = ({ user, org, virtualGroup }) => {
  const theme = useContext(KaizenThemeContext)
  const { hideNameOnUI } = user
  return (
    <div
      style={{
        //maxWidth: '150px',
        display: 'flex',
        //flexDirection: 'column',
        lineHeight: '0.75rem',
        flexWrap: 'wrap'
      }}
    >
      <div
        style={{
          maxWidth: '150px',
          marginRight: '0.5rem'
        }}
      >
        <Text textStyle='p2' overflow='truncate'>
          {user?.clientId}
        </Text>
      </div>
      {hideNameOnUI ? null : (
        <>
          <div style={{ maxWidth: '150px' }}>
            <Text
              textStyle='p2'
              overflow='truncate'
              color={theme.colors.textbox.placeholder}
            >
              {org?.label}
            </Text>
          </div>
          <div style={{ maxWidth: '150px' }}>
            <Text
              textStyle='p2'
              overflow='truncate'
              color={theme.colors.textbox.placeholder}
            >
              {virtualGroup?.label}
            </Text>
          </div>
        </>
      )}
    </div>
  )
}

const StyledTable = styled.div`
  table {
    border-collapse: collapse;

    tr {
      td,
      th {
        padding: 0.5rem 1rem 0.5rem 0;
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.table.body.border};

        :first-child {
          width: 100%;
        }
      }

      th {
        text-transform: uppercase;
      }

      :last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
`

const UserPermissions = ({ openUsernameChange, openPasswordChange }) => {
  const { role, user } = useAuth()
  const { isLdapUser } = user || {}
  const theme = useContext(KaizenThemeContext)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const [org] = useGlobal(GLOBAL_ORG)
  const { hideNameOnUI } = user
  /* const { getData, loading, abort } = useFetch({
    endpoint: API_DIAG_USER,
    actionLabel: `Get DLS diagnostic user status`,
    SessionExpired: SessionExpired,
    supressToast: true
  })
  const { getData: enableDiagUser, loading: enabling } = useFetch({
    endpoint: API_DIAG_USER_ENABLE,
    actionLabel: 'Enable diagnostic user',
    method: 'PATCH'
  })
  const { getData: disableDiagUser, loading: disabling } = useFetch({
    endpoint: API_DIAG_USER_DISABLE,
    actionLabel: 'Disable diagnostic user',
    method: 'PATCH'
  })

  useEffect(() => {
    const getDiagUserStatus = async () => {
      const data = await getData()
      if (data && data.isEnabled) {
        setDiagEnabled(data.isEnabled)
      } else {
        setDiagEnabled(false)
      }
    }
    getDiagUserStatus()

    return () => {
      abort()
    }
  }, [refresh]) //eslint-disable-line

  const onEnable = async () => {
    const result = await enableDiagUser()
    if (result) {
      const msg = `Enabled diagnostic user successfully!`
      notify(msg, null, null, `Diagnostic user enabled`)
    }
    setRefresh(val => !val)
  }

  const onDisable = async () => {
    const result = await disableDiagUser()
    if (result) {
      const msg = `Disabled diagnostic user successfully!`
      notify(msg, null, null, `Diagnostic user disabled`)
    }
    setRefresh(val => !val)
  }
*/
  return (
    <>
      <Block title='My Info'>
        <div>
          <Text textStyle='label' color={theme.colors.textbox.placeholder}>
            Username
          </Text>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Text textStyle='h3' overflow='truncate'>
              {user.clientId}
            </Text>
            <div style={{ margin: '-0.5rem -0.75rem' }}>
              <Button
                variant='link'
                onClick={openUsernameChange}
                disabled={isLdapUser}
              >
                Change username
              </Button>
            </div>
          </span>
        </div>
        <div style={{ marginTop: '0.5rem' }}>
          <Text textStyle='label' color={theme.colors.textbox.placeholder}>
            Role
          </Text>

          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Text textStyle='h4' overflow='truncate'>
              {role?.replaceAll('_', ' ')}
            </Text>
            <div style={{ margin: '-0.5rem -0.75rem' }}>
              <Button
                variant='link'
                onClick={openPasswordChange}
                disabled={isLdapUser}
              >
                Change password
              </Button>
            </div>
          </span>
        </div>
        {org && !hideNameOnUI ? (
          <div style={{ marginTop: '1rem' }}>
            <>
              <Text textStyle='label' color={theme.colors.textbox?.placeholder}>
                Organization
              </Text>
              <div style={{ marginTop: '-1rem' }}>
                <Textbox value={org.label} disabled />
              </div>
              <Text textStyle='label' color={theme.colors.textbox?.placeholder}>
                Virtual Group
              </Text>
              <div style={{ marginTop: '-1rem' }}>
                <Textbox value={virtualGroup.label} disabled />
              </div>
            </>
          </div>
        ) : null}
        <div style={{ marginTop: '1rem' }}>
          <Text textStyle='label' color={theme.colors.textbox.placeholder}>
            Permissions
          </Text>
          <StyledTable theme={theme}>
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>Area</th>
                  <th>Create</th>
                  <th>Read</th>
                  <th>Update</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {/* <PermissionRow
                  //create={USER_PERMISSIONS.ENTITLEMENT_CREATE}
                  read={USER_PERMISSIONS.ENTITLEMENT_RETRIEVE}
                  name='Entitlements'
                /> */}
                <PermissionRow
                  create={USER_PERMISSIONS.LICENSE_SERVER_CREATE}
                  read={USER_PERMISSIONS.LICENSE_SERVER_RETRIEVE}
                  update={USER_PERMISSIONS.LICENSE_SERVER_UPDATE}
                  remove={USER_PERMISSIONS.LICENSE_SERVER_DELETE}
                  name='License Servers'
                />
                <PermissionRow
                  create={USER_PERMISSIONS.LICENSE_POOL_CREATE}
                  read={USER_PERMISSIONS.LICENSE_POOL_RETRIEVE}
                  update={USER_PERMISSIONS.LICENSE_POOL_UPDATE}
                  remove={USER_PERMISSIONS.LICENSE_POOL_DELETE}
                  name='License Pools'
                />
                <PermissionRow
                  create={USER_PERMISSIONS.ACCESS_GROUP_CREATE}
                  read={USER_PERMISSIONS.ACCESS_GROUP_RETRIEVE}
                  update={USER_PERMISSIONS.ACCESS_GROUP_UPDATE}
                  remove={USER_PERMISSIONS.ACCESS_GROUP_DELETE}
                  name='Fulfillment Conditions'
                />
                {/* <PermissionRow
                  create={USER_PERMISSIONS.VIRTUAL_GROUP_CREATE}
                  read={USER_PERMISSIONS.VIRTUAL_GROUP_RETRIEVE}
                  update={USER_PERMISSIONS.VIRTUAL_GROUP_UPDATE}
                  remove={USER_PERMISSIONS.VIRTUAL_GROUP_DELETE}
                  name='Virtual Groups'
                /> */}
                {/* <PermissionRow
                  create={USER_PERMISSIONS.USER_CREATE}
                  read={USER_PERMISSIONS.USER_RETRIEVE}
                  update={USER_PERMISSIONS.USER_CREATE}
                  remove={USER_PERMISSIONS.USER_DELETE}
                  name='Users'
                /> */}
                {/* <PermissionRow
                  read={USER_PERMISSIONS.SOFTWARE_DOWNLOAD}
                  name='Software Downloads'
                /> */}
              </tbody>
            </table>
          </StyledTable>
        </div>

        {/* {!isPureContainer && (
          <StyledSwitch data-tip='Enables/disables the user "dls_diagnostics", this can be used via the hypervisor console to access log files'>
            {!loading && (
              <Switch
                id={'diagToggle'}
                label={'Diagnostic user'}
                onChange={e => {
                  const { checked } = e.target
                  if (checked) {
                    setDiagEnabled(true)
                    onEnable()
                  } else {
                    setDiagEnabled(false)
                    onDisable()
                  }
                }}
                checked={diagEnabled}
              />
            )}
            {enabling ||
              disabling ||
              (loading && (
                <span style={{ margin: '0 0.5rem' }}>
                  <Spinner size='tiny' />
                </span>
              ))}
          </StyledSwitch>
        )}*/}
      </Block>
    </>
  )
}

const PermissionRow = ({ create, read, update, remove, name }) => {
  const { permissions } = useAuth()
  const theme = useContext(KaizenThemeContext)
  const canCreate = permissions.includes(create)
  const canRead = permissions.includes(read)
  const canUpdate = permissions.includes(update)
  const canDelete = permissions.includes(remove)

  return (
    <tr>
      <td>{name}</td>
      <td style={{ textAlign: 'center' }}>
        {create && (
          <Icon
            name={
              !create
                ? 'ActionsClose'
                : canCreate
                ? 'StatusCircleCheck2'
                : 'StatusCircleError'
            }
            color={
              !create
                ? theme.colors.textbox.placeholder
                : canCreate
                ? theme.colors.brand
                : theme.colors.textbox.validationMessage
            }
          />
        )}
      </td>
      <td style={{ textAlign: 'center' }}>
        {read && (
          <Icon
            name={
              !read
                ? 'ActionsClose'
                : canRead
                ? 'StatusCircleCheck2'
                : 'StatusCircleError'
            }
            color={
              !read
                ? theme.colors.textbox.placeholder
                : canRead
                ? theme.colors.brand
                : theme.colors.textbox.validationMessage
            }
          />
        )}
      </td>
      <td style={{ textAlign: 'center' }}>
        {update && (
          <Icon
            name={
              !update
                ? 'ActionsClose'
                : canUpdate
                ? 'StatusCircleCheck2'
                : 'StatusCircleError'
            }
            color={
              !update
                ? theme.colors.textbox.placeholder
                : canUpdate
                ? theme.colors.brand
                : theme.colors.textbox.validationMessage
            }
          />
        )}
      </td>
      <td style={{ textAlign: 'center' }}>
        {remove && (
          <Icon
            name={
              !remove
                ? 'ActionsClose'
                : canDelete
                ? 'StatusCircleCheck2'
                : 'StatusCircleError'
            }
            color={
              !remove
                ? theme.colors.textbox.placeholder
                : canDelete
                ? theme.colors.brand
                : theme.colors.textbox.validationMessage
            }
          />
        )}
      </td>
    </tr>
  )
}
