import React, { useContext } from 'react'
import ReactJson from 'react-json-view'
import { KaizenThemeContext } from '@kaizen-ui/styles'

export const Details = ({ data }) => {
  const theme = useContext(KaizenThemeContext)
  const noFeatures = !data || data.length === 0

  return noFeatures ? (
    <>no features</>
  ) : (
    <div style={{ fontSize: '1rem' }}>
      <ReactJson
        theme={theme.darkMode ? 'ashes' : 'rjv-default'}
        src={data}
        name={false}
        collapsed={1}
        displayDataTypes={false}
        enableClipboard={true}
      />
    </div>
  )
}
