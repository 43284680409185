import React, { useState } from 'reactn'
import styled from 'styled-components'
import { Button } from '@kaizen-ui/complete'
import Modal from '@kaizen-ui/modal'
import { MetricChangesTable } from './MetricChangesTable'

export const ChartLegend = ({ usageData }) => {
  const {
    cpuUsageMetricTotalValue, memoryUsageMetricTotalValue, diskUsageMetricTotalValue,
    memoryCapacity,
    cpuCapacity,
    diskCapacity
  } = usageData || {}

  const [isCPUOpen, setCPUOpen] = useState(false)
  const [isMemoryOpen, setMemoryOpen] = useState(false)
  const [isDiskOpen, setDiskOpen] = useState(false)
  return (
    <>
      <StyledLegendContainer>
        <StyledLegendItem> <StyledLegendSpan
          style={{ backgroundColor: 'green' }}>CPU</StyledLegendSpan> {cpuUsageMetricTotalValue} Cores
          <HistoryButton
            onClick={() => {
              setCPUOpen(true)
            }}
          />
        </StyledLegendItem>
        <StyledLegendItem> <StyledLegendSpan
          style={{ backgroundColor: 'blue' }}>Memory</StyledLegendSpan> {memoryUsageMetricTotalValue} GB
          <HistoryButton
            onClick={() => {
              setMemoryOpen(true)
            }}
          />
        </StyledLegendItem>
        <StyledLegendItem> <StyledLegendSpan
          style={{ backgroundColor: 'orange' }}>Disk</StyledLegendSpan> {diskUsageMetricTotalValue} GB
          <HistoryButton
            onClick={() => {
              setDiskOpen(true)
            }}
          /></StyledLegendItem>
      </StyledLegendContainer>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={`Changes in CPU capacity`}
          open={isCPUOpen}
          size='large'
          backgroundClose
          onClose={() => {
            setCPUOpen(false)
          }}
        >{isCPUOpen &&
          <MetricChangesTable capacities={cpuCapacity} label={'CPU Capacity'} metricUnit={'Cores'} />}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={`Changes in Memory capacity`}
          open={isMemoryOpen}
          size='large'
          backgroundClose
          onClose={() => {
            setMemoryOpen(false)
          }}
        >
          {isMemoryOpen &&
            <MetricChangesTable capacities={memoryCapacity} label={'Memory Capacity'} metricUnit={'GB'} />}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={`Changes in Disk capacity`}
          open={isDiskOpen}
          size='large'
          backgroundClose
          onClose={() => {
            setDiskOpen(false)
          }}
        >{isDiskOpen &&
          <MetricChangesTable capacities={diskCapacity} label={'Disk Capacity'} metricUnit={'GB'} />}
        </Modal>
      </div>
    </>
  )
}

const StyledLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const StyledLegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

`

const StyledLegendSpan = styled.span`
  display: inline-block;
  width: max-content;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  margin-right: 5px;
  color: white;
`

const HistoryButton = ({
                         onClick = () => {
                         }
                       }) => {
  return (
    <span> <Button
      icon={{
        name: 'TimeHistory',
        variant: 'solid'
      }}
      onClick={onClick}
      shape='circle'
      size='small'
      //tag="button"
      type='info'
      variant='link'
      width='fit-content'
    >
    Circle Button
  </Button></span>
  )
}
