import React, { useGlobal } from 'reactn'
import { useHistory } from 'react-router-dom'
import {
  GLOBAL_ORG,
  GLOBAL_SERVER_ID,
  GLOBAL_USER,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'
import Text from '@kaizen-ui/text'
//import { StyledBanner } from '../../Components'
//import { FloatingSpinner } from '../FloatingSpinner'
import { ROUTE_DASHBOARD } from '../AppRouter'
//import { useAppNotifications } from '../../hooks'
import { theme } from 'common'
import { useAuth } from '../../hooks'
import { ROUTE_SETUP } from '../AppRouter'

export const RequireOrgId = ({ children }) => {
  const [org] = useGlobal(GLOBAL_ORG)

  return org ? (
    children
  ) : (
    <div status='info' title='No organization selected'>
      <h4>Please select an organization from the dropdown above</h4>
    </div>
  )
}

export const RequireVirtualGroupId = ({ children }) => {
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)

  return virtualGroup ? (
    children
  ) : (
    <div status='info' title='No virtual group selected'>
      <h4>Please select a virtual group from the dropdown above</h4>
    </div>
  )
}

export const RequireOrgAndVirtualGroupId = ({ children, interactive }) => {
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)

  if (interactive && !org)
    return (
      <div title='No organization selected' color={theme.colors.greenPrimary}>
        <h4>Please select an organization from the dropdown above</h4>
      </div>
    )

  if (interactive && !virtualGroup)
    return (
      <div title='No virtual group selected'>
        <h4>Please select a virtual group from the dropdown above</h4>
      </div>
    )

  if (org && virtualGroup) return children

  //return <FloatingSpinner visible />
  return <div>loading</div>
}

export const RequireServerId = ({ children }) => {
  const [serverId] = useGlobal(GLOBAL_SERVER_ID)

  if (serverId) return children

  return <></>
}

export const RequireSetup = ({ children }) => {
  const auth = useAuth()
  const history = useHistory()

  React.useEffect(() => {
    if (auth.setupRequired) {
      history.replace(ROUTE_SETUP)
    }
  }, [auth]) //eslint-disable-line react-hooks/exhaustive-deps

  if (!auth.setupRequired) {
    return children
  } else {
    return <></>
  }
}

export const RequirePendingSetup = ({ children }) => {
  const auth = useAuth()
  const history = useHistory()

  React.useEffect(() => {
    if (!auth.setupRequired) {
      history.replace(ROUTE_DASHBOARD)
    }
  }, [auth]) //eslint-disable-line react-hooks/exhaustive-deps

  if (auth.setupRequired) {
    return children
  } else {
    return <></>
  }
}

export const RequireUserAction = ({ children, requiredAction }) => {
  //const { handlePagePermission } = useAppNotifications()
  const [user] = useGlobal(GLOBAL_USER)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const history = useHistory()
  const vgId = virtualGroup && virtualGroup.id
  const isNvAdmin = user && user.orgName === '*'
  const permissions = !user
    ? []
    : isNvAdmin
    ? user.userScope[0].actions
    : user.userScope.find(group => group.virtualGroupId === vgId).actions
  const canView = permissions.includes(requiredAction)

  React.useEffect(() => {
    if (!canView) {
      //handlePagePermission()
      history.push(ROUTE_DASHBOARD)
    }
  }, [canView]) //eslint-disable-line react-hooks/exhaustive-deps

  return canView ? (
    children
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Text textStyle='h1'>You do not have permissions to view this page</Text>
    </div>
  )
}
