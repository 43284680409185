import React from 'react'
import styled from 'styled-components'
import { formatDateTime, Pill } from 'common'

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const FlexeraProperties = ({ server }) => {
  const {
    type,
    createdAt,
    description,
    lastUpdatedAt,
    macAddresses,
    failoverServerName
  } = server

  const macAddress = macAddresses && macAddresses.split(',')[0]
  const failoverMac =
    (macAddresses &&
      macAddresses.indexOf(',') > -1 &&
      macAddresses.split(',')[1]) ||
    'n/a'

  return (
    <div style={{ margin: '0.5rem -0.25rem' }}>
      <Row>
        <Pill label='Type' value={type} />
        <Pill label='MAC Address' value={macAddress} />
        <Pill label='Created' value={formatDateTime(createdAt)} />
        <Pill label='Modified' value={formatDateTime(lastUpdatedAt)} />
      </Row>
      <Row>
        <Pill label='Failover Server' value={failoverServerName || 'n/a'} />
        <Pill label='Failover MAC Address' value={failoverMac} />
      </Row>
      <Row>
        <Pill label='Description' value={description} />
      </Row>
    </div>
  )
}
