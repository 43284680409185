"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DurationSelector", {
  enumerable: true,
  get: function get() {
    return _DurationSelector.DurationSelector;
  }
});

var _DurationSelector = require("./DurationSelector");