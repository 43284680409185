"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useFetch", {
  enumerable: true,
  get: function get() {
    return _useFetch.useFetch;
  }
});

var _useFetch = require("./useFetch");