import React, { useMemo, useContext } from 'react'
import { Table, InlineButton } from 'common'
import { KaizenThemeContext } from '@kaizen-ui/styles'

export const PreviewManagePoolFeatures = ({ formik }) => {
  const theme = useContext(KaizenThemeContext)
  const changes = useMemo(() => {
    const { removals, adjustments, features } = formik.values

    const results = []
    removals.forEach(removal => {
      results.push({
        ...removal,
        displayLabel: removal.feature.displayLabel,
        emsProductKeyId: removal.feature.emsProductKeyId,
        changeType: 'Remove',
        delta: removal.feature.isCardinal ? removal.totalAllotment : 'n/a'
      })
    })
    features.forEach(feature => {
      results.push({
        ...feature,
        changeType: 'Add',
        delta: feature.isCardinal ? feature.addCount : 'n/a'
      })
    })
    adjustments.forEach(adjustment => {
      results.push({
        ...adjustment,
        displayLabel: adjustment.feature.displayLabel,
        emsProductKeyId: adjustment.feature.emsProductKeyId,
        delta: adjustment.feature.isCardinal
          ? adjustment.addCount - adjustment.totalAllotment
          : 'n/a',
        changeType:
          adjustment.totalAllotment > adjustment.addCount
            ? 'Decrease'
            : 'Increase'
      })
    })
    return results
  }, [formik.values])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'displayLabel',
        hidden: false
      },
      {
        Header: 'Product Key Id',
        accessor: 'emsProductKeyId'
      },
      {
        Header: 'Delta',
        accessor: 'delta',
        align: 'right',
        canResize: false,
        Cell: ({ row }) => {
          const { changeType, delta, isCardinal, feature } = row.original
          const isRemoval = changeType === 'Remove'
          const isDecrease = changeType === 'Decrease'

          const styles =
            isCardinal || feature.isCardinal
              ? {
                  color:
                    isRemoval || isDecrease
                      ? theme.colors.red500
                      : theme.colors.brand
                }
              : {}
          return <span style={styles}>{delta}</span>
        }
      },
      {
        Header: 'Type',
        accessor: 'changeType',
        canResize: false,
        Cell: ({ row }) => {
          const { changeType, id } = row.original
          const isRemoval = changeType === 'Remove'
          const isAddition = changeType === 'Add'
          const isIncrease = changeType === 'Increase'
          const isDecrease = changeType === 'Decrease'

          return (
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  marginRight: '0.5rem',
                  flex: 1,
                  color:
                    isRemoval || isDecrease
                      ? theme.colors.red500
                      : theme.colors.brand
                }}
              >
                {changeType}
              </span>
              <InlineButton
                icon={{
                  name: 'ActionsUndo',
                  color: theme.colors.table.body.foreground
                }}
                variant='link'
                tip='Undo this change'
                onClick={() => {
                  if (isRemoval) {
                    const rest = formik.values.removals.filter(e => e.id !== id)
                    formik.setFieldValue('removals', [...rest])
                  } else if (isAddition) {
                    const rest = formik.values.features.filter(e => e.id !== id)
                    formik.setFieldValue('features', [...rest])
                  } else if (isIncrease || isDecrease) {
                    const rest = formik.values.adjustments.filter(
                      e => e.id !== id
                    )
                    formik.setFieldValue('adjustments', [...rest])
                  }
                }}
              />
            </div>
          )
        }
      }
    ],
    [formik, theme]
  )

  return (
    <>
      <Table
        columns={columns}
        data={changes}
        label={'changes'}
        disableColumnHiding
        disableExporting
        initialPageSize={5}
        minHeight={1}
      />
    </>
  )
}
