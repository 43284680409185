import React, {
  useGlobal,
  useContext,
  useState,
  useEffect,
  useMemo
} from 'reactn'
import styled from 'styled-components'
import Tag from '@kaizen-ui/tag'
import Text from '@kaizen-ui/text'
import Switch from '@kaizen-ui/switch'
import ReactTooltip from 'react-tooltip'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import {
  GLOBAL_IS_UPGRADE_IN_PROGRESS,
  GLOBAL_OFFLINE_UPGRADE_TRIGGERED,
  GLOBAL_UPGRADE_STATUS,
  GLOBAL_VA_VERSION
} from '../../globalState'

import { InPlaceUpgrade } from './InPlaceUpgrade'
import { OfflineUpgrade } from './OfflineUpgrade'
import {
  HIGH_AVAILABILITY_STATE,
  UPGRADE_MODE,
  UPGRADE_STATUS
} from '../../api'

export const BlockSection = styled.div`
  margin: 0;
`
export const Step = styled.span`
  flex-shrink: 0;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.textbox.placeholder};
  font-size: 1.2rem;
`
export const BlockTitle = styled.h3`
  display: flex;
  align-items: center;
`
export const BlockContents = styled.div`
  font-size: 0.75rem;

  ${BlockSection}+${BlockSection} {
    margin-top: 2rem;
  }

  .kaizen-button.link {
    padding: 0;
  }
`

export const Upgrade = ({ serviceInstance, loadingSI, refreshSI }) => {
  const theme = useContext(KaizenThemeContext)
  const [version] = useGlobal(GLOBAL_VA_VERSION)
  const [upgradeStatus] = useGlobal(GLOBAL_UPGRADE_STATUS)
  const [isUpgradeInProgress] = useGlobal(GLOBAL_IS_UPGRADE_IN_PROGRESS)
  const { mode } = upgradeStatus || {}
  const isInPlaceUpgradeInProgress =
    isUpgradeInProgress && mode === UPGRADE_MODE.INPLACE
  const isPortalAssistedUpgradeInProgress =
    isUpgradeInProgress && mode === UPGRADE_MODE.OFFLINE

  //this flag is from /me from old upgrade flow, must be deprecated
  const [isOfflineUpgradeTriggered] = useGlobal(
    GLOBAL_OFFLINE_UPGRADE_TRIGGERED
  )

  //boolean for toggle
  const [portalAssistedInPro, setPortalAssistedInPro] = useState(
    isPortalAssistedUpgradeInProgress || isOfflineUpgradeTriggered
  )

  useEffect(() => {
    setPortalAssistedInPro(
      isPortalAssistedUpgradeInProgress || isOfflineUpgradeTriggered
    )
  }, [isOfflineUpgradeTriggered, isPortalAssistedUpgradeInProgress])

  const isStandalone = useMemo(() => {
    return (
      serviceInstance?.high_availability_config?.mode?.toLowerCase() ===
      HIGH_AVAILABILITY_STATE.STANDALONE.toLowerCase()
    )
  }, [serviceInstance?.high_availability_config?.mode])

  const noUpgradeJobPresent = useMemo(() => {
    return (
      !!!upgradeStatus?.state ||
      upgradeStatus?.state === UPGRADE_STATUS.COMPLETED
    )
  }, [upgradeStatus?.state])

  //different offline upgrade steps if standalone upgrade
  const isStandaloneUpgrade = useMemo(() => {
    return isStandalone && noUpgradeJobPresent
  }, [isStandalone, noUpgradeJobPresent])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  return (
    <Text>
      <h4>
        <span style={{ marginRight: '0.5rem' }}>
          NVIDIA Delegated License System:
        </span>
        <Tag
          clickable={false}
          color='green'
          variant={theme.darkMode ? 'outline' : 'solid'}
        >
          {version}
        </Tag>
      </h4>
      <div style={{ marginLeft: '-1rem', marginTop: '-1rem' }}>
        <span
          data-tip={
            isInPlaceUpgradeInProgress
              ? 'Cannot switch to portal assisted upgrade if in-place upgrade is in progress.'
              : isPortalAssistedUpgradeInProgress ||
                (isOfflineUpgradeTriggered && isStandaloneUpgrade)
              ? 'Cannot switch to in-place upgrade if portal assisted is in progress.'
              : null
          }
        >
          <Switch
            id={'upgradeToggle'}
            checked={portalAssistedInPro}
            onChange={() => setPortalAssistedInPro(val => !val)}
            label='Portal Assisted Upgrade'
            disabled={
              isUpgradeInProgress ||
              (isOfflineUpgradeTriggered && isStandaloneUpgrade)
            }
          />
        </span>
      </div>
      {portalAssistedInPro ? (
        <OfflineUpgrade
          serviceInstance={serviceInstance}
          refreshSI={refreshSI}
          loadingSI={loadingSI}
          isPortalAssistedUpgradeInProgress={isPortalAssistedUpgradeInProgress}
          isStandaloneUpgrade={isStandaloneUpgrade}
        />
      ) : (
        <InPlaceUpgrade />
      )}
    </Text>
  )
}
