import React, { useContext, useGlobal, useMemo, useState } from 'reactn'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import { BlockContents, BlockSection, BlockTitle, Step } from './Upgrade'
import {
  GLOBAL_IS_UPGRADE_IN_PROGRESS,
  GLOBAL_UPGRADE_STATUS,
  GLOBAL_UPGRADE_STATUS_LOADING,
  GLOBAL_UPGRADE_STATUS_REFRESH
} from '../../globalState'
import { Button, Text } from '@kaizen-ui/complete'
import Modal from '@kaizen-ui/modal'
import { StartInPlaceUpgrade } from './StartInPlaceUpgrade'
import styled from 'styled-components'
import { useNodeRole } from '../../hooks/useNodeRole'
import { Table, useAppNotifications, useFetch } from 'common'
import Icon from '@kaizen-ui/icon'
import { API_UPGRADE_STATUS, UPGRADE_STATUS } from '../../api'
import { SessionExpired } from '../../Components'
import { LiteSpinner } from 'common'
const Buttons = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  /*  button {
    margin-left: 1rem;
  }*/
`
const Capitalize = styled.div`
  text-transform: capitalize;
`

const NodeList = ({ nodes }) => {
  const theme = useContext(KaizenThemeContext)
  const [, setRefresh] = useGlobal(GLOBAL_UPGRADE_STATUS_REFRESH)
  const nodeListColumns = useMemo(
    () => [
      {
        Header: 'Fully qualified domain name',
        accessor: 'fqdn',
        disableFilters: true
      },
      {
        Header: 'IPv6 address',
        accessor: 'ipV6Adress',
        disableFilters: true
      },
      {
        Header: 'IPv4 address',
        accessor: 'networkLocation',
        disableFilters: true
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        Cell: ({ row }) => {
          const { status } = row.original
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                name={
                  status === 'up' ? 'StatusCircleCheck1' : 'StatusCircleError'
                }
                color={
                  status === 'up'
                    ? theme.colors.brand
                    : theme.colors.textbox.validationMessage
                }
                variant='solid'
              />
              <div style={{ width: '0.25rem' }} />
              <Capitalize
                style={{
                  color:
                    status === 'up'
                      ? theme.colors.brand
                      : theme.colors.textbox.validationMessage
                }}
              >
                {status === 'up' ? 'Active' : 'Down'}
              </Capitalize>
            </div>
          )
        }
      },
      {
        Header: 'Node Version',
        accessor: 'version',
        disableFilters: true
      }
    ],
    [theme.colors.brand, theme.colors.textbox.validationMessage]
  )
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: '0.5rem'
        }}
      >
        <Text textStyle={'h3'}>Nodes in the DLS Cluster</Text>
        <div style={{ marginLeft: '1rem' }}>
          <Button
            type='secondary'
            variant={'link'}
            onClick={() => setRefresh(val => !val)}
            icon={{ name: 'CloudRefresh' }}
          >
            Refresh Node List
          </Button>
        </div>
      </div>
      <Table
        columns={nodeListColumns}
        data={nodes || []}
        minHeight={'50px'}
        dataId={'id'}
        label={'Nodes'}
        initialPageSize={10}
        disablePagination
        disablePageSizeSelection
        disableFiltering
        disableSorting
        disableExporting
        disableSearch
        disableColumnHiding
      />
    </>
  )
}

export const InPlaceUpgrade = () => {
  const theme = useContext(KaizenThemeContext)
  const [upgradeStatus] = useGlobal(GLOBAL_UPGRADE_STATUS)
  const [isUpgradeInProgress] = useGlobal(GLOBAL_IS_UPGRADE_IN_PROGRESS)
  const [, setRefresh] = useGlobal(GLOBAL_UPGRADE_STATUS_REFRESH)
  const [upgradeStatusLoading] = useGlobal(GLOBAL_UPGRADE_STATUS_LOADING)
  /*console.log('upgradeStatusLoading : ', upgradeStatusLoading)*/
  const [triggerUpgradeOpen, setTriggerUpgradeOpen] = useState(false)

  const { isPrimary } = useNodeRole()
  /*  const { nodeRole, isPrimary, isSecondary } = useNodeRole()*/

  const nodes = useMemo(() => {
    return upgradeStatus?.cluster?.nodes?.map((node, index) => ({
      ...node,
      id: `node-${index}`
    }))
  }, [upgradeStatus?.cluster?.nodes])

  const allNodesUp = useMemo(() => {
    return upgradeStatus?.cluster?.nodes?.every(node => node?.status === 'up')
  }, [upgradeStatus?.cluster?.nodes])

  const { getData: exitUpgrade, loading: exiting } = useFetch({
    endpoint: API_UPGRADE_STATUS,
    actionLabel: 'Start In-Place Upgrade',
    SessionExpired: SessionExpired,
    method: 'DELETE',
    responseOnly: true
  })
  const { notify } = useAppNotifications()

  const exitUpgradeProcess = async () => {
    const result = await exitUpgrade()
    if (result) {
      const msg = `In-place upgrade exited successfully!`
      notify(msg, null, null, 'Exit In-Place Upgrade')
      setRefresh(val => !val)
    }
  }

  return (
    <BlockContents>
      <BlockSection>
        <BlockTitle theme={theme}>
          <Step>Step 1 -</Step>
          Trigger In-Place Upgrade
        </BlockTitle>
        <div style={{ marginBottom: '1rem' }}>
          <Text textStyle={'p1'}>
            Triggering in-place upgrade will put all nodes in the cluster in
            maintenance mode disabling everything except leasing operations.
            In-place upgrade can be triggered from primary node only.
          </Text>
        </div>

        {isPrimary && (
          <Buttons>
            <div
              data-tip={`${
                isUpgradeInProgress
                  ? 'Upgrade is in progress'
                  : 'Trigger in place upgrade'
              }`}
              /*  key={`trigger-${isPrimary + '-' + upgradeInProgress}`}*/
            >
              <Button
                onClick={() => setTriggerUpgradeOpen(true)}
                disabled={
                  upgradeStatusLoading || isUpgradeInProgress || !isPrimary
                }
              >
                Start In-Place Upgrade
              </Button>
            </div>

            {upgradeStatus?.state === UPGRADE_STATUS.INITIATED && allNodesUp ? (
              <>
                <div
                  data-tip={
                    'Exiting the upgrade process will take all nodes in the cluster out of maintenance mode, allowing modifications to be made to the DLS nodes.'
                  }
                  style={{ marginLeft: '1rem' }}
                >
                  <Button
                    onClick={exitUpgradeProcess}
                    type={'secondary'}
                    disabled={exiting}
                  >
                    Exit Upgrade Process
                  </Button>
                </div>
                {exiting && (
                  <div style={{ marginLeft: '1rem' }}>
                    <LiteSpinner />
                  </div>
                )}
              </>
            ) : null}
          </Buttons>
        )}

        {nodes?.length && (
          <>
            <br />
            <NodeList nodes={nodes} />
          </>
        )}
      </BlockSection>
      <BlockSection>
        <BlockTitle theme={theme}>
          <Step>Step 2 -</Step>
          Download latest DLS container zip artifact
        </BlockTitle>
        <div style={{ marginBottom: '1rem' }}>
          <Text textStyle={'p1'}>
            Login to NVIDIA Licensing Portal and download latest DLS container
            artifact from Software Downloads page.
          </Text>
        </div>
      </BlockSection>
      <BlockSection>
        <BlockTitle theme={theme}>
          <Step>Step 3 -</Step>
          Install downloaded DLS container artifact on each node in the cluster
        </BlockTitle>
        <div style={{ marginBottom: '1rem' }}>
          <Text textStyle={'p1'}>
            To upgrade the DLS container artifact on every node in the cluster,
            please refer to the NLS docs and follow the steps provided. After
            the upgrade is completed on all nodes, the upgrade process will be
            marked as complete and the maintenance mode will be exited.
          </Text>
        </div>
        <Text textStyle={'h4'}>
          Note : After upgrading at least one node, you can begin serving leases
          from the upgraded node by utilizing the "Mark As Primary" feature
          found on the Service Instance page of the upgraded DLS Node.
        </Text>
      </BlockSection>
      <div onClick={e => e.stopPropagation()}>
        {triggerUpgradeOpen && (
          <Modal
            title={'Start In-Place Upgrade'}
            open={triggerUpgradeOpen}
            size={'large'}
            onClose={() => {
              setTriggerUpgradeOpen(false)
            }}
          >
            <StartInPlaceUpgrade
              onUpdate={() => {
                setTriggerUpgradeOpen(false)
                setRefresh(val => !val)
              }}
            />
          </Modal>
        )}
      </div>
    </BlockContents>
  )
}
