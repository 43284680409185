import React, {
  useMemo,
  useContext,
  useEffect,
  useCallback,
  useState
} from 'react'
import ReactTooltip from 'react-tooltip'
import queryString from 'query-string'
import { SelectColumnFilter, Table } from 'common'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Icon from '@kaizen-ui/icon'
import Button from '@kaizen-ui/button'
import Modal from '@kaizen-ui/modal'
import ActionMenu, { ActionMenuItem } from '@kaizen-ui/action-menu'
import { PoolDetails } from './PoolDetails'
import { ROUTE_DASHBOARD } from '../../Components'
import { useHistory } from 'react-router-dom'
import { LS_STATUS_DISABLED, LS_STATUS_ENABLED } from '../../api'
import { ManagePoolFeatures } from './ManagePoolFeatures'
import { PoolStatus } from './PoolStatus'
import { SplitPoolFeatures } from './SplitPoolFeatures'
import { DeleteLicensePool } from './DeleteLicensePool'

export const LicensePools = ({
  server,
  onFilter,
  search,
  refresh,
  lastUpdate
}) => {
  const { licensePools, id, status } = server
  const theme = useContext(KaizenThemeContext)
  const history = useHistory()
  const { globalFilter, filters } = queryString.parse(window.location.search)
  const [filter, setFilter] = useState({ globalFilter, filters })

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }) => {
          const { name, isUnbound } = row.values
          const unbound = isUnbound === 'True'
          return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {name}
              {unbound && (
                <span
                  data-tip='This license pool is not bound to any fulfillment condition'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '0.5rem'
                  }}
                >
                  <Icon
                    name='AwardsFlag1'
                    color={theme.colors.orange500}
                    //size='large'
                    variant='solid'
                  />
                </span>
              )}
            </span>
          )
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectColumnFilter,
        Cell: ({ row }) => {
          const { status } = row.original
          const serverDisabled = server.status === LS_STATUS_DISABLED
          const disabled = status === LS_STATUS_DISABLED
          const style = serverDisabled
            ? {
                style: { color: theme.colors.textbox.placeholder },
                'data-tip':
                  'This server is in edit mode, it will not serve licenses'
              }
            : disabled
            ? {
                style: { color: theme.colors.red500 },
                'data-tip':
                  'This pool is in edit mode, it will not serve licenses'
              }
            : { style: {} }
          return <span {...style}>{status}</span>
        }
      },
      {
        Header: 'Unbound',
        accessor: 'isUnbound',
        hidden: true,
        Filter: SelectColumnFilter
      }
    ],
    [theme, server]
  )

  useEffect(() => {
    const { globalFilter, filters } = filter

    if (globalFilter || filters) {
      const parameters = {
        globalFilter,
        filters:
          typeof filters === 'string'
            ? filters
            : !filters || filters.length === 0
            ? undefined
            : queryString.stringify(
                filters.reduce(
                  (obj, item) => Object.assign(obj, { [item.id]: item.value }),
                  {}
                )
              )
      }
      const route = `${ROUTE_DASHBOARD}?tab=3&${queryString.stringify(
        parameters
      )}`
      history.replace(route)
    } else {
      const route = `${ROUTE_DASHBOARD}?tab=3`
      history.replace(route)
    }
  }, [filter]) //eslint-disable-line react-hooks/exhaustive-deps

  const rowDetails = useCallback(
    ({ row }) => {
      const { licensePoolFeatures, id: poolId } = row.original
      return (
        <PoolDetails
          licensePoolFeatures={licensePoolFeatures}
          poolId={poolId}
          serverId={id}
        />
      )
    },
    [id]
  )

  const rowActions = useCallback(
    (row, related) => (
      <PoolActions
        pool={row.original}
        related={related}
        onUpdate={refresh}
        //server={server}
      />
    ),
    [refresh]
  )

  const [userFilters] = React.useState(
    Object.entries(queryString.parse(filter?.filters)).map(f => {
      return { id: f[0], value: f[1] }
    })
  )

  return (
    <>
      <Table
        columns={columns}
        data={licensePools}
        renderRowSubComponent={rowDetails}
        label={'license pools'}
        onFilter={setFilter}
        globalSearch={globalFilter}
        refresh={refresh}
        lastUpdate={lastUpdate}
        relatedData={{
          serverStatus: status,
          canSplit: licensePools.length > 1,
          server
        }}
        rowActions={rowActions}
        filters={userFilters}
      />
    </>
  )
}

const PoolActions = ({ pool, related, onUpdate }) => {
  const theme = useContext(KaizenThemeContext)
  const [statusOpen, setStatusOpen] = useState(false)
  const [manageFeaturesOpen, setManageFeaturesOpen] = useState(false)
  const [splitOpen, setSplitOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { status, licensePoolFeatures } = pool
  const { serverStatus, canSplit: poolsAvailable, server } = related
  const allowActions =
    serverStatus === LS_STATUS_DISABLED ||
    (serverStatus === LS_STATUS_ENABLED && status === LS_STATUS_DISABLED)
  const isEnabled = status === LS_STATUS_ENABLED
  const canSplit =
    poolsAvailable &&
    licensePoolFeatures.map(pf => pf.feature).filter(f => f.isCardinal).length >
      0

  return (
    <>
      <ActionMenu
        width={190}
        parentElement={
          <div
            data-tip={
              allowActions
                ? 'Select actions available for this license pool'
                : 'Cannot make changes to this pool while it is enabled'
            }
          >
            <Button
              icon={{
                name: 'ActionsDrawer',
                color: theme.colors.brand
              }}
              type='secondary'
              variant='link'
              className='bright'
              //disabled={!allowActions}
            >
              Actions
            </Button>
          </div>
        }
        position='top-right'
        trigger='click'
      >
        <React.Fragment key='.0'>
          {/* <ActionMenuItem label='Edit' icon={{ name: 'ActionsEdit' }} disabled={!allowActions} /> */}
          <ActionMenuItem
            label={isEnabled ? 'Disable' : 'Enable'}
            icon={{
              name: isEnabled ? 'PlaybackCirclePause' : 'PlaybackCirclePlay'
            }}
            onClick={() => setStatusOpen(true)}
          />
          <ActionMenuItem
            label='Manage features'
            icon={{ name: 'ServerEdit' }}
            disabled={!allowActions}
            onClick={() => setManageFeaturesOpen(true)}
          />
          <ActionMenuItem
            label='Split or merge'
            icon={{ name: 'ServerEdit' }}
            disabled={!allowActions || !canSplit}
            onClick={() => setSplitOpen(true)}
          />
          <ActionMenuItem
            label='Delete'
            itemStyle='critical'
            icon={{ name: 'ActionsTrash', variant: 'solid' }}
            disabled={!allowActions}
            onClick={() => setDeleteOpen(true)}
          />
        </React.Fragment>
      </ActionMenu>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={isEnabled ? 'Disable License Pool' : 'Enable License Pool'}
          subtitle='Toggle the status of this license pool'
          className='noScroll custom'
          open={statusOpen}
          onClose={() => setStatusOpen(false)}
        >
          {statusOpen && (
            <PoolStatus
              pool={pool}
              server={server}
              onUpdate={() => {
                setStatusOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Manage Pool Features'
          subtitle='Add features from this server, modify or remove existing features from this pool'
          //className='noScroll custom'
          size='large'
          backgroundClose
          open={manageFeaturesOpen}
          onClose={() => setManageFeaturesOpen(false)}
        >
          {manageFeaturesOpen && (
            <ManagePoolFeatures
              pool={pool}
              server={server}
              onUpdate={() => {
                setManageFeaturesOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Split / Merge Pool Features'
          subtitle='Merge this pool with another, or move some features to another pool'
          //className='noScroll custom'
          size='large'
          backgroundClose
          open={splitOpen}
          onClose={() => setSplitOpen(false)}
        >
          {splitOpen && (
            <SplitPoolFeatures
              pool={pool}
              server={server}
              onUpdate={() => {
                setSplitOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={'Delete License Pool'}
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          className='autow'
        >
          {deleteOpen && (
            <DeleteLicensePool
              pool={pool}
              server={server}
              onUpdate={() => {
                setDeleteOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
    </>
  )
}
