"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Wizard", {
  enumerable: true,
  get: function get() {
    return _Wizard.Wizard;
  }
});

var _Wizard = require("./Wizard");