import React, { useContext, useState } from 'react'
import ActionMenu, {
  ActionMenuItem,
  ActionMenuDivider
} from '@kaizen-ui/action-menu'
import Button from '@kaizen-ui/button'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Modal from '@kaizen-ui/modal'
import {
  SERVER_DEPLOY_STATUS,
  LS_STATUS_ENABLED,
  LS_TYPE_NVIDIA,
  LS_LEASING_MODES
} from '../../api'
import { useHistory } from 'react-router-dom'
import { ROUTE_LICENSE_SERVER_DETAILS } from '../AppRouter'
import { ServerStatus } from './ServerStatus'
import { ManageFeatures } from './ManageFeatures'
import { CreatePool } from './CreatePool'
import { CreateCondition } from './CreateCondition'
import { GenerateClientToken } from '..'
import { UpdateServer } from './UpdateServer'
import { ToggleSimple } from './ToggleSimple'
import { GenerateNll } from './GenerateNll'
import { ServerSettings } from './ServerSettings'
import ReactTooltip from 'react-tooltip'

export const LicenseServerActions = ({
  server,
  detailed,
  serviceInstance,
  onUpdate,
  onClose,
  hideMenu = false,
  statusOpen: defaultStatusOpen = false
}) => {
  const theme = useContext(KaizenThemeContext)
  const history = useHistory()
  const {
    deployedOn,
    type,
    id,
    //serviceInstanceType,
    serviceInstanceId,
    status,
    leasingMode
  } = server
  //const isFlex = type === LS_TYPE_FLEXERA
  const isNvidia = type === LS_TYPE_NVIDIA
  //const deployedCloud = deployedOn === SERVER_DEPLOY_STATUS.CLOUD
  //const deployedOnPrem = deployedOn === SERVER_DEPLOY_STATUS.DLS
  const deployedNone = deployedOn === SERVER_DEPLOY_STATUS.NOT_DEPLOYED
  //const isOnDLS = deployedOn === SERVER_DEPLOY_STATUS.NA
  const isEnabled = status === LS_STATUS_ENABLED
  //const unbound = !serviceInstanceId
  //const cloudPending = serviceInstanceType === 'Cloud' && deployedNone
  //const boundToOnPrem = serviceInstanceType?.toLowerCase() === 'on-prem'
  //const boundToCloud = serviceInstanceType?.toLowerCase() === 'cloud'

  const [nllOpen, setNllOpen] = useState(false)
  const [simpleOpen, setSimpleOpen] = useState(false)
  const [statusOpen, setStatusOpen] = useState(defaultStatusOpen)
  const [manageFeaturesOpen, setManageFeaturesOpen] = useState(false)
  const [createPoolOpen, setCreatePoolOpen] = useState(false)
  const [createConditionOpen, setCreateConditionOpen] = useState(false)
  const [clientTokenOpen, setClientTokenOpen] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [updateServerOpen, setUpdateServerOpen] = useState(false)

  const iconProps = detailed ? {} : { color: theme.colors.brand }

  return (
    <>
      {!hideMenu && (
        <ActionMenu
          width={240}
          parentElement={
            <div data-tip='Select actions available for this server'>
              <Button
                icon={{
                  name: 'ActionsDrawer',
                  ...iconProps
                }}
                type={detailed ? 'primary' : 'secondary'}
                variant={detailed ? 'solid' : 'link'}
                className={!detailed ? 'bright' : ''}
              >
                Actions
              </Button>
            </div>
          }
          position='top-right'
          trigger='click'
        >
          <React.Fragment key='.0'>
            {!detailed && (
              <>
                <ActionMenuItem
                  label='View Details'
                  icon={{ name: 'ConnectionServer' }}
                  onClick={() =>
                    history.push(
                      `${ROUTE_LICENSE_SERVER_DETAILS.replace(':serverId', id)}`
                    )
                  }
                />
                <ActionMenuDivider />
              </>
            )}

            <ActionMenuItem
              label={isEnabled ? 'Disable' : 'Enable'}
              icon={{
                name: isEnabled ? 'PlaybackCirclePause' : 'PlaybackCirclePlay'
              }}
              onClick={() => setStatusOpen(true)}
            />

            <ActionMenuItem
              //disabled={!allowEdit}
              label='Manage Features'
              icon={{ name: 'ServerEdit' }}
              disabled={isNvidia && isEnabled}
              onClick={() => setManageFeaturesOpen(true)}
            />
            <ActionMenuItem
              label='Update server from NLP'
              icon={{ name: 'ActionsUpload' }}
              onClick={() => setUpdateServerOpen(true)}
            />
            <>
              <ActionMenuDivider />

              {leasingMode === LS_LEASING_MODES.Advanced.value ? (
                <>
                  <ActionMenuItem
                    disabled={isNvidia && isEnabled && !deployedNone}
                    label='Create Pool'
                    icon={{ name: 'ActionsAdd' }}
                    onClick={() => setCreatePoolOpen(true)}
                  />
                  <ActionMenuItem
                    disabled={isNvidia && isEnabled && !deployedNone}
                    label='Create Condition'
                    icon={{ name: 'ActionsAdd' }}
                    onClick={() => setCreateConditionOpen(true)}
                  />
                </>
              ) : null}

              {leasingMode === LS_LEASING_MODES.Node_Locked.value ? (
                <ActionMenuItem
                  label='Generate Node-Locked Licensing lease'
                  icon={{ name: 'EditPenTool1' }}
                  onClick={() => setNllOpen(true)}
                  disabled={status !== LS_STATUS_ENABLED}
                />
              ) : (
                <>
                  <ActionMenuItem
                    label='Generate client config token'
                    icon={{ name: 'ComputerDisk3' }}
                    onClick={() => setClientTokenOpen(true)}
                  />
                  <ActionMenuItem
                    label='Settings'
                    icon={{ name: 'ServerSetting' }}
                    onClick={() => setSettingsOpen(true)}
                  />
                </>
              )}

              <ActionMenuDivider />

              {/* {!isSimpleMode && (
                <ActionMenuItem
                  label='Convert leasing mode'
                  icon={{ name: 'EditSendToBack' }}
                  onClick={() => setSimpleOpen(true)}
                />
              )} */}
            </>
          </React.Fragment>
        </ActionMenu>
      )}
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Enable Node-Locked Licensing'
          subtitle='Grant feature leases manually for your environment'
          open={simpleOpen}
          onClose={() => {
            setSimpleOpen(false)
            onClose && onClose()
          }}
        >
          {simpleOpen && (
            <ToggleSimple
              server={server}
              onUpdate={() => {
                setSimpleOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <ReactTooltip id='modal-tooltip' multiline={true} />
        <Modal
          title='Generate Node-Locked Lease'
          subtitle='Configure the clients for node-locked licensing. A node-locked lease file will be generated with the selected features, for each VM.'
          backgroundClose
          open={nllOpen}
          size='large'
          onClose={() => {
            setNllOpen(false)
            onClose && onClose()
          }}
        >
          {nllOpen && (
            <GenerateNll
              serviceInstanceId={serviceInstanceId}
              server={server}
              onUpdate={() => {
                setNllOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Update License Server'
          subtitle='Add new features or replace the license server by uploading a new license server .bin file, downloaded from NVIDIA Licensing Portal.'
          //className='noScroll custom'
          //size='small'
          //size='large'
          className='autow'
          open={updateServerOpen}
          onClose={() => {
            setUpdateServerOpen(false)
            onClose && onClose()
          }}
        >
          {updateServerOpen && (
            <UpdateServer
              onUpdate={() => {
                setUpdateServerOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Generate Client Configuration Token'
          subtitle='Create a configuration token for client access to server resources'
          //className='noScroll custom'
          //size='small'
          size='large'
          backgroundClose
          open={clientTokenOpen}
          onClose={() => {
            setClientTokenOpen(false)
            onClose && onClose()
          }}
        >
          {clientTokenOpen && (
            <GenerateClientToken
              serviceInstance={serviceInstance}
              server={server}
              onUpdate={() => setClientTokenOpen(false)}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Server Settings'
          subtitle={
            isEnabled
              ? 'Server settings cannot be changed while the server is enabled'
              : 'View or adjust the configurable options for this server'
          }
          //size='large'
          className='autow'
          backgroundClose={false}
          open={settingsOpen}
          onClose={() => {
            setSettingsOpen(false)
            onClose && onClose()
          }}
        >
          {settingsOpen && (
            <ServerSettings
              server={server}
              onUpdate={() => setSettingsOpen(false)}
            />
          )}
        </Modal>
      </div>

      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={isEnabled ? 'Disable License Server' : 'Enable License Server'}
          subtitle='Toggle the status of this server'
          className='noScroll custom'
          open={statusOpen}
          onClose={() => {
            setStatusOpen(false)
            onClose && onClose()
          }}
        >
          {statusOpen && (
            <ServerStatus
              server={server}
              onUpdate={() => {
                setStatusOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={'Manage Server Features'}
          subtitle='Decrease or remove existing features on this server'
          //backgroundClose
          open={manageFeaturesOpen}
          onClose={() => {
            setManageFeaturesOpen(false)
            onClose && onClose()
          }}
          size='large'
        >
          {manageFeaturesOpen && (
            <ManageFeatures
              server={server}
              onUpdate={() => {
                setManageFeaturesOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>

      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={'Create License Pool'}
          subtitle='Enter a name for this new pool and choose from available features allocated to this server'
          //backgroundClose
          open={createPoolOpen}
          onClose={() => {
            setCreatePoolOpen(false)
            onClose && onClose()
          }}
          size='large'
        >
          {createPoolOpen && (
            <CreatePool
              server={server}
              onUpdate={() => {
                setCreatePoolOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={'Create Fulfillment Condition'}
          subtitle='Enter a name for this new fulfillment condition, select a match condition and choose the license pools to bind'
          //closeOnBackdropClick={false}
          open={createConditionOpen}
          onClose={() => {
            setCreateConditionOpen(false)
            onClose && onClose()
          }}
          size='large'
          //className='noScroll custom'
          className='custom'
        >
          {createConditionOpen && (
            <CreateCondition
              server={server}
              onUpdate={() => {
                setCreateConditionOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
    </>
  )
}
