import React from 'reactn'
import styled from 'styled-components'
import { ModalButtons, useAppNotifications, useFetch } from 'common'
import Result from '@kaizen-ui/result'
import Button from '@kaizen-ui/button'
import { API_AUTH_LDAP } from '../../api'
import { SessionExpired } from '../../Components'

const StyledResult = styled.div`
  > div {
    border: none;
  }
  text-align: center;
`

export const DeleteLDAPConfig = ({ onUpdate }) => {
  const { getData: removeLdap, loading: removingLdap } = useFetch({
    endpoint: API_AUTH_LDAP,
    actionLabel: 'Configure LDAP settings',
    SessionExpired: SessionExpired,
    method: 'DELETE',
    responseOnly: true
  })

  const { notify } = useAppNotifications()

  const submit = async () => {
    const body = {
      enable_ldap: 'false'
    }
    const result = await removeLdap({ body })
    if (result) {
      const msg = `Removed LDAP configuration was successfully!`
      notify(msg, null, null, 'Removed LDAP configuration')
      onUpdate && onUpdate()
    }
  }

  return (
    <>
      <StyledResult>
        <Result
          status={removingLdap ? 'loading' : 'warning'}
          subTitle={
            removingLdap
              ? 'Removing...'
              : 'Are you sure you want to remove LDAP configuration?'
          }
          title={'Remove LDAP configuration'}
        />
      </StyledResult>
      <ModalButtons>
        <Button type='critical' onClick={submit} disabled={removingLdap}>
          Remove LDAP Configuration
        </Button>
      </ModalButtons>
    </>
  )
}
