"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FloatingDateRangePicker", {
  enumerable: true,
  get: function get() {
    return _FloatingDateRangePicker.FloatingDateRangePicker;
  }
});

var _FloatingDateRangePicker = require("./FloatingDateRangePicker");