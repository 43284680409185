import React, { useGlobal } from 'reactn'
import { useFetch, useAppNotifications, StatusToggle } from 'common'
import {
  API_LICENSE_SERVER,
  LS_STATUS_DISABLED,
  LS_STATUS_OPERATION_DISABLE,
  LS_STATUS_OPERATION_ENABLE
} from '../../api'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'

export const ServerStatus = ({ server = {}, onUpdate }) => {
  const { notify } = useAppNotifications()
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const { status, id, name } = server
  const isEnabling = status === LS_STATUS_DISABLED

  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_LICENSE_SERVER(orgId, vgId, id),
    actionLabel: `${isEnabling ? 'Enable' : 'Disable'} license server`,
    method: 'PATCH'
  })
  const submit = async () => {
    const body = {
      licenseServerUpdate: {
        operation: isEnabling
          ? LS_STATUS_OPERATION_ENABLE
          : LS_STATUS_OPERATION_DISABLE
      }
    }

    const result = await sendRequest({ body })
    if (result) {
      const msg = `License server ${name} was successfully ${
        isEnabling ? 'enabled' : 'disabled'
      }!`
      notify(
        msg,
        null,
        null,
        `${isEnabling ? 'Enabled' : 'Disabled'} license server`
      )
      onUpdate && onUpdate()
    }
  }

  return (
    <StatusToggle
      submitting={submitting}
      onSubmit={submit}
      isEnabling={isEnabling}
      label='server'
    />
  )
}
