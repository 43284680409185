import React, { useContext, useRef, useState } from 'react'
import styled from 'styled-components'
import Block from '@kaizen-ui/block'
import Button from '@kaizen-ui/button'
import Icon from '@kaizen-ui/icon'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import { saveAs } from 'file-saver'
import moment from 'moment'
import {
  ContextNotifications,
  FileUpload,
  FormikError,
  PageHeader,
  useAppNotifications,
  useFetch
} from 'common'
import {
  API_SERVICE_INSTANCE_IDENTITY_TOKEN_UNAUTH,
  API_SERVICE_INSTANCE_UPGRADE,
  API_UPLOAD_UPGRADE_FILE,
  DOCS_VA_TRANSFER,
  DOCS_VA_UPGRADE,
  UPGRADE_FILE_INSTALL_STATUS
} from '../../api'
import { useAuth } from '../../hooks'
import { Progress, Result, Text } from '@kaizen-ui/complete'
import { toast } from 'react-toastify'
import {
  GLOBAL_UPGRADE_FILE_INSTALL_REFRESH,
  GLOBAL_UPGRADE_FILE_INSTALL_STATUS,
  GLOBAL_UPGRADE_FILE_INSTALL_TRIGGERED,
  GLOBAL_VA_VERSION
} from '../../globalState'
import { useGlobal } from 'reactn'

const BlockSection = styled.div`
  margin: 0;
`
const Step = styled.span`
  flex-shrink: 0;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.textbox.placeholder};
  font-size: 1.2rem;
`
const BlockTitle = styled.h3`
  display: flex;
  align-items: center;
`
const BlockContents = styled.div`
  ${BlockSection}+${BlockSection} {
    margin-top: 2rem;
  }

  .kaizen-button.link {
    padding: 0;
  }
`
const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  > div {
    margin-right: 1rem;
  }
`
const Warning = styled.div`
  display: flex;
  margin-top: 1rem;

  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
`
export const bytesToMegabytes = bytes => {
  return (bytes / (1024 * 1024)).toFixed(1)
}
export const Upgrade = () => {
  const { notify } = useAppNotifications()
  const { checkAuth } = useAuth()
  const theme = useContext(KaizenThemeContext)
  const [upgradeFileInstallTriggered] = useGlobal(
    GLOBAL_UPGRADE_FILE_INSTALL_TRIGGERED
  )
  const [upgradeFile, setUpgradeFile] = useState(undefined)
  const [downloading, setDownloading] = useState(false)

  //upgrade file upload tracking vars
  const uploadRequest = useRef(undefined)
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [uploadedBytes, setUploadedBytes] = useState(0)
  const [error, setError] = useState(undefined)
  const [version] = useGlobal(GLOBAL_VA_VERSION)
  const helpRef1 = React.useRef(null)
  const helpRef2 = React.useRef(null)
  const [step, setStep] = React.useState(-1)
  const help = [
    {
      title: 'Generating an Upgrade File for DLS',
      text:
        'After uploading the migration file for the DLS instance that you are migrating, you must generate an upgrade file for the instance. Generating the upgrade file involves generating and uploading the DLS instance token for the new virtual appliance that will host the migrated DLS instance.',
      link: DOCS_VA_UPGRADE(version),
      ref: helpRef1,
      offsetY: 25
    },
    {
      title:
        'Transferring Migration Data to the DLS Instance on an Upgraded Virtual Appliance',
      text: `After registering the DLS instance on an upgraded virtual appliance with the NVIDIA Licensing Portal, the downloaded upgrade file will be uploaded here.`,
      link: DOCS_VA_TRANSFER(version),
      ref: helpRef2,
      offsetY: 25
    }
  ]

  const { getData } = useFetch({
    endpoint: API_SERVICE_INSTANCE_IDENTITY_TOKEN_UNAUTH,
    actionLabel: 'Download service instance token for upgrade'
  })
  const { getData: sendUpgrade, loading: upgrading } = useFetch({
    endpoint: API_SERVICE_INSTANCE_UPGRADE,
    actionLabel: 'Upgrade service instance',
    method: 'POST'
  })
  const [, setUpgradeFileInstallStatusRefresh] = useGlobal(
    GLOBAL_UPGRADE_FILE_INSTALL_REFRESH
  )
  const [upgradeFileInstallStatus] = useGlobal(
    GLOBAL_UPGRADE_FILE_INSTALL_STATUS
  )

  const downloadToken = async () => {
    setDownloading(true)
    const data = await getData()
    if (data?.identityToken) {
      const blob = new Blob([JSON.stringify(data)], {
        type: 'text/plain'
      })
      saveAs(
        blob,
        `dls_instance_token_${moment(Date.now()).format(
          'MM-DD-YYYY-HH-mm-ss'
        )}.tok`
      )
    } else {
      notify('Unable to parse token', null, true)
    }
    setDownloading(false)
  }

  const processUpgradeFile = async () => {
    const data = await sendUpgrade()
    if (data) {
      notify(
        "We're upgrading your virtual appliance. Please check back in a few minutes.",
        { toastId: 'upgrade-file-install-progress', autoClose: false },
        null,
        'Upgrade in progress'
      )

      //warning in case error message returned from API
      if (data.message) {
        notify(
          data.message,
          { autoClose: false, type: toast.TYPE.WARNING },
          null,
          'Upgrade in progress'
        )
      }
      checkAuth(() => {
        //trigger portal assisted file installation status API after /me
        setUpgradeFileInstallStatusRefresh(val => !val)
      })
    }
  }

  const uploadFile = async () => {
    setUploading(true)
    const formData = new FormData()
    formData.append('file', upgradeFile)

    uploadRequest.current = new XMLHttpRequest()
    uploadRequest.current.open('POST', API_UPLOAD_UPGRADE_FILE)
    // upload progress event, to calculate % upload completed
    uploadRequest.current.upload.addEventListener('progress', function(e) {
      let percent_completed = (e.loaded / e.total) * 100
      setProgress(percent_completed)
      setUploadedBytes(e.loaded)
    })
    uploadRequest.current.addEventListener('load', function() {
      // HTTP status message (200, 404 etc)
      if (uploadRequest.current.status === 200) {
        notify(
          'Upgrade file uploaded successfully.',
          null,
          null,
          'Uploaded upgrade file'
        )
        processUpgradeFile()
        setError(undefined)
      } else {
        setError('Failed to upload upgrade file. Please try again later.')
        uploadRequest.current = undefined
      }
      setUploading(false)
    })

    uploadRequest.current.onerror = function() {
      setError('Failed to upload upgrade file. Please try again later.')
      setUploading(false)
      uploadRequest.current = undefined
    }

    uploadRequest.current.onabort = function() {
      uploadRequest.current = undefined
      setUploading(false)
    }

    uploadRequest.current.send(formData)
  }

  return (
    <>
      <ContextNotifications list={help} step={step} setStep={setStep} />
      <PageHeader
        title='Virtual Appliance Upgrade'
        subTitle='Configure this DLS instance as an upgrade to an existing DLS instance'
        showHelp={() => setStep(0)}
      />
      <div>
        <Block loading={upgrading || downloading}>
          <BlockContents>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 1 -</Step>
                Download the DLS instance token and register with the NVIDIA
                Licensing Portal
              </BlockTitle>
              <div>
                Download a DLS instance token and upload the same on the NVIDIA
                Licensing Portal. While uploading the DLS instance token, select
                the <b>For upgrade</b> option, then select the service instance
                to be upgraded.
              </div>
              <div style={{ marginTop: '1rem' }} ref={helpRef1}>
                <Button
                  icon={{ name: 'ActionsDownload' }}
                  variant='link'
                  //type='secondary'
                  onClick={downloadToken}
                  disabled={downloading}
                >
                  Download DLS Instance Token
                </Button>
              </div>
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 2 -</Step>
                Download the upgrade file from the NVIDIA Licensing Portal
              </BlockTitle>
              Go to the <b>Service Instance</b> page of the NVIDIA Licensing
              Portal, find the service instance to be upgraded. Click on{' '}
              <b>Download Upgrade File</b> to download the respective upgrade
              file.
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 3 -</Step>
                Upload the upgrade file here
              </BlockTitle>
              Upload the upgrade file downloaded from the NVIDIA Licensing
              Portal to complete the virtual appliance upgrade. You&#39;ll be
              able to use your old virtual appliance&#39;s credentials to login
              post upgrade.
              {upgradeFile && (
                <Warning>
                  <Icon name='StatusWarning' size='larger' />
                  <span>
                    Current virtual appliance&#39;s data will be replaced with
                    the data from the upgrade file. Update the IP address of the
                    current virtual appliance to the IP address of the old
                    virtual appliance (using the <b>Configure IP Address</b>{' '}
                    option on the <b>Service Instance</b> page) once the upgrade
                    is complete.
                  </span>
                </Warning>
              )}
              {uploading && (
                <div style={{ marginTop: '1rem', marginLeft: '0.5rem' }}>
                  <Text textStyle='p2'>
                    Do not close this page during the upload
                  </Text>
                </div>
              )}
              {uploading ? (
                <div
                  style={{
                    margin: '0.2rem 0.5rem 1rem 0.5rem',
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  {bytesToMegabytes(upgradeFile.size) > 1 && (
                    <Text textStyle={'p2'} overflow={'no-wrap'}>
                      <b>{bytesToMegabytes(uploadedBytes)}</b>MB /{' '}
                      <b>{bytesToMegabytes(upgradeFile.size)}</b>MB
                    </Text>
                  )}

                  <div
                    style={{
                      width: '100%',
                      marginLeft: '7px',
                      marginRight: '7px'
                    }}
                  >
                    <Progress progress={progress} total={100} size='large' />
                  </div>
                  <div style={{ marginLeft: '7px' }}>
                    <Button
                      variant='link'
                      onClick={() => uploadRequest.current.abort()}
                    >
                      Cancel Upload
                    </Button>
                  </div>
                </div>
              ) : null}
              {error && <FormikError>{error}</FormikError>}
              {upgradeFileInstallTriggered && (
                <Result
                  status='loading'
                  title='Upgrade file is being installed'
                />
              )}
              {upgradeFileInstallStatus?.state ===
                UPGRADE_FILE_INSTALL_STATUS.FAILED && (
                <Result
                  status='error'
                  icon={{ name: 'ServerError' }}
                  title={
                    'Last upgrade-file installation failed. Please try again.'
                  }
                  subTitle={''}
                />
              )}
              <Buttons ref={helpRef2}>
                <FileUpload
                  accept='.bin'
                  label='Select upgrade file'
                  onChange={setUpgradeFile}
                  variant='link'
                  type='primary'
                  iconName='FileBin'
                  disabled={
                    uploading || upgrading || upgradeFileInstallTriggered
                  }
                />
                <Button
                  disabled={
                    !upgradeFile ||
                    upgrading ||
                    uploading ||
                    upgradeFileInstallTriggered
                  }
                  icon={{ name: 'ActionsUpload' }}
                  onClick={uploadFile}
                >
                  Upload upgrade file
                </Button>
              </Buttons>
            </BlockSection>
          </BlockContents>
        </Block>
      </div>
    </>
  )
}
