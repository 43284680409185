import React, { useEffect, useState, useGlobal } from 'reactn'
import ReactTooltip from 'react-tooltip'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import Textbox from '@kaizen-ui/textbox'
import Button from '@kaizen-ui/button'
import Spinner from '@kaizen-ui/spinner'
import { KaizenThemeContext } from '@kaizen-ui/complete'
import { useFetch, useAppNotifications, FormikError } from 'common'
import { GLOBAL_SERVICE_INSTANCE_ID } from '../../globalState'
import {
  API_SERVICE_INSTANCE_NTP_SERVER,
  API_SERVICE_INSTANCE_NTP_SERVER_PING
} from '../../api'
import { SessionExpired } from '../../Components'
import { Icon } from '@kaizen-ui/complete'

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;

  button {
    margin-left: 0.5rem;
  }
`
const StyledText = styled.div`
  margin-top: -1rem;
`
export const FormikSuccess = styled.div`
  color: ${({ theme }) => theme.colors.tooltip.success.foreground};
  font-size: 0.75rem;
  font-weight: 700;
`
const DomainOrIpRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$|((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/

/*
const recursiveDomainOrIpRegex = /^(?<big>((?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?(,|$)|((\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*(,|$))|(\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*(,|$)))))(?&big)*(?<!,)$/
*/

const validationSchema = yup.object({
  ntpServer: yup
    .array()
    .transform(function(value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value
      }
      return originalValue
        ? originalValue.split(',').map(val => val.trim())
        : []
    })
    .test(
      'Check validity of the list',
      'Each comma separated value must be a valid IP address or domain name',
      values => {
        return values?.length
          ? values.every(input => DomainOrIpRegex.test(input))
          : true
      }
    )
    .required('NTP server(s) field is required')
})

export const NTPServerConfig = ({ onUpdate }) => {
  const theme = React.useContext(KaizenThemeContext)
  const { notify } = useAppNotifications()
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)
  const [ntpServer, setNTPServer] = useState('')
  const [inSync, setInSync] = React.useState(undefined)
  const [refreshToggle, setRefreshToggle] = useState(false)
  const [unreachable, setUnreachable] = useState([])
  const [isPingSuccessful, setIsPingSuccessful] = useState(false)

  const { getData, abort, loading } = useFetch({
    endpoint: API_SERVICE_INSTANCE_NTP_SERVER,
    actionLabel: 'Get NTP server info',
    SessionExpired: SessionExpired,
    supressToast: true
  })

  const { getData: configureNTP, loading: configuring } = useFetch({
    endpoint: API_SERVICE_INSTANCE_NTP_SERVER,
    actionLabel: 'Configure NTP server(s)',
    method: 'POST'
  })

  const { getData: pingNTP, loading: pinging } = useFetch({
    endpoint: API_SERVICE_INSTANCE_NTP_SERVER_PING,
    actionLabel: 'Ping NTP server(s)',
    method: 'POST'
  })

  const { getData: resetNTP, loading: resetting } = useFetch({
    endpoint: `${API_SERVICE_INSTANCE_NTP_SERVER}?revert=true`,
    actionLabel: 'Reset NTP server to default',
    method: 'POST'
  })

  useEffect(() => {
    const getNTPconfig = async () => {
      const data = await getData()
      if (data?.ntpServer) {
        setNTPServer(data?.ntpServer)
        setInSync(data?.isNtpSynchronized)
      } else {
        setInSync(undefined)
        setNTPServer('')
      }
    }
    getNTPconfig()
    return () => {
      abort()
    }
  }, [refreshToggle]) //eslint-disable-line react-hooks/exhaustive-deps

  const initialValues = {
    ntpServer: ntpServer
  }

  const submit = async (values, actions) => {
    const { ntpServer } = values || {}
    const { resetForm } = actions || {}
    const body = {
      ntpServer: ntpServer.split(/[\s,]+/).join()
    }
    const headers = { 'x-nv-service-instance-id': serviceInstanceId }
    configureNTP({ body, headers }).then(data => {
      if (data) {
        const msg = `NTP server(s) configured successfully!`
        notify(msg, null, null, 'Configured NTP server(s)')
        setRefreshToggle(val => !val)
        onUpdate && onUpdate()
      } else {
        resetForm({ values: initialValues })
      }
    })
  }

  const onResetToDefault = async () => {
    const headers = { 'x-nv-service-instance-id': serviceInstanceId }
    resetNTP({ body: {}, headers }).then(data => {
      if (data) {
        const msg = 'NTP server was reset to default successfully!'
        notify(msg, null, null, 'Reset NTP server to default')
        setRefreshToggle(val => !val)
        onUpdate && onUpdate()
      }
    })
  }

  const onPing = async () => {
    //setRefreshToggle(val => !val)
    const body = {
      ntpServers: formik.values.ntpServer.split(/[\s,]+/).join()
    }
    const headers = { 'x-nv-service-instance-id': serviceInstanceId }
    pingNTP({ body, headers }).then(data => {
      if (data?.nptServerPingStatusList) {
        setIsPingSuccessful(
          data.nptServerPingStatusList.every(serv => !!serv?.isServerReachable)
        )
        setUnreachable(
          data?.nptServerPingStatusList
            ?.filter(ntp => !ntp.isServerReachable)
            .map(ntp => ntp.ntpServer)
        )
      } else {
        setIsPingSuccessful(false)
        setUnreachable([])
      }
    })
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: submit
  })

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  React.useEffect(() => {
    setIsPingSuccessful(false)
    setUnreachable([])
  }, [formik?.values?.ntpServer])

  return (
    <div>
      <NtpStatus>
        <div
          data-tip={
            loading ? '' : `NTP server(s) ${inSync ? '' : 'not'} synchronized`
          }
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {loading ? (
            <div style={{ margin: '1rem 0.5rem 0 0' }}>
              <Spinner size='tiny' />
            </div>
          ) : (
            <Icon
              name={inSync ? 'StatusCircleCheck2' : 'StatusCircleError'}
              color={inSync ? theme.colors.brand : theme.colors.red500}
            />
          )}
        </div>

        <Textbox
          id='ntpServer'
          name='ntpServer'
          label='NTP Server(s)'
          placeholder='Domain name or IP address, enter comma separated values in case of multiple'
          className='nvl icon-status'
          value={formik.values.ntpServer}
          disabled={configuring || loading}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          validationMessage={
            formik.touched.ntpServer && formik.errors.ntpServer
          }
        />
      </NtpStatus>
      {isPingSuccessful && (
        <StyledText>
          <FormikSuccess>Ping successful!</FormikSuccess>
        </StyledText>
      )}

      {!!unreachable.length && (
        <StyledText>
          <FormikError>
            {`The following NTP servers are unreachable: ${unreachable.join(
              ', '
            )}`}
          </FormikError>
        </StyledText>
      )}

      <Buttons>
        {(configuring || resetting || loading || pinging) && (
          <span style={{ marginRight: '0.5rem' }}>
            <Spinner size='tiny' />
          </span>
        )}
        <Button
          onClick={onPing}
          disabled={resetting || configuring || loading || pinging}
          type='secondary'
        >
          Ping Server
        </Button>
        <Button
          onClick={onResetToDefault}
          disabled={resetting || configuring || loading || pinging}
          type='secondary'
        >
          Reset to Default
        </Button>

        <Button
          primary
          icon={{ name: 'TimeClock' }}
          onClick={formik.handleSubmit}
          disabled={
            configuring || resetting || loading || pinging || !isPingSuccessful
          }
        >
          Configure
        </Button>
      </Buttons>
    </div>
  )
}

const NtpStatus = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin: 2.25rem 0.5rem 1rem 0.5rem;
    position: absolute;
    z-index: 1;
  }

  #ntpServer {
    padding-left: 1.5rem;
  }
`
