import React, { useState, useGlobal } from 'reactn'
import styled from 'styled-components'
import Modal from '@kaizen-ui/modal'
import Button from '@kaizen-ui/button'
import Result from '@kaizen-ui/result'
import {
  InlineButton,
  ModalButtons,
  useAppNotifications,
  useFetch
} from 'common'
import { API_LEASES_RELEASE } from '../../api'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'

const StyledResult = styled.div`
  > div {
    border: none;
  }
`

export const LeaseActions = ({
  lease: { leaseId, isCardinal },
  onUpdate,
  serviceInstanceId
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div
        style={{ marginRight: '0.5rem' }}
        data-tip={
          isCardinal
            ? 'Force the release of this lease'
            : 'Force lease release is not allowed for non-cardinal features'
        }
      >
        <InlineButton
          type='critical'
          variant='link'
          disabled={!isCardinal}
          onClick={() => setOpen(true)}
        >
          Release
        </InlineButton>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Force Release'
          subtitle="Forcefully release 'stuck' leases without awaiting expiration"
          open={open}
          onClose={() => setOpen(false)}
        >
          {open && (
            <ForceRelease
              leaseId={leaseId}
              onUpdate={onUpdate}
              serviceInstanceId={serviceInstanceId}
            />
          )}
        </Modal>
      </div>
    </>
  )
}

export const ForceRelease = ({
  leaseId,
  onUpdate,
  serviceInstanceId,
  releaseAll
}) => {
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_LEASES_RELEASE(orgId, vgId),
    actionLabel: 'Force lease release',
    method: 'PATCH'
  })
  const { notify } = useAppNotifications()

  const submit = async () => {
    const headers = { 'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId }
    const body = releaseAll ? { bulkRelease: true } : { leases: [leaseId] }
    const result = await sendRequest({ headers, body })

    if (result) {
      const { releasedLeases, unreleasedLeases, bulkReleaseMessage } = result

      if (releaseAll) {
        notify(bulkReleaseMessage, null, false, 'Release all leases forcefully')
        onUpdate()
      } else {
        if (unreleasedLeases && unreleasedLeases.length > 0) {
          const error = unreleasedLeases.filter(l => l.leaseId === leaseId)?.[0]
            ?.errorMessage
          const msg = `Lease ${leaseId} failed to release! ${error}`
          notify(msg, null, true, 'Force lease release')
        }

        if (releasedLeases && releasedLeases.length > 0) {
          const msg = `Lease ${leaseId} successfully released!`
          notify(msg, null, null, 'Force lease release')
          onUpdate()
        }
      }
    }
  }

  return (
    <>
      <StyledResult>
        <Result
          status={submitting ? 'loading' : 'warning'}
          title={
            submitting
              ? 'Releasing...'
              : 'Are you sure you want to force release?'
          }
          subTitle={leaseId}
        />
      </StyledResult>
      <ModalButtons>
        <Button type='critical' onClick={submit} disabled={submitting}>
          Force Release
        </Button>
      </ModalButtons>
    </>
  )
}
