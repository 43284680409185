import React, { useEffect, useState, useContext, useMemo } from 'reactn'
import * as Yup from 'yup'
import styled from 'styled-components'
import Textbox from '@kaizen-ui/textbox'
import { useFormik } from 'formik'
import {
  useFetch,
  useAppNotifications,
  FloatingSpinner,
  Wizard,
  FormikError,
  FileUpload
} from 'common'
import { API_AUTH_LDAP, IpHostnameRegex } from '../../api'
import { SessionExpired } from '../../Components'
import Text from '@kaizen-ui/text'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Result from '@kaizen-ui/result'
import { useAuth } from '../../hooks'
import Tabs, { Tab } from '@kaizen-ui/tabs'
import Switch from '@kaizen-ui/switch'
import Icon from '@kaizen-ui/icon'
import Password from '@kaizen-ui/password'
import Button from '@kaizen-ui/button'
import Modal from '@kaizen-ui/modal'
import { DeleteLDAPConfig } from './DeleteLDAPConfig'
import { TestLDAPConnection } from './TestLDAPConnection'
import { Tag } from '@kaizen-ui/complete'
import Radio, { RadioGroup } from '@kaizen-ui/radio'

const StyledSwitch = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  > div {
    margin: 0;
    /* margin-bottom: 1rem;*/
  }
`
const SwitchLabel = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  > svg {
    margin-right: 0.5rem;
  }
`
const AuthType = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`
const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 1rem;
  }
`
const StyledResult = styled.div`
  > div {
    border: none;
  }
`
const StyledPing = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  button {
    margin-right: 1rem;
  }
`
const ClearFileButton = styled(Button)`
  padding-left: 0;
`

export const AUTH_TYPE = {
  SIMPLE: 'SIMPLE',
  NTLM: 'NTLM',
  SASL: 'SASL'
}
export const SASL_MECHANISM = {
  PLAIN: 'PLAIN',
  MD5: 'MD5',
  KERBEROS: 'KERBEROS'
}
export const SEARCH_SCOPE = {
  SUBTREE: 'SUBTREE',
  BASE: 'BASE'
}

const validationSchemaMain = Yup.object({
  ldap_server: Yup.string()
    .required('IP address or FQDN of LDAP server is required')
    .matches(IpHostnameRegex, 'Must be a valid IP address or FQDN'),
  ldap_port: Yup.number()
    .typeError('Must be a valid number')
    .min(1, 'Must be a number from 1 to 65535')
    .max(65535, 'Must be a number from 1 to 65535')
    .required(),
  use_secure: Yup.boolean().required(),
  ldaps_cert: Yup.string(),
  referrals: Yup.boolean().required(),
  domain: Yup.string(),
  root_dn: Yup.string(),
  search_base: Yup.string(),
  search_filter: Yup.string(),
  search_scope: Yup.string().required()
  /* connectionValidated: Yup.boolean().when('test_ldap', {
    is: val => {
      console.log('test_ldap, I was here : ', val)
      return !val
    },
    then: Yup.boolean()
      .required(
        'LDAP connectivity must be validated before saving LDAP configuration'
      )
      .oneOf(
        [true],
        'LDAP connectivity must be validated before saving LDAP configuration'
      )
  })*/
})

const validationSchemaOSFields = Yup.object({
  binddn: Yup.string().required('Bind DN is required'),
  bindpw: Yup.string().required('Bind DN password is required'),
  rootbinddn: Yup.string().required('Root Bind DN is required'),
  rootbinddnpwd: Yup.string().required('Root Bind DN password is required')
})

const LDAPFields = ({ formik, theme }) => {
  const { notify } = useAppNotifications()
  const [clear, setClear] = React.useState(false)
  const [file, setFile] = React.useState(undefined)

  const clearFile = () => {
    setClear(val => !val)
    setFile(undefined)
  }

  const verifyFile = async uploadedFile => {
    setFile(uploadedFile)
    const errMsg =
      'Could not process the certificate file. Please select a valid certificate file.'
    const errNotice = 'LDAPS certificate processing failed.'
    const reader = new FileReader()
    reader.onload = function(r) {
      let result = r.target.result
      if (result) {
        formik.setFieldValue('ldaps_cert', result)
      } else {
        const msg = errMsg
        notify(msg, null, true, errNotice)
        clearFile()
      }
    }
    reader.onerror = function(err) {
      const msg = errMsg
      notify(msg, null, true, errNotice)
      clearFile()
    }
    reader.readAsText(uploadedFile)
  }

  return (
    <>
      <Textbox
        id='ldap_server'
        name='ldap_server'
        label='LDAP server hostname or IP address'
        className='nvl'
        placeholder='LDAP server hostname or IP address'
        value={formik.values.ldap_server}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={
          formik.touched.ldap_server && formik.errors.ldap_server
        }
      />
      <Textbox
        id='ldap_port'
        name='ldap_port'
        label='LDAP Port'
        inputMode='numeric'
        className='nvl'
        placeholder='LDAP port number that DLS should connect to'
        value={formik.values.ldap_port}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={formik.touched.ldap_port && formik.errors.ldap_port}
      />
      <StyledSwitch key={`use_secure_${formik.values.use_secure}`}>
        <Switch
          id={'use_secure'}
          checked={formik.values.use_secure}
          onChange={() =>
            formik.setFieldValue('use_secure', !formik.values.use_secure)
          }
        />
        <SwitchLabel
          onClick={() =>
            formik.setFieldValue('use_secure', !formik.values.use_secure)
          }
        >
          <Icon name='CloudBase' />
          <span data-tip='Whether to use secure LDAP (LDAPS)'>
            Use secure LDAP (LDAPS)?
          </span>
        </SwitchLabel>
      </StyledSwitch>
      {formik.values.use_secure && (
        <div
          style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}
        >
          <FileUpload
            accept='.cer'
            label='Select LDAPS certificate'
            onChange={verifyFile}
            variant='link'
            type='primary'
            iconName='FileBase'
            clearFile={clear}
          />
          {file && (
            <ClearFileButton
              variant='link'
              type='secondary'
              onClick={clearFile}
              icon={{ name: 'ActionsCloseBold' }}
            ></ClearFileButton>
          )}
        </div>
      )}
      <AuthType>
        <Text textStyle='label' color={theme.colors.checkbox?.foreground}>
          LDAP Authentication Type : &nbsp;
        </Text>
        <div>NTLM</div>
      </AuthType>
      <StyledSwitch key={`referrals_${formik.values.referrals}`}>
        <Switch
          id={'referrals'}
          checked={formik.values.referrals}
          onChange={() =>
            formik.setFieldValue('referrals', !formik.values.referrals)
          }
        />
        <SwitchLabel
          onClick={() =>
            formik.setFieldValue('referrals', !formik.values.referrals)
          }
        >
          <Icon name='CloudBase' />
          <span data-tip='Whether to enable auto referrals for LDAP clients.'>
            Auto referrals for LDAP clients?
          </span>
        </SwitchLabel>
      </StyledSwitch>
      <Textbox
        id='domain'
        name='domain'
        label='Domain'
        className='nvl'
        placeholder='Domain that needs to be used as part of the username to login'
        value={formik.values.domain}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={formik.touched.domain && formik.errors.domain}
      />
      <Textbox
        id='root_dn'
        name='root_dn'
        label='Root DN'
        className='nvl'
        placeholder='Set root DN to where DLS would search within LDAP'
        value={formik.values.root_dn}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={formik.touched.root_dn && formik.errors.root_dn}
      />{' '}
      <Textbox
        id='search_base'
        name='search_base'
        label='Search Base'
        className='nvl'
        placeholder='Search base to search in ldap directory'
        value={formik.values.search_base}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={
          formik.touched.search_base && formik.errors.search_base
        }
      />
      <Textbox
        id='search_filter'
        name='search_filter'
        label='Search Filter'
        className='nvl'
        placeholder='Search filter for finding user and group entries'
        value={formik.values.search_filter}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={
          formik.touched.search_filter && formik.errors.search_filter
        }
      />
      <div key={formik?.values?.search_scope}>
        <Text textStyle='label' color={theme.colors.checkbox?.foreground}>
          Search Scope
        </Text>
        <RadioGroup
          inline={true}
          id='search_scope'
          name='search_scope'
          onChange={e => {
            formik.setFieldValue('search_scope', e.target.id)
          }}
          selected={formik?.values?.search_scope}
        >
          <Radio id={SEARCH_SCOPE.BASE} label={SEARCH_SCOPE.BASE} />
          <Radio id={SEARCH_SCOPE.SUBTREE} label={SEARCH_SCOPE.SUBTREE} />
        </RadioGroup>

        {formik.touched.search_scope && formik.errors.search_scope && (
          <FormikError>{formik.errors.search_scope}</FormikError>
        )}
      </div>
    </>
  )
}

export const LDAPConfig = ({ ldapRef }) => {
  const { notify } = useAppNotifications()
  const theme = useContext(KaizenThemeContext)
  const [ldapConfig, setLdapConfig] = useState(null)
  /*  const [isNotConfigured, setIsNotConfigured] = useState(false)*/
  const [refreshToggle, setRefreshToggle] = useState(false)
  const [ldapRemoveOpen, setLdapRemoveOpen] = useState(false)
  const [testConnectionOpen, setTestConnectionOpen] = useState(false)

  //Form related
  const { user } = useAuth()
  const { isPureContainer } = user || {}
  /* console.log('isPureContainer : ', isPureContainer)*/

  const [active, setActive] = useState(1)
  const {
    ldap_server,
    ldap_port,
    use_secure,
    referrals,
    domain,
    root_dn,
    search_base,
    search_filter,
    search_scope,
    os_ldap_settings
  } = ldapConfig || {}

  const { binddn, bindpw, rootbinddn, rootbinddnpwd } = os_ldap_settings || {}

  useEffect(() => {
    if (ldapRef?.current /*&& active === 2*/) {
      ldapRef?.current?.scrollIntoView()
    }
  }, [active, ldapRef])

  const {
    getData,
    loading: ldapConfigLoading,
    abort,
    error: fetchLdapConfigErr
  } = useFetch({
    endpoint: API_AUTH_LDAP,
    actionLabel: 'Get LDAP setting',
    SessionExpired: SessionExpired,
    supressToast: true,
    returnResponse: true
  })

  const { getData: configLdap, loading: configuring } = useFetch({
    endpoint: API_AUTH_LDAP,
    actionLabel: 'Configure LDAP settings',
    SessionExpired: SessionExpired,
    method: 'POST'
  })

  useEffect(() => {
    const getLDAPconfig = async () => {
      const data = await getData()
      /*if (data?.responseStatus === 404) {
        setIsNotConfigured(true)
      } else */
      if (data?.ldap_server) {
        setLdapConfig(data)
      } else {
        setLdapConfig({})
      }
    }
    getLDAPconfig()
    return () => {
      abort()
    }
  }, [refreshToggle]) //eslint-disable-line react-hooks/exhaustive-deps

  /*  const test = {
    enable_ldap: 'true',
    ldap_server: 'ykldap.nvidia.com',
    ldap_port: 389,
    use_secure: 'false',
    authentication_type: 'NTLM',
    sasl_mechanism: '',
    referrals: 'false',
    root_dn: 'OU=Users,OU=Accounts,DC=NVIDIA,DC=COM',
    search_base: '',
    search_filter:
      '(&(objectClass=person)(objectClass=user)(sAMAccountName={0}))',
    search_scope: 'SUBTREE',
    os_ldap_settings: {
      base: 'OU=Users,OU=Accounts,DC=NVIDIA,DC=COM',
      uri: 'ldap://ykldap.nvidia.com',
      port: 389,
      binddn: '',
      bindpw: '',
      rootbinddn: '',
      rootbinddnpwd: ''
    }
  }*/

  const initialValues = {
    ldap_server: ldap_server ? ldap_server : '',
    ldap_port: ldap_port ? Number(ldap_port) : 389,
    use_secure: use_secure?.toLowerCase() === 'true', //comes as a string from backend
    ldaps_cert: '',
    /* authentication_type: AUTH_TYPE.NTLM,*/
    /*sasl_mechanism: SASL_MECHANISM.KERBEROS,*/
    referrals: referrals?.toLowerCase() === 'true', //comes as a string from backend
    domain: domain ? domain : '',
    root_dn: root_dn ? root_dn : '',
    search_base: search_base ? search_base : '',
    search_filter: search_filter ? search_filter : '',
    search_scope: search_scope ? search_scope : SEARCH_SCOPE.BASE,
    /*additional fields for VM based for OS inputs*/
    binddn: binddn ? binddn.replaceAll('"', '') : '', //comes as a double string from backend
    bindpw: bindpw ? bindpw : '',
    rootbinddn: rootbinddn ? rootbinddn.replaceAll('"', '') : '', //comes as a double string from backend
    rootbinddnpwd: rootbinddnpwd ? rootbinddnpwd : '',
    connectionValidated: false, //for operations only
    test_ldap: true, //for operations only
    test_ldap_creds: {} //for operations only
  }

  const submit = async values => {
    const {
      ldap_server,
      ldap_port,
      use_secure,
      referrals,
      domain,
      root_dn,
      search_base,
      search_filter,
      search_scope,
      binddn,
      bindpw,
      rootbinddn,
      rootbinddnpwd,
      test_ldap,
      test_ldap_creds,
      ldaps_cert
    } = values

    const cert = ldaps_cert ? { ldap_tls_ca_certs: ldaps_cert } : {}
    const body = {
      enable_ldap: 'true',
      test_ldap,
      test_ldap_creds,
      ldap_server,
      ldap_port: Number(ldap_port),
      use_secure: use_secure.toString(),
      authentication_type: AUTH_TYPE.NTLM,
      referrals: referrals.toString(),
      domain,
      root_dn,
      search_base,
      search_filter,
      search_scope,
      ...cert,
      ...(isPureContainer
        ? {}
        : {
            os_ldap_settings: {
              base: root_dn,
              uri: ldap_server,
              port: Number(ldap_port),
              binddn,
              bindpw,
              rootbinddn,
              rootbinddnpwd
            }
          })
    }
    const data = await configLdap({ body })
    if (data) {
      /*const msg = `LDAP server not reachable. Try again.`*/
      if (test_ldap) {
        const msg = `LDAP server connection has been successfully validated!`
        notify(msg, null, null, 'Validate LDAP connection')
        formik.setFieldValue('test_ldap', false)
        formik.setFieldValue('test_ldap_creds', {})
        formik.setFieldValue('connectionValidated', true)
        setTestConnectionOpen(false)
      } else {
        const msg = `LDAP configuration has been successfully saved.`
        notify(msg, null, null, 'LDAP configured successfully')
        formik.setFieldValue('connectionValidated', false)
        setRefreshToggle(val => !val)
        setActive(1)
      }
    } else {
      formik.setFieldValue('connectionValidated', false)
    }
  }

  const formik = useFormik({
    validationSchema: isPureContainer
      ? validationSchemaMain
      : active === 2
      ? validationSchemaOSFields
      : validationSchemaMain,
    initialValues,
    enableReinitialize: true,
    onSubmit: submit
  })

  /*  console.log(formik.values)
  console.log(formik.errors)*/

  useEffect(() => {
    formik.setFieldValue('connectionValidated', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.ldap_server,
    formik.values.ldap_port,
    formik.values.use_secure,
    formik.values.referrals,
    formik.values.domain,
    formik.values.root_dn,
    formik.values.search_base,
    formik.values.search_filter,
    formik.values.search_scope
  ])

  const steps = useMemo(
    () => [
      {
        label: 'Basic details',
        help: '',
        value: 1,
        allowNext: formik.values.connectionValidated
      },
      {
        label: 'Additional details',
        help:
          'Additional inputs needed to configure LDAP for VM based DLS setups',
        value: 2
      }
    ],
    [formik]
  )

  if (ldapConfigLoading || fetchLdapConfigErr)
    return (
      <StyledResult>
        <Result
          status={fetchLdapConfigErr ? 'error' : 'loading'}
          subTitle={fetchLdapConfigErr}
        />
      </StyledResult>
    )
  return (
    <div style={{ overflow: 'auto' }}>
      {/*  {!ldap_server ? (
        <div style={{ textAlign: 'center' }}>
          <Text textStyle={'optionLabel'}>
            LDAP is not configured. Enter following details to configure.
          </Text>
        </div>
      ) : null}*/}

      <FloatingSpinner visible={configuring} />

      {isPureContainer ? (
        <div>
          <LDAPFields
            formik={formik}
            theme={theme}
            loadingConfig={ldapConfigLoading}
          />
          <StyledPing>
            <Button
              type={'primary'}
              size={'small'}
              /* variant={'link'}*/
              /* variant={'outline'}*/
              icon={{ name: 'ObjectsNetwork' }}
              onClick={() => {
                formik.validateForm().then(errors => {
                  formik.setTouched({ ...formik.touched, ...errors })
                  if (!Object.keys(errors)?.length) {
                    setTestConnectionOpen(true)
                  }
                })
              }}
              disabled={configuring || !formik.isValid}
            >
              Test LDAP Connection
            </Button>{' '}
            {formik.values.connectionValidated ? (
              <Tag
                color='green'
                icon={{
                  name: 'StatusCircleCheck2'
                }}
                variant='outline'
                clickable={false}
              >
                Validated
              </Tag>
            ) : null}
          </StyledPing>
          <br />
          <br />
          <Buttons>
            {ldap_server && (
              <Button
                onClick={() => {
                  setLdapRemoveOpen(true)
                }}
                disabled={configuring}
                type='critical'
              >
                Remove LDAP Configuration
              </Button>
            )}

            <Button
              onClick={formik.handleSubmit}
              disabled={
                configuring ||
                !formik.isValid ||
                !formik.values.connectionValidated
              }
            >
              Save LDAP Configuration
            </Button>
          </Buttons>
        </div>
      ) : (
        <Wizard
          steps={steps}
          numbered
          horizontal
          active={active}
          setActive={setActive}
        >
          <Tabs selectedTabId={active}>
            <Tab id={1}>
              <LDAPFields formik={formik} theme={theme} />
              <StyledPing>
                <Button
                  type={'primary'}
                  size={'small'}
                  /* variant={'link'}*/
                  /* variant={'outline'}*/
                  icon={{ name: 'ObjectsNetwork' }}
                  onClick={() => {
                    formik.validateForm().then(errors => {
                      formik.setTouched({ ...formik.touched, ...errors })
                      if (!Object.keys(errors)?.length) {
                        setTestConnectionOpen(true)
                      }
                    })
                  }}
                  disabled={configuring || !formik.isValid}
                >
                  Test LDAP Connection
                </Button>{' '}
                {formik.values.connectionValidated ? (
                  <Tag
                    color='green'
                    icon={{
                      name: 'StatusCircleCheck2'
                    }}
                    variant='outline'
                    clickable={false}
                  >
                    Validated
                  </Tag>
                ) : null}
              </StyledPing>
              <div
                style={{
                  position: 'absolute',
                  bottom: '1.7rem'
                }}
              >
                {ldap_server && (
                  <Button
                    variant={'link'}
                    icon={{ name: 'ActionsTrash' }}
                    /* variant={'outline'}
                    size={'small'}*/
                    type='critical'
                    onClick={() => {
                      setLdapRemoveOpen(true)
                    }}
                    disabled={configuring}
                  >
                    Remove LDAP Configuration
                  </Button>
                )}
              </div>
            </Tab>
            <Tab id={2}>
              <>
                <Textbox
                  id='binddn'
                  name='binddn'
                  label='Bind DN (distinguished name)'
                  className='nvl'
                  placeholder='Distinguished name (DN) for your LDAP bind user'
                  value={formik.values.binddn}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  validationMessage={
                    formik.touched.binddn && formik.errors.binddn
                  }
                />
                <Password
                  name='bindpw'
                  label='Bind Password'
                  className='nvl'
                  placeholder='Password for your LDAP bind user'
                  showVisibilityToggle={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bindpw}
                  validationMessage={
                    formik.touched.bindpw && formik.errors.bindpw
                  }
                  valid={formik.touched.bindpw && !formik.errors.bindpw}
                />

                <Textbox
                  id='rootbinddn'
                  name='rootbinddn'
                  label='Root Bind DN (distinguished name)'
                  className='nvl'
                  placeholder='Distinguished name (DN) for your root LDAP bind user'
                  value={formik.values.rootbinddn}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  validationMessage={
                    formik.touched.rootbinddn && formik.errors.rootbinddn
                  }
                />
                <Password
                  name='rootbinddnpwd'
                  label='Root Bind DN Password'
                  className='nvl'
                  placeholder='Password for root bind user'
                  showVisibilityToggle={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.rootbinddnpwd}
                  validationMessage={
                    formik.touched.rootbinddnpwd && formik.errors.rootbinddnpwd
                  }
                  valid={
                    formik.touched.rootbinddnpwd && !formik.errors.rootbinddnpwd
                  }
                />
              </>
              <Buttons>
                <Button
                  onClick={formik.handleSubmit}
                  disabled={
                    configuring ||
                    !formik.isValid ||
                    !formik.values.connectionValidated
                  }
                >
                  Save LDAP Configuration
                </Button>
              </Buttons>
            </Tab>
          </Tabs>
        </Wizard>
      )}

      <div onClick={e => e.stopPropagation()}>
        {ldapRemoveOpen && (
          <Modal
            title={'Remove LDAP configuration'}
            open={ldapRemoveOpen}
            size={'medium'}
            onClose={() => {
              setLdapRemoveOpen(false)
            }}
          >
            <DeleteLDAPConfig
              ldapConfig={ldapConfig}
              isPureContainer={isPureContainer}
              onUpdate={() => {
                setLdapRemoveOpen(false)
                setRefreshToggle(val => !val)
                setActive(1)
              }}
            />
          </Modal>
        )}
      </div>
      <div onClick={e => e.stopPropagation()}>
        {testConnectionOpen && (
          <Modal
            title={'Test LDAP Connection'}
            open={testConnectionOpen}
            size={'medium'}
            onClose={() => {
              setTestConnectionOpen(false)
            }}
          >
            <TestLDAPConnection
              ldapFormik={formik}
              isPureContainer={!!isPureContainer}
              onUpdate={() => {
                setTestConnectionOpen(false)
              }}
            />
          </Modal>
        )}
      </div>
    </div>
  )
}
