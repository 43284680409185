"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useAppNotifications", {
  enumerable: true,
  get: function get() {
    return _useAppNotifications.useAppNotifications;
  }
});

var _useAppNotifications = require("./useAppNotifications");