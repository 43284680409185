import { Button, Result } from '@kaizen-ui/complete'
import { useFetch, useAppNotifications, ModalButtons } from 'common'
import { API_LICENSE_SERVER, LS_LEASING_MODES } from '../../api'
import React, { useGlobal } from 'reactn'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'

export const ToggleSimple = ({ server, onUpdate }) => {
  const { notify } = useAppNotifications()
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const { id, name, leasingMode } = server
  const isEnabling = React.useMemo(
    () => leasingMode !== LS_LEASING_MODES.Node_Locked.value,
    [leasingMode]
  )
  const { getData: sendRequest, loading: submitting, error } = useFetch({
    endpoint: API_LICENSE_SERVER(orgId, vgId, id),
    actionLabel: `Enabling Node-Locked Licensing`,
    method: 'PATCH'
  })
  const submit = async () => {
    const body = {
      licenseServerUpdate: {
        operation: 'SWITCH_MODE'
      }
    }

    const result = await sendRequest({ body })
    if (result) {
      const msg = `Node-Locked Licensing mode enabled for ${name}'
      !`
      notify(
        msg,
        null,
        null,
        `${isEnabling ? 'Enabled' : 'Disabled'} Node-Locked Licensing`
      )
      onUpdate && onUpdate()
    }
  }
  const formStatus = React.useMemo(() => {
    if (error) return { status: 'error', subTitle: error }

    if (submitting) return { status: 'loading' }

    return {
      status: 'info',
      title: 'Enable Node-Locked Licensing?',
      subTitle: 'You will not be able to return to standard leasing mode.'
    }
  }, [submitting, error])

  return (
    <>
      <Result {...formStatus} />
      <ModalButtons>
        <Button onClick={submit} disabled={submitting}>
          Enable Node-Locked Licensing
        </Button>
      </ModalButtons>
    </>
  )
}
