import React, { useEffect, useState, useGlobal, useContext } from 'reactn'
import * as Yup from 'yup'
import styled from 'styled-components'
import Textbox from '@kaizen-ui/textbox'
import Button from '@kaizen-ui/button'
import Spinner from '@kaizen-ui/spinner'
import { useFormik } from 'formik'
import { useFetch, useAppNotifications, ModalSelect } from 'common'
import { GLOBAL_SERVICE_INSTANCE_ID } from '../../globalState'
import { API_SERVICE_INSTANCE_NW_SHARE } from '../../api'
import { SessionExpired } from '../../Components'
import Text from '@kaizen-ui/text'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Result from '@kaizen-ui/result'

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 0.5rem;
  }
`
const StyledResult = styled.div`
  > div {
    border: none;
  }
`
const PLATFORM = {
  WINDOWS: 'windows',
  UNIX: 'unix'
}
const OPERATION = {
  MOUNT: 'mount',
  UNMOUNT: 'unmount'
}
const PlatformOptions = [
  { label: 'Windows', value: PLATFORM.WINDOWS },
  { label: 'Unix', value: PLATFORM.UNIX }
]

const networkShareRegexWin = '^(\\/)(\\/[\\w\\.\\-_]+){2,}(\\/*)$'
const networkShareRegexUnix = '^(?:[a-zA-Z0-9\\-_.]+):(\\/[a-zA-Z0-9\\-_.]+)+$'

const validationSchema = Yup.object({
  platform: Yup.string().required('Platform selection is required'),
  filepath: Yup.string()
    .required('Network share path is required')
    .when('platform', {
      is: val => val === PLATFORM.WINDOWS,
      then: Yup.string().matches(
        networkShareRegexWin,
        'Must be a valid network share path'
      )
    })
    .when('platform', {
      is: val => val === PLATFORM.UNIX,
      then: Yup.string().matches(
        networkShareRegexUnix,
        'Must be a valid network share path'
      )
    }),
  username: Yup.string().when('platform', {
    is: val => val === 'windows',
    then: Yup.string().required('Username is required')
  }),
  password: Yup.string().when('platform', {
    is: val => val === 'windows',
    then: Yup.string().required('Password is required')
  })
})

export const LogArchivalSetting = ({ onUpdate }) => {
  const { notify } = useAppNotifications()
  const theme = useContext(KaizenThemeContext)
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)
  /* const [refreshToggle, setRefreshToggle] = useState(false)*/
  const [nwShareConfig, setNwShareConfig] = useState(null)
  const [refreshToggle, setRefreshToggle] = useState(false)

  const {
    getData,
    loading: nwShareConfigLoading,
    abort,
    error: nwShareFetchErr
  } = useFetch({
    endpoint: API_SERVICE_INSTANCE_NW_SHARE,
    actionLabel: 'Get log archival setting',
    SessionExpired: SessionExpired,
    supressToast: true
  })
  const { getData: configNwShare, loading: configuring } = useFetch({
    endpoint: API_SERVICE_INSTANCE_NW_SHARE,
    actionLabel: 'Edit log archival setting ',
    SessionExpired: SessionExpired,
    method: 'POST'
  })

  useEffect(() => {
    const getNwShareSetting = async () => {
      const data = await getData()
      if (data) {
        setNwShareConfig(data)
      }
    }
    getNwShareSetting()
    return () => {
      abort()
    }
  }, [refreshToggle]) //eslint-disable-line react-hooks/exhaustive-deps

  const { platform, filepath, mount_point } = nwShareConfig || {}
  /*const { filepath, platform, mount_point } = {
    filepath: '//10.24.129.193/nls',
    platform: 'windows',
    mount_point: '/var/log/licensing'
  }*/
  const initialValues = {
    platform: platform ? platform : '',
    filepath: filepath ? filepath : '',
    username: '',
    password: '',
    operation: ''
  }

  const submit = async values => {
    if (filepath) {
      unmountNetworkShare()
    } else {
      mountNetworkShare(values)
    }
  }
  const mountNetworkShare = values => {
    const { platform, filepath, username, password } = values
    const body = {
      platform: platform,
      operation: OPERATION.MOUNT,
      filepath: filepath,
      ...(username && { username }),
      ...(password && { password })
    }
    const headers = { 'x-nv-service-instance-id': serviceInstanceId }
    configNwShare({ body, headers }).then(data => {
      if (data) {
        const msg = `Mounted network share for periodic logs backup successfully.`
        notify(msg, null, null, 'Log archival setting updated')
        setRefreshToggle(val => !val)
        onUpdate && onUpdate()
      }
    })
  }
  const unmountNetworkShare = () => {
    const body = {
      platform: platform,
      operation: OPERATION.UNMOUNT,
      filepath: filepath
    }
    const headers = { 'x-nv-service-instance-id': serviceInstanceId }
    configNwShare({ body, headers }).then(data => {
      if (data) {
        const msg = `Unmounted network share successfully.`
        notify(msg, null, null, 'Log archival setting updated')
        setRefreshToggle(val => !val)
        onUpdate && onUpdate()
      }
    })
  }

  const formik = useFormik({
    ...(filepath ? {} : { validationSchema }),
    initialValues,
    enableReinitialize: true,
    onSubmit: submit
  })

  if (nwShareConfigLoading || nwShareFetchErr)
    return (
      <StyledResult>
        <Result
          status={nwShareFetchErr ? 'error' : 'loading'}
          subTitle={nwShareFetchErr}
        />
      </StyledResult>
    )

  return (
    <div>
      <Text textStyle='label' color={theme.colors.checkbox?.foreground}>
        Platform
      </Text>
      <ModalSelect
        id={'platform'}
        name='platform'
        placeholder='Select a platform'
        options={PlatformOptions}
        isClearable={false}
        value={
          PlatformOptions.find(pf => pf.value === formik.values.platform) ||
          null
        }
        onChange={option => {
          formik.setFieldTouched('platform', true)
          formik.setFieldValue('platform', option?.value || '')
        }}
        onBlur={formik.handleBlur}
        validationMessage={formik.touched.platform && formik.errors.platform}
        valid={!formik.touched.platform || !formik.errors.platform}
        required
        disabled={platform || nwShareConfigLoading}
      />
      <Textbox
        id='filepath'
        name='filepath'
        label={`Network Share Path (use forward slashes) e.g. ${
          formik.values.platform === PLATFORM.WINDOWS
            ? '//<server_name or ip_address>/<filepath>'
            : '<server_name or ip_address>:<filepath>'
        }`}
        placeholder='A resource on a local network that can be used for periodic log archival'
        className='nvl'
        value={formik.values.filepath}
        disabled={filepath || nwShareConfigLoading}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={formik.touched.filepath && formik.errors.filepath}
      />
      {formik?.values?.platform === PLATFORM.WINDOWS && !filepath && (
        <>
          <Textbox
            id='username'
            label='Username'
            name='username'
            placeholder='Username'
            className='nvl'
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            validationMessage={
              formik.touched.username && formik.errors.username
            }
            disabled={nwShareConfigLoading}
          />
          <Textbox
            id='password'
            name='password'
            inputType='password'
            label='Password'
            placeholder='Password'
            className='nvl'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            validationMessage={
              formik.touched.password && formik.errors.password
            }
            disabled={nwShareConfigLoading}
          />
        </>
      )}
      <Textbox
        label={`Mount Point (the location where the network share ${
          filepath ? 'is' : 'will be'
        } mounted)`}
        placeholder={`The location where the network share ${
          filepath ? 'is' : 'will be'
        } mounted`}
        value={mount_point || '/var/log/licensing'}
        disabled={true}
      />
      <Buttons>
        {configuring && (
          <span style={{ marginRight: '0.5rem' }}>
            <Spinner size='tiny' />
          </span>
        )}
        <Button
          icon={{ name: 'ConnectionServerNetwork2' }}
          onClick={formik.handleSubmit}
          disabled={configuring || !formik.isValid}
        >
          {filepath ? 'Unmount' : 'Mount'}
        </Button>
      </Buttons>
    </div>
  )
}
