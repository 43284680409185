"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHistoryFilters = useHistoryFilters;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/slicedToArray"));

var _react = require("react");

var _queryString = _interopRequireDefault(require("query-string"));

var _reactRouterDom = require("react-router-dom");

function useHistoryFilters(_ref) {
  var endpoint = _ref.endpoint;
  var history = (0, _reactRouterDom.useHistory)();

  var _queryString$parse = _queryString["default"].parse(window.location.search),
      globalFilter = _queryString$parse.globalFilter,
      filters = _queryString$parse.filters;

  var _useState = (0, _react.useState)({
    globalFilter: globalFilter,
    filters: filters
  }),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      filter = _useState2[0],
      setFilter = _useState2[1];

  (0, _react.useEffect)(function () {
    var globalFilter = filter.globalFilter,
        filters = filter.filters;

    if (globalFilter || filters) {
      var parameters = {
        globalFilter: globalFilter,
        filters: typeof filters === 'string' ? filters : !filters || filters.length === 0 ? undefined : _queryString["default"].stringify(filters.reduce(function (obj, item) {
          return Object.assign(obj, (0, _defineProperty2["default"])({}, item.id, item.value));
        }, {}))
      };
      history.replace("".concat(endpoint, "?").concat(_queryString["default"].stringify(parameters)));
    } else {
      history.replace(endpoint);
    }
  }, [filter]); //eslint-disable-line react-hooks/exhaustive-deps

  return {
    filter: filter,
    setFilter: setFilter
  };
}