import React, { useContext, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Block from '@kaizen-ui/block'
//import Button from '@kaizen-ui/button'
import Progress from '@kaizen-ui/progress'
import { useFetch } from 'common'
import { SessionExpired } from '../../../Components'
import {
  API_SERVICE_INSTANCE_CONFIGURE_HA,
  HIGH_AVAILABILITY_STATUS
} from '../../../api'

const StyledBlock = styled.div`
  margin-top: 1rem;
  > div[elevation] {
    overflow-y: auto;
    overflow-x: hidden;

    button {
      z-index: 0;
    }
  }
`
const ProgressLabel = styled.div`
  //mix-blend-mode: difference;
  position: absolute;
  margin-top: 1.25rem;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
  padding: 0 1rem;
`

export const AddingNode = ({ onComplete, isClustered }) => {
  const theme = useContext(KaizenThemeContext)
  const { getData, abort } = useFetch({
    endpoint: API_SERVICE_INSTANCE_CONFIGURE_HA,
    actionLabel: 'Get high availability status',
    SessionExpired: SessionExpired
  })
  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)
  const [status, setStatus] = useState(undefined)
  const [message, setMessage] = useState(undefined)
  const label = useMemo(() => {
    if (status === HIGH_AVAILABILITY_STATUS.COMPLETED) {
      return 'Completed successfully!'
    } else if (status === HIGH_AVAILABILITY_STATUS.FAILED) {
      return 'Configuration failed!'
    } else {
      return message || isClustered
        ? 'The node is being added to the cluster'
        : 'The service instance is being configured for high availability'
    }
  }, [isClustered, message, status])

  useEffect(() => {
    const interval = setInterval(() => {
      getStatus()
    }, 5000)
    const getStatus = async () => {
      const data = await getData()
      if (data) {
        const { message, percent, status } = data

        setProgress(parseInt(percent))
        setStatus(status)
        setMessage(message)

        if (
          status === HIGH_AVAILABILITY_STATUS.COMPLETED ||
          status === HIGH_AVAILABILITY_STATUS.FAILED
        ) {
          clearInterval(interval)
          setLoading(false)

          if (status === HIGH_AVAILABILITY_STATUS.COMPLETED) {
            onComplete && onComplete()
          }
        }
      }
    }
    //getStatus()

    return () => {
      clearInterval(interval)
      abort()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledBlock theme={theme}>
      <Block
        loading={loading}
        title={
          isClustered
            ? 'Adding Node to the Cluster'
            : 'Configuring High Availability'
        }
        titleIcon={{
          name: 'SettingsWrench',
          color: theme.colors.brand,
          size: 24
        }}
      >
        <>
          <ProgressLabel>{label}</ProgressLabel>
          <div style={{ marginTop: '1rem' }}>
            <Progress
              progress={progress}
              total={100}
              size='large'
              color={
                status === HIGH_AVAILABILITY_STATUS.FAILED
                  ? theme.colors.red700
                  : theme.colors.brand
              }
            />
          </div>
        </>
      </Block>
    </StyledBlock>
  )
}
