import { useState, useEffect, useGlobal } from 'reactn'
import { useFetch } from 'common'
import { API_LICENSE_SERVER, formatLicenseServerWithFeatures } from '../../api'
import { SessionExpired } from '../../Components'
import {
  GLOBAL_ORG,
  GLOBAL_SERVER_ID,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'

export const useLicenseServer = ({
  licenseServer: propLicenseServer,
  autoLoad
} = {}) => {
  const [licenseServer, setLicenseServer] = useState(propLicenseServer)
  const [serverId] = useGlobal(GLOBAL_SERVER_ID)
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id

  useEffect(() => {
    setLicenseServer(propLicenseServer)
  }, [propLicenseServer])

  const { getData, abort, loading, error } = useFetch({
    endpoint: API_LICENSE_SERVER(orgId, vgId, serverId),
    actionLabel: 'Get license server details',
    SessionExpired: SessionExpired,
    normalizer: formatLicenseServerWithFeatures
  })

  const get = async () => {
    const data = await getData()
    if (data) {
      setLicenseServer(data)
    } else {
      setLicenseServer(undefined)
    }
    return data
  }

  useEffect(() => {
    if (!orgId || !vgId || !serverId) {
      return
    }
    autoLoad && get()
    return () => {
      abort()
    }
  }, [autoLoad, orgId, serverId, vgId]) //eslint-disable-line react-hooks/exhaustive-deps

  return {
    get,
    loading: loading,
    error: error,
    licenseServer
  }
}
