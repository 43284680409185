import React, { useState, useContext, useMemo } from 'react'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import styled from 'styled-components'
import moment from 'moment'
import Icon from '@kaizen-ui/icon'
import Button from '@kaizen-ui/button'
import Modal from '@kaizen-ui/modal'
import { Table, useAppNotifications } from 'common'
import { theme } from 'common'

export const AppNotifications = () => {
  const theme = useContext(KaizenThemeContext)
  const [open, setOpen] = useState(false)
  const { notifications } = useAppNotifications()
  const hasNotifications = notifications.length > 0
  const sorted = React.useMemo(
    () => notifications.sort((a, b) => moment(a.date).isAfter(b.date)),
    [notifications]
  )

  const cols = useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.values.action}
            {row.original.isError && (
              <span style={{ marginLeft: '0.5rem', display: 'flex' }}>
                <Icon
                  name='MessengerSquareExclamation'
                  variant='solid'
                  color={theme.colors.textbox.validationMessage}
                />
              </span>
            )}
          </div>
        )
      },
      {
        Header: 'Message',
        accessor: 'message',
        width: 300
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ row }) => moment(row.values.date).format('h:mm:ss a')
      }
    ],
    [theme]
  )

  return hasNotifications ? (
    <>
      <Modal
        title='Previous Notifications'
        size='large'
        backgroundClose
        open={open}
        onClose={() => setOpen(false)}
      >
        <Table
          columns={cols}
          data={sorted}
          label='notifications'
          minHeight={1}
          disableColumnHiding
          onBeforeExport={() => setOpen(false)}
        />
      </Modal>
      <StyledButton data-tip={`${notifications.length} previous notifications`}>
        <Button
          variant='link'
          onClick={() => setOpen(true)}
          type='primary'
          icon={{
            name: 'MessengerSquareChat',
            variant: 'solid',
            color: theme.colors.greenPrimary
          }}
        >
          <span
            style={{
              color: theme.darkMode
                ? theme.colors.lightGray000
                : theme.colors.darkGray900
            }}
          >
            {notifications.length}
          </span>
        </Button>
      </StyledButton>
    </>
  ) : (
    <></>
  )
}

const StyledButton = styled.div`
  .button-text {
    color: #fff;
    text-decoration: none !important;
  }
`

const StyledNotificationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 1rem 0.25rem 1rem;
`
const StyledNotification = styled.div`
  border-bottom: 2px solid ${theme.colors.greenPrimary};

  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    font-weight: 300;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    color: ${theme.colors.greenPrimary};
  }

  svg {
    margin-right: 0.5rem;
  }

  span {
    text-align: center;
    display: block;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }
`

export const Notification = ({ data }) => {
  const { message, orgNames } = data
  const isGlobal = !orgNames
  const theme = useContext(KaizenThemeContext)

  return (
    <>
      <StyledNotification>
        <StyledNotificationHeader>
          <Icon
            name='StatusCircleInformation'
            color={theme.colors.greenPrimary}
            size='larger'
          />
          <h2>System Notification</h2>
        </StyledNotificationHeader>
        <span>
          {`A notification for ${
            isGlobal ? 'all customers' : 'your organization'
          }`}
        </span>
      </StyledNotification>
      <p>{message}</p>
    </>
  )
}
