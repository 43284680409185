import React, {
  useMemo,
  useContext,
  useEffect,
  useCallback,
  useState,
  useGlobal
} from 'reactn'
import queryString from 'query-string'
import { Prompt } from 'react-router'
import ReactTooltip from 'react-tooltip'
import Icon from '@kaizen-ui/icon'
import Button from '@kaizen-ui/button'
import Modal from '@kaizen-ui/modal'
// import Text from '@kaizen-ui/text'
// import posed, { PoseGroup } from 'react-pose'
import ActionMenu, { ActionMenuItem } from '@kaizen-ui/action-menu'
import {
  SelectColumnFilter,
  Table,
  useAppNotifications,
  useFetch
} from 'common'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import { FulfillmentConditionDetails } from './FulfillmentConditionDetails'
import {
  API_FULFILLMENT_CONDITIONS,
  FC_TYPES,
  LS_STATUS_DISABLED,
  LS_STATUS_ENABLED
} from '../../api'
import { useHistory } from 'react-router-dom'
import { ROUTE_DASHBOARD } from '../../Components'
import { FulfillmentConditionStatus } from './FulfillmentConditionStatus'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'
import { CreateCondition } from '../../Components/LicenseServerActions/CreateCondition'
import { DeleteFulfillmentCondition } from './DeleteFulfillmentCondition'

export const FullfillmentConditions = ({
  server,
  refresh,
  lastUpdate,
  onTabChange
}) => {
  const { notify } = useAppNotifications()
  const { fulfillmentConditions, id, status, name } = server
  const history = useHistory()
  const theme = useContext(KaizenThemeContext)
  const [fcSavePrompt, setFcSavePrompt] = useState(false)
  const [rows, setRows] = useState(undefined)
  const { globalFilter, filters } = queryString.parse(window.location.search)
  const [filter, setFilter] = useState({ globalFilter, filters })
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const isFiltered = filter?.filters?.length > 0 || filter?.globalFilter
  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_FULFILLMENT_CONDITIONS(orgId, vgId, id),
    actionLabel: `Save FC order for ${name}`,
    method: 'PATCH',
    responseOnly: true
  })
  const submit = async () => {
    const body = {
      fulfillmentConditions: rows.map((fc, index) => {
        return {
          evaluationOrderIndex: index,
          fulfillmentConditionId: fc.id
        }
      })
    }

    const result = await sendRequest({ body })
    if (result) {
      const msg = `Fulfillment condition ordering for ${name} was successfully saved!`
      notify(msg, null, null, `Save FC order for ${name}`)
      setRows(undefined)
      setFcSavePrompt(false)
      ReactTooltip.hide()
      refresh && refresh()
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Order',
        accessor: 'evaluationOrderIndex',
        canResize: false,
        disableFilters: true,
        hidden: isFiltered,
        width: 85,
        Cell: ({ row }) => {
          const { evaluationOrderIndex } = row.values
          const changed = evaluationOrderIndex !== row.index

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{row.index}</span>
              {changed && (
                <span
                  data-tip='The binding order has changed for this pool, but has not yet been saved'
                  style={{ display: 'flex', marginLeft: '0.5rem' }}
                >
                  <Icon name='AwardsFlag1' variant='solid' />
                </span>
              )}
            </div>
          )
        }
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Type',
        accessor: 'selectionRuleName',
        Filter: SelectColumnFilter
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectColumnFilter,
        Cell: ({ row }) => {
          const { status } = row.original
          const serverDisabled = server.status === LS_STATUS_DISABLED
          const disabled = status === LS_STATUS_DISABLED
          const style = serverDisabled
            ? {
                style: { color: theme.colors.textbox.placeholder },
                'data-tip':
                  'This server is in edit mode, it will not serve licenses'
              }
            : disabled
            ? {
                style: { color: theme.colors.red500 },
                'data-tip':
                  'This fulfillment condition is in edit mode, it will not serve licenses'
              }
            : { style: {} }
          return <span {...style}>{status}</span>
        }
      },
      {
        Header: 'Description',
        accessor: 'description',
        hideOnLoad: true
      }
    ],
    [theme, isFiltered, server]
  )

  const rowDetails = useCallback(
    ({ row }) => {
      return (
        <FulfillmentConditionDetails
          condition={row.original}
          serverId={id}
          refresh={refresh}
          onTabChange={onTabChange}
        />
      )
    },
    [id, onTabChange, refresh]
  )

  const rowActions = useCallback(
    (row, related) => (
      <FulfillmentActions
        fulfillmentCondition={row.original}
        related={related}
        onUpdate={refresh}
        server={server}
      />
    ),
    [refresh, server]
  )

  useEffect(() => {
    const { globalFilter, filters } = filter

    if (globalFilter || filters) {
      const parameters = {
        globalFilter,
        filters:
          typeof filters === 'string'
            ? filters
            : !filters || filters.length === 0
            ? undefined
            : queryString.stringify(
                filters.reduce(
                  (obj, item) => Object.assign(obj, { [item.id]: item.value }),
                  {}
                )
              )
      }
      const route = `${ROUTE_DASHBOARD}?tab=4&${queryString.stringify(
        parameters
      )}`
      history.replace(route)
    } else {
      const route = `${ROUTE_DASHBOARD}?tab=4`
      history.replace(route)
    }
  }, [filter]) //eslint-disable-line react-hooks/exhaustive-deps

  const [userFilters] = React.useState(
    Object.entries(queryString.parse(filter?.filters)).map(f => {
      return { id: f[0], value: f[1] }
    })
  )

  return (
    <>
      <Prompt
        when={fcSavePrompt}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <div
        style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5rem' }}
      >
        <Icon name='StatusCircleInformation' />
        <span
          style={{
            marginLeft: '0.5rem',
            color: theme.colors.textbox.placeholder
          }}
        >
          Fulfillment conditions configured as &apos;reference match&apos; can
          be reordered by drag and drop. The license server must be disabled to
          change the evaluation order.
        </span>
      </div>
      <Table
        label='fulfillment conditions'
        columns={columns}
        data={fulfillmentConditions}
        //onFilter={onFilter}
        disableSorting
        disabled={submitting}
        fetching={submitting}
        disableFiltering={fcSavePrompt}
        renderRowSubComponent={rowDetails}
        useDragAndDrop={
          fulfillmentConditions.length > 1 &&
          !isFiltered &&
          server?.status === LS_STATUS_DISABLED
        }
        dragName={`fcs-${id}`}
        blockDrag={{ name: 'selectionRuleName', value: FC_TYPES.UNIVERSAL }}
        onIndexChanged={rows => {
          const changes = rows.filter(
            (row, i) => row.evaluationOrderIndex !== i
          )
          const hasChanges = changes.length > 0
          setFcSavePrompt(hasChanges)
          if (hasChanges) {
            setRows(rows)
          } else {
            setRows(undefined)
          }
        }}
        saveLabel={'Save the evaluation order of your fulfillment conditions'}
        save={fcSavePrompt ? submit : null}
        refresh={refresh}
        lastUpdate={lastUpdate}
        relatedData={{ serverStatus: status }}
        onFilter={setFilter}
        rowActions={rowActions}
        globalSearch={globalFilter}
        filters={userFilters}
      />
    </>
  )
}

const FulfillmentActions = ({
  fulfillmentCondition,
  related,
  onUpdate,
  server
}) => {
  const theme = useContext(KaizenThemeContext)
  const [statusOpen, setStatusOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { status } = fulfillmentCondition
  const { serverStatus } = related
  const allowActions =
    serverStatus === LS_STATUS_DISABLED ||
    (serverStatus === LS_STATUS_ENABLED && status === LS_STATUS_DISABLED)
  const isEnabled = status === LS_STATUS_ENABLED

  return (
    <>
      <ActionMenu
        width={190}
        parentElement={
          <div
            data-tip={
              allowActions
                ? 'Select actions available for this fulfillment condition'
                : 'Cannot make changes to this fulfillment condition while it is enabled'
            }
          >
            <Button
              icon={{
                name: 'ActionsDrawer',
                color: theme.colors.brand
              }}
              type='secondary'
              variant='link'
              className='bright'
              //disabled={!allowActions}
            >
              Actions
            </Button>
          </div>
        }
        position='top-right'
        trigger='click'
      >
        <React.Fragment key='.0'>
          {/* <ActionMenuItem label='Edit' icon={{ name: 'ActionsEdit' }} disabled={!allowActions} /> */}
          <ActionMenuItem
            label={isEnabled ? 'Disable' : 'Enable'}
            icon={{
              name: isEnabled ? 'PlaybackCirclePause' : 'PlaybackCirclePlay'
            }}
            onClick={() => setStatusOpen(true)}
          />
          <ActionMenuItem
            label='Edit'
            icon={{ name: 'ActionsEdit' }}
            disabled={!allowActions}
            onClick={() => setEditOpen(true)}
          />
          <ActionMenuItem
            label='Delete'
            itemStyle='critical'
            icon={{ name: 'ActionsTrash', variant: 'solid' }}
            disabled={!allowActions}
            onClick={() => setDeleteOpen(true)}
          />
        </React.Fragment>
      </ActionMenu>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={
            isEnabled
              ? 'Disable Fulfillment Condition'
              : 'Enable Fulfillment Condition'
          }
          subtitle='Toggle the status of this fulfillment condition'
          className='noScroll custom'
          open={statusOpen}
          onClose={() => setStatusOpen(false)}
        >
          {statusOpen && (
            <FulfillmentConditionStatus
              fulfillmentCondition={fulfillmentCondition}
              server={server}
              onUpdate={() => {
                setStatusOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={'Edit Fulfillment Condition'}
          subtitle='Update the name, description, match type, or bound pools of this fulfillment condition'
          //closeOnBackdropClick={false}
          open={editOpen}
          onClose={() => setEditOpen(false)}
          size='large'
          className='custom'
        >
          {editOpen && (
            <CreateCondition
              fulfillmentCondition={fulfillmentCondition}
              server={server}
              onUpdate={() => {
                setEditOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={'Delete Fulfillment Condition'}
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          className='autow'
        >
          {deleteOpen && (
            <DeleteFulfillmentCondition
              fulfillmentCondition={fulfillmentCondition}
              server={server}
              onUpdate={() => {
                setDeleteOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
    </>
  )
}
