import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Button, { ButtonGroup } from '@kaizen-ui/button'
import Text from '@kaizen-ui/text'
import Icon from '@kaizen-ui/icon'
import { ROUTE_LOGIN, ROUTE_DASHBOARD } from '../../Components'
import { useAuth } from '../../hooks'
import styled from 'styled-components'

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  p {
    margin-bottom: 2rem;
  }
`

export const RouteError = () => {
  const theme = useContext(KaizenThemeContext)
  const history = useHistory()
  //const location = useLocation()
  const auth = useAuth()
  const { user } = auth
  const [loading, setLoading] = useState(true)

  //const { from } = location.state || { from: { pathname: '/' } }

  /**   entering a protected page by entering the URL directly in the browser 
        will result in the error page being displayed due to the route being 
        unavailable prior to authentication, check here if the user 
        is loaded and take appropriate action
    */

  React.useEffect(() => {
    if (!user) {
      history.push(ROUTE_LOGIN)
    } else {
      setLoading(false)
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* <FloatingSpinner visible={loading} /> */}
      {!loading && (
        <>
          <div status='info' title='This page does not exist' />

          {user && (
            <Centered>
              <StyledNoData>
                <div>
                  <StyledIcon>
                    <Icon
                      name='FileError'
                      size={64}
                      color={theme.colors.lightGray000}
                    />
                  </StyledIcon>
                  <div>
                    <Text textStyle='h3'>
                      This page does not exist, perhaps a page was renamed or
                      the URL was mistyped.
                    </Text>
                  </div>
                </div>
              </StyledNoData>

              <ButtonGroup>
                <Button
                  icon={{ name: 'ArrowPrevious' }}
                  onClick={history.goBack}
                >
                  Go Back
                </Button>
                <Button
                  icon={{ name: 'ObjectsHome' }}
                  onClick={() => history.push(ROUTE_DASHBOARD)}
                >
                  Go to Dashboard
                </Button>
              </ButtonGroup>
            </Centered>
          )}
        </>
      )}
    </div>
  )
}

const StyledNoData = styled.div`
  padding: 2rem;
  display: flex;
  flex: 1;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
const StyledIcon = styled.div`
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.darkGray300};
`
