import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Textbox from '@kaizen-ui/textbox'
import Password from '@kaizen-ui/password'
import Button from '@kaizen-ui/button'
import Icon from '@kaizen-ui/icon'
import { ModalSpinner, useFetch } from 'common'
import { useAuth } from '../../hooks'
import { API_USER_PW_CHANGE } from '../../api'

const PullRight = styled.div`
  display: flex;
  justify-content: flex-end;
`
const StyledSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > h2 {
    margin-left: 0.5rem;
    font-weight: 100;
  }

  b {
    margin-left: 0.5rem;
  }
`

const validationSchema = Yup.object({
  oldPassword: Yup.string().required('Current password is required.'),
  password: Yup.string()
    .matches(/^\S*$/, 'Should not contain any whitespaces')
    .min(8, 'Must have at least 8 characters')
    .max(64, 'Must have fewer than 64 characters')
    .required('You must enter a password'),
  passwordCopy: Yup.string().when('password', {
    is: val => {
      return val?.length > 0
    },
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'Both passwords must match')
      .required('Both passwords must match'),
    otherwise: Yup.string().required('You must enter a password')
  })
})

export const ChangePassword = ({
  setChangePasswordOpen,
  setHasCredsChanged
}) => {
  const theme = useContext(KaizenThemeContext)
  const [localResetSecret, setLocalResetSecret] = useState('')
  const [secretCopied, setSecretCopied] = useState(false)
  const { user, serviceInstanceId, signout } = useAuth()

  const { getData: sendPwChange, loading: submitting } = useFetch({
    endpoint: API_USER_PW_CHANGE,
    actionLabel: `Change password for ${user?.clientId}`,
    method: 'POST'
  })

  const initialValues = {
    oldPassword: '',
    password: '',
    passwordCopy: ''
  }

  const submit = async values => {
    ReactTooltip.hide()
    const headers = { 'x-nv-service-instance-id': serviceInstanceId }
    const body = {
      new: values.password,
      current: values.oldPassword,
      username: user?.clientId
    }

    const data = await sendPwChange({ body, headers })
    if (data?.localResetSecret) {
      setLocalResetSecret(data?.localResetSecret)
      setHasCredsChanged(true)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: submit,
    validationSchema
  })

  return (
    <>
      {submitting && <ModalSpinner />}
      {localResetSecret ? (
        <>
          <StyledSuccess>
            <Flex>
              <Icon
                name='StatusCircleCheck2'
                variant='solid'
                color={theme.colors.brand}
                size='large'
              />
              <h2>Password changed successfully!</h2>
            </Flex>
            <div>
              Your username is <b>{formik.values.email}</b>
            </div>
            <Flex>
              Your local reset secret is <b>{localResetSecret}</b>
              <div data-tip='Copy secret to clipboard'>
                <CopyToClipboard
                  text={localResetSecret}
                  onCopy={() => setSecretCopied(true)}
                >
                  <Button
                    type='success'
                    variant='link'
                    icon={{
                      name: secretCopied ? 'StatusCircleCheck2' : 'FileCopy'
                    }}
                  />
                </CopyToClipboard>
              </div>
            </Flex>
            <br />
            <div style={{ marginBottom: '1rem' }}>
              Copy this local reset secret and store it in a secure location. In
              case the password is forgotten, this value can be used one time to
              reset the password.
            </div>
            <Button
              primary
              onClick={() => {
                setLocalResetSecret('')
                setChangePasswordOpen(false)
                signout()
              }}
            >
              Continue to Login
            </Button>
          </StyledSuccess>
        </>
      ) : (
        <>
          <form>
            <div>
              <div>
                <Textbox
                  label='Username'
                  value={user?.clientId}
                  disabled={true}
                />
              </div>
            </div>
            <div>
              <div data-tip='Enter the current password'>
                <Password
                  name='oldPassword'
                  label='Current password'
                  className='nvl'
                  showVisibilityToggle={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.oldPassword}
                  validationMessage={
                    formik.touched.oldPassword && formik.errors.oldPassword
                  }
                  valid={
                    formik.touched.oldPassword && !formik.errors.oldPassword
                  }
                />
              </div>
            </div>
            <div>
              <div data-tip='Enter the new password'>
                <Password
                  name='password'
                  label='New password'
                  className='nvl'
                  showVisibilityToggle={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  validationMessage={
                    formik.touched.password && formik.errors.password
                  }
                  valid={formik.touched.password && !formik.errors.password}
                />
              </div>
            </div>
            <div>
              <div data-tip='Enter the new password again'>
                <Password
                  name='passwordCopy'
                  label='Confirm new password'
                  className='nvl'
                  showVisibilityToggle={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordCopy}
                  validationMessage={
                    formik.touched.passwordCopy && formik.errors.passwordCopy
                  }
                  valid={
                    formik.touched.passwordCopy && !formik.errors.passwordCopy
                  }
                />
              </div>
            </div>

            <PullRight>
              <Button
                tag='submit'
                onClick={formik.handleSubmit}
                disabled={submitting}
              >
                Change Password
              </Button>
            </PullRight>
          </form>
        </>
      )}
    </>
  )
}
