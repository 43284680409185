import React, {
  useState,
  useGlobal,
  useContext,
  useMemo,
  useEffect,
  useCallback
} from 'reactn'
import styled from 'styled-components'
import moment from 'moment'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Icon from '@kaizen-ui/icon'
import {
  GLOBAL_ORG,
  GLOBAL_SERVICE_INSTANCE_ID,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'
import {
  useFetch,
  PageHeader,
  Table,
  formatDateTime,
  FloatingDateRangePicker,
  SelectOptionsColumnFilter
} from 'common'
import { ROUTE_EVENTS, SessionExpired } from '../../Components'
import { API_EVENTS, formatEventsForList } from '../../api'
import { Details } from './Details'
import Modal from '@kaizen-ui/modal'
import { useExportFileGenStatus } from '../../hooks/useExportFileGenStatus'
import { ExportEvents } from './ExportEvents'

const defaultRange = {
  start: moment(new Date())
    .add(-7, 'days')
    .startOf('day')
    .utc()
    .format(),
  end: moment(new Date())
    .endOf('day')
    .utc()
    .format()
}

export const Events = () => {
  const history = useHistory()
  const theme = useContext(KaizenThemeContext)
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const [pageLoading, setPageLoading] = useState(true)
  const [pageCount, setPageCount] = useState(0)
  const [totalResults, setTotalResults] = useState(0)
  const { start, end } = queryString.parse(window.location.search)
  const [filter, setFilter] = useState({ serviceInstanceId, start, end })
  const { generationStatus, setRefreshStatus } = useExportFileGenStatus(
    serviceInstanceId
  )
  const [events, setEvents] = useState([])
  const [refreshFilters, setRefreshFilters] = useState(null)
  const [exportEventsOpen, setExportEventsOpen] = useState(false)
  const { getData, loading, error, lastUpdate } = useFetch({
    endpoint: API_EVENTS(orgId, vgId),
    method: 'POST',
    actionLabel: 'Get events',
    SessionExpired: SessionExpired,
    normalizer: formatEventsForList,
    supressToast: true
  })
  const selectedDateRange = useMemo(() => {
    const { start, end } = filter
    const startVal = start ? start : defaultRange.start
    const endVal = end ? end : defaultRange.end

    return { start: new Date(startVal), end: new Date(endVal) }
  }, [filter])

  useEffect(() => {
    if (filter) {
      history.replace(`${ROUTE_EVENTS}?${queryString.stringify(filter)}`)
    } else {
      history.replace(ROUTE_EVENTS)
    }
  }, [filter]) //eslint-disable-line react-hooks/exhaustive-deps

  const quickFilterOptions = useMemo(() => {
    return serviceInstanceId
      ? [
          {
            label: 'Admin',
            field: 'eventCategory',
            value: 200,
            iconName: 'ViewHeadline'
          },
          {
            label: 'Leasing',
            field: 'eventCategory',
            value: 100,
            iconName: 'ViewHeadline',
            iconVariant: 'solid'
          },
          {
            label: 'Critical',
            field: 'isCritical',
            value: 'CRITICAL',
            iconName: 'StatusWarning'
          }
        ]
      : []
  }, [serviceInstanceId])

  React.useEffect(() => {
    document.title = 'DLS - Events'
  }, [])

  const columns = useMemo(() => {
    const requiresSi = serviceInstanceId ? {} : { hidden: true }

    return [
      {
        Header: 'Type',
        accessor: 'eventCategory',
        canResize: false,
        disableSortBy: true,
        disableFilters: !serviceInstanceId,
        //Filter: CategoryColumnFilter
        Filter: props => (
          <SelectOptionsColumnFilter
            {...props}
            options={[
              { label: 'All', value: '' },
              {
                label: 'Admin',
                value: 200
              },
              { label: 'Leasing', value: 100 }
            ]}
          />
        )
      },
      {
        Header: 'User',
        accessor: 'eventBy',
        disableSortBy: true,
        disableFilters: true
      },
      {
        Header: 'Status',
        accessor: 'isCritical',
        canResize: false,
        hideOnLoad: true,
        width: 150,
        ...requiresSi,
        //Filter: StatusColumnFilter,
        //Filter: props => <StatusColumnFilter {...props} />,
        Filter: props => (
          <SelectOptionsColumnFilter
            {...props}
            options={[
              { label: 'All', value: '' },
              {
                label: 'Critical',
                value: 'CRITICAL'
              }
            ]}
          />
        ),
        Cell: ({ row }) => {
          const { isCritical } = row.values

          return isCritical ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                name='AwardsFlag1'
                variant='solid'
                color={theme.colors.textbox.validationMessage}
              />
              <span style={{ marginLeft: '0.5rem' }}>Critical</span>
            </div>
          ) : (
            <></>
          )
        }
      },
      // {
      //   Header: 'User',
      //   accessor: 'eventBy',
      //   width: 75
      // },
      {
        Header: 'Description',
        accessor: 'description',
        hidden: false,
        width: 300,
        disableFilters: true
      },
      {
        Header: 'Date',
        width: 75,
        accessor: 'eventTime',
        disableFilters: true,
        Cell: ({ row }) => <>{formatDateTime(row.values.eventTime)}</>
      },
      {
        accessor: 'operation_id',
        Header: 'Operation Id',
        ...requiresSi
      }
    ]
  }, [serviceInstanceId, theme])

  const rowDetails = useCallback(({ row }) => {
    return <Details data={row.original.detail} />
  }, [])

  const getEvents = async ({
    pageSize,
    pageIndex,
    page_size,
    page_number,
    filters
  }) => {
    const { start, end } = filter
    const headers = {
      'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId
    }

    const startVal = start
      ? start
      : moment(new Date())
          .add(-7, 'days')
          .startOf('day')
          .utc()
          .format()
    const endVal = end
      ? end
      : moment(new Date())
          .endOf('day')
          .utc()
          .format()
    const category = filters?.find(f => f.id === 'eventCategory')
    const isCritical = filters?.find(f => f.id === 'isCritical')
    const operationId = filters?.find(f => f.id === 'operation_id')?.value
    const activityList = category ? [category.value] : [100, 200]
    const priority = isCritical ? { priority: 'CRITICAL' } : {}
    const body = {
      page_size: page_size || pageSize,
      page_number: page_number || pageIndex || 0,
      ival: { start: startVal, end: endVal },
      activityList,
      ...(operationId ? { operationId } : {}),
      ...priority
    }
    const data = await getData({
      body,
      headers
    })

    if (data && data.events) {
      const count =
        (data && data.paginationInfo && data.paginationInfo.totalPages) || 0
      const total =
        (data && data.paginationInfo && data.paginationInfo.totalResults) || 0
      setEvents(data)
      setPageCount(count)
      setTotalResults(total)
    } else {
      setEvents([])
      setPageCount(0)
      setTotalResults(0)
    }
    setRefreshFilters(body)
    setPageLoading(false)
  }

  const updateTableData = useCallback(getEvents, [vgId, filter]) //eslint-disable-line react-hooks/exhaustive-deps

  const resetOn = useMemo(() => {
    return { vgId, filter }
  }, [vgId, filter])

  return (
    <>
      <PageHeader
        title='Events'
        subTitle={'View events in this service instance'}
        footer={
          <>
            <div style={{ marginTop: '0.5rem', display: 'flex' }}>
              <FloatingDateRangePicker
                tip='Select a date range to limit the events displayed'
                onChange={val => {
                  const { start, end, ...rest } = filter

                  if (val.start && val.end) {
                    setFilter({
                      start: moment(val.start)
                        .utc()
                        .format(),
                      end: moment(val.end)
                        .utc()
                        .format(),
                      ...rest
                    })
                  } else {
                    setFilter({ ...rest })
                  }
                }}
                value={selectedDateRange}
                defaultValue={defaultRange}
              />
            </div>
          </>
        }
      >
        {/* <Button
          type='secondary'
          onClick={() => setExportEventsOpen(true)}
          icon={{ name: 'CloudDownload' }}
        >
          Bulk Export
        </Button>*/}
      </PageHeader>

      <StyledModal onClick={e => e.stopPropagation()} theme={theme}>
        <Modal
          title='Export Events Data'
          subtitle='Events will be exported to a .log file in csv format.'
          open={exportEventsOpen}
          onClose={() => setExportEventsOpen(false)}
          className='modal-export-events'
          size={'large'}
        >
          {exportEventsOpen && (
            <ExportEvents
              generationStatus={generationStatus}
              setRefreshStatus={setRefreshStatus}
              sid={serviceInstanceId}
              start={start}
              end={end}
              activityList={refreshFilters?.activityList || []}
            />
          )}
        </Modal>
      </StyledModal>
      <Table
        columns={columns}
        data={(events && events.events) || []}
        renderRowSubComponent={rowDetails}
        loading={pageLoading}
        fetching={loading}
        error={error}
        label={'events'}
        refresh={() => getEvents(refreshFilters)}
        lastUpdate={lastUpdate}
        disableExporting={!serviceInstanceId || !events.events}
        disableSearch
        disableSorting
        allowExpand={original =>
          original.detail ? Object.keys(original.detail).length > 0 : false
        }
        onFetch={updateTableData}
        pageCount={pageCount}
        totalResults={totalResults}
        resetOn={resetOn}
        onExport={() => {
          setExportEventsOpen(true)
        }}
        quickFilters={quickFilterOptions}
      />
    </>
  )
}

const StyledModal = styled.div`
  .modal-export-events {
    background-color: ${({ theme }) => theme.colors.pageBackground};
    .modal-subtitle {
      margin-bottom: 0 !important;
    }
  }
`
