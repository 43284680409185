import React from 'react'
import { useAppNotifications, useFetch } from 'common'
import { API_CLUSTER_NODE_REMOVE } from '../../../api'
import Result from '@kaizen-ui/result'
import { SessionExpired } from '../../../Components'

export const RemoveNode = ({ node, onUpdate }) => {
  const { notify } = useAppNotifications()
  const { fqdn, ip_v6_address, network_location } = node || {}
  const {
    getData: removeNodeAPI,
    loading: removingNode,
    error: removeNodeError
  } = useFetch({
    endpoint: API_CLUSTER_NODE_REMOVE,
    actionLabel: 'Remove node from the cluster',
    method: 'POST',
    responseOnly: true,
    SessionExpired: SessionExpired
  })

  const removeNode = async () => {
    const body = {
      nodeIp: fqdn || ip_v6_address || network_location, //fqdn/ipv6/ipv4 preference
      retainQueue: false, //always to be passed as false from UI
      makeStandAlone: false // pass false for removing single node
    }
    const ok = await removeNodeAPI({ body })
    if (ok) {
      notify(
        `The secondary node ${fqdn ||
          ip_v6_address ||
          network_location} was successfully removed from the cluster!`,
        null,
        null,
        'Removed node from high availability cluster'
      )
      onUpdate && onUpdate()
    }
  }

  return (
    <div>
      <Result
        status={removingNode ? 'loading' : removeNodeError ? 'error' : 'info'}
        title={
          removingNode
            ? 'Removing node'
            : removeNodeError
            ? undefined
            : 'Are you sure?'
        }
        subTitle={
          removingNode
            ? ''
            : removeNodeError
            ? removeNodeError
            : `This will remove the secondary node ${fqdn ||
                ip_v6_address ||
                network_location} from HA cluster. The removed node will be cleaned up.`
        }
        actions={[
          {
            children: 'Remove Node',
            onClick: removeNode,
            disabled: removingNode,
            type: 'critical',
            key: 0
          }
        ]}
      />
    </div>
  )
}
