import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Icon from '@kaizen-ui/icon'
import { Text } from '@kaizen-ui/complete'
import Radio, { RadioGroup } from '@kaizen-ui/radio'
import { HIGH_AVAILABILITY_NODE_ROLE, IP_ADDRESS_TYPE } from '../../api'

const Outer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.result.border};
  background: ${({ theme }) => theme.colors.result.background};
  margin-bottom: 1rem;
  padding: 1rem;
`
const NodeTitle = styled.div`
  margin: 0 0 1rem 0;
  display: flex;
  align-items: center;

  > div {
    display: flex;
    margin-right: 0.5rem;
  }
  > span {
    margin-right: 1rem;
  }
`

export const NodeSection = ({
  node,
  onSelectionChange,
  ind,
  showInd,
  selection
}) => {
  const theme = useContext(KaizenThemeContext)
  const { fqdn, network_location, ip_v6_address, role, down_time, node_id } =
    node || {}
  const [addressType, setAddressType] = useState(
    selection?.addressType ||
      (fqdn
        ? 'FQDN'
        : ip_v6_address
        ? IP_ADDRESS_TYPE.IPV6
        : IP_ADDRESS_TYPE.IPV4)
  )

  useEffect(() => {
    onSelectionChange({
      node_id,
      role,
      addressType,
      ...(addressType === 'FQDN'
        ? { address: fqdn }
        : addressType === IP_ADDRESS_TYPE.IPV6
        ? { address: ip_v6_address }
        : { address: network_location })
    })
  }, [addressType]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Outer theme={theme} key={node?.node_id}>
      <NodeTitle>
        {showInd && <Text textStyle={'h3'}>{`${ind + 1}.`}</Text>}

        <div>
          <Icon
            name={'ComputerScreen2'}
            size={22}
            color={
              down_time
                ? theme.colors.red500
                : role === HIGH_AVAILABILITY_NODE_ROLE.PRIMARY
                ? theme.colors.brand
                : ''
            }
          />
        </div>
        <Text textStyle={'h3'}>{`${role} Node`}</Text>
      </NodeTitle>
      <div
        onClick={e => {
          e.stopPropagation()
        }}
        key={`addressType-${addressType}`}
        style={{ overflow: 'auto' }}
      >
        <RadioGroup
          inline={true}
          id={'addressType'}
          name='addressType'
          onChange={e => setAddressType(e.target.value)}
          selected={addressType}
        >
          <Radio
            id={`FQDN${ind}`}
            label={`FQDN (${fqdn || 'not available'})`}
            disabled={!fqdn}
            value={'FQDN'}
          />
          <Radio
            id={`${IP_ADDRESS_TYPE.IPV6}${ind}`}
            label={`IPv6 (${ip_v6_address || 'not assigned'})`}
            disabled={!ip_v6_address}
            value={IP_ADDRESS_TYPE.IPV6}
          />
          <Radio
            id={`${IP_ADDRESS_TYPE.IPV4}${ind}`}
            label={`IPv4 (${network_location || 'not assigned'})`}
            disabled={!network_location}
            value={IP_ADDRESS_TYPE.IPV4}
          />
        </RadioGroup>
      </div>
    </Outer>
  )
}
