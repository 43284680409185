import React, {
  useState,
  useGlobal,
  //useContext,
  useMemo,
  useEffect,
  useCallback,
  useContext
} from 'reactn'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import {
  GLOBAL_ORG,
  GLOBAL_SERVER_ID,
  GLOBAL_SERVICE_INSTANCE_ID,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'
import {
  useFetch,
  PageHeader,
  Table,
  formatDateTime,
  SelectColumnFilter,
  FileUpload,
  useAppNotifications
} from 'common'
import Button, { ButtonGroup } from '@kaizen-ui/button'
import {
  ROUTE_LEASES,
  SessionExpired,
  LeaseActions,
  LeaseActionsNLL
} from '../../Components'
import {
  API_LEASES,
  API_LICENSE_SERVER,
  API_LOCKED_LEASES,
  API_RESET_FORCE_RELEASE,
  formatLeasesFlat,
  formatLicenseServerWithFeatures,
  formatNllTransactions,
  LS_LEASING_MODES
} from '../../api'
import Modal from '@kaizen-ui/modal'
import { ForceRelease } from '../../Components/LeaseActions/LeaseActions'
import { Banner, Icon, Spinner, Text } from '@kaizen-ui/complete'
import styled from 'styled-components'
import Block from '@kaizen-ui/block'
import { KaizenThemeContext } from '@kaizen-ui/styles'
const StyledHeader = styled.span`
  display: flex;
  white-space: normal;
  align-items: center;
  > span {
    margin-left: 0.5rem;
  }
`
export const Leases = () => {
  const history = useHistory()
  const theme = useContext(KaizenThemeContext)
  const [serverId] = useGlobal(GLOBAL_SERVER_ID)
  const [server, setServer] = useState({})
  const { leasingMode } = server

  const [refresh, setRefresh] = useState(false)
  const [refreshNllLeases, setRefreshNllLeases] = useState(false)
  //const theme = useContext(KaizenThemeContext)
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const [pageLoading, setPageLoading] = useState(true)
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)

  const { globalFilter, filters } = queryString.parse(window.location.search)
  const [filter, setFilter] = useState({ globalFilter, filters })
  const [leases, setLeases] = useState([])
  const [nllLeases, setNllLeases] = useState([])
  const [open, setOpen] = useState(false)
  const [canReleaseAll, setCanReleaseAll] = React.useState(false)
  const [file, setFile] = useState(undefined)
  const [clear, setClear] = React.useState(false)
  const { notify } = useAppNotifications()

  const {
    getData: getLSDetails,
    abort: abortLS,
    error: getLSError,
    loading: loadingLS
  } = useFetch({
    endpoint: API_LICENSE_SERVER(orgId, vgId, serverId),
    actionLabel: 'Get license server details',
    SessionExpired: SessionExpired,
    normalizer: formatLicenseServerWithFeatures
  })

  const {
    getData: getLeaseData,
    abort: abortLeases,
    error: leaseError,
    loading: loadingLeases,
    lastUpdate: lastUpdateLeases
  } = useFetch({
    endpoint: API_LEASES(orgId, vgId),
    actionLabel: `Get leases for service instance ${serviceInstanceId}`,
    SessionExpired: SessionExpired,
    normalizer: formatLeasesFlat,
    supressToast: true
  })

  const {
    getData: getNllLeaseData,
    abort: abortNllLeases,
    error: leaseNllError,
    loading: loadingNllLeases,
    lastUpdate: lastUpdateNllLeases
  } = useFetch({
    endpoint: API_LOCKED_LEASES(orgId, vgId),
    actionLabel: `Get leases for service instance ${serviceInstanceId}`,
    SessionExpired: SessionExpired,
    normalizer: formatNllTransactions,
    supressToast: true
  })

  useEffect(() => {
    const getServer = async () => {
      const data = await getLSDetails()

      if (data) {
        setServer(data)
        setPageLoading(false)
      } else {
        history.goBack()
      }
    }
    getServer()

    return () => {
      abortLS()
    }
  }, [vgId]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.title = 'DLS - Leases'
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'leaseId'
      },
      {
        Header: 'Feature Name (PKID)',
        accessor: 'featureName',
        Cell: ({ row }) => {
          const { featureName, emsProductKeyId } = row.original
          return (
            <div>
              <div>{featureName}</div>
              <small>{emsProductKeyId}</small>
            </div>
          )
        },
        Filter: SelectColumnFilter
      },
      {
        Header: 'Issued On',
        accessor: 'leaseBegin',
        Cell: ({ row }) => <Text>{formatDateTime(row.values.leaseBegin)}</Text>,
        disableFilters: true
      },
      {
        Header: 'Expires',
        accessor: 'leaseExpires',
        Cell: ({ row }) => (
          <Text>
            {row.values.expireAt === 'never'
              ? row.values.expireAt
              : formatDateTime(row.values.leaseExpires)}
          </Text>
        ),
        disableFilters: true
      },
      { Header: 'VGPU ID', accessor: 'gpu_id', hideOnLoad: true },
      {
        Header: () => (
          <StyledHeader>
            Client Origin Ref{' '}
            <span data-tip='Unique token to identify a licensed client'>
              <Icon name='StatusCircleInformation' />
            </span>
          </StyledHeader>
        ),
        label: 'Client Origin Ref',
        accessor: 'originRef'
      },
      { Header: 'Client Hostname', accessor: 'hostname' },
      {
        Header: 'Client MAC Addresses',
        accessor: 'macAddresses',
        Cell: ({ row }) => {
          const { macAddresses } = row.original

          return macAddresses && macAddresses.length
            ? macAddresses.join(', ')
            : 'unavailable'
        }
      },
      {
        Header: 'Client IP Addresses',
        accessor: 'ipAddresses',
        Cell: ({ row }) => {
          const { ipAddresses } = row.original

          return ipAddresses && ipAddresses.length
            ? ipAddresses.join(', ')
            : 'unavailable'
        }
      }
    ],
    []
  )

  const rowActions = useCallback((row, related) => {
    const { serviceInstanceId } = related

    return (
      <LeaseActions
        lease={row.original}
        onUpdate={() => setRefresh(val => !val)}
        serviceInstanceId={serviceInstanceId}
      />
    )
  }, [])

  useEffect(() => {
    const { globalFilter, filters } = filter

    if (globalFilter || filters) {
      const parameters = {
        globalFilter,
        filters:
          typeof filters === 'string'
            ? filters
            : !filters || filters.length === 0
            ? undefined
            : queryString.stringify(
                filters.reduce(
                  (obj, item) => Object.assign(obj, { [item.id]: item.value }),
                  {}
                )
              )
      }
      history.replace(`${ROUTE_LEASES}?${queryString.stringify(parameters)}`)
    } else {
      history.replace(ROUTE_LEASES)
    }
  }, [filter]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !serviceInstanceId ||
      !serverId ||
      leasingMode === LS_LEASING_MODES.Node_Locked.value
    ) {
      setLeases([])
      setPageLoading(false)
      return
    }

    const instanceHeader = serviceInstanceId
      ? {
          'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId
        }
      : {}
    const getLeases = async () => {
      const data = await getLeaseData({
        headers: instanceHeader,
        parameters: { license_server_ids: [serverId] }
      })

      if (data) {
        setLeases(data.leases)
        setCanReleaseAll(data.isBulkReleaseAllowed)
      } else {
        setLeases([])
        setCanReleaseAll(false)
      }
      setPageLoading(false)
    }
    getLeases()

    return () => {
      abortLeases()
    }
  }, [serviceInstanceId, refresh]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!serverId || leasingMode !== LS_LEASING_MODES.Node_Locked.value) return

    const instanceHeader = serviceInstanceId
      ? {
          'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId
        }
      : {}
    const getNllLeases = async () => {
      const data = await getNllLeaseData({
        headers: instanceHeader,
        parameters: { license_server_ids: [serverId] }
      })

      if (data) {
        setNllLeases(data)
      } else {
        setNllLeases([])
      }
    }
    getNllLeases()

    return () => {
      abortNllLeases()
    }
  }, [serverId, refreshNllLeases, refresh, leasingMode, serviceInstanceId]) //eslint-disable-line react-hooks/exhaustive-deps

  const [userFilters] = React.useState(
    Object.entries(queryString.parse(filter?.filters)).map(f => {
      return { id: f[0], value: f[1] }
    })
  )

  const nllLeaseColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id'
      },
      {
        Header: 'Created',
        accessor: 'created_at_local'
      }
    ],
    []
  )
  const nllFileView = useCallback(
    ({ row }) => {
      const files = row.original.nll_lic_files

      return files.map(file => {
        return (
          <div key={file.id} style={{ margin: '0.5rem' }}>
            <Block
            //titleIcon={{ name: 'FileBase', color: '#76b900' }}
            //title={file.name}
            //collapsible
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name='FileBase' color='#76b900' size='large' />
                <span style={{ marginLeft: '0.5rem' }}>{file.name}</span>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  color: theme.colors.textbox.placeholder
                }}
              >
                <div>
                  {file.platform_evidence?.map((pe, index) => {
                    const { id, val } = pe
                    return (
                      <div key={index} style={{ margin: '0.25rem' }}>
                        <Text textStyle='label'>{id}: </Text>
                        <Text textStyle='code'>{val}</Text>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div>
                {file.leases?.map(l => {
                  const {
                    lease_ref,
                    product_name,
                    feature_version,
                    lease_expire
                  } = l
                  return (
                    <div key={lease_ref} style={{ margin: '0.25rem' }}>
                      {product_name}-{feature_version} {'-'} lease expires:{' '}
                      {formatDateTime(lease_expire)}
                    </div>
                  )
                })}
              </div>
            </Block>
          </div>
        )
      })
    },
    [theme]
  )

  const rowActionsNLL = useCallback(
    row => {
      return (
        <LeaseActionsNLL
          lease={row.original}
          onUpdate={() => setRefreshNllLeases(val => !val)}
          server={server}
        />
      )
    },
    [server]
  )

  const { getData: uploadForceToken, loading: uploading } = useFetch({
    endpoint: API_RESET_FORCE_RELEASE,
    actionLabel: 'Get local secret via NLP token',
    method: 'PUT'
  })

  useEffect(() => {
    if (file) {
      const readFile = async () => {
        const reader = new FileReader()
        reader.onload = async function(r) {
          let result
          try {
            result = JSON.parse(r.target.result)
          } catch (error) {
            const msg =
              'Could not process the file. Please select a valid reset token.'
            notify(msg, null, true, 'Processing force release all reset token')
            setClear(val => !val)
          }
          if (result) {
            const data = await uploadForceToken({ body: result })
            if (data) {
              notify(
                'Successfully reset the force release all limit',
                null,
                null,
                'Reset force release all limit'
              )
              setRefresh(val => !val)
            } else {
              setClear(val => !val)
            }
          }
        }
        reader.onerror = function(err) {
          const msg =
            'Could not process the file. Please select a valid local reset token.'
          notify(msg, null, true, 'Processing force release all reset token')
        }
        reader.readAsText(file)
      }
      readFile()
    }
  }, [file]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {leases?.length > 0 && !canReleaseAll && (
        <div style={{ marginTop: '1rem' }}>
          <Banner status='warning'>
            Release all usage has exceeded the maximum times allowed. Please go
            to the services instances page to request a new token for this
            service instance.
          </Banner>
        </div>
      )}
      <PageHeader
        title='Leases'
        tip='Get help with leases'
        subTitle='View leases issued from this service instance'
      >
        {leases?.length ? (
          <ButtonGroup>
            {!canReleaseAll && (
              <div style={{ display: 'flex' }}>
                {uploading && (
                  <span style={{ marginRight: '1rem' }}>
                    <Spinner size='tiny' />
                  </span>
                )}
                <FileUpload
                  disabled={uploading}
                  accept='.tok'
                  label='Select force release all token'
                  onChange={setFile}
                  clearFile={clear}
                />
              </div>
            )}

            <div
              style={{ marginLeft: '0.5rem' }}
              data-tip={
                canReleaseAll
                  ? 'Forcefully release all leases without awaiting expiration'
                  : 'Release all usage has exceeded the maximum times allowed. Please download a new force release token from the portal.'
              }
            >
              <Button
                disabled={!canReleaseAll}
                type='critical'
                //tip={'Forcefully release all leases without awaiting expiration'}
                onClick={() => setOpen(true)}
                icon={{ name: 'ActionsCircleSubtract' }}
              >
                Release All
              </Button>
            </div>
          </ButtonGroup>
        ) : null}
      </PageHeader>

      {leasingMode === LS_LEASING_MODES.Node_Locked.value ? (
        <Table
          columns={nllLeaseColumns}
          data={nllLeases}
          fetching={loadingNllLeases}
          loading={pageLoading}
          error={leaseNllError || getLSError}
          label={'lease files'}
          refresh={() => setRefreshNllLeases(val => !val)}
          lastUpdate={lastUpdateNllLeases}
          renderRowSubComponent={nllFileView}
          relatedData={{ serviceInstanceId }}
          globalSearch={globalFilter}
          rowActions={rowActionsNLL}
        />
      ) : (
        <Table
          columns={columns}
          data={leases}
          dataId='leaseId'
          loading={pageLoading || loadingLS}
          fetching={loadingLeases}
          error={leaseError || getLSError}
          label={'leases'}
          refresh={() => setRefresh(val => !val)}
          lastUpdate={lastUpdateLeases}
          onFilter={setFilter}
          globalSearch={globalFilter}
          relatedData={{ serviceInstanceId: serviceInstanceId }}
          rowActions={rowActions}
          filters={userFilters}
        />
      )}

      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Force Release All Leases'
          subtitle='Forcefully release all leases without awaiting expiration'
          open={open}
          onClose={() => setOpen(false)}
        >
          {open && (
            <ForceRelease
              releaseAll={true}
              onUpdate={() => {
                setRefresh(val => !val)
                setOpen(false)
              }}
              serviceInstanceId={serviceInstanceId}
            />
          )}
        </Modal>
      </div>
    </>
  )
}
