import { Block, KaizenThemeContext } from '@kaizen-ui/complete'
import {
  FileUpload,
  FloatingSpinner,
  formatDateTime,
  InlineButton,
  SelectOptionsColumnFilter,
  Table,
  useAppNotifications,
  useFetch
} from 'common'
import React from 'react'
import { API_KEYS, API_KEYS_IMPORT, API_KEY_STATUS } from '../../../api'
import { SessionExpired } from '../../../Components'
import Text from '@kaizen-ui/text'

export const SCOPES = {
  DOWNLOADS: 'SoftwareDownload',
  LICENSING: 'RetrieverForAllResources',
  ENTERPRISE: 'Enterprise'
}

export const SCOPE_NAMES = {
  SOFTWAREDOWNLOAD: 'Software Downloads',
  RETRIEVERFORALLRESOURCES: 'Licensing State',
  ENTERPRISE: 'Enterprise'
}

export const ApiKeys = () => {
  const { notify } = useAppNotifications()
  const theme = React.useContext(KaizenThemeContext)
  const [pageLoading, setPageLoading] = React.useState(true)
  const [refresh, setRefresh] = React.useState(false)
  const [keys, setKeys] = React.useState([])
  const { getData, abort, error, loading } = useFetch({
    endpoint: API_KEYS,
    actionLabel: 'List api keys',
    SessionExpired: SessionExpired,
    normalizer: data => formatResults(data?.apiKeys)
  })
  const { getData: sendImport } = useFetch({
    endpoint: API_KEYS_IMPORT,
    actionLabel: 'Import api keys',
    SessionExpired: SessionExpired,
    method: 'POST'
  })
  const [processing, setProcessing] = React.useState(false)
  const [clear, setClear] = React.useState(false)

  const formatResults = data => {
    return data?.map(d => {
      const { expireAt, entityAccess, scopeLimiter, oldKeys, ...rest } = d

      const keys = oldKeys ? { oldKeys: formatResults(oldKeys) } : {}
      return {
        ...rest,
        expireAt: expireAt ? expireAt : 'never',
        accessString: entityAccess?.join(', '),
        entityAccess,
        scopeLimiter,
        scopeLimiterString: scopeLimiter
          ?.map(
            sl =>
              `${sl.type === 'DLS_SI' ? 'SI: ' : 'VG: '} ${sl.description ||
                sl.value}`
          )
          .join(', '),
        ...keys
      }
    })
  }

  React.useEffect(() => {
    const get = async () => {
      const data = await getData()

      if (data) {
        setKeys(data)
      }
      setPageLoading(false)
    }
    get()

    return () => {
      abort()
    }
  }, [refresh]) //eslint-disable-line react-hooks/exhaustive-deps

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        hidden: false,
        Cell: ({ row }) => (
          <Text overflow='truncate'>
            <span data-tip={row.values.name}>{row.values.name} </span>
          </Text>
        )
      },
      {
        Header: 'Access',
        accessor: 'accessString',
        Cell: ({ row }) => {
          const { entityAccess } = row.original
          return (
            <>
              {entityAccess.map(s => SCOPE_NAMES[s.toUpperCase()]).join(', ')}
            </>
          )
        },
        Filter: props => (
          <SelectOptionsColumnFilter
            {...props}
            options={[
              { label: 'All', value: null },
              { label: SCOPE_NAMES.ENTERPRISE, value: SCOPES.ENTERPRISE },
              {
                label: SCOPE_NAMES.RETRIEVERFORALLRESOURCES,
                value: SCOPES.LICENSING
              },
              { label: SCOPE_NAMES.SOFTWAREDOWNLOAD, value: SCOPES.DOWNLOADS }
            ]}
          />
        )
      },
      {
        Header: 'Expires',
        accessor: 'expireAt',
        width: 330,
        Cell: ({ row }) => (
          <Text>
            {row.values.expireAt === 'never'
              ? row.values.expireAt
              : formatDateTime(row.values.expireAt)}
          </Text>
        )
      },
      { Header: 'rotateStatus', accessor: 'status', hidden: true },
      {
        Header: 'Status',
        accessor: 'expiryStatus',
        width: 50,
        Cell: ({ row }) => {
          const { expiryStatus: status } = row.values

          const iconProps =
            status === API_KEY_STATUS.ENABLED
              ? { name: 'ActionsCircleAdd' }
              : status === API_KEY_STATUS.DISABLED
              ? {
                  name: 'ActionsCircleClose',
                  color: theme.colors.textbox.placeholder
                }
              : {
                  name: 'ActionsCircleDelete',
                  color: theme.colors.textbox.validationMessage
                }
          return (
            <div
              data-tip={status}
              style={{ color: iconProps.color || 'inherit' }}
            >
              {/* <Icon {...iconProps} /> */}
              {status}
            </div>
          )
        }
      },
      {
        Header: 'Key',
        accessor: 'apiKey',
        //disableFilters: true,
        //disableSortBy: true,
        Cell: ({ row }) => (
          <>
            <PasswordField value={row.values.apiKey} />
          </>
        )
      }
    ],
    [theme]
  )

  const verifyFile = async uploadedFile => {
    setProcessing(true)
    const reader = new FileReader()
    reader.onload = function(r) {
      let result = r.target.result
      const body = { encodedFile: result }
      if (result) {
        sendImport({ body }).then(res => {
          if (res) {
            const msg = 'API keys file processed successfully!'
            notify(msg, null, null, 'API keys imported')
            setRefresh(val => !val)
            setProcessing(false)
          } else {
            setProcessing(false)
          }
        })
      } else {
        const msg =
          'Could not process the API keys file. Please select a valid API keys file.'
        notify(msg, null, true, 'API keys import failed.')
        setProcessing(false)
      }
    }
    reader.onerror = function(err) {
      const msg =
        'Could not process the API keys file. Please select a valid API keys file.'
      notify(msg, null, true, 'API keys import processing failed')
      setProcessing(false)
    }
    reader.readAsText(uploadedFile)
  }

  const rowDetails = React.useCallback(({ row }) => {
    return (
      <Details
        data={row.original.oldKeys}
        onUpdate={() => setRefresh(val => !val)}
      />
    )
  }, [])

  React.useEffect(() => {
    if (!processing) setClear(val => !val)
  }, [processing])

  return (
    <>
      <FloatingSpinner visible={processing} />
      <div style={{ marginBottom: '1rem' }}>
        <Block>
          {/* <Button variant='link' onClick={() => setTest(val => !val)}>
            Import API keys
          </Button> */}
          <FileUpload
            accept='.tok'
            label='Import API keys'
            onChange={verifyFile}
            variant='link'
            type='primary'
            iconName='FileBase'
            clearFile={clear}
          />
        </Block>
      </div>
      <Table
        dataId='apiKey'
        columns={columns}
        data={keys}
        loading={pageLoading}
        fetching={loading}
        error={error}
        label='keys'
        refresh={() => setRefresh(val => !val)}
        minHeight={1}
        allowExpand={original => original.oldKeys?.length > 0}
        renderRowSubComponent={rowDetails}
      />
    </>
  )
}

const Details = ({ data, onUpdate }) => {
  const theme = React.useContext(KaizenThemeContext)
  const columns = React.useMemo(
    () => [
      // {
      //   Header: 'Name',
      //   accessor: 'name',
      //   hidden: false
      // },
      // {
      //   Header: 'Access',
      //   accessor: 'accessString',
      //   Cell: ({ row }) => {
      //     const { entityAccess } = row.original
      //     return (
      //       <>
      //         {entityAccess.map(s => SCOPE_NAMES[s.toUpperCase()]).join(', ')}
      //       </>
      //     )
      //   },
      //   Filter: props => (
      //     <SelectOptionsColumnFilter
      //       {...props}
      //       options={[
      //         { label: 'All', value: null },
      //         { label: SCOPE_NAMES.ENTERPRISE, value: SCOPES.ENTERPRISE },
      //         {
      //           label: SCOPE_NAMES.RETRIEVERFORALLRESOURCES,
      //           value: SCOPES.LICENSING
      //         },
      //         { label: SCOPE_NAMES.SOFTWAREDOWNLOAD, value: SCOPES.DOWNLOADS }
      //       ]}
      //     />
      //   )
      // },
      // {
      //   Header: 'Limiter',
      //   accessor: 'scopeLimiterString'
      // },
      // {
      //   Header: 'Creator',
      //   accessor: 'createdBy',
      //   Cell: ({ row }) => (
      //     <Text overflow='truncate'>{row.values.createdBy}</Text>
      //   )
      // },
      {
        Header: 'Updated',
        accessor: 'lastUpdatedAt'
      },
      {
        Header: 'Expires',
        accessor: 'expireAt'
      },
      { Header: 'rotateStatus', accessor: 'status', hidden: true },
      {
        Header: 'Status',
        accessor: 'expiryStatus',
        Cell: ({ row }) => {
          const { expiryStatus: status } = row.values

          const iconProps =
            status === API_KEY_STATUS.ENABLED
              ? { name: 'ActionsCircleAdd' }
              : status === API_KEY_STATUS.DISABLED
              ? {
                  name: 'ActionsCircleClose',
                  color: theme.colors.textbox.placeholder
                }
              : {
                  name: 'ActionsCircleDelete',
                  color: theme.colors.textbox.validationMessage
                }
          return (
            <div
              data-tip={status}
              style={{ color: iconProps.color || 'inherit' }}
            >
              {/* <Icon {...iconProps} /> */}
              {status}
            </div>
          )
        }
      },
      {
        Header: 'Key',
        accessor: 'apiKey',
        //disableFilters: true,
        //disableSortBy: true,
        Cell: ({ row }) => (
          <>
            <PasswordField value={row.values.apiKey} />
          </>
        )
      }
    ],
    [theme]
  )

  return (
    <>
      <Table
        dataId='apiKey'
        columns={columns}
        data={data}
        label='previous keys'
        minHeight={1}
        disableExporting
        disablePagination
        disableSearch
        disableColumnHiding
      />
    </>
  )
}

const PasswordField = ({ value }) => {
  const [visible, setVisible] = React.useState(false)

  return visible ? (
    value
  ) : (
    <InlineButton
      variant='link'
      onClick={() => {
        setVisible(true)
      }}
    >
      view api key
    </InlineButton>
  )
}
