import React, { useContext } from 'react'
import styled from 'styled-components'
import { Icon, Tag } from '@kaizen-ui/complete'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import {
  SERVER_DEPLOY_STATUS,
  LS_STATUS_ENABLED,
  LS_LEASING_MODES
} from '../../api'
import { formatDateTime, Pill } from 'common'
import { Link } from 'react-router-dom'
import { ROUTE_SERVICE_INSTANCES } from '../../Components'

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const NvidiaProperties = ({ server }) => {
  const theme = useContext(KaizenThemeContext)
  const {
    type,
    createdAt,
    description,
    lastUpdatedAt,
    status,
    serviceInstanceName,
    serviceInstanceType,
    serviceInstanceId,
    instantCloneSupport,
    collisionFilter,
    deployedOn,
    scopeReference,
    leasingMode
  } = server
  const isEnabled = status === LS_STATUS_ENABLED
  const leasingModeOptions = Object.entries(LS_LEASING_MODES).map(entry => {
    return { label: entry[1].label, value: entry[1].value }
  })

  return (
    <div style={{ margin: '0.5rem -0.25rem' }}>
      <Row>
        <Pill
          label='Status'
          value={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                name={isEnabled ? 'ServerCheck' : 'ServerError'}
                color={isEnabled ? theme.colors.brand : theme.colors.red500}
              />
              <span style={{ marginLeft: '0.5rem' }}>{status}</span>
            </div>
          }
        />
        <Pill label='Type' value={type} />
        <Pill label='Created' value={formatDateTime(createdAt)} />
        <Pill label='Modified' value={formatDateTime(lastUpdatedAt)} />
      </Row>
      <Row>
        <Pill
          label='Service Instance'
          value={
            serviceInstanceName ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '0.5rem' }}>
                  <Link
                    to={`${ROUTE_SERVICE_INSTANCES}?filters=xid%3D${serviceInstanceId}`}
                  >
                    {serviceInstanceName}
                  </Link>
                </span>
                <Icon
                  name={
                    serviceInstanceType === 'Cloud'
                      ? 'CloudCheckmark'
                      : 'DatabaseBase'
                  }
                  color={theme.colors.brand}
                />
                <span style={{ marginLeft: '0.5rem' }}>
                  {serviceInstanceType === 'Cloud' ? 'CLS' : 'DLS'}
                </span>
                {instantCloneSupport && (
                  <span
                    style={{ marginLeft: '0.5rem' }}
                    data-tip='Supports non-persistent desktop pools'
                  >
                    <Tag clickable={false} clearable={false} color='green'>
                      NPDP
                    </Tag>
                  </span>
                )}
              </div>
            ) : (
              <span style={{ color: theme.colors.grayLight }}>UNBOUND</span>
            )
          }
        />
        <span data-tip='Non-Persistent desktop pools can be enabled/disabled on the service instance page'>
          <Pill
            label='Non-Persistent desktop pools'
            value={instantCloneSupport ? collisionFilter.join('/') : 'Disabled'}
          />
        </span>
        <Pill
          label='Install Status'
          value={
            serviceInstanceName ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  name={
                    serviceInstanceName ? 'ServerCheck' : 'ConnectionServer'
                  }
                  color={
                    deployedOn === SERVER_DEPLOY_STATUS.NOT_DEPLOYED
                      ? theme.colors.red500
                      : theme.colors.brand
                  }
                />
                <span style={{ marginLeft: '0.5rem' }}>
                  <span>INSTALLED</span>
                </span>
              </div>
            ) : (
              <span style={{ color: theme.colors.grayLight }}>UNBOUND</span>
            )
          }
        />
        <Pill
          label='Leasing Mode'
          value={
            leasingModeOptions.find(option => option.value === leasingMode)
              ?.label
          }
        />
      </Row>
      <Row>
        <Pill label='Description' value={description} />
        {scopeReference ? (
          <Pill label='Scope Reference' value={scopeReference} />
        ) : null}
      </Row>
    </div>
  )
}
