import React, { useGlobal, useEffect } from 'reactn'
import { useHistory } from 'react-router-dom'
import Button from '@kaizen-ui/button'
import ReactTooltip from 'react-tooltip'
import { ROUTE_MAINTENANCE, SessionExpired } from '..'
import { API_LICENSE_SERVER_RETURN_FEATURES } from '../../api'
import {
  GLOBAL_ORG,
  GLOBAL_PENDING_RETURNS,
  GLOBAL_SERVER_ID,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'
import { useFetch } from 'common'

export const PendingReturnNotifier = () => {
  const [hasPending, setHasPending] = useGlobal(GLOBAL_PENDING_RETURNS)
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const [serverId] = useGlobal(GLOBAL_SERVER_ID)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const history = useHistory()
  const { getData } = useFetch({
    endpoint: API_LICENSE_SERVER_RETURN_FEATURES(orgId, vgId, serverId),
    actionLabel: 'Check for pending returns',
    SessionExpired: SessionExpired
  })

  useEffect(() => {
    if (!vgId || !serverId) return
    const checkPending = async values => {
      const data = await getData()
      if (data) {
        setHasPending(
          data?.featureCountsToReturn?.length > 0 ||
            data?.featuresToReturn?.length > 0
        )
      }
    }
    checkPending()
    ReactTooltip.rebuild()
  }, [vgId, serverId]) //eslint-disable-line react-hooks/exhaustive-deps

  return hasPending ? (
    <div
      style={{ display: 'flex', alignItems: 'center' }}
      data-tip='There are pending feature returns to be exported to the NVIDIA Licensing Portal'
    >
      <Button
        variant='link'
        icon={{ name: 'ActionsSyncWarning' }}
        onClick={() => history.push(ROUTE_MAINTENANCE)}
      ></Button>
    </div>
  ) : (
    <></>
  )
}
