"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FileUpload", {
  enumerable: true,
  get: function get() {
    return _FileUpload.FileUpload;
  }
});

var _FileUpload = require("./FileUpload");