import React, { useContext, useState, useMemo, useGlobal } from 'reactn'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Block from '@kaizen-ui/block'
import Button from '@kaizen-ui/button'
import Modal from '@kaizen-ui/modal'
import {
  BASE_URL,
  HIGH_AVAILABILITY_NODE_ROLE,
  HIGH_AVAILABILITY_STATE,
  NODE_ROLE
} from '../../../api'
import { NodeDetails } from './NodeDetails'
import { AddNode } from './AddNode'
import Result from '@kaizen-ui/result'
import { GLOBAL_NODE_ROLE } from '../../../globalState'
import { MakeStandalone } from './MakeStandalone'

const StyledBlock = styled.div`
  > div[elevation] {
    overflow-y: auto;
    overflow-x: hidden;

    button {
      z-index: 0;
    }
  }
`

export const ClusterNodes = ({
  nodeList = [],
  maxHaNodesSupported = 2,
  haState,
  loading,
  onUpdate,
  isPureContainer,
  restartRef
}) => {
  const theme = useContext(KaizenThemeContext)
  const baseUrl = new URL(BASE_URL)
  const currentLocation = baseUrl?.hostname
  const [convertStandaloneOpen, setConvertStandaloneOpen] = useState(false)

  const isClustered = haState === HIGH_AVAILABILITY_STATE.CLUSTERED
  const [addNodeOpen, setAddNodeOpen] = useState(false)

  const [nodeRole] = useGlobal(GLOBAL_NODE_ROLE) //note - role of currently logged in node
  const loggedIntoPrimary = useMemo(
    () => nodeRole?.toLowerCase() === NODE_ROLE.PRIMARY?.toLowerCase(),
    [nodeRole]
  )
  const primaryNode = useMemo(
    () =>
      nodeList.find(
        n => n?.role?.toLowerCase() === NODE_ROLE.PRIMARY.toLowerCase()
      ),
    [nodeList]
  )

  const atleastOneNodeDown = useMemo(
    () =>
      nodeList.some(
        n => n?.role?.toLowerCase() === NODE_ROLE.UNKNOWN.toLowerCase()
      ),
    [nodeList]
  )

  const existingNodeAddresses = useMemo(
    () => [
      ...(nodeList.map(node => node.network_location)?.filter(a => a) || []),
      ...(nodeList.map(node => node.ip_v6_address)?.filter(a => a) || []),
      ...(nodeList.map(node => node.fqdn)?.filter(a => a) || [])
    ],
    [nodeList]
  )

  const addNodeLimitReached = useMemo(() => {
    return nodeList?.length >= maxHaNodesSupported
  }, [maxHaNodesSupported, nodeList?.length])

  return (
    <StyledBlock theme={theme}>
      <Block
        loading={loading}
        title={isClustered ? 'Node List' : 'Node Details'}
        titleIcon={{
          name: 'ConnectionNetworkComputers2',
          size: 24
        }}
        actions={
          loggedIntoPrimary ? (
            <>
              {nodeList?.length > 1 ? (
                <div data-tip='Convert DLS setup to standalone mode'>
                  <Button
                    variant='solid'
                    type='secondary'
                    onClick={() => {
                      setConvertStandaloneOpen(true)
                    }}
                  >
                    Make Standalone
                  </Button>
                </div>
              ) : null}

              <span
                data-tip={
                  addNodeLimitReached
                    ? `Cluster can have upto ${maxHaNodesSupported} nodes only`
                    : isClustered
                    ? 'Add Node to the Cluster'
                    : 'Configure High Availability'
                }
              >
                <Button
                  variant='solid'
                  icon={{ name: 'ConnectionNetworkComputers2' }}
                  type='primary'
                  onClick={() => {
                    setAddNodeOpen(true)
                  }}
                  disabled={
                    haState === HIGH_AVAILABILITY_STATE.IN_PROGRESS ||
                    addNodeLimitReached
                  }
                >
                  {isClustered
                    ? 'Add Node to the Cluster'
                    : 'Configure High Availability'}
                </Button>
              </span>
            </>
          ) : null
        }
      >
        {!loading && !nodeList?.length ? (
          <div>
            <Result
              status='error'
              title={'Node List'}
              subTitle={'Unable to fetch node list. Please try again.'}
              icon={{
                name: 'ConnectionNetworkComputers2'
              }}
            />
          </div>
        ) : null}

        {nodeList.map((node, ind) => {
          return (
            <Outer theme={theme} key={node?.node_id}>
              <NodeDetails
                ind={ind}
                showInd={(nodeList?.length || 0) > 1}
                node={node}
                isPrimary={
                  node?.role?.toLowerCase() ===
                  HIGH_AVAILABILITY_NODE_ROLE.PRIMARY.toLowerCase()
                }
                isSecondary={
                  node?.role?.toLowerCase() ===
                  HIGH_AVAILABILITY_NODE_ROLE.SECONDARY.toLowerCase()
                }
                isClustered={isClustered}
                isPureContainer={isPureContainer}
                isCurrentlyLoggedInHere={
                  currentLocation === node.network_location ||
                  currentLocation === node.ip_v6_address ||
                  currentLocation === node.fqdn
                }
                atleastOneNodeDown={atleastOneNodeDown}
                existingNodeAddresses={existingNodeAddresses}
                onUpdate={onUpdate}
                restartRef={restartRef}
              />
            </Outer>
          )
        })}
      </Block>

      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={
            isClustered
              ? 'Add Node to the Cluster'
              : 'Configure High Availability'
          }
          //subtitle='Create a configuration token for client access to server resources'
          className='autow'
          open={addNodeOpen}
          onClose={() => setAddNodeOpen(false)}
        >
          {addNodeOpen && (
            <AddNode
              existingNodeAddresses={existingNodeAddresses}
              onUpdate={() => {
                setAddNodeOpen(false)
                onUpdate && onUpdate(val => !val)
              }}
              isClustered={isClustered}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={'Convert setup to standalone mode'}
          open={convertStandaloneOpen}
          onClose={() => setConvertStandaloneOpen(false)}
        >
          {convertStandaloneOpen && (
            <>
              <MakeStandalone
                primaryNode={primaryNode}
                onUpdate={() => {
                  setConvertStandaloneOpen(false)
                  onUpdate && onUpdate(val => !val)
                }}
              />
            </>
          )}
        </Modal>
      </div>
    </StyledBlock>
  )
}

const Outer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.result.border};
  background: ${({ theme }) => theme.colors.result.background};
  margin-bottom: 1rem;
  padding: 1rem;
`
