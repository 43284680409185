import React, { useContext, useGlobal, useState } from 'reactn'
import { FileUpload, useFetch, useAppNotifications, ModalSpinner } from 'common'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Button from '@kaizen-ui/button'
import { GLOBAL_SERVICE_INSTANCE_ID } from '../../globalState'
import { API_LICENSE_SERVER_INSTALL } from '../../api'
import { useAuth } from '../../hooks'
import { Icon, Text } from '@kaizen-ui/complete'

const Buttons = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const UpdateServer = ({ onUpdate }) => {
  const theme = useContext(KaizenThemeContext)
  const { notify } = useAppNotifications()
  const { checkAuth } = useAuth()
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)
  const [file, setFile] = useState(undefined)

  const { getData: submitInstall, loading: uploading } = useFetch({
    endpoint: API_LICENSE_SERVER_INSTALL,
    actionLabel: 'Install license server update',
    method: 'PUT'
  })

  const submit = async () => {
    const reader = new FileReader()
    reader.onload = async function(r) {
      let result = r.target.result
      const headers = { 'x-nv-service-instance-id': serviceInstanceId }
      const body = { license: result }
      if (result) {
        const data = await submitInstall({ body, headers })
        if (data) {
          notify(
            'The license server was successfully updated',
            null,
            null,
            'Installed license server update'
          )
          checkAuth(() => {
            onUpdate && onUpdate()
          })
        }
      }
    }
    reader.onerror = function(err) {
      const msg =
        'Could not process the license server file. Please select a valid license server file.'
      notify(msg, null, true, 'License server update failed')
    }
    reader.readAsText(file)
  }

  return (
    <>
      {uploading && <ModalSpinner />}
      <div style={{ color: theme.colors.lightGray500, marginBottom: '1rem' }}>
        Select the license server bin file downloaded from the NVIDIA Licensing
        Portal
      </div>
      <Tip>
        <Icon name='StatusCircleInformation' color={theme.colors.brand} />
        <Text textStyle='p2'>
          If you install a license server different from the currently installed
          license server, the existing license server will get replaced.
        </Text>
      </Tip>
      <Buttons>
        <FileUpload
          accept='.bin'
          label='Select license server file'
          onChange={setFile}
        />
        <Button
          disabled={!file || uploading}
          icon={{ name: 'ServerBase' }}
          onClick={submit}
        >
          Install server update
        </Button>
      </Buttons>
    </>
  )
}

const Tip = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
`
