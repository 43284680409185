import React, { useRef, useGlobal } from 'reactn'
import styled from 'styled-components'
import { Text } from '@kaizen-ui/complete'
import { PageHeader, CollapsibleBlock, ContextNotifications } from 'common'
import { EmailAlerts } from './EmailAlerts'
import { ApiKeys } from './ApiKeys'
import { ServiceInstanceSettings } from '../ServiceInstance/ServiceInstanceSettings'
import { useAuth, useLicenseServer, useServiceInstance } from '../../hooks'
import { LogArchivalSetting } from '../ServiceInstance/LogArchivalSetting'
import { SSLConfiguration } from '../ServiceInstance/SSLConfiguration'
import { NTPServerConfig } from '../ServiceInstance/NTPServerConfig'
import { LDAPConfig } from '../ServiceInstance/LDAPConfig'
import { GLOBAL_VA_VERSION } from '../../globalState'
import {
  DOCS_SETTINGS_DLS_SSL_SETUP,
  DOCS_SETTINGS_EMAIL_ALERTS,
  DOCS_SETTINGS_RETENTION_EVENTS,
  DOCS_SETTINGS_SERVER,
  LS_LEASING_MODES
} from '../../api'

const StyledSetting = styled.div`
  .eOAMak {
    margin-bottom: 14px;
  }
`

const SettingSubTitle = ({ subtitle }) => {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <Text textStyle={'p1'}>{subtitle}</Text>
    </div>
  )
}

export const Settings = () => {
  const { user } = useAuth()
  const { isPureContainer } = user || {}
  const { serviceInstance } = useServiceInstance({ autoLoad: true })
  const { licenseServer } = useLicenseServer({ autoLoad: true })
  const { leasingMode } = licenseServer || {}
  const ldapRef = useRef(null)
  const [version] = useGlobal(GLOBAL_VA_VERSION)
  const helpRef1 = React.useRef(null)
  const helpRef2 = React.useRef(null)
  const helpRef3 = React.useRef(null)
  const [step, setStep] = React.useState(-1)
  const help = [
    {
      title: 'Editing License Server Settings',
      text: `You can configure lease duration options (duration, renewal, etc.) for this DLS instance.`,
      link: DOCS_SETTINGS_SERVER(version),
      ref: helpRef2,
      offsetY: 55
    },
    {
      title: 'Setting the Retention Period of Events on a DLS Instance',
      text: `A DLS instance records events related to administration of the instance and the serving of licenses from the instance to licensed clients. These events are displayed on the Events page of the instance. You can control the number of events that are displayed on this page by setting the retention period of events on a DLS instance.`,
      link: DOCS_SETTINGS_RETENTION_EVENTS(version),
      ref: helpRef2,
      offsetY: 55
    },
    {
      title:
        'Configuring a DLS Virtual Appliance with a Third-Party Signed SSL Certificate',
      text:
        'If you are installing a wildcard domain certificate for all nodes in an HA cluster, perform this task from the primary node in the cluster only. The certificate is propagated automatically to the secondary node in the cluster.',
      link: DOCS_SETTINGS_DLS_SSL_SETUP(version),
      ref: helpRef1,
      offsetY: 55
    },
    {
      title: 'Configuring Email Alerts for Licensing Events',
      text: `To avoid disruption to your services, you can configure email alerts for licensing events that relate to the expiration or exhaustion of NLS resources. These alerts remind you to renew the affected resources before they expire or are exhausted.`,
      link: DOCS_SETTINGS_EMAIL_ALERTS(version),
      ref: helpRef3,
      offsetY: 55
    }
  ]
  return (
    <>
      <ContextNotifications list={help} step={step} setStep={setStep} />
      <PageHeader
        title='Service Instance Settings'
        subTitle='View and configure various configuration options'
        showHelp={() => setStep(0)}
      ></PageHeader>
      <StyledSetting>
        <div ref={helpRef2}>
          <CollapsibleBlock title='Basic Settings'>
            <SettingSubTitle
              subtitle={
                'Settings defined at Service Instance level applicable to License Servers, if these settings are not overridden at License Server level. Also, includes appliance service log deletion settings.'
              }
            />
            <ServiceInstanceSettings
              serviceInstance={serviceInstance}
              isDisconnected={
                leasingMode === LS_LEASING_MODES.Node_Locked.value
              }
            />
          </CollapsibleBlock>
        </div>

        {!isPureContainer && (
          <CollapsibleBlock title='Log Archival'>
            <SettingSubTitle
              subtitle={
                'Logs generated by DLS Services can be exported periodically to configured network path.'
              }
            />
            <LogArchivalSetting />
          </CollapsibleBlock>
        )}
        <div ref={helpRef1}>
          <CollapsibleBlock title='SSL Configuration'>
            <SettingSubTitle
              subtitle={
                'Configure SSL certificate and associated private key for HTTPS communication to DLS Appliance.'
              }
            />

            <SSLConfiguration />
          </CollapsibleBlock>
        </div>

        {!isPureContainer && (
          <CollapsibleBlock title='NTP Server Configuration'>
            <SettingSubTitle
              subtitle={
                'Network Time Protocol (NTP) synchronizes DLS clock time across a network. When NTP is configured, DLS Appliance contacts an NTP server to synchronize its time.'
              }
            />

            <NTPServerConfig />
          </CollapsibleBlock>
        )}
        <div ref={helpRef3}>
          <CollapsibleBlock title='E-mail Alerts'>
            <SettingSubTitle
              subtitle={
                'Configure and store SMTP server details to enable email alerts.'
              }
            />
            <EmailAlerts />
          </CollapsibleBlock>
        </div>
        <CollapsibleBlock title='API keys'>
          <SettingSubTitle
            subtitle={
              'List of API Keys that can be used to hit endpoints on this DLS Appliance. Check details for expiry and access limitations. Software Download access type is not applicable to DLS.'
            }
          />
          <ApiKeys />
        </CollapsibleBlock>
        <div ref={ldapRef}>
          <CollapsibleBlock title='LDAP configuration'>
            <SettingSubTitle
              subtitle={'Configure DLS to use LDAP authentication'}
            />
            <LDAPConfig ldapRef={ldapRef} />
          </CollapsibleBlock>
        </div>
      </StyledSetting>
    </>
  )
}
