import React from 'reactn'
import { ModalButtons, useAppNotifications, useFetch } from 'common'
import Button from '@kaizen-ui/button'
import { API_NODE_ROLE } from '../../api'
import { SessionExpired } from '../../Components'
import { ModalSpinner } from 'common'

export const MarkAsPrimary = ({ onUpdate }) => {
  const { getData: markAsPrimary, loading: markingAsPrimary } = useFetch({
    endpoint: API_NODE_ROLE,
    actionLabel: 'Mark As Primary',
    SessionExpired: SessionExpired,
    method: 'POST',
    responseOnly: true
  })
  const { notify } = useAppNotifications()

  const submit = async () => {
    const result = await markAsPrimary()
    if (result) {
      const msg = `Marked node as Primary successfully!`
      notify(msg, null, null, 'Mark As Primary')
      onUpdate && onUpdate()
    }
  }

  return (
    <>
      <div>
        {markingAsPrimary ? <ModalSpinner /> : null}
        <ol>
          <li>
            The current node will be designated as the Primary node and will
            begin serving leases.
          </li>
          <li>All other nodes in the cluster will be marked as secondary.</li>
        </ol>
      </div>

      <ModalButtons>
        <Button type='primary' onClick={submit} disabled={markingAsPrimary}>
          Mark As Primary
        </Button>
      </ModalButtons>
    </>
  )
}
