import React from 'reactn'
import styled from 'styled-components'
import { useAppNotifications, useFetch } from 'common'
import Result from '@kaizen-ui/result'
import Button from '@kaizen-ui/button'
import { API_UPGRADE_STATUS, UPGRADE_MODE } from '../../api'
import { SessionExpired } from '../../Components'

const StyledResult = styled.div`
  > div {
    border: none;
  }
  text-align: center;
`
const StyledDesc = styled.div`
  text-align: left;
`
const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StartInPlaceUpgrade = ({ onUpdate }) => {
  const { getData: startInPlaceUpgrade, loading: starting } = useFetch({
    endpoint: API_UPGRADE_STATUS,
    actionLabel: 'Start In-Place Upgrade',
    SessionExpired: SessionExpired,
    method: 'POST',
    responseOnly: true
  })

  const { notify } = useAppNotifications()

  const submit = async () => {
    const body = {
      mode: UPGRADE_MODE.INPLACE
    }
    const result = await startInPlaceUpgrade({ body })
    if (result) {
      const msg = `In-place upgrade started successfully!`
      notify(msg, null, null, 'Start In-Place Upgrade')
      onUpdate && onUpdate()
    }
  }

  return (
    <>
      <StyledResult>
        <Result
          status={starting ? 'loading' : 'warning'}
          title={'Do you really want to start the in-place upgrade?'}
        >
          <StyledDesc>
            <ol>
              <li>
                This will put all nodes in the cluster into maintenance mode and
                you won't be able to make any changes to the appliance except
                for the ones necessary for the upgrade.
              </li>
              <li>
                The nodes will still be accessible and continue serving leases
                during this time.
              </li>
            </ol>
          </StyledDesc>
          <ActionButtons>
            <Button type='primary' onClick={submit} disabled={starting}>
              Start In-Place Upgrade
            </Button>
          </ActionButtons>
        </Result>
      </StyledResult>
    </>
  )
}
