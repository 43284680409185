import { ModalButtons, useAppNotifications, useFetch } from 'common'
import styled from 'styled-components'
import React, { useGlobal } from 'reactn'
import Result from '@kaizen-ui/result'
import Button from '@kaizen-ui/button'
import { API_LICENSE_POOL } from '../../api'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'

const StyledResult = styled.div`
  > div {
    border: none;
  }
`

export const DeleteLicensePool = ({ pool, server, onUpdate }) => {
  const { id, name } = pool
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_LICENSE_POOL(orgId, vgId, server?.id, id),
    actionLabel: `Delete License Pool ${name} from ${server?.name}`,
    method: 'DELETE',
    responseOnly: true
  })
  const { notify } = useAppNotifications()

  const submit = async () => {
    const result = await sendRequest()

    if (result) {
      const msg = `License pool ${name} was successfully deleted!`
      notify(msg, null, null, 'Deleted license pool')
      onUpdate && onUpdate()
    }
  }

  return (
    <>
      <StyledResult>
        <Result
          status={submitting ? 'loading' : 'warning'}
          subTitle={
            submitting
              ? 'Deleting...'
              : 'Are you sure you want to delete this license pool?'
          }
          title={name}
        />
      </StyledResult>
      <ModalButtons>
        <Button type='critical' onClick={submit} disabled={submitting}>
          Delete License Pool
        </Button>
      </ModalButtons>
    </>
  )
}
