import React from 'reactn'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Card from '@kaizen-ui/card'
import Icon from '@kaizen-ui/icon'
import Text from '@kaizen-ui/text'
import Tag from '@kaizen-ui/tag'
import ReactTooltip from 'react-tooltip'
//import { USER_PERMISSIONS } from '../../api'
//import { useAuth } from '../../hooks'

const StyledStatus = styled.div`
  margin: 0 0 1rem 0;

  button {
    margin-left: 0.5rem;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.textbox.placeholder};

  svg {
    margin-right: 0.5rem;
  }
`

export const AlertDisplay = ({ alert, title, icon, onClick }) => {
  const theme = React.useContext(KaizenThemeContext)
  const emails = React.useMemo(() => alert?.emailIds.join(', '), [alert])
  //const { permissions } = useAuth()

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <Card
      childrenMaxLines={10}
      childrenMinLines={10}
      elevation='lowest'
      primaryFooterAction={{
        label: alert ? 'Edit' : 'Create',
        onClick: onClick
        // disabled: !permissions.includes(
        //   USER_PERMISSIONS.ALERT_CONFIGURATION_CREATE
        // )
      }}
      center={!alert}
    >
      <>
        <Title theme={theme}>
          <Icon name={icon} size='large' />
          <h2>{title}</h2>
        </Title>
        {alert ? (
          <>
            {/* <StyledSwitch checked={!alert.disable} label='Enabled' /> */}
            <StyledStatus>
              Status:{' '}
              <Tag color={alert.disable ? 'red' : 'green'} clickable={false}>
                {!alert.disable ? 'Enabled' : 'Disabled'}
              </Tag>
            </StyledStatus>
            <div>Recipients:</div>
            <div data-tip={emails}>
              <Text
                overflow='truncate'
                color={theme.colors.textbox.placeholder}
              >
                {emails}
              </Text>
            </div>
          </>
        ) : (
          <>
            <div>This alert has not been created</div>
          </>
        )}
      </>
    </Card>
  )
}
