import React, {
  useGlobal,
  useEffect,
  useState,
  useContext,
  useMemo
} from 'reactn'
import Result from '@kaizen-ui/result'
import Button, { ButtonGroup } from '@kaizen-ui/button'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Text from '@kaizen-ui/text'
import Tabs, { Tab } from '@kaizen-ui/tabs'
import Tag from '@kaizen-ui/tag'
import Switch from '@kaizen-ui/switch'
import styled from 'styled-components'
import {
  ModalButtons,
  useFetch,
  Numberbox,
  DurationSelector,
  useAppNotifications,
  ModalSpinner
} from 'common'
import { SessionExpired } from '..'
import {
  API_GET_SETTINGS,
  API_PUT_SETTINGS,
  LS_STATUS_ENABLED,
  SETTINGS_DATA_TYPES,
  SETTINGS_SCOPES
} from '../../api'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'
import moment from 'moment'

function normalize(data) {
  if (!data || !data.configurationSettings) return null

  /** Get unique subcatories */
  const subCats = [
    ...new Set(
      data.configurationSettings
        .map(setting => setting.subCategory)
        .sort((a, b) => a.localeCompare(b))
    )
  ]

  /** Create list of settings grouped by subcategory */
  const sections = []
  subCats.forEach(subCat => {
    sections.push({
      settings: data.configurationSettings.filter(
        setting => setting.subCategory === subCat
      ),
      name: subCat
    })
  })

  return sections
}

const featureOverage = {
  specName: 'Maximum Allowed Feature Overage',
  settingValue: 10,
  defaultValue: 10,
  readOnly: true,
  specDataType: 'percent',
  specDescription:
    'A percentage of the total allocated feature count, limiting the maximum overage count per feature.'
}

const StyledResult = styled.div`
  > div {
    border: none;
  }
`
const Styles = styled.div`
  .content {
    padding: 0 0 0 1rem;
    display: flex;

    > div {
      flex: 1;
    }
  }
  .regular.left {
    button {
      text-transform: capitalize;
    }
  }
`
export const ServerSettings = ({ server, onUpdate }) => {
  const theme = useContext(KaizenThemeContext)
  const { notify } = useAppNotifications()
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org?.id
  const vgId = virtualGroup?.id
  const { id, status } = server
  const [sections, setSections] = useState([])
  const [pageLoading, setPageLoading] = useState(true)
  const isReadOnly = status === LS_STATUS_ENABLED
  const [changes, setChanges] = useState([])
  const nonDefaults = useMemo(() => {
    const results = []
    sections.forEach(section => {
      section.settings.forEach(setting => {
        const { settingValue, defaultValue, specId } = setting
        if (settingValue !== defaultValue) {
          results.push({
            specId,
            value: defaultValue
          })
        }
      })
    })
    return results
  }, [sections])

  const { getData, loading, error, abort } = useFetch({
    endpoint: API_GET_SETTINGS(orgId, vgId, SETTINGS_SCOPES.LICENSE_SERVER, id),
    actionLabel: 'Get server settings',
    SessionExpired: SessionExpired,
    normalizer: normalize
  })
  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_PUT_SETTINGS(orgId),
    actionLabel: 'Save server settings',
    method: 'PUT',
    SessionExpired: SessionExpired
  })

  useEffect(() => {
    const getSettings = async () => {
      const data = await getData()

      if (data) {
        setSections(data)
        setPageLoading(false)
      }
    }
    getSettings()

    return () => {
      abort()
    }
  }, [vgId]) //eslint-disable-line react-hooks/exhaustive-deps

  const submit = async values => {
    const body = {
      settingScope: SETTINGS_SCOPES.LICENSE_SERVER,
      settingScopeInstanceXid: id,
      virtualGroupId: vgId,
      configurationSettings: values.map(change => {
        const { specId, value } = change
        return {
          specId,
          settingValue: value
        }
      })
    }
    const result = await sendRequest({ body })
    if (result) {
      const msg = `Server settings successfully updated!`
      notify(msg, null, null, 'Updated server settings')
      onUpdate && onUpdate()
    }
  }

  if (loading || error || pageLoading)
    return (
      <StyledResult>
        <Result status={error ? 'error' : 'loading'} />
      </StyledResult>
    )

  return (
    <>
      {submitting && <ModalSpinner />}
      {/* {isReadOnly && (
        <div>Settings cannot be changed while the server is enabled</div>
      )} */}
      <Styles>
        <Tabs tabPosition='left'>
          <Tab id='Overage' title='Feature Overage'>
            <SettingItem
              setting={featureOverage}
              value={featureOverage.settingValue}
            />
          </Tab>
          {sections.map(section => {
            return (
              <Tab
                key={section.name}
                id={section.name}
                title={section.name.replaceAll('_', ' ')}
              >
                <ItemList theme={theme}>
                  {section.settings.map(setting => {
                    const { settingId, specId, settingValue } = setting

                    return (
                      <SettingItem
                        key={settingId}
                        setting={setting}
                        locked={isReadOnly}
                        onChange={setChanges}
                        value={
                          changes.find(change => change.specId === specId)
                            ?.value || settingValue
                        }
                      />
                    )
                  })}
                </ItemList>
              </Tab>
            )
          })}
        </Tabs>
        <ModalButtons>
          <ButtonGroup>
            <Button
              type='secondary'
              disabled={changes.length === 0}
              icon={{ name: 'ActionsUndo' }}
              onClick={() => setChanges([])}
            >
              Undo changes
            </Button>
            <Button
              type='secondary'
              disabled={isReadOnly || nonDefaults.length === 0 || submitting}
              icon={{ name: 'ActionsSyncWarning' }}
              onClick={() => submit(nonDefaults)}
            >
              Reset All to Defaults
            </Button>
            <Button
              disabled={isReadOnly || changes.length === 0 || submitting}
              icon={{ name: 'ServerSetting' }}
              onClick={() => submit(changes)}
            >
              Save Settings
            </Button>
          </ButtonGroup>
        </ModalButtons>
      </Styles>
    </>
  )
}

const ItemStyle = styled.div`
  margin: 0.5rem 0;
  //display: flex;
  //align-items: center;
  padding: 0 0.5rem;

  > div {
    :first-child {
      flex: 1;
    }
    :last-child {
      margin-top: 1rem;
    }
  }
`
const ItemHeader = styled.div`
  text-transform: capitalize;
`
const ItemList = styled.div`
  ${ItemStyle}+${ItemStyle}{
      margin-top: 0.5rem;
      padding-top: 0.5rem;
      border-top: 1px solid ${({ theme }) =>
        theme.darkMode ? theme.colors.darkGray500 : theme.colors.lightGray300};
  }
`
const StyledSwitch = styled.div`
  > div {
    margin: 0 0 0.5rem 0;

    .switch-display,
    label {
      margin-right: 0 !important;
    }
  }
`
const SettingItem = ({ setting, value, onChange, locked }) => {
  const {
    specName,
    specDescription,
    specDataType,
    specMin,
    specMax,
    settingValue,
    defaultValue,
    specId
  } = setting
  const isReadOnly = locked || setting.readOnly
  const theme = useContext(KaizenThemeContext)
  const isDuration = specDataType === SETTINGS_DATA_TYPES.TIMERANGE
  const isInt = specDataType === SETTINGS_DATA_TYPES.INTEGER
  const isBool = specDataType === SETTINGS_DATA_TYPES.BOOLEAN
  const isPercent = specDataType === 'percent'
  const isDefault = defaultValue === value
  const isChanged = value !== settingValue

  const updateSettingChange = val => {
    onChange(changes => {
      const rest = changes.filter(change => change.specId !== specId)

      const valForm = isInt ? val + '' : val
      if (valForm !== settingValue) {
        return [...rest, { specId, value: valForm }]
      } else {
        return rest
      }
    })
  }

  return (
    <ItemStyle>
      <div>
        <ItemHeader>
          <span
            style={{
              padding: '0.25rem 0',
              display: 'inline-block',
              opacity: isDefault ? '0.8' : 1
            }}
          >
            <Text textStyle='h3'>{specName.replaceAll('_', ' ')}</Text>
          </span>
          {!isDefault && (
            <span style={{ marginLeft: '1rem', fontSize: '0.8rem' }}>
              <Tag color='green' clickable={false}>
                Custom
              </Tag>
            </span>
          )}
          {isReadOnly && (
            <span style={{ marginLeft: '1rem', fontSize: '0.8rem' }}>
              <Tag clickable={false} color='gray'>
                Read-only
              </Tag>
            </span>
          )}
        </ItemHeader>
        <div>
          <div style={{ maxWidth: '300px', marginBottom: '0.5rem' }}>
            <Text textStyle='p2' color={theme.colors.textbox.placeholder}>
              {specDescription}
            </Text>
          </div>
          <Text textStyle='p2'>
            {isDuration && (
              <div>
                <div>
                  <span style={{ color: theme.colors.textbox.placeholder }}>
                    Accepted values from
                  </span>{' '}
                  <span>
                    {`${Math.trunc(
                      moment.duration(specMin).asDays()
                    )}D, ${moment
                      .duration(specMin)
                      .hours()}H, ${moment.duration(specMin).minutes()}M`}
                  </span>{' '}
                  <span style={{ color: theme.colors.textbox.placeholder }}>
                    to
                  </span>{' '}
                  <span>
                    {`${Math.trunc(
                      moment.duration(specMax).asDays()
                    )}D, ${moment
                      .duration(specMax)
                      .hours()}H, ${moment.duration(specMax).minutes()}M`}
                  </span>
                </div>
                <div>
                  <span style={{ color: theme.colors.textbox.placeholder }}>
                    Default value:{' '}
                  </span>
                  <span>
                    {`${Math.trunc(
                      moment.duration(defaultValue).asDays()
                    )}D, ${moment
                      .duration(defaultValue)
                      .hours()}H, ${moment.duration(defaultValue).minutes()}M`}
                  </span>
                </div>
              </div>
            )}
            {isInt && (
              <div>
                <span style={{ color: theme.colors.textbox.placeholder }}>
                  Accepted values{' '}
                </span>
                <span>
                  {specMin} - {specMax}
                </span>
                <span style={{ color: theme.colors.textbox.placeholder }}>
                  , default value:{' '}
                </span>
                <span>{defaultValue}</span>
              </div>
            )}
          </Text>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        {/* <div>Default: x</div> */}
        {isBool && (
          <StyledSwitch>
            <Switch
              disabled={isReadOnly}
              showText
              checked={value === 'True'}
              onChange={() =>
                updateSettingChange(value === 'True' ? 'False' : 'True')
              }
            />
          </StyledSwitch>
        )}
        {isInt && (
          <Numberbox
            disabled={isReadOnly}
            value={value}
            onChange={updateSettingChange}
            min={specMin}
            max={specMax}
          />
        )}
        {isDuration && (
          <DurationSelector
            readOnly={isReadOnly}
            onChange={updateSettingChange}
            min={specMin}
            max={specMax}
            value={value}
          />
        )}
        {isPercent && `${value}%`}
        {isChanged && (
          <div>
            <Button
              type='secondary'
              variant='link'
              icon={{ name: 'ActionsUndo' }}
              onClick={() => {
                onChange(changes => {
                  const rest = changes.filter(
                    change => change.specId !== specId
                  )
                  return rest
                })
              }}
            >
              Undo
            </Button>
          </div>
        )}
      </div>
    </ItemStyle>
  )
}
