import React from 'reactn'
import styled from 'styled-components'
import { useAppNotifications, useFetch } from 'common'
import Result from '@kaizen-ui/result'
import Button from '@kaizen-ui/button'
import { API_UPGRADE_STATUS, UPGRADE_MODE } from '../../api'
import { SessionExpired } from '../../Components'

const StyledResult = styled.div`
  > div {
    border: none;
  }
  text-align: center;
`
const StyledDesc = styled.div`
  text-align: left;
`
const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TriggerOfflineUpgradeOnSecondary = ({
  onUpdate,
  secondaryNodeAddress
}) => {
  const { getData: startPortalAssistedUpgrade, loading: starting } = useFetch({
    endpoint: API_UPGRADE_STATUS,
    actionLabel: `Trigger Portal Assisted Upgrade for Node - ${secondaryNodeAddress}`,
    SessionExpired: SessionExpired,
    method: 'POST',
    responseOnly: true
  })

  const { notify } = useAppNotifications()

  const submit = async () => {
    const body = {
      mode: UPGRADE_MODE.OFFLINE,
      secondaryNodeAddress
    }
    const result = await startPortalAssistedUpgrade({ body })
    if (result) {
      const msg = `Triggered Portal Assisted Upgrade for Node - ${secondaryNodeAddress}!`
      notify(msg, null, null, 'Trigger Portal Assisted Upgrade')
      onUpdate && onUpdate()
    }
  }

  return (
    <>
      <StyledResult>
        <Result
          status={starting ? 'loading' : 'warning'}
          icon={{
            name: 'ServerRefresh'
          }}
          title={`Do you really want to start portal assisted upgrade for node ${secondaryNodeAddress}?`}
        >
          <StyledDesc>
            <ol>
              <li>
                If you start an upgrade for one of the secondary nodes, all the
                secondary nodes in the cluster will be removed from the cluster
                and wiped clean. The current Primary node will be put in
                maintenance mode, which means that modifications will be
                disabled.
              </li>
              <li>
                Client leasing operations will still be allowed on the primary
                node.
              </li>
              <li>
                The license configuration of the current virtual appliance will
                be exported to a Migration File, but events will not be
                included. You'll see an option to download the Migration File
                once file is ready.
              </li>
            </ol>
          </StyledDesc>
          <ActionButtons>
            <Button type='primary' onClick={submit} disabled={starting}>
              Upgrade
            </Button>
          </ActionButtons>
        </Result>
      </StyledResult>
    </>
  )
}
