import React, { useState, useEffect, useGlobal } from 'reactn'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { KaizenThemeProvider, KaizenThemes } from '@kaizen-ui/styles'
import { BrowserRouter as Router } from 'react-router-dom'
import { useAuth, ProvideAuth, useUpgradeFileInstallStatus } from './hooks'
import { FloatingSpinner, GlobalStyle, useFetch } from 'common'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AppRouter, Header, SideNav } from './Components'
import { DLS_VERSION } from './api'
import { GLOBAL_SERVER_DOWN, GLOBAL_VA_VERSION } from './globalState'
import { ServerErrorPage } from './Pages'
import { useNodeRole } from './hooks/useNodeRole'

const AppLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`
const Row = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`

const themeOptions = [
  { label: 'Dark', value: KaizenThemes.enterpriseDark },
  { label: 'Light', value: KaizenThemes.enterpriseLight }
]

function App() {
  const [loading, setLoading] = useState(true)
  const [, setVersion] = useGlobal(GLOBAL_VA_VERSION)
  const [serverDown] = useGlobal(GLOBAL_SERVER_DOWN)
  const ls = localStorage.getItem('nvl::theme') || 'Light'
  const defaultTheme =
    themeOptions.find(to => to.label === ls) || themeOptions[1]
  const [theme, setTheme] = useState(defaultTheme)
  const { checkAuth, user, meError } = useAuth()
  useNodeRole()
  useUpgradeFileInstallStatus(user)

  const { getData, abort } = useFetch({
    endpoint: DLS_VERSION,
    actionLabel: 'Get current DLS version',
    supressToast: true
  })

  useEffect(() => {
    checkAuth(() => {
      setLoading(false)
    })
    const getDLSversion = async () => {
      const data = await getData()
      if (data) {
        const { buildNumber } = data || {}
        setVersion(buildNumber)
      }
    }
    getDLSversion()
    return () => {
      abort()
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      toast.dismiss('upgrade-file-install-progress')
    }
  }, [user])

  return loading ? (
    <>
      <GlobalStyle theme={theme.value} />
      <KaizenThemeProvider theme={theme.value}>
        {/* <Text>loading, please wait...</Text> */}
        <FloatingSpinner visible />
      </KaizenThemeProvider>
    </>
  ) : (
    <>
      <GlobalStyle theme={theme.value} />
      <KaizenThemeProvider theme={theme.value}>
        <ReactTooltip effect='solid' className='nvl' />
        <ProvideAuth>
          <Router>
            <ToastContainer draggable={false} hideProgressBar />
            <AppLayout>
              <Header
                theme={theme}
                setTheme={setTheme}
                themeOptions={themeOptions}
              />
              {serverDown ? (
                <ServerErrorPage error={meError} />
              ) : (
                <Row>
                  <SideNav />
                  <AppRouter />
                </Row>
              )}
            </AppLayout>
          </Router>
        </ProvideAuth>
      </KaizenThemeProvider>
    </>
  )
}

export default App
