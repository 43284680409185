import React, { useGlobal } from 'reactn'
import { Route, Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import //DEV_MODE,
//USER_PERMISSIONS
'../../api'
import { useAuth } from '../../hooks'
import {
  Login,
  SetPassword,
  Support,
  // LicenseServers,
  // DevTools,
  //LicenseServerDetails,
  // Notifications,
  ServiceInstance,
  Setup,
  Upgrade,
  Dashboard,
  Leases,
  Events,
  Maintenance,
  Metrics,
  Settings
} from '../../Pages'
import {
  OnlineMigrationNotifier,
  RequireOrgAndVirtualGroupId,
  RequireOrgId,
  //RequireUserAction,
  RouteError
} from '../../Components'
import {
  RequirePendingSetup,
  RequireServerId,
  RequireSetup
} from '../RequireGlobalValues'
import { UpgradeNotifier } from '../UpgradeNotifier'
import { GLOBAL_MAIN_CONTENT_REF } from '../../globalState'

export const ROUTE_LOGIN = '/login'
export const ROUTE_DASHBOARD = '/'
export const ROUTE_SETUP = '/setup'
export const ROUTE_SET_PASSWORD = '/set-password'
export const ROUTE_UPGRADE = '/va-upgrade'
export const ROUTE_RESET_PASSWORD = '/reset-password'
export const ROUTE_ENTITLEMENTS = '/entitlements'
export const ROUTE_SERVICE_INSTANCES = '/service-instances'
export const ROUTE_SUPPORT = '/support'
export const ROUTE_LICENSE_SERVERS = '/license-servers'
export const ROUTE_LICENSE_SERVER_DETAILS = '/license-server/details/:serverId'
export const ROUTE_LICENSE_SERVER_CREATE = '/license-server/create'
export const ROUTE_VIRTUAL_GROUPS = '/virtual-groups'
export const ROUTE_HISTORY = '/history'
export const ROUTE_USERS = '/user-management'
export const ROUTE_ORG_TRANSFERS = '/org-transfers'
export const ROUTE_SOFTWARE = '/software'
export const ROUTE_LEASES = '/leases'
export const ROUTE_MAINTENANCE = '/maintenance'
export const ROUTE_METRICS = '/metrics'
export const ROUTE_DEVTOOLS = '/devtools'
export const ROUTE_ADMIN_NOTIFICATIONS = '/admin-notifications'
export const ROUTE_EVENTS = '/events'
export const ROUTE_ADMIN_SEARCH = '/admin-search'
export const ROUTE_SETTINGS = '/settings'

const Main = styled.div`
  padding: 0 1rem 1rem 1rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`

function PrivateRoute({ children, ...rest }) {
  const auth = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.setupRequired ? (
          <>
            {/* {children} */}
            <Redirect to={ROUTE_SETUP} />
          </>
        ) : auth.user ? (
          children
        ) : (
          <>
            {children}
            <Redirect
              push={true}
              to={{
                pathname: ROUTE_LOGIN,
                state: { from: location }
              }}
            />
          </>
        )
      }
    />
  )
}

/** If a page requires org and/or virtual group ids to be selected, wrap in a RequireOrgAndVirtualGroupId, or just org, or just vg
 *  If a page requires a user permission, wrap in RequireUserAction and pass in the required permission, yes this should be renamed ;-)
 *  Pages that require authentication should use PrivateRoute instead of Route
 */
export const AppRouter = () => {
  const scrollRef = React.useRef(undefined)
  const [, setScroll] = useGlobal(GLOBAL_MAIN_CONTENT_REF)

  React.useEffect(() => {
    setScroll(scrollRef)
  }, [scrollRef]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Main ref={scrollRef}>
        {/*old*/}
        <OnlineMigrationNotifier />
        {/*new*/}
        <UpgradeNotifier />
        <Switch>
          <Route exact path={ROUTE_LOGIN}>
            <RequireSetup>
              <Login />
            </RequireSetup>
          </Route>
          <Route exact path={ROUTE_SETUP}>
            <RequirePendingSetup>
              <Setup />
            </RequirePendingSetup>
          </Route>

          <Route exact path={ROUTE_SET_PASSWORD}>
            <RequirePendingSetup>
              <SetPassword />
            </RequirePendingSetup>
          </Route>

          <Route exact path={ROUTE_UPGRADE}>
            <RequirePendingSetup>
              <Upgrade />
            </RequirePendingSetup>
          </Route>

          <Route exact path={ROUTE_SUPPORT}>
            <Support />
          </Route>

          <PrivateRoute exact path={ROUTE_DASHBOARD}>
            <RequireSetup>
              <Dashboard />
            </RequireSetup>
          </PrivateRoute>

          <PrivateRoute exact path={ROUTE_LEASES}>
            <RequireServerId>
              <RequireOrgId>
                <Leases />
              </RequireOrgId>
            </RequireServerId>
          </PrivateRoute>

          <PrivateRoute exact path={ROUTE_SETTINGS}>
            <RequireServerId>
              <RequireOrgId>
                <Settings />
              </RequireOrgId>
            </RequireServerId>
          </PrivateRoute>

          <PrivateRoute exact path={ROUTE_METRICS}>
            <RequireServerId>
              <RequireOrgId>
                <Metrics />
              </RequireOrgId>
            </RequireServerId>
          </PrivateRoute>

          <PrivateRoute exact path={ROUTE_MAINTENANCE}>
            <RequireServerId>
              <RequireOrgId>
                <Maintenance />
              </RequireOrgId>
            </RequireServerId>
          </PrivateRoute>

          <PrivateRoute exact path={ROUTE_SERVICE_INSTANCES}>
            <ServiceInstance />
          </PrivateRoute>

          <PrivateRoute exact path={ROUTE_EVENTS}>
            <RequireOrgAndVirtualGroupId>
              <RequireServerId>
                <Events />
              </RequireServerId>
            </RequireOrgAndVirtualGroupId>
          </PrivateRoute>

          <Route component={RouteError} />
        </Switch>
      </Main>
    </>
  )
}
