import {
  GLOBAL_USER_COMMON,
  GLOBAL_ORG_COMMON,
  GLOBAL_VIRTUAL_GROUP_COMMON,
  GLOBAL_NOTIFICATIONS_COMMON
} from 'common'
export const GLOBAL_USER = GLOBAL_USER_COMMON
export const GLOBAL_ORG = GLOBAL_ORG_COMMON
export const GLOBAL_VIRTUAL_GROUP = GLOBAL_VIRTUAL_GROUP_COMMON
export const GLOBAL_NOTIFICATIONS = GLOBAL_NOTIFICATIONS_COMMON
export const GLOBAL_SERVER_ID = 'serverId'
export const GLOBAL_IS_UNREGISTERED_USER = 'isUnregistered'
export const GLOBAL_SERVICE_INSTANCE_ID = 'serviceInstanceId'
export const GLOBAL_PENDING_RETURNS = 'hasPendingReturns'
export const GLOBAL_VA_VERSION = 'vaVersion'

//node Role
export const GLOBAL_NODE_ROLE = 'nodeRole'
export const GLOBAL_NODE_ROLE_REFRESH = 'nodeRoleRefresh'

//inplace and portal assisted upgrade
export const GLOBAL_UPGRADE_STATUS_REFRESH = 'upgradeStatusRefresh'
export const GLOBAL_UPGRADE_STATUS = 'upgradeStatus'
export const GLOBAL_UPGRADE_STATUS_LOADING = 'upgradeStatusLoading'
export const GLOBAL_IS_UPGRADE_IN_PROGRESS = 'isUpgradeInProgress'
export const GLOBAL_UPGRADE_MODE = 'upgradeMode'
//eo inplace and portal assisted upgrade

//overhead for offline
export const GLOBAL_OFFLINE_UPGRADE_TRIGGERED = 'isOfflineUpgradeTriggered'

//online migration backward compatibility
export const GLOBAL_ONLINE_MIGRATION_STATUS = 'onlineMigrationStatus'
export const GLOBAL_ONLINE_MIGRATION_STATUS_REFRESH =
  'onlineMigrationStatusRefresh'

export const GLOBAL_UPGRADE_FILE_INSTALL_TRIGGERED =
  'upgradeFileInstallTriggered'
export const GLOBAL_UPGRADE_FILE_INSTALL_REFRESH =
  'upgradeFileInstallStatusRefresh'
export const GLOBAL_UPGRADE_FILE_INSTALL_STATUS = 'upgradeFileInstallStatus'

export const GLOBAL_MAIN_CONTENT_REF = 'scrollRef'
export const GLOBAL_IS_CONTAINERIZED = 'isContainerized'
export const GLOBAL_IS_USER_LOCKED = 'isUserLocked'
export const GLOBAL_SERVER_DOWN = 'serverDown'
/*export const GLOBAL_EVENTS_DOWNLOAD = 'downloadEvents'
export const GLOBAL_EVENTS_DOWNLOAD_ENCRY = 'downloadEventsEncrypted'*/

export const initialState = {
  user: null,
  orgs: [],
  org: null,
  virtualGroup: null,
  virtualGroups: [],
  groupUpdated: null,
  notifications: [],
  serverId: null,
  isUnregistered: false,
  serviceInstanceId: null,
  hasPendingReturns: false,
  version: '',
  //new upgrade
  nodeRole: '',
  nodeRoleRefresh: false,
  upgradeMode: '',
  upgradeStatus: undefined,
  upgradeStatusLoading: false,
  isUpgradeInProgress: false,
  upgradeStatusRefresh: false,
  //eo new upgrade
  isOfflineUpgradeTriggered: false,
  isContainerized: false,
  isUserLocked: false,
  /*  downloadEvents: false,
  downloadEventsEncrypted: false,*/
  scrollRef: undefined,
  serverDown: false,
  upgradeFileInstallTriggered: false,
  upgradeFileInstallStatusRefresh: false,
  upgradeFileInstallStatus: null,
  //online migration backward compatibility
  onlineMigrationStatusRefresh: false,
  onlineMigrationStatus: undefined
}
