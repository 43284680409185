import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Tabs, { Tab } from '@kaizen-ui/tabs'
import Button from '@kaizen-ui/button'
import {
  ModalButtons,
  ModalSpinner,
  useAppNotifications,
  useFetch
} from 'common'
import { ModifyPoolFeatures } from './ModifyPoolFeatures'
import { AddServerFeatures } from '../../Components/LicenseServerActions/AddServerFeatures'
import { PreviewManagePoolFeatures } from './PreviewManagePoolFeatures'
import { API_LICENSE_POOL } from '../../api'
import { useGlobal } from 'reactn'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'

const StyledTabs = styled.div`
  overflow: auto;
  margin: 0.5rem;

  .content {
    display: block;
    padding: 1rem 0.5rem 0 0;
  }
`
const validationSchema = Yup.object().shape(
  {
    features: Yup.array().when(['removals', 'adjustments'], {
      is: (removals, adjustments) =>
        (removals && removals.length) || (adjustments && adjustments.length),
      then: Yup.array(),
      otherwise: Yup.array().min(
        1,
        'You must add, remove or modify at least 1 feature'
      )
    }),
    removals: Yup.array().when(['features', 'adjustments'], {
      is: (features, adjustments) =>
        (features && features.length) || (adjustments && adjustments.length),
      then: Yup.array(),
      otherwise: Yup.array().min(
        1,
        'You must add, remove or modify at least 1 feature'
      )
    }),
    adjustments: Yup.array()
  },
  [
    ['features', 'removals'],
    ['features', 'adjustments'],
    ['removals', 'adjustments']
  ]
)
const initialValues = {
  features: [],
  removals: [],
  adjustments: []
}

export const ManagePoolFeatures = ({ pool, onUpdate, server }) => {
  const { notify } = useAppNotifications()
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const { id: serverId, name: serverName } = server
  const { id, name } = pool

  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_LICENSE_POOL(orgId, vgId, serverId, id),
    actionLabel: `Feature updates for ${name} license pool`,
    method: 'PATCH'
  })

  const submit = async values => {
    const { features, removals, adjustments } = values
    const removing = adjustments.filter(f => !f.increase)
    const adding = adjustments.filter(f => f.increase)
    const adj = {
      managePoolRequest: {
        removeLicenses: removing.map(f => {
          return {
            licensePoolFeatureId: f.id,
            totalAllotment: f.addCount
          }
        }),
        addLicenses: adding.map(f => {
          return {
            licensePoolFeatureId: f.id,
            totalAllotment: f.addCount
          }
        })
      }
    }
    const add = {
      addFeatureRequest: {
        addFeatures: features.map(f => {
          const { isCardinal } = f
          const count = isCardinal
            ? { totalAllotment: parseInt(f.addCount) }
            : {}
          return {
            licenseServerFeatureId: f.id,
            ...count
          }
        })
      }
    }
    const del = {
      removeFeatureRequest: {
        removeFeatures: removals.map(feature => ({
          licensePoolFeatureId: feature && feature.id
        }))
      }
    }

    const body = {
      ...(features && features.length ? add : {}),
      ...(removals && removals.length ? del : {}),
      ...(adjustments && adjustments.length ? adj : {})
    }

    const result = await sendRequest({ body })
    if (result) {
      const msg = `License pool ${name} was successfully updated on server ${serverName}!`
      notify(msg, null, null, `Updated the license pool features`)
      onUpdate && onUpdate()
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: submit
  })

  return (
    <>
      {submitting && <ModalSpinner />}
      <StyledTabs>
        <Tabs>
          <Tab id={1} title='Modify existing'>
            <ModifyPoolFeatures server={server} pool={pool} formik={formik} />
          </Tab>
          <Tab id={2} title='Add new'>
            <AddServerFeatures server={server} formik={formik} pool={pool} />
          </Tab>
          <Tab id={3} title='Preview changes' disabled={!formik.dirty}>
            <PreviewManagePoolFeatures formik={formik} />
          </Tab>
        </Tabs>
      </StyledTabs>
      <ModalButtons>
        <Button
          disabled={!formik.isValid || submitting}
          onClick={formik.handleSubmit}
        >
          Update Pool Features
        </Button>
      </ModalButtons>
    </>
  )
}
