import React, { useMemo, useContext, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import Icon from '@kaizen-ui/icon'
import {
  formatDate,
  formatExpirationDate,
  SelectColumnFilter,
  Table,
  UsageIndicator
} from 'common'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import {
  LS_FEATURE_STATUS_EXPIRED,
  LS_FEATURE_STATUS_EXPIRING,
  LS_FEATURE_STATUS_FUTURE_START
} from '../../api'
import queryString from 'query-string'
import { ROUTE_DASHBOARD } from '../../Components'
import { useHistory } from 'react-router-dom'

const Uppercase = styled.div`
  text-transform: uppercase;
`

export const ServerFeatures = ({ server, search, lastUpdate, refresh }) => {
  const { licenseServerFeatures } = server
  const theme = useContext(KaizenThemeContext)
  const history = useHistory()
  const { globalFilter, filters } = queryString.parse(window.location.search)
  const [filter, setFilter] = useState({ globalFilter, filters })

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Feature (PKID)',
        accessor: 'displayLabel',
        Cell: ({ row }) => {
          const { displayLabel, emsProductKeyId, status } = row.original

          const isExpiring = status === LS_FEATURE_STATUS_EXPIRING
          const isExpired = status === LS_FEATURE_STATUS_EXPIRED

          const dateStyle = isExpired
            ? {
                style: {
                  borderLeft: `5px solid ${theme.colors.danger}`,
                  padding: '0.75rem',
                  margin: '-0.75rem'
                }
              }
            : isExpiring
            ? {
                style: {
                  borderLeft: `5px solid ${theme.colors.warning}`,
                  padding: '0.75rem',
                  margin: '-0.75rem'
                }
              }
            : {
                style: {
                  borderLeft: `5px solid transparent`,
                  padding: '0.75rem',
                  margin: '-0.75rem'
                }
              }

          return (
            <div {...dateStyle}>
              <div>{displayLabel}</div>
              <small>{emsProductKeyId}</small>
            </div>
          )
        }
      },
      {
        accessor: 'isOverage',
        hidden: true,
        Filter: SelectColumnFilter,
        Header: 'In Overage'
      },
      {
        accessor: 'isUnallocated',
        hidden: true,
        Filter: SelectColumnFilter,
        Header: 'Unallocated'
      },
      {
        Header: 'Assigned / Allocated',
        accessor: 'totalQuantity',
        align: 'right',
        Cell: ({ row }) => {
          const {
            totalQuantity,
            isCardinal,
            inUseQuantity,
            overageCount
          } = row.original

          return !isCardinal ? (
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}
            >
              n/a
            </div>
          ) : (
            <>
              <UsageIndicator
                assignedQuantity={inUseQuantity}
                totalQuantity={totalQuantity}
                short
                overageCount={overageCount}
              />
            </>
          )
        }
      },
      {
        Header: 'License Type',
        accessor: 'licenseType',
        Cell: ({ row }) => {
          const { licenseType } = row.original
          const formatted = licenseType.replaceAll('_', ' ')
          return <Uppercase>{formatted}</Uppercase>
        }
      },
      {
        Header: 'Effective',
        accessor: 'startDate',
        width: 100,
        Cell: ({ row }) => {
          const { startDate, status } = row.original
          const pending = status === LS_FEATURE_STATUS_FUTURE_START
          const attrs = pending
            ? {
                style: {
                  fontStyle: 'italic',
                  color: theme.colors.tooltip.warning.foreground,
                  marginRight: '0.5rem'
                },
                'data-tip': 'Start date is in the future'
              }
            : {}

          return (
            <>
              <span {...attrs}>{formatDate(startDate)}</span>
              {pending && (
                <Icon
                  name='StatusWarning'
                  color={theme.colors.tooltip.warning.foreground}
                />
              )}
            </>
          )
        }
      },
      {
        Header: 'Expiration',
        accessor: 'endDate',
        width: 100,
        Cell: ({ row }) => {
          const { endDate, status } = row.original

          const isExpiring = status === LS_FEATURE_STATUS_EXPIRING
          const isExpired = status === LS_FEATURE_STATUS_EXPIRED

          const dateStyle = isExpired
            ? { style: { color: theme.colors.red500 } }
            : isExpiring
            ? { style: { color: theme.colors.orange700 } }
            : {}
          const cellStyle = isExpired
            ? { 'data-tip': 'This feature has expired', 'data-type': 'error' }
            : isExpiring
            ? {
                'data-tip': 'This feature will expire soon',
                'data-type': 'warning'
              }
            : {}
          return (
            <div {...cellStyle}>
              {(isExpiring || isExpired) && (
                <Icon
                  name='StatusWarning'
                  //color={isExpired ? theme.colors.danger : theme.colors.warning}
                />
              )}

              <span {...dateStyle}>{formatExpirationDate(endDate)}</span>
            </div>
          )
          //return <>{formatExpirationDate(endDate)}</>
        }
      }
    ],
    [theme]
  )

  useEffect(() => {
    const { globalFilter, filters } = filter

    if (globalFilter || filters) {
      const parameters = {
        globalFilter,
        filters:
          typeof filters === 'string'
            ? filters
            : !filters || filters.length === 0
            ? undefined
            : queryString.stringify(
                filters.reduce(
                  (obj, item) => Object.assign(obj, { [item.id]: item.value }),
                  {}
                )
              )
      }
      const route = `${ROUTE_DASHBOARD}?tab=2&${queryString.stringify(
        parameters
      )}`
      history.replace(route)
    } else {
      const route = `${ROUTE_DASHBOARD}?tab=2`
      history.replace(route)
    }
  }, [filter]) //eslint-disable-line react-hooks/exhaustive-deps

  const [userFilters] = React.useState(
    Object.entries(queryString.parse(filter?.filters)).map(f => {
      return { id: f[0], value: f[1] }
    })
  )

  return (
    <>
      <Table
        columns={columns}
        data={licenseServerFeatures}
        label={'server features'}
        refresh={refresh}
        lastUpdate={lastUpdate}
        onFilter={setFilter}
        globalSearch={search}
        filters={userFilters}
      />
    </>
  )
}
