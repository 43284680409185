import React, { useEffect, useState, useMemo, useGlobal } from 'reactn'
import {
  ContextNotifications,
  ModalSpinner,
  PageHeader,
  useFetch
} from 'common'
import Button, { ButtonGroup } from '@kaizen-ui/button'
import {
  API_SERVICE_INSTANCES,
  HIGH_AVAILABILITY_STATE,
  DOCS_GEN_CCT,
  DOCS_HA_CONFIG,
  DOCS_HIGH_AVAILABILITY,
  DOCS_SI_CONFIGURE,
  LS_LEASING_MODES
} from '../../api'
import { SessionExpired } from '../../Components'
import { ServiceInstanceProperties } from './ServiceInstanceProperties'
import { ClusterNodes } from './ClusterNodes'
import { ServiceInstanceActions } from './ServiceInstanceActions'
import { AddingNode } from './ClusterNodes/AddingNode'
import {
  GLOBAL_NODE_ROLE_REFRESH,
  GLOBAL_USER,
  GLOBAL_VA_VERSION
} from '../../globalState'
import { useLicenseServer } from '../../hooks'

export const ServiceInstance = () => {
  /* const theme = useContext(KaizenThemeContext)*/
  const [pageLoading, setPageLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [, setNodeRoleRefresh] = useGlobal(GLOBAL_NODE_ROLE_REFRESH)
  const [si, setSi] = useState(undefined)
  const [user] = useGlobal(GLOBAL_USER)
  const { isPureContainer } = user || {}
  const { licenseServer } = useLicenseServer({ autoLoad: true })
  const { leasingMode } = licenseServer || {}
  const { getData, loading, abort } = useFetch({
    endpoint: API_SERVICE_INSTANCES,
    actionLabel: 'Get service instance',
    SessionExpired: SessionExpired
  })

  const haState = useMemo(() => si?.high_availability_config?.mode, [si])

  const nodeList = useMemo(
    () =>
      si?.high_availability_config?.config?.nodeList?.sort((a, b) => {
        if (a.role < b.role) {
          return -1
        }
        if (a.role > b.role) {
          return 1
        }
        return 0
      }) || [],
    [si]
  )

  useEffect(() => {
    document.title = 'DLS - Service Instance'
    const getSi = async () => {
      const data = await getData()
      if (data) {
        setPageLoading(false)
        setSi(data)
      }
    }
    getSi()
    setNodeRoleRefresh(val => !val)

    return () => {
      abort()
    }
  }, [refresh]) //eslint-disable-line react-hooks/exhaustive-deps

  const [version] = useGlobal(GLOBAL_VA_VERSION)
  const helpRefHa = React.useRef(null)
  const helpRefSiActions = React.useRef(null)
  const helpRefRestart = React.useRef(null)
  const [step, setStep] = React.useState(-1)
  const help = [
    {
      title: 'High Availability for NVIDIA License System DLS Instances',
      text:
        'To provide licensed clients with continued access to licenses if a DLS instance fails, you can configure DLS instances for high availability. High availability requires two DLS instances in a failover configuration',
      link: DOCS_HIGH_AVAILABILITY(version),
      ref: helpRefHa,
      offsetY: 35
    },
    {
      title: 'Configuring an HA Cluster of DLS Instances',
      text: `If you are configuring an HA cluster of containerized DLS instances, you must deploy the containers that host the instances on two different Kubernetes clusters.`,
      link: DOCS_HA_CONFIG(version),
      ref: helpRefHa,
      offsetY: 35,
      offsetX: 50
    },
    {
      title: 'Generating a Client Configuration Token for a DLS Instance',
      text: `A client configuration token identifies the service instance, license servers, and fulfillment conditions to be used to serve a license in response to a request from a licensed client. This information must be exchanged between a service instance and a licensed client to enable the service instance to serve licenses to the client.`,
      link: DOCS_GEN_CCT(version),
      ref: helpRefSiActions,
      offsetY: 35,
      offsetX: -330
    },
    {
      title: 'Changing the Name and Description of a DLS Instance',
      text: `By default, a DLS instance is created with the name DEFAULT_timestamp and the description ON_PREM_SERVICE_INSTANCE. To distinguish a DLS instance on the NVIDIA Licensing Portal when multiple DLS instances are configured, change these defaults to a meaningful name and the description in the Edit menu.`,
      ref: helpRefSiActions,
      offsetY: 35,
      offsetX: -330
    },
    {
      title: 'Supporting Non-Persistent Desktop Pools from a DLS Instance',
      text: `Non-persistent desktop pools are used by VM instances with reused MAC addresses, such as VM instances created by using Citrix Machine Creation Services (MCS) or VMware Instant Clone technology. If you plan to serve licenses to non-persistent desktop pools from a service instance, prevent the service instance from serving multiple licenses to clients that have the same MAC address.`,
      ref: helpRefSiActions,
      offsetY: 35,
      offsetX: -330
    },
    {
      title: `Restarting a DLS Instance's Internal Services`,
      text: `If a DLS instance has failed because its internal services are no longer active, you can restart the inactive services to recover from the failure.`,
      ref: helpRefRestart,
      offsetY: 35,
      link: DOCS_SI_CONFIGURE(version)
    }
  ]

  if (pageLoading) return <ModalSpinner />

  return (
    <>
      <ContextNotifications list={help} step={step} setStep={setStep} />
      <PageHeader
        title='Service Instance Details'
        //helpUrl={DOCS_LICENSE_SERVER}
        //tip='Get help with license servers'
        subTitle='Manage the DLS service instance and customize to your environment'
        showHelp={() => setStep(0)}
      >
        {si && (
          <ButtonGroup>
            <Button
              type='secondary'
              onClick={() => setRefresh(val => !val)}
              icon={{ name: 'CloudRefresh' }}
            >
              Refresh
            </Button>
            <div ref={helpRefSiActions}>
              <ServiceInstanceActions
                serviceInstance={si}
                onUpdate={() => setRefresh(val => !val)}
                isSimpleMode={
                  leasingMode === LS_LEASING_MODES.Node_Locked.value
                }
              />
            </div>
          </ButtonGroup>
        )}
      </PageHeader>

      <ServiceInstanceProperties
        loading={loading}
        si={si}
        haState={haState}
        isSimpleMode={leasingMode === LS_LEASING_MODES.Node_Locked.value}
        setRefresh={setRefresh}
        isPureContainer={isPureContainer}
        haRef={helpRefHa}
      />

      <div style={{ marginTop: '1rem' }}>
        <ClusterNodes
          nodeList={nodeList}
          maxHaNodesSupported={
            si?.high_availability_config?.maxHaNodesSupported
          }
          loading={loading}
          onUpdate={() => setRefresh(val => !val)}
          isPureContainer={isPureContainer}
          haState={haState}
          restartRef={helpRefRestart}
        />

        {/*{haState === HIGH_AVAILABILITY_STATE.UNCONFIGURED && (
          <StyledBanner theme={theme}>
            <Banner
              title='High availability is not configured'
              status='passive'
              icon={{
                name: 'StatusWarning',
                size: 48
              }}
            >
              This instance needs to be configured either as STANDALONE or
              CLUSTERED
            </Banner>
          </StyledBanner>
        )}*/}

        {haState === HIGH_AVAILABILITY_STATE.IN_PROGRESS && (
          <AddingNode
            onComplete={() => setRefresh(val => !val)}
            isClustered={nodeList?.length >= 2}
          />
        )}
      </div>
    </>
  )
}
