import React, {
  useGlobal,
  useState,
  useEffect,
  useMemo,
  useContext
} from 'reactn'
import { Table } from 'common'
import Textbox from '@kaizen-ui/textbox'
import Icon from '@kaizen-ui/icon'
import styled from 'styled-components'
import { KaizenThemeContext, KaizenThemes } from '@kaizen-ui/styles'
import { InlineButton } from 'common'
import {
  //GLOBAL_ORG,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'
import ReactTooltip from 'react-tooltip'

const StyledTextbox = styled.div`
  input[inputmode='numeric'] {
    width: 6rem;
    margin: 0;
  }

  > div {
    margin: 0;
  }
`
const FormikError = styled.div`
  color: ${KaizenThemes.enterpriseDark.colors.red500};
  position: absolute;
  margin-top: -0.5rem;
`

export const ModifyPoolFeatures = ({ server, pool, formik }) => {
  const theme = useContext(KaizenThemeContext)
  //const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  //const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const [features, setFeatures] = useState([])
  const [, setFilter] = useState({})
  const filteredFeatures = useMemo(() => {
    return features.filter(
      feature => !formik.values.removals.map(r => r.id).includes(feature.id)
    )
  }, [formik.values, features])

  useEffect(() => {
    const parseFeatures = async () => {
      const { licensePoolFeatures } = pool
      setFeatures(
        licensePoolFeatures.map(poolFeature => {
          const { feature, inUse, totalAllotment } = poolFeature

          return {
            ...poolFeature,
            feature,
            min: inUse || 1,
            available: 2,
            max: parseInt(feature.unassignedQuantity) + parseInt(totalAllotment)
          }
        })
      )
    }
    parseFeatures()
  }, [vgId]) //eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'feature.displayLabel',
        hidden: false
      },
      {
        Header: 'Product Key Id',
        accessor: 'feature.emsProductKeyId'
      },
      {
        Header: 'Allocated',
        accessor: 'totalAllotment',
        minWidth: 210,
        Cell: ({ row }) => {
          const {
            totalAllotment,
            min,
            max,
            inUse,
            feature: { isCardinal }
          } = row.original
          const match = formik.values.adjustments.find(
            e => e.id === row.original.id
          )
          const value = (match && match.addCount) || totalAllotment
          return (
            <>
              {isCardinal ? (
                <>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <div
                      data-tip={`This feature can be reduced to ${min}`}
                      style={{
                        padding: '0 0.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        color:
                          value <= min
                            ? theme.colors.table.header.actionDisabled
                            : theme.colors.table.body.foreground
                      }}
                    >
                      <div>
                        <Icon
                          name='ActionsCircleSubtract'
                          color={
                            value <= min
                              ? theme.colors.table.header.actionDisabled
                              : theme.colors.table.body.foreground
                          }
                        />
                      </div>
                      <div>{min}</div>
                    </div>
                    <LicenseCount
                      row={row}
                      value={value}
                      onChange={val => {
                        const rest = formik.values.adjustments.filter(
                          e => e.id !== row.original.id
                        )
                        const change = parseInt(val) !== totalAllotment
                        if (change) {
                          formik.setFieldValue('adjustments', [
                            ...rest,
                            {
                              ...row.original,
                              addCount: parseInt(val),
                              increase: parseInt(val) > totalAllotment
                            }
                          ])
                        } else {
                          formik.setFieldValue('adjustments', [...rest])
                        }
                      }}
                    />
                    <div
                      data-tip={`This feature can be increased to ${max}`}
                      style={{
                        padding: '0 0.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        color:
                          value >= max
                            ? theme.colors.table.header.actionDisabled
                            : theme.colors.table.body.foreground
                      }}
                    >
                      <div>
                        <Icon
                          name='ActionsCircleAdd'
                          color={
                            value >= max
                              ? theme.colors.table.header.actionDisabled
                              : theme.colors.table.body.foreground
                          }
                        />
                      </div>
                      <div>{max}</div>
                    </div>
                  </div>
                  <InlineButton
                    icon={{ name: 'ActionsTrash' }}
                    type='critical'
                    variant='link'
                    tip={
                      inUse > 0
                        ? 'This feature cannot be removed as it has leases issued against it'
                        : 'Remove this feature from the pool'
                    }
                    disabled={inUse > 0}
                    onClick={() => {
                      const rest = formik.values.removals.filter(
                        e => e.id !== row.original.id
                      )
                      formik.setFieldValue('removals', [
                        ...rest,
                        { ...row.original }
                      ])
                    }}
                  />
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    flex: 1
                  }}
                >
                  <span
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      display: 'flex'
                    }}
                  >
                    {'n/a'}
                  </span>
                  <InlineButton
                    icon={{ name: 'ActionsTrash' }}
                    type='critical'
                    variant='link'
                    tip={
                      inUse > 0
                        ? 'This feature cannot be removed as it has leases issued against it'
                        : 'Remove this feature from the pool'
                    }
                    disabled={inUse > 0}
                    onClick={() => {
                      ReactTooltip.hide()
                      const restRemovals = formik.values.removals.filter(
                        e => e.id !== row.original.id
                      )
                      const restAdjustments = formik.values.adjustments.filter(
                        e => e.id !== row.original.id
                      )
                      formik.setFieldValue('adjustments', [...restAdjustments])
                      formik.setFieldValue('removals', [
                        ...restRemovals,
                        { ...row.original }
                      ])
                    }}
                  />
                </div>
              )}
            </>
          )
        },
        canResize: false,
        disableFilters: true,
        align: 'center',
        padding: '0'
      }
    ],
    [formik, theme]
  )

  return (
    <>
      <Table
        columns={columns}
        data={filteredFeatures}
        //loading={pageLoading}
        //fetching={loading}
        //error={error}
        label={'pool features'}
        //lastUpdate={lastUpdate}
        onFilter={setFilter}
        resetOn={vgId}
        disableColumnHiding
        disableExporting
        initialPageSize={5}
        minHeight={1}
        //disablePageSizeSelection
      />
      {formik.touched.features && formik.errors.features && (
        <FormikError>{formik.errors.features}</FormikError>
      )}
    </>
  )
}

const LicenseCount = ({ row, onChange, value: valueProp }) => {
  const [value, setValue] = useState(valueProp)
  const {
    original: {
      max,
      min,
      feature: { isCardinal }
    }
  } = row

  return isCardinal ? (
    <StyledTextbox>
      <Textbox
        value={value}
        onChange={e => setValue(e.target.value)}
        inputMode='numeric'
        onBlur={() => {
          if (value > max) {
            setValue(max)
            onChange(max)
          } else if (value < min || isNaN(value)) {
            setValue(min)
            onChange(min)
          } else {
            onChange(value)
          }
        }}
      />
    </StyledTextbox>
  ) : (
    <></>
  )
}
