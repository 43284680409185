"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "SpinnerView", {
  enumerable: true,
  get: function get() {
    return _SpinnerView.SpinnerView;
  }
});

var _SpinnerView = require("./SpinnerView");