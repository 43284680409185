import React, {
  useMemo,
  useContext,
  useEffect,
  useState,
  useGlobal
} from 'reactn'
import ReactTooltip from 'react-tooltip'
import { useHistory } from 'react-router-dom'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Icon from '@kaizen-ui/icon'
import Button from '@kaizen-ui/button'
import { Table, useAppNotifications, useFetch } from 'common'
import { ROUTE_DASHBOARD } from '../../Components'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'
import { API_FULFILLMENT_CONDITION, LS_STATUS_DISABLED } from '../../api'

export const FulfillmentConditionDetails = ({
  serverId,
  condition,
  refresh,
  onTabChange
}) => {
  const {
    name,
    bindings,
    id,
    description,
    evaluationOrderIndex,
    selectionRuleId,
    status
  } = condition
  const { notify } = useAppNotifications()
  const history = useHistory()
  const theme = useContext(KaizenThemeContext)
  const data = useMemo(() => bindings, [bindings])
  const [fcSavePrompt, setFcSavePrompt] = useState(false)
  const useDrag = React.useMemo(
    () => data.length > 1 && status === LS_STATUS_DISABLED,
    [data, status]
  )
  const [rows, setRows] = useState(undefined)
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_FULFILLMENT_CONDITION(orgId, vgId, serverId, id),
    actionLabel: `Save pool order for ${name}`,
    method: 'PUT',
    responseOnly: true
  })
  const submit = async () => {
    const body = {
      bindings: rows.map((lp, index) => {
        return {
          evaluationOrderIndex: index,
          licensePoolId: lp.id
        }
      }),
      description,
      evaluationOrderIndex,
      name,
      selectionRuleId
    }

    const result = await sendRequest({ body })
    if (result) {
      const msg = `License pool ordering for ${name} was successfully saved!`
      notify(msg, null, null, `Save pool order for ${name}`)
      setRows(undefined)
      setFcSavePrompt(false)
      ReactTooltip.hide()
      refresh && refresh()
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Order',
        accessor: 'evaluationOrderIndex',
        canResize: false,
        disableFilters: true,
        width: 85,
        Cell: ({ row }) => {
          const { evaluationOrderIndex } = row.values
          const changed = evaluationOrderIndex !== row.index

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{row.index}</span>
              {changed && (
                <span
                  data-tip='The binding order has changed for this pool, but has not yet been saved'
                  style={{ display: 'flex', marginLeft: '0.5rem' }}
                >
                  <Icon name='AwardsFlag1' variant='solid' />
                </span>
              )}
            </div>
          )
        }
      },
      {
        Header: 'Name',
        accessor: 'pool.name',
        Cell: ({ row }) => {
          const {
            pool: { name }
          } = row.original

          return (
            <div
              data-tip='Go to pool'
              onClick={() => ReactTooltip.hide()}
              style={{ fontSize: '0.9rem' }}
            >
              <Button
                variant='link'
                onClick={() => {
                  const route = `${ROUTE_DASHBOARD}?tab=3&filters=name%3D${name}`
                  history.replace(route)
                  onTabChange(3)
                  ReactTooltip.hide()
                }}
              >
                {name}
              </Button>
            </div>
            // <span
            //   onClick={() =>
            //     history.push(
            //       `${ROUTE_LICENSE_SERVER_DETAILS.replace(
            //         ':serverId',
            //         serverId
            //       )}?tab=3&filters=name%3D${name}`
            //     )
            //   }
            // >
            //   {name}
            // </span>
          )
        }
      },
      {
        Header: 'Status',
        accessor: 'pool.status',
        Cell: ({ row }) => {
          const { status } = row.original.pool
          const disabled = status === 'DISABLED'
          const style = disabled
            ? {
                style: { color: theme.colors.red500 },
                'data-tip':
                  'This pool is in edit mode, it will not serve licenses'
              }
            : { style: {} }
          return <span {...style}>{status}</span>
        }
      }
    ],
    [theme, serverId] //eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <>
      <div
        style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5rem' }}
      >
        <Icon name='StatusCircleInformation' />
        <span
          style={{
            marginLeft: '0.5rem',
            color: theme.colors.textbox.placeholder
          }}
        >
          Bound pools can be reordered by drag and drop. The fulfillment
          condition must be disabled to change the evaluation order.
        </span>
      </div>
      <Table
        label='bound pools'
        disabled={submitting}
        fetching={submitting}
        columns={columns}
        data={data}
        minHeight={1}
        useDragAndDrop={useDrag}
        dragName={`bindings-${id}`}
        onIndexChanged={rows => {
          const changes = rows.filter(
            (row, i) => row.evaluationOrderIndex !== i
          )
          const hasChanges = changes.length > 0
          setFcSavePrompt(hasChanges)
          if (hasChanges) {
            setRows(rows)
          } else {
            setRows(undefined)
          }
        }}
        saveLabel={'Save the evaluation order of your bound pools'}
        save={fcSavePrompt ? submit : null}
        disableSorting
      />
    </>
  )
}
