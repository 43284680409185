import React from 'react'
import Button from '@kaizen-ui/button'
import Icon from '@kaizen-ui/icon'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks'
import { ROUTE_LOGIN } from '../AppRouter'
import { theme } from 'common'

const ExpiredActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`
const ExpiredHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${theme.colors.danger};

  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    font-weight: 300;
    margin: 1rem 1rem 1rem 0;
    padding: 0;
    white-space: nowrap;
    //color: ${theme.colors.danger};
  }

  svg {
    margin-right: 0.5rem;
    margin-left: 1rem;
  }
`
const StyledExpired = styled.div`
  p {
    text-align: center;
  }
`

export const SessionExpired = () => {
  const { signout } = useAuth()
  const history = useHistory()

  React.useEffect(() => {
    const autoDismiss = setTimeout(() => {
      toast.dismiss()
      history.push(ROUTE_LOGIN)
      signout()
    }, 5000)

    return () => {
      clearTimeout(autoDismiss)
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledExpired>
      <ExpiredHeader>
        <Icon name='StatusWarning' color={theme.colors.danger} size='larger' />
        <h2>Your session has expired!</h2>
      </ExpiredHeader>
      {/* <p>
        It looks like your session has expired, please return to the login page
        to reauthenticate.
      </p> */}
      <p>
        It looks like your session has expired, redirecting to the login page.
      </p>
      <ExpiredActions>
        <Button
          type='secondary'
          onClick={() => {
            toast.dismiss()
            //signout(() => {})
            history.push(ROUTE_LOGIN)
            signout()
          }}
        >
          Return to Login
        </Button>
      </ExpiredActions>
    </StyledExpired>
  )
}
