import React, {
  useContext,
  useEffect,
  useGlobal,
  useMemo,
  useState
} from 'reactn'
import moment from 'moment'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Text from '@kaizen-ui/text'
import { Block, Result } from '@kaizen-ui/complete'
import {
  GLOBAL_ORG,
  GLOBAL_USER,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'
import {
  FloatingDateRangePicker,
  FormikError,
  ModalButtons,
  ModalSpinner,
  Pill,
  SwitchList,
  useAppNotifications,
  useFetch
} from 'common'
import { SessionExpired } from '../../Components'
import {
  API_EVENTS_EXPORT_DOWNLOAD,
  API_EVENTS_EXPORT_DOWNLOAD_ENCRYPTED,
  API_EVENTS_EXPORT_JOB,
  EVENTS_EXPORT_STATUS
} from '../../api'
import Button, { ButtonGroup } from '@kaizen-ui/button'
import styled from 'styled-components'
/*import { saveAs } from 'file-saver'*/
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`
const StyledBlock = styled.div`
  > div {
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.pageBackground} !important;
  }
`

/*const StyledSwitch = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-top: 1rem;
    margin-left: 0;
  }
`*/

const defaultRange = {
  start: moment(new Date())
    .add(-7, 'days')
    .startOf('day')
    .utc()
    .format(),
  end: moment(new Date())
    .endOf('day')
    .utc()
    .format()
}

export const ExportEvents = ({
  generationStatus,
  setRefreshStatus,
  sid,
  start,
  end,
  activityList,
  encrypted = false
}) => {
  const { notify } = useAppNotifications()
  const theme = useContext(KaizenThemeContext)
  const [user] = useGlobal(GLOBAL_USER)
  const clientId = user?.clientId || ''
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  /* const [downloadEvents, setDownloadEvents] = useGlobal(GLOBAL_EVENTS_DOWNLOAD)
  const [downloadEventsEncrypted, setDownloadEventsEncrypted] = useGlobal(
    GLOBAL_EVENTS_DOWNLOAD_ENCRY
  )*/
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const [generateCollapsed, setGenerateCollapsed] = useState(
    generationStatus?.state === EVENTS_EXPORT_STATUS.COMPLETED
  )
  const [downloadCollapsed, setDownloadCollapsed] = useState(false)
  const categoryOptions = encrypted
    ? [
        { id: 200, label: 'Admin' },
        { id: 100, label: 'Lease' },
        { id: 300, label: 'Telemetry' },
        { id: 400, label: 'Diagnostic' }
      ]
    : [
        { id: 200, label: 'Admin' },
        { id: 100, label: 'Lease' }
      ]

  const [filter, setFilter] = useState({
    dates: {
      start: start ? start : defaultRange.start,
      end: end ? end : defaultRange.end
    },
    categories: activityList?.length
      ? activityList
      : encrypted
      ? [100, 200, 300, 400]
      : [100, 200]
    /* isCritical: false*/
  })
  const selectedDateRange = useMemo(() => {
    const { start, end } = filter.dates
    const mStart = moment(start)
    const mEnd = moment(end)
    /*console.log(mEnd.diff(mStart, 'days'))
    console.log('formatDateTime(start) : ', formatDateTime(start))
    console.log('formatDateTime(end) : ', formatDateTime(end))*/
    const isInvalid = mEnd.diff(mStart, 'days') > 30 //equivalent to 31 days, eg 1st Jan to 31st Jan allowed
    return { start: new Date(start), end: new Date(end), isInvalid }
  }, [filter])

  const { getData: triggerExport, loading: triggeringExport } = useFetch({
    endpoint: API_EVENTS_EXPORT_JOB(orgId, vgId),
    method: 'POST',
    actionLabel: `Generate ${encrypted ? 'encrypted' : ''} events export file ${
      encrypted ? 'for NLP' : ''
    }`,
    SessionExpired: SessionExpired
  })

  const { getData: getDownloadUrl, loading: gettingDownloadUrl } = useFetch({
    endpoint: 'dynamic',
    actionLabel: 'Download events export file',
    SessionExpired: SessionExpired
  })

  /* const { getData: getExportData, loading: gettingExport } = useFetch({
    endpoint: API_EVENTS_EXPORT_DOWNLOAD_JSON(orgId, vgId),
    method: 'GET',
    actionLabel: `Download ${encrypted ? 'encrypted' : ''} events export file`,
    SessionExpired: SessionExpired
  })*/

  useEffect(() => {
    setRefreshStatus(val => !val)
  }, []) //eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (generationStatus?.state === EVENTS_EXPORT_STATUS.COMPLETED) {
      setGenerateCollapsed(true)
      setDownloadCollapsed(false)
    }
  }, [generationStatus?.state])
  const triggerEventsExportGen = async () => {
    /*const priority = isCritical ? { priority: 'CRITICAL' } : {}*/
    const headers = {
      'x-nv-service-instance-id': sid
    }
    const body = {
      triggeredBy: clientId,
      activityList: filter.categories,
      ival: { start: filter.dates.start, end: filter.dates.end },
      ...(encrypted ? { encrypt: true } : {})
      /* ...priority*/
    }

    const data = await triggerExport({ body, headers })
    if (data) {
      notify(
        `Triggered ${
          encrypted ? 'encrypted ' : ''
        }export file generation for events. The export file will be available for download once the generation is complete.`
      )
      setGenerateCollapsed(true)
      setDownloadCollapsed(false)
      setRefreshStatus(val => !val)
    }
  }
  const downloadExportFile = async () => {
    const headers = {
      'x-nv-service-instance-id': sid
    }
    const endpoint = encrypted
      ? API_EVENTS_EXPORT_DOWNLOAD_ENCRYPTED
      : API_EVENTS_EXPORT_DOWNLOAD
    const data = await getDownloadUrl({ endpoint, headers })
    if (data?.length) {
      /*[
        {
          name: 'on_premises_export_04-14-2022-12-04-35.log',
          type: 'file',
          mtime: 'Thu, 14 Apr 2022 12:04:35 GMT',
          size: 669
        }
      ]*/
      const fileName = data[0]?.name
      if (fileName) {
        /*console.log('filename : ', fileName)
        console.log('downloadUrl : ', downloadUrl)*/
        window.location.href = `${endpoint}${fileName}`
      } else {
        console.log(
          'Received invalid response while fetching download-url of the file to be downloaded.'
        )
      }
    }
  }

  /*const getEventsExportAsJSON = async () => {
    const headers = {
      'x-nv-service-instance-id': sid
    }
    const parameters = encrypted ? { encrypted: true } : {}
    /!*const startTime = moment()
    let endTime = null*!/
    if (encrypted) {
      setDownloadEventsEncrypted(true)
    } else {
      setDownloadEvents(true)
    }

    const data = await getExportData({
      headers,
      parameters
    })

    if (data && data.exportedData) {
      const blob = new Blob([data.exportedData], {
        type: 'application/octet-stream'
      })
      if (encrypted) {
        saveAs(
          blob,
          `on-premises_export_${moment(Date.now()).format(
            'MM-DD-YYYY-HH-mm-ss'
          )}.log`
        )
      } else {
        saveAs(
          blob,
          `events_${moment(Date.now()).format('MM-DD-YYYY-HH-mm-ss')}.log`
        )
      }

      /!* endTime = moment()
      console.log('startTime : ', startTime)
      console.log('endTime : ', endTime)
      const duration = moment.duration(endTime.diff(startTime)).as('seconds')
      console.log('duration(seconds) : ', duration)*!/
    }
    if (encrypted) {
      setDownloadEventsEncrypted(false)
    } else {
      setDownloadEvents(false)
    }
  }*/

  const JobDetails = ({ style }) => {
    const { details } = generationStatus || {}
    const { ival, activityList = [] } = details
    /* details: {
    ival: {
      start: '2021-11-01T18:30:00+00:00',
        end: '2022-03-25T18:29:59+00:00'
    },
    activityList: [100, 200, 150],
      priority: 'ALL',
      encrypt: false
  },*/
    const formatDate = date => {
      return moment
        .utc(date, 'YYYY-MM-DD hh:mm')
        .local()
        .format('ll')
    }
    return (
      <div style={style || {}}>
        <h3 style={{ color: theme.colors.block.titleForeground }}>
          Export File Details:
        </h3>
        <Row>
          <Pill
            label={'Categories'}
            value={categoryOptions
              .filter(cat => activityList?.includes(cat.id))
              ?.map(cat => cat.label)
              ?.join(', ')}
          />
        </Row>
        <Row>
          <Pill label='From' value={formatDate(ival?.start)} />
          <Pill label='To' value={formatDate(ival?.end)} />
        </Row>
      </div>
    )
  }

  const GenerateFile = () => (
    <StyledBlock theme={theme}>
      <Block
        collapsible
        collapsed={generateCollapsed}
        onToggle={() => setGenerateCollapsed(val => !val)}
        title='Generate A New Events Export File'
      >
        {triggeringExport && <ModalSpinner />}
        <Text textStyle='label'>Date range (upto 31 days)</Text>
        <div style={{ display: 'flex' }}>
          <FloatingDateRangePicker
            tip='Select the date range of events to be exported'
            value={selectedDateRange}
            onChange={val => {
              const { dates, ...rest } = filter
              if (val.start && val.end) {
                setFilter({
                  dates: {
                    start: moment(val.start)
                      .utc()
                      .format(),
                    end: moment(val.end)
                      .utc()
                      .format()
                  },
                  ...rest
                })
              } else {
                setFilter({ ...rest })
              }
            }}
            defaultValue={defaultRange}
          />
        </div>
        <FormikError>
          {selectedDateRange?.isInvalid ? 'Must be within 31 day range' : ''}
        </FormikError>
        <div style={{ marginTop: '1rem' }}>
          <Text textStyle='label'>Categories</Text>
          <SwitchList
            options={categoryOptions}
            value={filter.categories}
            onChange={val => {
              const { categories, ...rest } = filter

              setFilter({ ...rest, categories: val })
            }}
          />
        </div>
        {/*   <div style={{ marginTop: '1rem' }}>
          <div>
            <Text textStyle='label'>Severity</Text>
          </div>
          <Switch
            id={'criticalEvents'}
            label={'Critical'}
            onChange={e => {
              const { checked } = e.target
              const { isCritical, ...rest } = filter
              setFilter({ ...rest, isCritical: !!checked })
            }}
            checked={filter.isCritical}
          />
        </div>*/}
        <div>
          <small>
            Events export file generation takes between few seconds to few
            minutes depending on the number of events being exported.
          </small>
        </div>
        <ModalButtons>
          <Button
            onClick={() => triggerEventsExportGen()}
            disabled={
              triggeringExport ||
              selectedDateRange?.isInvalid ||
              filter?.categories?.length === 0
            }
          >
            Generate Events Export File
          </Button>
        </ModalButtons>
      </Block>
    </StyledBlock>
  )

  const DownloadFile = () => (
    <StyledBlock>
      <Block
        collapsible
        collapsed={downloadCollapsed}
        onToggle={() => setDownloadCollapsed(val => !val)}
        title='Download Generated Events Export File'
      >
        <JobDetails />
        <ModalButtons>
          <ButtonGroup>
            <Button
              type={'secondary'}
              onClick={e => {
                e.stopPropagation()
                setDownloadCollapsed(true)
                setGenerateCollapsed(false)
              }}
            >
              Generate a new file
            </Button>
            <Button
              type={'primary'}
              onClick={e => {
                e.stopPropagation()
                /* getEventsExportAsJSON()*/
                downloadExportFile()
              }}
              disabled={gettingDownloadUrl}
              /* disabled={gettingExport}*/
            >
              Download Events Export File
            </Button>
          </ButtonGroup>
        </ModalButtons>
      </Block>
    </StyledBlock>
  )

  /* if (
    (encrypted && downloadEventsEncrypted) ||
    (!encrypted && downloadEvents)
  ) {
    return (
      <SpinnerView
        title={'Export file is being downloaded'}
        subtitle={
          'Events export file download takes between few seconds to few minutes depending on the number of events being exported. You can close this window, download will continue in the background.'
        }
      />
    )
  }*/

  return (
    <div>
      {generationStatus?.state === EVENTS_EXPORT_STATUS.RUNNING ? (
        <>
          <Result
            status='info'
            title='Export file is being generated.'
            subTitle='Events export file generation takes between few seconds to few
            minutes depending on the number of events being
            exported.'
          >
            <JobDetails style={{ marginTop: '-1rem' }} />
          </Result>
        </>
      ) : generationStatus?.state === EVENTS_EXPORT_STATUS.FAILED ? (
        <>
          <Result
            status='error'
            title={'Export file generation failed.'}
            subTitle={'Please try again.'}
            // generationStatus?.message
          />
          <br />
          <GenerateFile />
        </>
      ) : generationStatus?.state === EVENTS_EXPORT_STATUS.COMPLETED ? (
        <>
          <DownloadFile />
          <br />
          <GenerateFile />
        </>
      ) : (
        <GenerateFile />
      )}
    </div>
  )
}
