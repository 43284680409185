import React, { useMemo, useContext, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import Icon from '@kaizen-ui/icon'
import { formatDate, formatExpirationDate, Table, UsageIndicator } from 'common'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import {
  LS_FEATURE_STATUS_EXPIRED,
  LS_FEATURE_STATUS_EXPIRING
} from '../../api'

export const PoolDetails = ({ licensePoolFeatures }) => {
  const theme = useContext(KaizenThemeContext)

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Feature',
        accessor: 'displayLabel',
        Cell: ({ row }) => {
          const { displayLabel, emsProductKeyId, status } = row.original.feature

          const isExpiring = status === LS_FEATURE_STATUS_EXPIRING
          const isExpired = status === LS_FEATURE_STATUS_EXPIRED

          const dateStyle = isExpired
            ? {
                style: {
                  borderLeft: `5px solid ${theme.colors.danger}`,
                  padding: '0.75rem',
                  margin: '-0.75rem'
                }
              }
            : isExpiring
            ? {
                style: {
                  borderLeft: `5px solid ${theme.colors.warning}`,
                  padding: '0.75rem',
                  margin: '-0.75rem'
                }
              }
            : {
                style: {
                  borderLeft: `5px solid transparent`,
                  padding: '0.75rem',
                  margin: '-0.75rem'
                }
              }

          return (
            <div {...dateStyle}>
              <div>{displayLabel}</div>
              <div>{`(${emsProductKeyId})`}</div>
            </div>
          )
        }
      },
      {
        Header: 'In Use / Allocated',
        accessor: 'totalAllotment',
        align: 'right',
        Cell: ({ row }) => {
          const { inUse, totalAllotment } = row.original
          const { isCardinal } = row.original.feature

          return (
            <>
              {isCardinal ? (
                <div
                  style={{
                    flex: 1
                  }}
                  data-tip={`${totalAllotment} licenses have been assigned to this pool, of which ${inUse} leases have been issued`}
                >
                  <UsageIndicator
                    assignedQuantity={inUse}
                    totalQuantity={totalAllotment}
                    short
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flex: 1
                  }}
                >
                  n/a
                </div>
              )}
            </>
          )
          //return <RightAlign>{isCardinal ? totalQuantity : 'n/a'}</RightAlign>
        }
      },
      {
        Header: 'Effective',
        accessor: 'startDate',
        width: 100,
        Cell: ({ row }) => {
          const { startDate } = row.original.feature

          return <small>{formatDate(startDate)}</small>
        }
      },
      {
        Header: 'Expiration',
        accessor: 'endDate',
        width: 100,
        Cell: ({ row }) => {
          const { endDate, status } = row.original.feature

          const isExpiring = status === LS_FEATURE_STATUS_EXPIRING
          const isExpired = status === LS_FEATURE_STATUS_EXPIRED

          return isExpired || isExpiring ? (
            <div
              data-tip={
                isExpired
                  ? 'This feature has expired'
                  : isExpiring
                  ? 'This feature will expire soon'
                  : null
              }
            >
              <Icon
                name='StatusWarning'
                color={isExpired ? theme.colors.danger : theme.colors.warning}
              />
              {formatExpirationDate(endDate)}
            </div>
          ) : (
            <small>{formatExpirationDate(endDate)}</small>
          )
        }
      }
    ],
    [theme]
  )

  return (
    <Table
      columns={columns}
      data={licensePoolFeatures}
      label='pool features'
      minHeight={1}
      disablePageSizeSelection
    />
  )
}
