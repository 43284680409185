import { useEffect, useMemo, useGlobal } from 'reactn'
import { useFetch } from 'common'
import { API_NODE_ROLE, NODE_ROLE } from '../../api'
import { SessionExpired } from '../../Components'
import { GLOBAL_NODE_ROLE, GLOBAL_NODE_ROLE_REFRESH } from '../../globalState'
import { useAuth } from '../useAuth'

export function useNodeRole() {
  const [nodeRole, setNodeRole] = useGlobal(GLOBAL_NODE_ROLE)
  const [refresh] = useGlobal(GLOBAL_NODE_ROLE_REFRESH)
  const { user } = useAuth()

  const isPrimary = useMemo(
    () => nodeRole?.toLowerCase() === NODE_ROLE.PRIMARY?.toLowerCase(),
    [nodeRole]
  )

  const isSecondary = useMemo(
    () => nodeRole?.toLowerCase() === NODE_ROLE.SECONDARY?.toLowerCase(),
    [nodeRole]
  )

  const { getData, abort } = useFetch({
    endpoint: API_NODE_ROLE,
    actionLabel: 'Get node role',
    SessionExpired: SessionExpired
    /*   normalizer: () => {
      return {role: NODE_ROLE.PRIMARY }
    },
    normalizer: () => {
      return {role: NODE_ROLE.SECONDARY }
    }*/
  })

  useEffect(() => {
    const getRole = async () => {
      if (!user) {
        return
      }
      const data = await getData()

      if (data?.role) {
        setNodeRole(data.role)
      } else {
        setNodeRole(undefined)
      }
    }
    getRole()
    return () => {
      abort()
    }
  }, [user, refresh]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    nodeRole,
    isPrimary,
    isSecondary
  }
}
