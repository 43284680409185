"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DefaultError", {
  enumerable: true,
  get: function get() {
    return _DefaultError.DefaultError;
  }
});

var _DefaultError = require("./DefaultError");