import React, { useGlobal } from 'reactn'
import styled from 'styled-components'
import Button from '@kaizen-ui/button'
import Spinner from '@kaizen-ui/spinner'
import { useFetch, useAppNotifications } from 'common'
import { GLOBAL_SERVICE_INSTANCE_ID } from '../../globalState'
import { API_SERVICE_INSTANCES_PRE_REGISTER } from '../../api'
import { Banner } from '@kaizen-ui/complete'
import { useAuth } from '../../hooks'

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 0.5rem;
  }
`

export const PreRegisterServiceInstance = ({ serviceInstance, onUpdate }) => {
  const { notify } = useAppNotifications()
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)
  const { user, checkAuth } = useAuth()
  const { isMarkedPreReg } = user || {}
  const { name } = serviceInstance

  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_SERVICE_INSTANCES_PRE_REGISTER,
    actionLabel: 'Pre-register service instance',
    method: 'PATCH'
  })
  const submit = async () => {
    const headers = { 'x-nv-service-instance-id': serviceInstanceId }
    const result = await sendRequest({ headers })
    if (result) {
      const msg = `Pre-registered service instance ${name} successfully!`
      notify(msg, null, null, 'Pre-registered service instance')
      checkAuth(() => onUpdate())
    }
  }

  return (
    <div>
      {isMarkedPreReg ? (
        <p>The DLS instance is already pre-registered</p>
      ) : (
        <>
          <StyledBanner>
            <Banner
              elevation='none'
              icon={{
                name: 'StatusWarning',
                size: 24,
                variant: 'solid'
              }}
              status='warning'
            >
              <span style={{ fontSize: '14px' }}>
                Pre-registration is irreversible
              </span>
            </Banner>
          </StyledBanner>
          <p>
            If the DLS is installed in an air-gapped network which restricts any
            outbound traffic, enabling this setting would help the DLS service
            instance register with NVIDIA Licensing Portal.
          </p>
        </>
      )}

      <Buttons>
        {submitting && (
          <span style={{ marginRight: '0.5rem' }}>
            <Spinner size='tiny' />
          </span>
        )}
        <Button
          icon={{ name: 'CustomNgcServer' }}
          onClick={submit}
          disabled={submitting || isMarkedPreReg}
          data-tip={
            isMarkedPreReg ? 'The DLS instance is already pre-registered' : ''
          }
        >
          Pre-Register Service Instance
        </Button>
      </Buttons>
    </div>
  )
}

const StyledBanner = styled.div`
  align-self: center;
  margin-bottom: 1rem;
  > div {
    padding: 0.4rem 0.8rem;
  }
`
