import React, { useGlobal } from 'reactn'
import * as Yup from 'yup'
import Button from '@kaizen-ui/button'
import Textbox from '@kaizen-ui/textbox'
import Text from '@kaizen-ui/text'
import { useFormik } from 'formik'
import { AddServerFeatures } from './AddServerFeatures'
import {
  ModalButtons,
  useAppNotifications,
  useFetch,
  ModalSpinner
} from 'common'
import { API_LICENSE_POOLS } from '../../api'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'

const nameRegExp = /^[a-zA-Z]/
const allowedChars = /^[-_A-Za-z0-9]+$/
const validationSchema = Yup.object({
  name: Yup.string()
    .min(4, 'Name must have more than 4 characters')
    .matches(nameRegExp, 'Must start with an alphabet')
    .max(32, 'Name must have fewer than 32 characters')
    .matches(allowedChars, 'Cannot include special characters')
    .required('A license pool name must be provided'),
  features: Yup.array()
    // .of(
    //   Yup.object({
    //     id: Yup.string().required(),
    //     totalQuantity: Yup.number().min(1, 'Must Be Greater Than 0')
    //   })
    // )
    .min(1, 'You must add at least 1 feature')
})
const initialValues = {
  name: '',
  features: []
}

export const CreatePool = ({ server, onUpdate }) => {
  const { id } = server
  const { notify } = useAppNotifications()
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_LICENSE_POOLS(orgId, vgId, id),
    actionLabel: 'Create license pool',
    method: 'POST'
  })
  const submit = async values => {
    const { name, features } = values

    const body = {
      licensePoolCreate: {
        licensePoolFeatures: features.map(f => {
          const { isCardinal } = f
          const count = isCardinal
            ? { totalAllotment: parseInt(f.addCount) }
            : {}
          return {
            licenseServerFeatureId: f.id,
            ...count
          }
        }),
        name: name
      }
    }
    const result = await sendRequest({ body })
    if (result) {
      const msg = `License pool ${name} was successfully created!`
      notify(msg, null, null, `Created license pool`)
      onUpdate && onUpdate()
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: submit
  })

  return (
    <>
      {submitting && <ModalSpinner />}
      <div style={{ marginBottom: '0.5rem' }}>
        <Textbox
          id='name'
          name='name'
          label='Name'
          className='nvl'
          placeholder='Enter a name for this license pool'
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          validationMessage={formik.touched.name && formik.errors.name}
        />
      </div>
      <div>
        <Text textStyle='label'>Available server features</Text>
      </div>
      <AddServerFeatures server={server} formik={formik} />
      <ModalButtons>
        <Button
          disabled={
            (!formik.isValid &&
              formik.touched.name &&
              formik.touched.features) ||
            submitting
          }
          onClick={e => {
            formik.setFieldTouched('name', true)
            formik.setFieldTouched('features', true)
            formik.handleSubmit(e)
          }}
        >
          Create License Pool
        </Button>
      </ModalButtons>
    </>
  )
}
