import { ModalButtons, useAppNotifications, useFetch } from 'common'
import React, { useGlobal } from 'reactn'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { API_USAGE_METRICS_CONFIG } from '../../api'
import { SessionExpired } from '../../Components'
import { Result, Textbox, Button, ButtonGroup } from '@kaizen-ui/complete'
import { GLOBAL_SERVICE_INSTANCE_ID } from '../../globalState'

const validationSchema = Yup.object({
  // collection: Yup.number()
  //   .typeError('Must be a number')
  //   .min(1, 'Must be at least 1 minute')
  //   .required(),
  clean_up: Yup.number()
    .typeError('Must be a number')
    .min(1, 'Must be between 1 and 30 days')
    .max(30, 'Must be between 1 and 30 days')
    .required()
})
const initialValues = {
  //collection: 1,
  clean_up: 30
}

export const UsageSettings = ({ onUpdate }) => {
  const { notify } = useAppNotifications()
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)
  const {
    getData: getUsageMetrics,
    abort: abortUsageMetrics,
    loading: loadingUsageMetrics,
    error: errorUsageMetrics
  } = useFetch({
    endpoint: API_USAGE_METRICS_CONFIG,
    actionLabel: 'Get system metrics configuration',
    SessionExpired: SessionExpired
    //normalizer: formatUsageMetrics
  })

  const submit = async values => {
    const {
      //collection,
      clean_up
    } = values

    const body = {
      metricConfig: [
        //{ category: 'COLLECTION', value: parseInt(collection) },
        { category: 'CLEAN_UP', value: parseInt(clean_up) }
      ]
    }
    const headers = { 'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId }
    const result = await getUsageMetrics({ body, method: 'PUT', headers })
    if (result) {
      const msg = `System metrics updated successfully!`
      notify(msg, null, null, 'Edited system metrics configuration')
      onUpdate && onUpdate()
    }
  }
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: submit
  })

  React.useEffect(() => {
    const getUsage = async () => {
      const data = await getUsageMetrics()
      if (data?.metricConfig) {
        // formik.setFieldValue(
        //   'collection',
        //   data.metricConfig.find(s => s.category === 'COLLECTION')?.value
        // )
        formik.setFieldValue(
          'clean_up',
          data.metricConfig.find(s => s.category === 'CLEAN_UP')?.value
        )
      }
    }
    getUsage()
    return () => {
      abortUsageMetrics()
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {(errorUsageMetrics || loadingUsageMetrics) && (
        <Result status={errorUsageMetrics ? 'error' : 'loading'}></Result>
      )}
      {!errorUsageMetrics && !loadingUsageMetrics && (
        <>
          {/* <Textbox
            inputMode='numeric'
            id='collection'
            name='collection'
            label='How often should system metrics be collected (Minutes)'
            className='nvl'
            placeholder='Enter the interval in minutes to collect system metrics'
            value={formik.values.collection}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            validationMessage={
              formik.touched.collection && formik.errors.collection
            }
          /> */}
          <Textbox
            inputMode='numeric'
            id='clean_up'
            name='clean_up'
            label='How long should system metrics be maintained before removal (Days)'
            className='nvl'
            placeholder='Enter the amount of days to maintain system metrics'
            value={formik.values.clean_up}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            validationMessage={
              formik.touched.clean_up && formik.errors.clean_up
            }
          />
          <ModalButtons>
            <ButtonGroup>
              <Button
                type='secondary'
                icon={{ name: 'ActionsUndo' }}
                onClick={formik.handleReset}
                disabled={loadingUsageMetrics}
              >
                Reset to Default
              </Button>
              <Button
                onClick={formik.handleSubmit}
                disabled={loadingUsageMetrics}
              >
                Configure
              </Button>
            </ButtonGroup>
          </ModalButtons>
        </>
      )}
    </>
  )
}
