import React, { useContext } from 'react'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Banner from '@kaizen-ui/banner'
import Icon from '@kaizen-ui/icon'
import Progress from '@kaizen-ui/progress'
import {
  ONLINE_MIGRATION_STATUS,
  ONLINE_MIGRATION_STATUS_DISPLAY
} from '../../api'
import { useAuth, useOnlineMigrationNotifier } from '../../hooks'
import { AckOnlineUpgrade } from '..'

const Outer = styled.div`
  margin-top: 1rem;

  .content {
    flex: 1;
  }
`
export const OnlineMigrationNotifier = () => {
  const { migrationStatus, pendingMigration } = useOnlineMigrationNotifier()
  const { user } = useAuth()
  const theme = useContext(KaizenThemeContext)

  return (
    <>
      {pendingMigration && user && (
        <Outer>
          <Banner
            status='passive'
            icon={{ name: 'CloudTransfer', size: 36 }}
            // title={
            //   migrationStatus?.state
            //     ? MIGRATION_STATUS_DISPLAY[migrationStatus?.state]
            //     : 'Migration in progress'
            // }
            title={
              migrationStatus?.state === ONLINE_MIGRATION_STATUS.FAILED
                ? 'Upgrade Failed'
                : 'Upgrade In Progress'
            }
          >
            {migrationStatus?.state ===
              ONLINE_MIGRATION_STATUS.DATA_TRANSFER_INITIATED &&
              migrationStatus?.state === ONLINE_MIGRATION_STATUS.VALIDATED && (
                <Progress indeterminate size='thin' />
              )}
            <div
              style={{
                margin: '0 0 1rem 0',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div>
                <span
                  style={{
                    color: theme.colors.textbox.placeholder
                  }}
                >
                  Source:
                </span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon name='ComputerScreen1' />
                  <span style={{ marginLeft: '0.5rem' }}>
                    {migrationStatus?.sourceIP}
                  </span>
                </div>
                <div>version {migrationStatus?.sourceBuildNum}</div>
              </div>
              <div style={{ margin: '0 1rem', display: 'flex' }}>
                <Icon name='ArrowRight' size='larger' />
              </div>
              <div>
                <span
                  style={{
                    color: theme.colors.textbox.placeholder
                  }}
                >
                  Target:
                </span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon name='ComputerScreen1' />
                  <span style={{ marginLeft: '0.5rem' }}>
                    {migrationStatus?.targetIP}
                  </span>
                </div>
                <div>version {migrationStatus?.targetBuildNum}</div>
              </div>
            </div>
            <div>
              {ONLINE_MIGRATION_STATUS_DISPLAY[migrationStatus?.state] || ''}
            </div>
            <Progress
              progress={4}
              total={4}
              color={
                migrationStatus?.state === ONLINE_MIGRATION_STATUS.FAILED
                  ? theme.colors.textbox.validationMessage
                  : theme.colors.brand
              }
            />

            {migrationStatus?.state ===
              ONLINE_MIGRATION_STATUS.DATA_TRANSFER_COMPLETED &&
              migrationStatus?.isTargetNode && (
                <div style={{ marginTop: '0.5rem' }}>
                  <AckOnlineUpgrade />
                </div>
              )}
          </Banner>
        </Outer>
      )}
    </>
  )
}
