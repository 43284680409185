import React from 'react'
import { Button, Result, Switch } from '@kaizen-ui/complete'
import {
  ModalButtons,
  useAppNotifications,
  useFetch,
  ModalSelect
} from 'common'
import { API_SERVICE_INSTANCES } from '../../api'
import ReactTooltip from 'react-tooltip'

const selectOptions = [
  { label: 'Hostname', value: 'HOSTNAME' },
  { label: 'Mac Address', value: 'MAC' },
  { label: 'Hostname/Mac Address', value: 'HOSTNAME,MAC' }
]

export const ToggleInstantClone = ({ serviceInstance, onUpdate }) => {
  const { notify } = useAppNotifications()
  const [enabled, setEnabled] = React.useState(
    serviceInstance?.instantCloneSupport
  )
  const [collision, setCollision] = React.useState(
    selectOptions.find(
      option =>
        option.value === serviceInstance?.collisionFilter?.join(',') ||
        option.value === 'MAC'
    )
  )
  const { getData: sendRequest, loading: submitting, error } = useFetch({
    endpoint: API_SERVICE_INSTANCES,
    actionLabel: 'Edit service instance',
    method: 'PATCH'
  })
  const submit = async () => {
    const { name, description, xid } = serviceInstance

    const dynamic = enabled
      ? { collisionFilter: collision?.value.split(',') }
      : { collisionFilter: [] }
    const body = {
      name,
      description,
      instantCloneSupport: enabled,
      ...dynamic
    }
    const headers = { 'X-NV-SERVICE-INSTANCE-ID': xid }
    const result = await sendRequest({ body, headers })
    if (result) {
      const msg = `Service Instance ${name} updated successfully!`
      notify(
        msg,
        null,
        null,
        `${enabled ? 'Disabled' : 'Enabled'} non-persistent desktop pools`
      )
      onUpdate && onUpdate()
    }
  }

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const resultProps = React.useMemo(() => {
    if (error) return { status: 'error', subTitle: error }
    else if (submitting) {
      return {
        status: 'loading',
        subTitle: `${
          enabled ? 'Enabling' : 'Disabling'
        } non-persistent desktop pools`
      }
    } else {
      return {
        status: 'info',
        title: `${
          enabled ? 'Enable' : 'Disable'
        } non-persistent desktop pools?`,
        children: (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <small>
                Enable this option if you plan to serve licenses to
                non-persistent desktop pools from this service instance.
                Enabling this option prevents this service instance from serving
                multiple licenses to clients that have the same MAC address or
                hostname. When this option is enabled, the service instance
                checks the MAC address or hostname of the client in each request
                to check out a license. If the request specifies the MAC address
                or hostname of a client that has already checked out a license,
                the existing checkout is cleared before the request is
                fulfilled.
              </small>
            </div>
            <Switch
              label='Enable non-persistent desktop pools'
              checked={enabled}
              onChange={() => setEnabled(val => !val)}
            />
            {enabled && (
              <ModalSelect
                clearable={false}
                options={selectOptions}
                value={collision}
                onChange={option => setCollision(option)}
              />
            )}
          </>
        )
      }
    }
  }, [submitting, error, enabled, collision])

  return (
    <>
      <Result {...resultProps} />
      <ModalButtons>
        <Button onClick={submit} disabled={submitting}>
          {enabled ? 'Enable' : 'Disable'} non-persistent desktop pools
        </Button>
      </ModalButtons>
    </>
  )
}
