import React, { useMemo, useState, useGlobal, useEffect } from 'reactn'
import moment from 'moment'
import { saveAs } from 'file-saver'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Button from '@kaizen-ui/button'
import {
  Block,
  Spinner,
  Text,
  DatePicker,
  Switch,
  Banner
} from '@kaizen-ui/complete'
import {
  Table,
  FormikError,
  useFetch,
  ModalSpinner,
  ModalButtons
} from 'common'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'
import {
  API_SERVICE_INSTANCE_TOKEN_CONFIG,
  API_SERVICE_INSTANCE_TOKEN_DOWNLOAD,
  formatServiceInstanceTokenConfig
} from '../../api'
import { SessionExpired } from '..'
import { NodeSection } from './NodeSection'
import { LeasingPortSelector } from './LeasingPortSelector'
import { useContext } from 'react'
import { KaizenThemeContext } from '@kaizen-ui/styles'

const StyledBlock = styled.div`
  padding: 7px;
  > div {
    padding: 14px;
    overflow: visible;
  }
`
const StyledSwitch = styled(Switch)`
  margin-left: 0;
`
const StyledBanner = styled(Banner)`
  .content {
    padding-top: 0;
  }
`

const validationSchema = Yup.object({
  scopes: Yup.array().min(1, 'You must select at least 1 scope reference'),
  classRefs: Yup.array()
})
const initialValues = {
  scopes: [],
  classRefs: []
}

export const GenerateClientToken = ({ serviceInstance, server, onUpdate }) => {
  const [expiry, setExpiry] = React.useState('')
  const [noExpiry, setNoExpiry] = React.useState(false)
  const theme = useContext(KaizenThemeContext)
  const { xid, high_availability_config } = serviceInstance || {}
  const [selectionList, setSelectionList] = useState([])
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const [pageLoading, setPageLoading] = useState(true)
  const [selected, setSelected] = useState([])
  const [selectedFcs, setSelectedFcs] = useState([])
  const [scopes, setScopes] = useState([])
  const [classRefs, setClassRefs] = useState([])
  const [, setFilter] = useState({})
  const [, setFcFilter] = useState({})

  const [nodeList, setNodeList] = useState([])
  const [leasingPortList, setLeasingPortList] = useState([])
  const [selectedLeasingPort, setSelectedLeasingPort] = useState('')
  useEffect(() => {
    if (high_availability_config?.config?.nodeList) {
      setNodeList(
        high_availability_config?.config?.nodeList?.sort((a, b) => {
          if (a.role < b.role) {
            return -1
          }
          if (a.role > b.role) {
            return 1
          }
          return 0
        })
      )
    }
    if (high_availability_config?.config?.leasingPorts) {
      setLeasingPortList(
        high_availability_config?.config?.leasingPorts
          ?.sort((a, b) => {
            if (a.port < b.port) {
              return -1
            }
            if (a.port > b.port) {
              return 1
            }
            return 0
          })
          ?.map(({ isDefault, port }) => ({
            isDefault: isDefault,
            port: `${port}`
          }))
      )

      setSelectedLeasingPort(
        `${
          high_availability_config?.config?.leasingPorts?.find(o => o.isDefault)
            ?.port
        }`
      )
    }
  }, [high_availability_config])

  const { getData, loading, error, abort, lastUpdate } = useFetch({
    endpoint: API_SERVICE_INSTANCE_TOKEN_CONFIG(orgId, vgId),
    actionLabel: 'Get service instance token references',
    SessionExpired: SessionExpired,
    normalizer: data => formatServiceInstanceTokenConfig(data, server?.id)
  })
  const {
    getData: downloadToken,
    loading: submitting,
    abort: abortDownload
  } = useFetch({
    endpoint: API_SERVICE_INSTANCE_TOKEN_DOWNLOAD,
    actionLabel: `Download client token for instance ${xid}`,
    method: 'POST',
    SessionExpired: SessionExpired
  })

  const submit = async values => {
    //extra, if there is no date value (nothing selected in datepicker) and switch is not enabled, then do not send expiry value in request
    const extra =
      !expiry && !noExpiry ? {} : { expiry: noExpiry ? '3000-01-01' : expiry }
    const body = {
      scopeReferenceList: values.scopes.map(option => option.value),
      fulfillmentClassReferenceList: values.classRefs.map(
        option => option.value
      ),
      addressTypeSelections: selectionList,
      leasingPort: Number(selectedLeasingPort),
      ...extra
    }

    const data = await downloadToken({ body })
    if (data?.messengerToken) {
      const blob = new Blob([data?.messengerToken], {
        type: 'text/plain'
      })
      saveAs(
        blob,
        `client_configuration_token_${moment(Date.now()).format(
          'MM-DD-YYYY-HH-mm-ss'
        )}.tok`
      )
      onUpdate && onUpdate()
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: submit
  })

  const minExpiryDate = React.useMemo(
    () =>
      moment()
        .add(1, 'days')
        .toDate(),
    []
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Server Name',
        accessor: 'displayName'
      },
      {
        Header: 'Reference',
        accessor: 'value'
      }
    ],
    []
  )
  const classColumns = useMemo(
    () => [
      {
        Header: 'Condition Name',
        accessor: 'displayName'
      },
      {
        Header: 'Server Name',
        accessor: 'serverName'
      },
      {
        Header: 'Reference',
        accessor: 'value'
      }
    ],
    []
  )

  const driverIssue = React.useMemo(() => {
    return (
      noExpiry || moment(expiry).isAfter(moment(new Date()).add(12, 'years'))
    )
  }, [noExpiry, expiry])

  useEffect(() => {
    const getRefs = async () => {
      const data = await getData({
        headers: {
          'X-NV-SERVICE-INSTANCE-ID': xid
        }
      })

      if (data) {
        const { scopes, classRefs } = data
        setScopes(scopes)
        if (scopes?.length && scopes?.[0]) {
          setSelected([scopes[0]])
          formik.setFieldValue('scopes', [scopes[0]])
        }
        setClassRefs(classRefs)
        setPageLoading(false)
      }
    }
    getRefs()

    return () => {
      abort()
      abortDownload()
    }
  }, [vgId]) //eslint-disable-line react-hooks/exhaustive-deps

  const onSelectionChange = selection => {
    setSelectionList(existingList => [
      ...existingList.filter(sel => sel.node_id !== selection.node_id),
      selection
    ])
  }
  return (
    <>
      {submitting && <ModalSpinner />}

      <Text textStyle={'p1'}>
        The client configuration token will include the selected addresses,
        which the clients will use to establish a connection with the license
        server.
      </Text>

      <StyledBlock theme={theme}>
        <Block
          title={`Address Type`}
          titleIcon={{
            name: 'ConnectionServerNetwork2',
            size: 24
          }}
          onClick={e => {
            e.stopPropagation()
          }}
          collapsible
        >
          {nodeList.map((node, ind) => {
            return (
              <div key={ind} onClick={e => e.stopPropagation()}>
                <NodeSection
                  ind={ind}
                  showInd={(nodeList?.length || 0) > 1}
                  node={node}
                  onSelectionChange={onSelectionChange}
                  selection={selectionList.find(
                    selection => selection?.node_id === node.node_id
                  )}
                />
              </div>
            )
          })}
        </Block>
      </StyledBlock>

      <LeasingPortSelector
        leasingPortList={leasingPortList}
        setSelectedLeasingPort={setSelectedLeasingPort}
        selectedLeasingPort={selectedLeasingPort}
        collapsible
      />

      <StyledBlock theme={theme}>
        <Block
          title={`Expiration`}
          titleIcon={{
            name: 'TimeClock',
            size: 24
          }}
          onClick={e => {
            e.stopPropagation()
          }}
          collapsible
          collapsed
        >
          <div>
            <StyledSwitch
              label='Token never expires'
              checked={noExpiry}
              onChange={() => setNoExpiry(val => !val)}
            />
            {!noExpiry && (
              <DatePicker
                label='Select the date for this token to expire'
                onChange={value =>
                  setExpiry(moment(value).format('YYYY-MM-DD'))
                }
                placeholderText='Leave blank for default'
                minDate={minExpiryDate}
              />
            )}
            {driverIssue && (
              <StyledBanner
                icon={{ name: 'StatusWarning', size: 'larger' }}
                status='warning'
                title='Please be advised'
              >
                <>
                  <div style={{ marginTop: '1rem' }}>
                    A CCT token expiration date greater than 12 years will not
                    be recognized by the following vGPU releases:
                  </div>
                  <div>
                    <b>13.0 to 13.7, 15.0 to 15.2</b>
                  </div>
                  <div>This corresponds to the following driver versions</div>
                  <div>
                    <b>
                      Windows 474.30 and below, Windows 528.89 and below, Linux
                      470.182.03 and below, Linux 525.105.17 and below
                    </b>
                  </div>
                </>
              </StyledBanner>
            )}
          </div>
        </Block>
      </StyledBlock>

      <StyledBlock theme={theme}>
        <Block
          title={
            pageLoading
              ? 'Loading scope references'
              : `Scope reference (${selected.length}) selected`
          }
          onClick={e => {
            e.stopPropagation()
          }}
          titleIcon={{
            name: 'ActionsFilter',
            size: 24
          }}
          collapsible
          collapsed
        >
          {pageLoading ? (
            <Spinner />
          ) : (
            <Table
              columns={columns}
              data={scopes}
              disabled={submitting}
              loading={pageLoading}
              fetching={loading}
              error={error}
              label={'scope references'}
              dataId='value'
              //refresh={() => setRefresh(val => !val)}
              lastUpdate={lastUpdate}
              onFilter={setFilter}
              //globalSearch={search}
              selectedRows={selected}
              onSelect={values => {
                setSelected(values)
                formik.setFieldTouched('scopes', true)
                formik.setFieldValue('scopes', values)
              }}
              disablePageSizeSelection
              disableColumnHiding
              disableExporting
              initialPageSize={5}
              minHeight={1}
            />
          )}
          {formik.touched.scopes && formik.errors.scopes && (
            <FormikError>{formik.errors.scopes}</FormikError>
          )}
        </Block>
      </StyledBlock>

      <StyledBlock theme={theme}>
        <Block
          title='Fulfillment reference (Optional)'
          onClick={e => {
            e.stopPropagation()
          }}
          collapsible
          collapsed
        >
          <Table
            columns={classColumns}
            data={classRefs}
            disabled={submitting}
            loading={pageLoading}
            fetching={loading}
            error={error}
            label={'class references'}
            dataId='value'
            //refresh={() => setRefresh(val => !val)}
            lastUpdate={lastUpdate}
            onFilter={setFcFilter}
            //globalSearch={search}
            selectedRows={selectedFcs}
            onSelect={values => {
              setSelectedFcs(values)
              formik.setFieldTouched('classRefs', true)
              formik.setFieldValue('classRefs', values)
            }}
            disablePageSizeSelection
            disableColumnHiding
            disableExporting
            initialPageSize={5}
            minHeight={1}
          />
        </Block>
      </StyledBlock>

      {formik.touched.scopes && formik.errors.scopes && (
        <FormikError>{formik.errors.scopes}</FormikError>
      )}
      <ModalButtons>
        <Button
          disabled={submitting || pageLoading}
          onClick={formik.handleSubmit}
          icon={{ name: 'ActionsDownload' }}
        >
          Download Client Configuration Token
        </Button>
      </ModalButtons>
    </>
  )
}
