import React, { useState, useMemo } from 'react'
import { Table } from 'common'
import styled from 'styled-components'
import { KaizenThemes } from '@kaizen-ui/styles'
import Textbox from '@kaizen-ui/textbox'

const StyledTextbox = styled.div`
  input[inputmode='numeric'] {
    width: 6rem;
    margin: 0;
  }

  > div {
    margin: 0;
  }
`
const FormikError = styled.div`
  color: ${KaizenThemes.enterpriseDark.colors.red500};
  position: absolute;
  margin-top: -0.5rem;
`

const LicenseCount = ({ row, onChange, value: valueProp }) => {
  const [value, setValue] = useState(valueProp)
  const { isSelected, original } = row
  const { isCardinal, unassignedQuantity } = original

  return isCardinal ? (
    <StyledTextbox>
      <Textbox
        value={value}
        onChange={e => setValue(e.target.value)}
        disabled={!isSelected}
        inputMode='numeric'
        onBlur={() => {
          if (value > unassignedQuantity) {
            setValue(unassignedQuantity)
            onChange(unassignedQuantity)
          } else if (value < 1 || isNaN(value)) {
            setValue(1)
            onChange(1)
          } else {
            onChange(value)
          }
        }}
      />
    </StyledTextbox>
  ) : (
    <></>
  )
}

export const AddServerFeatures = ({
  server,
  formik,
  pool = { licensePoolFeatures: [] }
}) => {
  const [selected, setSelected] = useState(formik.values.features)
  const features = useMemo(() => {
    return server.licenseServerFeatures.filter(
      feature =>
        (!feature.isCardinal || feature.unassignedQuantity > 0) &&
        !pool.licensePoolFeatures
          .map(pf => pf.licenseServerFeatureId)
          .includes(feature.id)
    )
  }, [server, pool])
  const [, setFilter] = useState({})

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'displayLabel',
        hidden: false
      },
      {
        Header: 'Product Key Id',
        accessor: 'emsProductKeyId'
      },
      {
        Header: 'Available',
        accessor: 'unassignedQuantity',
        canResize: false,
        align: 'right',
        Cell: ({ row }) => {
          const { isCardinal, unassignedQuantity } = row.original

          return isCardinal ? unassignedQuantity : 'n/a'
        }
      },
      {
        Header: 'Added',
        accessor: 'addCount',
        Cell: ({ row }) => {
          const match = formik.values.features.find(
            e => e.id === row.original.id
          )
          const value = (match && match.addCount) || 1
          return (
            <LicenseCount
              row={row}
              value={value}
              onChange={val => {
                const rest = formik.values.features.filter(
                  e => e.id !== row.original.id
                )
                const match = formik.values.features.find(
                  e => e.id === row.original.id
                )
                formik.setFieldValue('features', [
                  ...rest,
                  { ...match, addCount: parseInt(val) }
                ])
              }}
            />
          )
        },
        canResize: false,
        disableFilters: true,
        align: 'center',
        padding: '0'
      }
    ],
    [formik]
  )

  return (
    <>
      <Table
        columns={columns}
        data={features}
        label={'server features'}
        onFilter={setFilter}
        selectedRows={selected}
        onSelect={values => {
          setSelected(values)
          formik.setFieldTouched('features', true)
          const existing = [...formik.values.features]
          const results = []
          values.forEach(selected => {
            const match = existing.find(e => e.id === selected.id)
            const addCount = match ? match.addCount : 1
            results.push({
              ...selected,
              addCount
            })
          })
          formik.setFieldValue('features', results)
        }}
        disableColumnHiding
        disableExporting
        initialPageSize={5}
        minHeight={1}
        disablePageSizeSelection
      />
      {formik.touched.features && formik.errors.features && (
        <FormikError>{formik.errors.features}</FormikError>
      )}
    </>
  )
}
