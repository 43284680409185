import { useEffect, useGlobal } from 'reactn'
import { useFetch } from 'common'
import { API_UPGRADE_STATUS, UPGRADE_STATUS } from '../../api'
import { SessionExpired } from '../../Components'
import {
  GLOBAL_UPGRADE_MODE,
  GLOBAL_UPGRADE_STATUS_REFRESH,
  GLOBAL_UPGRADE_STATUS,
  GLOBAL_IS_UPGRADE_IN_PROGRESS
} from '../../globalState'
import { useAuth } from '../useAuth'

/*const sampleStatus = {
  mode: UPGRADE_MODE.INPLACE /!* - enum of OFFLINE and INPLACE*!/,
  message: 'message',
  oldVersion: 'oldVersion',
  isAckRequired:
    'isAckRequired' /!*- applicable for Offline Upgrade, to show ack button only when this is true*!/,
  state:
    UPGRADE_STATUS.INITIATED /!*- enum INITIATED(in-place upgrade triggered), IN_PROGRESS(one of the node is updated), FAILED, COMPLETED*!/,
  cluster: [
    {
      status: 'status',
      networkLocation: 'networkLocation',
      ipV6Adress: 'ipV6Adress',
      fqdn: 'fqdn',
      version: 'version'
    }
  ] /!*-- state of cluster before offline upgrade was triggered, current cluster nodes when in-place upgrade in progress*!/
}*/

export function useUpgradeNotifier() {
  //const [migrationStatus, setUpgradeStatus] = useState(undefined)
  const [upgradeStatus, setUpgradeStatus] = useGlobal(GLOBAL_UPGRADE_STATUS)
  const [upgradeMode, setUpgradeMode] = useGlobal(GLOBAL_UPGRADE_MODE)
  const [refresh] = useGlobal(GLOBAL_UPGRADE_STATUS_REFRESH)
  const [isUpgradeInProgress, setIsUpgradeInProgress] = useGlobal(
    GLOBAL_IS_UPGRADE_IN_PROGRESS
  )
  const { user } = useAuth()

  /*  const upgradeInProgress = useMemo(() => {
    return (
      upgradeStatus?.state === UPGRADE_STATUS.INITIATED ||
      upgradeStatus?.state === UPGRADE_STATUS.IN_PROGRESS
    )
  }, [upgradeStatus])*/

  /*  const pendingUpgrade = useMemo(
    () =>
      [
        UPGRADE_STATUS.INITIATED,
        UPGRADE_STATUS.IN_PROGRESS,
        UPGRADE_STATUS.FAILED,
        UPGRADE_STATUS.COMPLETED
      ].includes(upgradeStatus?.state),
    [upgradeStatus]
  )*/

  const { getData, loading: gettingUpgradeStatus, abort } = useFetch({
    endpoint: API_UPGRADE_STATUS,
    actionLabel: 'Check upgrade status',
    SessionExpired: SessionExpired
    // normalizer: () => sampleStatus
    // normalizer: () => {
    //   return { ...mock, state: MIGRATION_STATUS.INITIATED }
    // }
    // normalizer: () => {
    //   return { ...mock, state: MIGRATION_STATUS.IN_PROGRESS }
    // }
    // normalizer: () => {
    //   return { ...mock, state: MIGRATION_STATUS.FAILED }
    // }
    // normalizer: () => {
    //   return { ...mock, state: MIGRATION_STATUS.COMPLETED }
    // }
  })

  useEffect(() => {
    const interval = setInterval(() => {
      getStatus()
    }, 15000)

    const getStatus = async () => {
      if (!user) {
        clearInterval(interval)
        return
      }
      const data = await getData()

      if (!data || !data.state) {
        setUpgradeStatus(undefined)
        setUpgradeMode('')
        setIsUpgradeInProgress(false)
        clearInterval(interval)
        return
      }

      const { state, mode } = data
      /*  console.log('state : ', state)*/
      setUpgradeMode(mode || '')
      setUpgradeStatus(data)
      setIsUpgradeInProgress(
        state === UPGRADE_STATUS.INITIATED ||
          state === UPGRADE_STATUS.IN_PROGRESS
      )
      if (
        state === UPGRADE_STATUS.FAILED ||
        state === UPGRADE_STATUS.COMPLETED
      ) {
        clearInterval(interval)
      }
    }
    getStatus()

    return () => {
      clearInterval(interval)
      abort()
    }
  }, [user, refresh]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    upgradeMode,
    upgradeStatus,
    isUpgradeInProgress,
    gettingUpgradeStatus
  }
}
