import { useState, useEffect, useGlobal } from 'reactn'
import { useFetch } from 'common'
import {
  API_EVENTS_EXPORT_JOB,
  EVENTS_EXPORT_STATUS,
  formatEventsExportGenStatus
} from '../../api'
import { SessionExpired } from '../../Components'
import { useAuth } from '..'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'

/*
const mock = {
  jobId: 'e911a38a-d6aa-4dba-a055-fc1d26b342ad',
  triggeredBy: 'stg-t96kos64rnf84uduvi0ldiao5a',
  message: 'Completed processing of export report object',
  details: {
    ival: {
      start: '2021-11-01T18:30:00+00:00',
      end: '2022-03-25T18:29:59+00:00'
    },
    activityList: [100, 200, 150],
    priority: 'ALL',
    encrypt: false
  },
  state: 'COMPLETED' //["TRIGGERED", "RUNNING", "COMPLETED", "FAILED"]
}
*/

export function useExportFileGenStatus(sid, encrypted = false) {
  const [generationStatus, setGenerationStatus] = useState(undefined)
  const [refresh, setRefreshStatus] = useState(false)
  const { user } = useAuth()
  const clientId = user?.clientId || ''
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id

  /* const generationInProgress = useMemo(
    () => generationStatus?.state === EVENTS_EXPORT_STATUS.RUNNING,
    [generationStatus]
  )*/
  const { getData, abort } = useFetch({
    endpoint: API_EVENTS_EXPORT_JOB(orgId, vgId),
    actionLabel: 'Check export file generation status',
    SessionExpired: SessionExpired,
    supressToast: true,
    normalizer: formatEventsExportGenStatus //returns one of ["RUNNING", "COMPLETED", "FAILED"]
    //normalizer: () => mock
    // normalizer: () => {
    //   return { ...mock, state: EVENTS_EXPORT_STATUS.RUNNING }
    // }
    // normalizer: () => {
    //   return { ...mock, state: EVENTS_EXPORT_STATUS.COMPLETED }
    // }
    // normalizer: () => {
    //   return { ...mock, state: EVENTS_EXPORT_STATUS.FAILED }
    // }
  })

  useEffect(() => {
    const interval = setInterval(() => {
      getStatus()
    }, 5000)

    const getStatus = async () => {
      if (!user || !sid) {
        clearInterval(interval)
        return
      }

      const headers = {
        'x-nv-service-instance-id': sid
      }
      const parameters = encrypted
        ? { triggered_by: clientId, encrypted: true }
        : { triggered_by: clientId }

      const data = await getData({ headers, parameters })
      if (!data || !data.state) {
        setGenerationStatus(undefined)
        clearInterval(interval)
        return
      }
      const { state } = data
      setGenerationStatus(data)
      if (
        state === EVENTS_EXPORT_STATUS.FAILED ||
        state === EVENTS_EXPORT_STATUS.COMPLETED ||
        state === EVENTS_EXPORT_STATUS.ARCHIVED
      ) {
        clearInterval(interval)
      }
    }
    getStatus()

    return () => {
      clearInterval(interval)
      abort()
    }
  }, [user, refresh, sid]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    generationStatus,
    setRefreshStatus
  }
}
