import React, { useMemo, useEffect, useContext, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Select from '@kaizen-ui/select'
import Icon from '@kaizen-ui/icon'
import Text from '@kaizen-ui/text'
import Textbox from '@kaizen-ui/textbox'
import Switch from '@kaizen-ui/switch'
import Button from '@kaizen-ui/button'
import ReactTooltip from 'react-tooltip'
import {
  //formatDate,
  //formatExpirationDate,
  UsageIndicator,
  Table,
  ModalButtons,
  FormikError,
  SelectColumnFilter,
  useFetch,
  useAppNotifications,
  ModalSpinner
} from 'common'
import { API_LICENSE_POOL } from '../../api'
import { useGlobal } from 'reactn'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'

const StyledSwitch = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;

  > div {
    margin: 0;
  }
`

const validationSchema = Yup.object({
  features: Yup.array().when(['merge'], {
    is: merge => merge,
    then: Yup.array(),
    otherwise: Yup.array().min(1, 'You must select at least 1 feature to split')
  }),
  destPool: Yup.string().required('You must select a destination license pool')
})
const initialValues = {
  merge: false,
  features: [],
  destPool: ''
}

export const SplitPoolFeatures = ({ pool, server, onUpdate }) => {
  const { notify } = useAppNotifications()
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const { id: serverId } = server
  //const [merge, setMerge] = useState(false)
  const { id, name, licensePoolFeatures } = pool
  //const [selectedPool, setSelectedPool] = useState(null)
  //const [selectedFeatures, setSelectedFeatures] = useState([])
  const poolOptions = useMemo(() => {
    const { licensePools } = server
    const { id } = pool
    return licensePools
      .filter(lp => lp.id !== id)
      .map(lp => {
        return { label: lp.name, value: lp.id }
      })
  }, [server, pool])
  const theme = useContext(KaizenThemeContext)

  const { getData: mergePool, loading: mergingPool } = useFetch({
    endpoint: API_LICENSE_POOL(orgId, vgId, serverId, id),
    actionLabel: 'The license pool was successfully merged!',
    method: 'DELETE'
  })

  const { getData: moveFeatures, loading: movingFeatures } = useFetch({
    endpoint: API_LICENSE_POOL(orgId, vgId, serverId, id),
    actionLabel: `The features in the license pool ${name} were successfully moved!`,
    method: 'PATCH'
  })

  const submit = async values => {
    const { destPool, merge, features } = values
    if (merge) {
      const parameters = { merge_with: destPool }
      const result = await mergePool({ parameters })
      if (result) {
        const msg = `License pool ${name} was successfully merged to ${destPool}!`
        notify(
          msg,
          null,
          null,
          `The license pool ${name} was merged to ${destPool}!`
        )
        onUpdate && onUpdate()
      }
    } else {
      const body = {
        splitFeatureRequest: {
          destinationPoolId: destPool,
          splitFeatures: features.map(f => {
            return {
              licensePoolFeatureId: f.id,
              splitQuantity: parseInt(f.addCount) || 1
            }
          })
        }
      }
      const result = await moveFeatures({ body })
      if (result) {
        const msg = `Feature(s) from license pool ${name} were successfully moved to ${destPool}!`
        notify(msg, null, null, `Moved features from license pool ${name}`)
        onUpdate && onUpdate()
      }
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: submit
  })

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Feature',
        accessor: 'feature.displayLabel'
      },
      {
        Header: 'Status',
        accessor: 'feature.status',
        Filter: SelectColumnFilter,
        width: 50
      },
      {
        Header: 'In Use / Allocated',
        accessor: 'totalAllotment',
        disableFilters: true,
        align: 'right',
        Cell: ({ row }) => {
          const { inUse, totalAllotment } = row.original
          const { isCardinal } = row.original.feature

          return (
            <>
              {isCardinal ? (
                <div
                  data-tip={`${totalAllotment} licenses have been assigned to this pool, of which ${inUse} leases have been issued`}
                >
                  <UsageIndicator
                    assignedQuantity={inUse}
                    totalQuantity={totalAllotment}
                    short
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flex: 1
                  }}
                >
                  n/a
                </div>
              )}
            </>
          )
          //return <RightAlign>{isCardinal ? totalQuantity : 'n/a'}</RightAlign>
        }
      },
      {
        Header: 'Move Licenses',
        accessor: 'addCount',
        disableFilters: true,
        width: 80,
        minWidth: 100,
        Cell: ({ row }) => {
          const { isSelected } = row
          const { inUse, totalAllotment, id, feature } = row.original
          const { isCardinal } = feature
          const min = 1
          const max = totalAllotment - inUse
          const match = formik.values.features.find(e => e.id === id)
          const value = (match && match.addCount) || 1

          return isCardinal ? (
            <div style={{ display: 'flex', flex: 1 }}>
              <div
                data-tip={`Minimum amount that can be split is ${min}`}
                style={{
                  padding: '0 0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  flexDirection: 'column',
                  color:
                    value <= min || !isSelected
                      ? theme.colors.table.header.actionDisabled
                      : theme.colors.table.body.foreground
                }}
              >
                <div>
                  <Icon
                    name='ActionsCircleSubtract'
                    color={
                      value <= min || !isSelected
                        ? theme.colors.table.header.actionDisabled
                        : theme.colors.table.body.foreground
                    }
                  />
                </div>
                <div>{min}</div>
              </div>
              <LicenseCount
                row={row}
                value={value}
                onChange={val => {
                  const rest = formik.values.features.filter(e => e.id !== id)
                  formik.setFieldValue('features', [
                    ...rest,
                    { ...row.original, addCount: parseInt(val) }
                  ])
                }}
              />
              <div
                data-tip={`Maximum amount that can be split is ${max}`}
                style={{
                  padding: '0 0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  flexDirection: 'column',
                  color:
                    value >= max || !isSelected
                      ? theme.colors.table.header.actionDisabled
                      : theme.colors.table.body.foreground
                }}
              >
                <div>
                  <Icon
                    name='ActionsCircleAdd'
                    color={
                      value >= max || !isSelected
                        ? theme.colors.table.header.actionDisabled
                        : theme.colors.table.body.foreground
                    }
                  />
                </div>
                <div>{max}</div>
              </div>
            </div>
          ) : (
            <>n/a</>
          )
        }
      }
    ],
    [theme, formik]
  )

  return (
    <>
      {(movingFeatures || mergingPool) && <ModalSpinner />}
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
        <Text textStyle='label'>Destination pool</Text>
        <div
          style={{
            marginBottom: '1rem',
            display: 'flex'
          }}
        >
          <div>
            <Select
              className='nvl'
              name='destPool'
              options={poolOptions}
              value={poolOptions.find(
                po => po.value === formik.values.destPool
              )}
              onChange={option => {
                formik.setFieldTouched('destPool', true)
                formik.setFieldValue('destPool', option?.value || '')
                //setSelectedPool(option)
              }}
            />
            <FormikError>
              {formik.touched.destPool && formik.errors.destPool}
            </FormikError>
          </div>

          <StyledSwitch>
            <Switch
              id={'merge-all-switch'}
              label='Merge all features?'
              checked={formik.values.merge}
              onChange={() => {
                formik.setFieldValue('merge', !formik.values.merge)
              }}
            />
          </StyledSwitch>
        </div>
        {formik.values.merge && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1rem',
              marginTop: '0.5rem'
            }}
          >
            <Icon name='StatusWarning' />
            <span style={{ marginLeft: '0.25rem' }}>
              <Text textStyle='p2'>
                This option will merge all features from this pool into the
                selected pool. This license pool will be deleted after the
                merge.
              </Text>
            </span>
          </div>
        )}
      </div>
      <Table
        columns={columns}
        //data={licensePoolFeatures.filter(pf => pf.feature.isCardinal)}
        data={licensePoolFeatures.filter(pf => {
          const { inUse, totalAllotment } = pf
          const hasAvailableCount = totalAllotment - inUse >= 1
          return pf.feature.isCardinal && hasAvailableCount
        })}
        label='pool features'
        minHeight={1}
        disabled={formik.values.merge}
        disablePageSizeSelection
        disableExporting
        disableColumnHiding
        selectedRows={formik.values.features}
        onSelect={selected => {
          formik.setFieldTouched('features', true)
          formik.setFieldValue('features', selected)
          //setSelectedFeatures(selected)
        }}
      />
      <FormikError>
        {formik.touched.features && formik.errors.features}
      </FormikError>
      <ModalButtons>
        <Button
          disabled={
            (!formik.isValid &&
              formik.touched.destPool &&
              formik.touched.features) ||
            movingFeatures ||
            mergingPool
          }
          onClick={e => {
            formik.setFieldTouched('destPool', true)
            formik.setFieldTouched('features', true)
            formik.handleSubmit(e)
          }}
        >
          {formik.values.merge ? 'Merge Pool' : 'Split Pool Features'}
        </Button>
      </ModalButtons>
    </>
  )
}

const StyledTextbox = styled.div`
  input[inputmode='numeric'] {
    width: 6rem;
    margin: 0;
  }

  > div {
    margin: 0;
  }
`
const LicenseCount = ({ row, onChange, value: valueProp }) => {
  const [value, setValue] = useState(valueProp)
  const {
    isSelected,
    original: {
      inUse,
      totalAllotment,
      feature: { isCardinal }
    }
  } = row
  const min = 1
  const max = totalAllotment - inUse

  return isCardinal ? (
    <StyledTextbox>
      <Textbox
        value={value}
        disabled={!isSelected}
        onChange={e => setValue(e.target.value)}
        inputMode='numeric'
        onBlur={() => {
          if (value > max) {
            setValue(max)
            onChange(max)
          } else if (value < min || isNaN(value)) {
            setValue(min)
            onChange(min)
          } else {
            onChange(value)
          }
        }}
      />
    </StyledTextbox>
  ) : (
    <></>
  )
}
