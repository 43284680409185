import React, {
  useContext,
  useGlobal,
  useState,
  useEffect,
  useMemo
} from 'reactn'
import { useHistory } from 'react-router-dom'
import {
  PageHeader,
  FileUpload,
  useFetch,
  useAppNotifications,
  FloatingSpinner
} from 'common'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Card from '@kaizen-ui/card'
import {
  GLOBAL_SERVER_ID,
  GLOBAL_SERVICE_INSTANCE_ID,
  GLOBAL_VA_VERSION
} from '../../globalState'
import {
  API_LICENSE_SERVER_INSTALL,
  API_SERVICE_INSTANCES,
  DOCS_ROOT,
  HIGH_AVAILABILITY_STATE,
  SI_STATES
} from '../../api'
import { useAuth } from '../../hooks'
import { LicenseServerDetails } from '..'
import { ROUTE_SERVICE_INSTANCES, SessionExpired } from '../../Components'
import { useServiceInstanceTokenDownloader } from '../ServiceInstance'

const Row = styled.div`
  display: flex;

  .full {
    overflow: auto;
    .card-content-text {
      overflow: auto;
    }
  }
  .card-title {
    color: ${({ theme }) => theme.colors.textbox.normal.foreground};
  }
  .card-content-text {
    max-height: max-content;
    min-height: max-content;
  }
  > div + div {
    margin-left: 1rem;
  }
`

export const Dashboard = () => {
  const theme = useContext(KaizenThemeContext)
  const history = useHistory()
  const { notify } = useAppNotifications()
  const { checkAuth, user } = useAuth()
  const { isMarkedPreReg } = user || {}
  const [serverId] = useGlobal(GLOBAL_SERVER_ID)
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)
  const [version] = useGlobal(GLOBAL_VA_VERSION)
  const [si, setSi] = useState(undefined)
  const [file, setFile] = useState(undefined)
  const siPending = useMemo(() => si?.state === SI_STATES.PENDING, [si])
  const haUnconfigured = useMemo(
    () =>
      si?.high_availability_config?.mode ===
      HIGH_AVAILABILITY_STATE.UNCONFIGURED,
    [si]
  )

  React.useEffect(() => {
    document.title = 'DLS - Dashboard'
  }, [])

  const { getData: submitInstall, loading: uploading } = useFetch({
    endpoint: API_LICENSE_SERVER_INSTALL,
    actionLabel: 'Install license server',
    method: 'PUT'
  })
  const { getData, abort } = useFetch({
    endpoint: API_SERVICE_INSTANCES,
    actionLabel: 'Get service instance',
    SessionExpired: SessionExpired
  })
  const {
    download,
    loading: downloadingIdentity
  } = useServiceInstanceTokenDownloader(si)

  useEffect(() => {
    const getSi = async () => {
      const data = await getData()
      if (data) {
        setSi(data)
      }
    }
    getSi()

    return () => {
      abort()
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const submit = async () => {
    const reader = new FileReader()
    reader.onload = async function(r) {
      let result = r.target.result
      const headers = { 'x-nv-service-instance-id': serviceInstanceId }
      const body = { license: result }
      if (result) {
        const data = await submitInstall({ body, headers })
        if (data) {
          notify(
            'The license server was successfully installed',
            null,
            null,
            'Installed license server'
          )
          checkAuth(() => {})
        }
      }
    }
    reader.onerror = function() {
      const msg =
        'Could not process the license server file. Please select a valid license server file.'

      notify(msg, null, true, 'License server install failed')
    }
    reader.readAsText(file)
  }

  return serverId ? (
    <LicenseServerDetails serviceInstance={si} />
  ) : (
    <div>
      <PageHeader
        title='Dashboard'
        subTitle='Complete basic setup'
        helpUrl={DOCS_ROOT(version)}
      />
      {uploading && <FloatingSpinner />}
      <Row theme={theme}>
        {siPending && (
          <Card
            headerEnabled
            headerIcon={{ name: 'CustomNgcServer' }}
            title='Service instance not registered'
            childrenMinLines={20}
            primaryFooterAction={{
              label: 'Download DLS Instance Token',
              disabled: downloadingIdentity || isMarkedPreReg,
              onClick: download
            }}
          >
            <>
              <div style={{ color: theme.colors.lightGray500 }}>
                This service instance has not yet been registered with the
                NVIDIA Licensing Portal
              </div>
              {/* <DownloadButton>
                <Button
                  variant='link'
                  icon={{ name: 'ActionsDownload' }}
                  disabled={downloadingIdentity}
                  onClick={download}
                >
                  Download DLS Instance Token
                </Button>
              </DownloadButton> */}
            </>
          </Card>
        )}
        {haUnconfigured && (
          <Card
            headerEnabled
            headerIcon={{ name: 'StatusWarning' }}
            title='High availability is not configured'
            childrenMinLines={20}
            primaryFooterAction={{
              label: 'Configure High Availability',
              onClick: () => history.push(ROUTE_SERVICE_INSTANCES)
            }}
          >
            <>
              <div style={{ color: theme.colors.lightGray500 }}>
                This instance needs to be configured either as STANDALONE or
                CLUSTERED
              </div>
            </>
          </Card>
        )}
        <Card
          headerEnabled
          headerIcon={{ name: 'ServerAdd' }}
          title='No license server installed'
          childrenMinLines={20}
          primaryFooterAction={{
            label: 'Install server',
            disabled: !file || uploading,
            onClick: submit
          }}
        >
          <>
            <div
              style={{ color: theme.colors.lightGray500, marginBottom: '1rem' }}
            >
              Select the license server bin file downloaded from the NVIDIA
              Licensing Portal
            </div>

            <FileUpload
              accept='.bin'
              label='Select license server file'
              onChange={setFile}
            />
          </>
        </Card>
      </Row>
    </div>
  )
}
