import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Textbox from '@kaizen-ui/textbox'
import Password from '@kaizen-ui/password'
import Button from '@kaizen-ui/button'
import Icon from '@kaizen-ui/icon'
import { FileUpload, ModalSpinner, useAppNotifications, useFetch } from 'common'
import { useAuth } from '../../hooks'
import { API_LOCAL_SECRET_TOKEN, API_USER_PW_RESET } from '../../api'

const PullRight = styled.div`
  display: flex;
  justify-content: flex-end;
`
const StyledSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Flex = styled.div`
  display: flex;
  align-items: center;

  > h2 {
    margin-left: 0.5rem;
    font-weight: 100;
  }

  b {
    margin-left: 0.5rem;
  }
`

const initialValues = {
  email: '',
  localResetSecret: '',
  password: '',
  passwordCopy: ''
}
const validationSchema = Yup.object({
  localResetSecret: Yup.string().required('The local reset secret is required'),
  password: Yup.string()
    .matches(/^\S*$/, 'Should not contain any whitespaces')
    .min(8, 'Must have at least 8 characters')
    .max(64, 'Must have fewer than 64 characters')
    .required('You must enter a password'),
  passwordCopy: Yup.string().when('password', {
    is: val => {
      return val?.length > 0
    },
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'Both passwords must match')
      .required('Both passwords must match'),
    otherwise: Yup.string().required('You must enter a password')
  })
})

export const ForgotPassword = ({ onPasswordChange }) => {
  const { notify } = useAppNotifications()
  const theme = useContext(KaizenThemeContext)
  const [localResetSecret, setLocalResetSecret] = useState('')
  const [secretCopied, setSecretCopied] = useState(false)
  const { user, serviceInstanceId } = useAuth()
  const [file, setFile] = useState(undefined)

  const { getData: sendPwReset, loading: submitting } = useFetch({
    endpoint: API_USER_PW_RESET,
    actionLabel: `Reset password for ${user?.clientId}`,
    method: 'POST'
  })
  const { getData: submitNlpToken, loading: uploading } = useFetch({
    endpoint: API_LOCAL_SECRET_TOKEN,
    actionLabel: 'Get local secret via NLP token',
    method: 'PUT'
  })

  const submit = async values => {
    ReactTooltip.hide()
    const headers = { 'x-nv-service-instance-id': serviceInstanceId }
    const body = { local: values.localResetSecret, pw: values.password }

    const data = await sendPwReset({ body, headers })
    if (data?.localResetSecret) {
      setLocalResetSecret(data?.localResetSecret)
      onPasswordChange()
    }
  }
  const formik = useFormik({
    initialValues,
    onSubmit: submit,
    validationSchema
  })

  useEffect(() => {
    if (file) {
      const readFile = async () => {
        const reader = new FileReader()
        reader.onload = async function(r) {
          let result
          try {
            result = JSON.parse(r.target.result)
          } catch (error) {
            const msg =
              'Could not process the file. Please select a valid local reset secret token.'
            notify(msg, null, true, 'Processing NLP token')
          }
          if (result) {
            const data = await submitNlpToken({ body: result })
            if (data?.localResetSecret) {
              formik.setFieldValue('localResetSecret', data?.localResetSecret)
            }
          }
        }
        reader.onerror = function(err) {
          const msg =
            'Could not process the file. Please select a valid local reset secret token.'
          notify(msg, null, true, 'Processing NLP token')
        }
        reader.readAsText(file)
      }
      readFile()
    }
  }, [file]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {(submitting || uploading) && <ModalSpinner />}
      {localResetSecret ? (
        <>
          <StyledSuccess>
            <Flex>
              <Icon
                name='StatusCircleCheck2'
                variant='solid'
                color={theme.colors.brand}
                size='large'
              />
              <h2>Password reset successfully!</h2>
            </Flex>
            {/* <div>
              Your username is <b>{formik.values.email}</b>
            </div>*/}
            <Flex>
              Your local reset secret is <b>{localResetSecret}</b>
              <div data-tip='Copy secret to clipboard'>
                <CopyToClipboard
                  text={localResetSecret}
                  onCopy={() => setSecretCopied(true)}
                >
                  <Button
                    type='success'
                    variant='link'
                    icon={{
                      name: secretCopied ? 'StatusCircleCheck2' : 'FileCopy'
                    }}
                  />
                </CopyToClipboard>
              </div>
            </Flex>
            <br />
            <div>
              Copy this local reset secret and store it in a secure location. In
              case the password is forgotten, this value can be used one time to
              reset the password.
            </div>
          </StyledSuccess>
        </>
      ) : (
        <>
          <form>
            {/* <div>
              <div>
                <Textbox
                  name='email'
                  label='Username'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  validationMessage={
                    formik.touched.email && formik.errors.email
                  }
                  disabled
                />
              </div>
            </div> */}
            <div>
              <Textbox
                name='localResetSecret'
                label='Local reset secret'
                className='nvl'
                onChange={formik.handleChange}
                placeholder='Enter the local reset secret '
                value={formik.values.localResetSecret}
                validationMessage={
                  formik.touched.localResetSecret &&
                  formik.errors.localResetSecret
                }
              />
              <FileUpload
                accept='.tok'
                label='Select DLS local reset secret'
                onChange={setFile}
              />
            </div>
            <div>
              <div data-tip='Enter the password'>
                <Password
                  name='password'
                  label='Password'
                  className='nvl'
                  placeholder='Enter the new password'
                  showVisibilityToggle={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  validationMessage={
                    formik.touched.password && formik.errors.password
                  }
                  valid={formik.touched.password && !formik.errors.password}
                />
              </div>
            </div>
            <div>
              <div data-tip='Enter the password again'>
                <Password
                  name='passwordCopy'
                  label='Confirm password'
                  className='nvl'
                  placeholder='Confirm the new password'
                  showVisibilityToggle={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordCopy}
                  validationMessage={
                    formik.touched.passwordCopy && formik.errors.passwordCopy
                  }
                  valid={
                    formik.touched.passwordCopy && !formik.errors.passwordCopy
                  }
                />
              </div>
            </div>

            <PullRight>
              <Button
                tag='submit'
                onClick={formik.handleSubmit}
                disabled={submitting || uploading}
              >
                Reset password
              </Button>
            </PullRight>
          </form>
        </>
      )}
    </>
  )
}
