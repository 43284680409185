import React from 'reactn'
import * as Yup from 'yup'
import styled from 'styled-components'
import Textbox from '@kaizen-ui/textbox'
import Button from '@kaizen-ui/button'
import { useFormik } from 'formik'
import Password from '@kaizen-ui/password'

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const validationSchema = Yup.object({
  username: Yup.string().required('LDAP username is required'),
  password: Yup.string().required('LDAP password is required')
})

export const TestLDAPConnection = ({ ldapFormik }) => {
  const initialValues = {
    username: '',
    password: ''
  }

  const submit = async values => {
    const { username, password } = values
    ldapFormik.setFieldValue('test_ldap', true)
    ldapFormik.setFieldValue('test_ldap_creds', {
      username,
      password
    })
    ldapFormik.handleSubmit()
  }
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: submit
  })
  return (
    <div>
      <Textbox
        id='username'
        name='username'
        label='Enter your LDAP username'
        className='nvl'
        placeholder='username'
        value={formik.values.username}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={formik.touched.username && formik.errors.username}
      />
      <Password
        name='password'
        label='LDAP Password'
        placeholder='password'
        showVisibilityToggle={false}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        required={formik.errors.password}
        validationMessage={formik.errors.password}
      />
      <Buttons>
        <Button
          type={'primary'}
          onClick={formik.handleSubmit}
          disabled={ldapFormik.isSubmitting || formik.isSubmitting}
          tag={'submit'}
        >
          Test LDAP Connection
        </Button>
      </Buttons>
    </div>
  )
}
