"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "SwitchList", {
  enumerable: true,
  get: function get() {
    return _SwitchList.SwitchList;
  }
});

var _SwitchList = require("./SwitchList");