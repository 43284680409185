import React, { useGlobal, useContext, useEffect, useState } from 'reactn'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { saveAs } from 'file-saver'
import Card from '@kaizen-ui/card'
import Modal from '@kaizen-ui/modal'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import styled from 'styled-components'
import {
  GLOBAL_IS_UPGRADE_IN_PROGRESS,
  GLOBAL_ORG,
  GLOBAL_PENDING_RETURNS,
  GLOBAL_SERVER_ID,
  GLOBAL_SERVICE_INSTANCE_ID,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'
import {
  API_LICENSE_SERVER_RETURN_FEATURES,
  API_RETURN_FEATURES_EXPORT
} from '../../api'
import { SessionExpired } from '../../Components'
import { PageHeader, useFetch } from 'common'
import { Upgrade } from './Upgrade'
import { ExportEvents } from '../Events/ExportEvents'
import { useExportFileGenStatus } from '../../hooks/useExportFileGenStatus'
import { toast } from 'react-toastify'
import { useServiceInstance } from '../../hooks'

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;

  > div {
    margin-bottom: 1rem;
    /*width: 300px;*/
    margin-left: 1rem;
  }
  > div + div {
    margin-left: 1rem;
  }
`

export const Maintenance = () => {
  const [isUpgradeInProgress] = useGlobal(GLOBAL_IS_UPGRADE_IN_PROGRESS)
  const theme = useContext(KaizenThemeContext)
  const [hasPending, setHasPending] = useGlobal(GLOBAL_PENDING_RETURNS)
  const [previousReturnFileContent, setPreviousReturnFileContent] = useState('')

  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const [serverId] = useGlobal(GLOBAL_SERVER_ID)
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const [eventsOpen, setEventsOpen] = useState(false)
  const { generationStatus, setRefreshStatus } = useExportFileGenStatus(
    serviceInstanceId,
    true
  )
  const [refresh, setRefresh] = useState(false)

  const {
    serviceInstance,
    loading: loadingSI,
    setRefresh: refreshSI
  } = useServiceInstance({
    autoLoad: true
  })
  const { getData } = useFetch({
    endpoint: API_LICENSE_SERVER_RETURN_FEATURES(orgId, vgId, serverId),
    actionLabel: 'Check for pending returns',
    SessionExpired: SessionExpired
  })

  const { getData: getFeatureReturns, loading: gettingFeatReturn } = useFetch({
    endpoint: API_RETURN_FEATURES_EXPORT(orgId, vgId, serverId),
    actionLabel: 'Generate feature return file',
    method: 'POST',
    SessionExpired: SessionExpired
  })

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [eventsOpen])

  useEffect(() => {
    if (!vgId || !serverId || isUpgradeInProgress) return
    const checkPending = async () => {
      const data = await getData()
      if (data) {
        setHasPending(
          data?.featureCountsToReturn?.length > 0 ||
            data?.featuresToReturn?.length > 0
        )
        setPreviousReturnFileContent(data?.previousReturnFileContent || '')
      }
    }
    checkPending()
  }, [vgId, refresh, serverId, isUpgradeInProgress]) //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    document.title = 'DLS - Maintenance'
  }, [])

  const downloadFeatureReturns = async () => {
    const data = await getFeatureReturns()
    if (data?.returnLicense) {
      const blob = new Blob([data?.returnLicense], {
        type: 'application/octet-stream'
      })
      saveAs(
        blob,
        `on-premises_feature_return_${moment(Date.now()).format(
          'MM-DD-YYYY-HH-mm-ss'
        )}.bin`
      )
      setRefresh(val => !val)
    }
  }

  const downloadOldFeatureReturns = async () => {
    if (previousReturnFileContent) {
      const blob = new Blob([previousReturnFileContent], {
        type: 'application/octet-stream'
      })
      saveAs(
        blob,
        `on-premises_previous_feature_return_${moment(Date.now()).format(
          'MM-DD-YYYY-HH-mm-ss'
        )}.bin`
      )
      setRefresh(val => !val)
    } else {
      toast.error('No previous return file found!')
    }
  }

  return (
    <>
      {!isUpgradeInProgress && (
        <>
          <PageHeader
            title='Maintenance'
            //helpUrl={DOCS_LICENSE_SERVER}
            //tip='Get help with license servers'
            subTitle='Synchronize data with the NVIDIA Licensing Portal and manage the virtual appliance version'
          />
          <Row theme={theme}>
            <div
              style={{
                width: '300px'
              }}
            >
              <Card
                headerEnabled
                headerIcon={{ name: 'ViewList', variant: 'solid' }}
                title='Events'
                childrenMinLines={20}
                primaryFooterAction={{
                  label: 'Export events',
                  disabled: isUpgradeInProgress,
                  onClick: () => setEventsOpen(true)
                }}
              >
                <div>
                  Events exported from here can be imported to the NVIDIA
                  Licensing Portal
                </div>
              </Card>
            </div>
            <div
              style={
                !hasPending && previousReturnFileContent
                  ? { width: 'min-content' }
                  : { width: '300px' }
              }
            >
              <Card
                headerEnabled
                headerIcon={{ name: 'ConnectionServer', variant: 'solid' }}
                title='Feature Return'
                childrenMinLines={20}
                primaryFooterAction={{
                  label: 'Export feature returns',
                  onClick: downloadFeatureReturns,
                  disabled:
                    !hasPending || isUpgradeInProgress || gettingFeatReturn
                }}
                {...(!hasPending && previousReturnFileContent
                  ? {
                      secondaryFooterAction: {
                        label: 'Download last return file',
                        onClick: downloadOldFeatureReturns
                        /*disabled: downloadingPrevious*/
                      }
                    }
                  : {})}
              >
                {hasPending ? (
                  <>
                    There are pending feature returns, click below to generate
                    the file for upload to the NVIDIA Licensing Portal
                  </>
                ) : (
                  <>
                    There are no pending feature returns at this time.
                    {previousReturnFileContent
                      ? ' You can re-download the previously exported feature return file using "Download last return file."'
                      : ''}
                  </>
                )}
              </Card>
            </div>
          </Row>
        </>
      )}
      <PageHeader
        title='Virtual Appliance Upgrade'
        subTitle='Complete the following steps for upgrading to a new version of a
        virtual appliance'
        footer={
          <Upgrade
            serviceInstance={serviceInstance}
            refreshSI={refreshSI}
            loadingSI={loadingSI}
          />
        }
      />

      <StyledModal onClick={e => e.stopPropagation()} theme={theme}>
        <Modal
          title='Export Events Data'
          subtitle='Events will be exported to a .log file in encrypted format. The exported file can be imported to the NVIDIA Licensing Portal.'
          open={eventsOpen}
          onClose={() => setEventsOpen(false)}
          className='modal-export-events'
          size={'large'}
        >
          {eventsOpen && (
            <ExportEvents
              generationStatus={generationStatus}
              setRefreshStatus={setRefreshStatus}
              sid={serviceInstanceId}
              encrypted={true}
            />
          )}
        </Modal>
      </StyledModal>
    </>
  )
}

const StyledModal = styled.div`
  .modal-export-events {
    background-color: ${({ theme }) => theme.colors.pageBackground};
    .modal-subtitle {
      margin-bottom: 0 !important;
    }
  }
`
