import React, {
  useMemo,
  useState,
  useGlobal,
  useEffect,
  useContext
} from 'reactn'
import styled from 'styled-components'
import * as Yup from 'yup'
import Textbox from '@kaizen-ui/textbox'
import Tabs, { Tab } from '@kaizen-ui/tabs'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Text from '@kaizen-ui/text'
import Button from '@kaizen-ui/button'
import { useFormik } from 'formik'
import {
  Wizard,
  useFetch,
  useAppNotifications,
  Radios,
  ConditionBindings,
  ModalButtons,
  ModalSpinner
} from 'common'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'
import {
  API_FULFILLMENT_CONDITION,
  API_FULFILLMENT_CONDITIONS,
  API_FULFILLMENT_CONDITION_MATCH_OPTIONS
} from '../../api'
import { SessionExpired } from '../SessionExpired'

const nameRegExp = /^[a-zA-Z]/
//const allowedChars = /^[-_A-Za-z0-9]+$/
const validationSchema = Yup.object({
  name: Yup.string()
    .min(4, 'Name must have more than 4 characters')
    .matches(nameRegExp, 'Must start with an alphabet')
    .max(32, 'Name must have fewer than 32 characters')
    //.matches(allowedChars, 'Cannot include special characters')
    .required('A fulfillment condition name must be provided'),
  description: Yup.string().max(250, 'Must have fewer than 250 characters'),
  matchCondition: Yup.string().required('You must select a match condition'),
  bindings: Yup.array().min(1, 'You must add at least 1 license pool')
})

export const CreateCondition = ({
  server,
  onUpdate,
  fulfillmentCondition = {}
}) => {
  const initialValues = useMemo(() => {
    const {
      name = '',
      description = '',
      selectionRuleId = '',
      bindings = []
    } = fulfillmentCondition
    return {
      name,
      description,
      matchCondition: selectionRuleId,
      bindings: bindings.map(pool => pool.pool)
    }
  }, [fulfillmentCondition])
  const theme = useContext(KaizenThemeContext)
  const { notify } = useAppNotifications()
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const { id, name } = server
  const isEdit = !!fulfillmentCondition?.id
  const [matchCondition, setMatchCondition] = useState(
    initialValues.matchCondition
  )
  const [matchOptions, setMatchOptions] = useState([])
  const [selectedPools, setSelectedPools] = useState(
    isEdit ? fulfillmentCondition?.bindings.map(pool => pool.pool) : []
  )
  const [active, setActive] = useState(1)
  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_FULFILLMENT_CONDITIONS(orgId, vgId, id),
    actionLabel: 'Create fulfillment condition',
    method: 'POST',
    SessionExpired: SessionExpired
  })
  const { getData: sendUpdateRequest, loading: submittingUpdate } = useFetch({
    endpoint: API_FULFILLMENT_CONDITION(
      orgId,
      vgId,
      id,
      fulfillmentCondition?.id
    ),
    actionLabel: `Edit FC ${name} on ${server?.name}`,
    method: 'PUT',
    responseOnly: true,
    SessionExpired: SessionExpired
  })
  const { getData, loading, abort } = useFetch({
    endpoint: `${API_FULFILLMENT_CONDITION_MATCH_OPTIONS(orgId, vgId, id)}${
      isEdit ? `?fulfillment_condition_id=${fulfillmentCondition?.id}` : ''
    }`,
    actionLabel: `Get match condition options for server ${name}`,
    SessionExpired: SessionExpired,
    normalizer: data => {
      if (!data || !data.selectionRules) return []

      const rules = data.selectionRules.map(sr => {
        const { selectionRuleXid, selectionRuleName } = sr

        return { label: selectionRuleName, value: selectionRuleXid }
      })
      return rules
    }
  })
  const submit = async values => {
    const { bindings, name, description } = formik.values

    const fcStatus = isEdit ? {} : { enabled: true }
    const body = {
      bindings: bindings.map((b, i) => {
        return {
          evaluationOrderIndex: i,
          licensePoolId: b.id
        }
      }),
      description,
      ...fcStatus,
      name,
      selectionRuleId: matchCondition
    }

    isEdit ? submitUpdate(body) : submitNew(body)
  }

  const submitNew = async body => {
    const result = await sendRequest({ body })
    if (result) {
      const msg = `Fulfillment condition ${name} was successfully created!`
      notify(msg, null, null, 'Created fulfillment condition')
      onUpdate && onUpdate()
    }
  }

  const submitUpdate = async body => {
    const result = await sendUpdateRequest({ body })
    if (result) {
      const msg = `Fulfillment condition ${name} was successfully edited!`
      notify(msg, null, null, 'Edited fulfillment condition')
      onUpdate && onUpdate()
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: submit
  })

  const steps = useMemo(
    () => [
      {
        label: 'Name and match condition',
        help: isEdit
          ? 'Review the basic details of this fulfillment condition'
          : 'Enter a name, an optional description, and select the match condition for this fulfillment condition',
        value: 1,
        allowNext:
          !formik.touched.name ||
          !formik.touched.matchCondition ||
          (!formik.errors.name &&
            !formik.errors.description &&
            !formik.errors.matchCondition),
        onNext: () => {
          if (!formik.touched.name || !formik.touched.matchCondition) {
            formik.setFieldTouched('name', true)
            formik.setFieldTouched('matchCondition', true)
            return false
          } else {
            return true
          }
        }
      },
      {
        label: 'Select license pools',
        help: isEdit
          ? 'Review or update the bound pools'
          : 'Select one or more license pools to bind to the new fulfillment condition',
        value: 2,
        allowNext:
          !formik.touched.bindings ||
          (formik.touched.bindings && !formik.errors.bindings),
        onNext: () => {
          if (!formik.touched.bindings) {
            formik.setFieldTouched('bindings', true)
            return false
          } else {
            return true
          }
        }
      },
      {
        label: `Preview condition ${isEdit ? 'update' : 'creation'}`,
        help: 'Review your selections for this fulfillment condition',
        value: 3
      }
    ],
    [formik, isEdit]
  )

  useEffect(() => {
    const getOptions = async () => {
      const data = await getData()

      setMatchOptions(data)
      if (data && data.length === 1) {
        setMatchCondition(data[0].value)
        formik.setFieldValue('matchCondition', data[0].value)
        formik.setFieldTouched('matchCondition', true)
      }

      if (isEdit) {
        formik.setFieldTouched('matchCondition', true)
        formik.setFieldTouched('name', true)
        formik.setFieldTouched('description', true)
        formik.setFieldTouched('bindings', true)
      }
    }
    getOptions()

    return () => {
      abort()
    }
  }, [server]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Wizard
        steps={steps}
        numbered
        horizontal
        active={active}
        setActive={setActive}
        tabOverflow={active === 1 ? 'visible' : 'auto'}
      >
        <Tabs selectedTabId={active}>
          <Tab id={1}>
            <>
              <Textbox
                id='name'
                name='name'
                label='Name'
                className='nvl'
                placeholder='Enter a name for this fulfillment condition'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                validationMessage={formik.touched.name && formik.errors.name}
              />
              <Textbox
                id='description'
                name='description'
                label='Description'
                inputType='multiLine'
                className='nvl'
                placeholder='Optionally, enter a description for this fulfillment condition'
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                validationMessage={
                  formik.touched.description && formik.errors.description
                }
              />
              {/* <Select
                  value={matchCondition}
                  options={matchOptions}
                  clearable={false}
                  onChange={setMatchCondition}
                  loading={loading}
                /> */}

              <Radios
                label='Match condition'
                value={matchCondition}
                options={matchOptions}
                onChange={val => {
                  formik.setFieldTouched('matchCondition', true)
                  setMatchCondition(val)
                  formik.setFieldValue('matchCondition', val)
                  //
                }}
                loading={loading}
                inline
                validationMessage={
                  formik.touched.matchCondition && formik.errors.matchCondition
                }
              />
            </>
          </Tab>
          <Tab id={2}>
            <ConditionBindings
              pools={server.licensePools}
              selected={selectedPools}
              onChange={val => {
                formik.setFieldTouched('bindings', true)
                setSelectedPools(val)
                formik.setFieldValue('bindings', val)
              }}
              validationMessage={
                formik.touched.bindings && formik.errors.bindings
              }
            />
          </Tab>
          <Tab id={3}>
            {(submitting || submittingUpdate) && <ModalSpinner />}
            <div style={{ width: '400px', margin: '0 auto' }}>
              <PreviewItem>
                <Text textStyle='label'>Name</Text>
                <div>{formik.values.name}</div>
              </PreviewItem>
              {formik.values.description && (
                <PreviewItem>
                  <Text textStyle='label'>Description</Text>
                  <div> {formik.values.description}</div>
                </PreviewItem>
              )}
              <PreviewItem>
                <Text textStyle='label'>Match condition</Text>
                <div>
                  {
                    (matchOptions || []).find(mo => mo.value === matchCondition)
                      ?.label
                  }
                </div>
              </PreviewItem>
              <div
                style={{
                  marginTop: '1rem',
                  color: theme.colors.textbox.placeholder,
                  fontSize: '1rem'
                }}
              >
                <strong style={{ color: theme.colors.brand }}>Reminder!</strong>{' '}
                Feature requests will be evaluated in the order of the bound
                license pools
              </div>

              <div style={{ marginTop: '1rem' }}>
                <Text textStyle='label'>
                  Bound pools ({formik.values.bindings.length})
                </Text>
                <Bindings>
                  {formik.values.bindings.map((binding, index) => {
                    const { name, id } = binding
                    return (
                      <div key={id}>
                        <span
                          data-tip='Evaluation order index'
                          style={{
                            fontSize: '1rem',
                            color: theme.colors.brand,
                            marginRight: '1rem',
                            marginLeft: '1rem'
                          }}
                        >
                          {index}
                        </span>
                        <span>{name}</span>
                      </div>
                    )
                  })}
                </Bindings>
              </div>
            </div>
            <div style={{ marginTop: '2rem' }}>
              <ModalButtons>
                <Button
                  disabled={
                    !formik.isValid ||
                    submitting ||
                    submittingUpdate ||
                    !formik.dirty
                  }
                  onClick={formik.handleSubmit}
                >
                  {isEdit ? 'Edit' : 'Create'} Fulfillment Condition
                </Button>
              </ModalButtons>
            </div>
          </Tab>
        </Tabs>
      </Wizard>
    </>
  )
}

const Bindings = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.textbox.normal.border};
  background: ${({ theme }) => theme.colors.textbox.normal.background};
  overflow: auto;
  max-height: 100px;
  width: 400px;

  > div {
    padding: 0.5rem;
  }
`
const PreviewItem = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-start;

  > span {
    width: 120px;
    flex-shrink: 0;
  }
`
