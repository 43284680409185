import { formatDateTime } from 'common'
import moment from 'moment'

//runtime configuration options
export const DEV_MODE = process.env.REACT_APP_DEV_MODE === 'true'
export const BASE_URL = DEV_MODE
  ? process.env.REACT_APP_API_URL
  : window?.location?.origin

export const SUPPORT_URL =
  process.env.REACT_APP_SUPPORT_URL ||
  'https://stid.nvidia.com/affwebservices/public/saml2sso?SPID=https://espcommunity.salesforce.com'
export const HUB_URL =
  process.env.REACT_APP_HUB_URL || 'https://stid.nvidia.com/dashboard'
export const DOCS_BASE_URL = 'https://docs.nvidia.com/license-system/dls'

//dls version
export const DLS_VERSION = `${BASE_URL}/service_instance_manager/v1/build-number`

//documentation
export const DOCS_ROOT = dlsVersion =>
  dlsVersion
    ? `${DOCS_BASE_URL}/${dlsVersion}/nvidia-dls-user-guide/`
    : DOCS_BASE_URL
export const RELEASE_NOTES = dlsVersion =>
  dlsVersion
    ? `${DOCS_BASE_URL}/${dlsVersion}/nvidia-dls-release-notes/`
    : DOCS_BASE_URL

export const DOCS_SERVICE_INSTANCE = `${DOCS_ROOT}#configuring-service-instance`
export const DOCS_USER_MANAGEMENT = `${DOCS_ROOT}#managing-users-nvidia-license-portal`
export const DOCS_USER_ROLES = `${DOCS_ROOT}#role-based-access-to-virtual-groups`
export const DOCS_USER_CREATE = `${DOCS_ROOT}#adding-user-nvidia-licesning-portal`
export const DOCS_VIRTUAL_GROUP_CREATE = `${DOCS_ROOT}#creating-a-virtual-group`
export const DOCS_VIRTUAL_GROUP = `${DOCS_ROOT}#managing-virtual-groups`
export const DOCS_VIRTUAL_GROUP_DELETE = `${DOCS_ROOT}#deleting-a-virtual-group`
export const DOCS_LICENSE_SERVER = dlsVersion =>
  `${DOCS_ROOT(
    dlsVersion
  )}#registering-license-server-and-getting-license-files`
export const DOCS_VA_UPGRADE = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#generating-upgrade-file-for-dls-instance`
export const DOCS_VA_TRANSFER = dlsVersion =>
  `${DOCS_ROOT(
    dlsVersion
  )}#transferring-migration-data-to-dls-instance-on-new-virtual-appliance`
export const DOCS_SETTINGS_DLS_SSL_SETUP = dlsVersion =>
  `${DOCS_ROOT(
    dlsVersion
  )}#configuring-dls-virtual-appliance-with-3rd-party-ssl-certificate`
export const DOCS_SETTINGS_RETENTION_EVENTS = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#setting-retention-period-events-dls-instance`
export const DOCS_SETTINGS_SERVER = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#editing-license-server-settings`
export const DOCS_SETTINGS_EMAIL_ALERTS = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#editing-license-server-settings`
export const DOCS_SETUP_USER = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#user-accounts-on-dls-virtual-appliance`
export const DOCS_SETUP_ADMIN = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#registering-dls-administrator-user`
export const DOCS_SETUP_GEN_UPGRADE = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#generating-upgrade-file-for-dls-instance`
export const DOCS_HIGH_AVAILABILITY = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#nls-service-instance-high-availability`
export const DOCS_HA_CONFIG = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#configuring-ha-cluster-dls-instances`
export const DOCS_GEN_CCT = dlsVersion =>
  `${DOCS_ROOT(
    dlsVersion
  )}#generating-client-configuration-token-for-dls-instance`
export const DOCS_SI_CONFIGURE = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#configuring-service-instance`
export const DOCS_LICENSE_SERVER_MANAGE_POOLS = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#adding-removing-licenses-in-license-pool`
export const DOCS_LICENSE_SERVER_MANAGE_CONDITIONS = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#managing-fulfillment-conditions`
export const DOCS_LICENSE_SERVER_MANAGE_LEASES = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#manual-release-specific-clients`
export const DOCS_LICENSE_SERVER_MANAGE_NLL = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#generating-node-locked-license`
export const DOCS_LICENSE_SERVER_MANAGE = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#managing-licenses-on-license-server`
export const DOCS_DISK_SPACE_THRESHOLD_CONFIG = dlsVersion =>
  `${DOCS_ROOT(dlsVersion)}#disk-space-threshold-configuration`

//auth
export const API_USERS_ME = `${BASE_URL}/auth/v1/user/me`
export const API_LOGIN = `${BASE_URL}/auth/v1/login`
export const API_LOGOUT = `${BASE_URL}/auth/v1/logout`
export const API_AUTH_LDAP = `${BASE_URL}/auth/v1/ldap`
export const API_USER_REGISTRATION = `${BASE_URL}/service_instance_manager/v1/ca-registration`
export const API_USER_PW_RESET = `${BASE_URL}/service_instance_manager/v1/pw-reset`
export const API_USER_PW_CHANGE = `${BASE_URL}/service_instance_manager/v1/pw-change`
export const API_LOCAL_SECRET_TOKEN = `${BASE_URL}/service_instance_manager/v1/service-instance/local-reset-secret-token`
export const API_RESET_FORCE_RELEASE = `${BASE_URL}/service_instance_manager/v1/service-instance/reset-bulk-lease-release-limit-token`
export const API_DIAG_USER = `${BASE_URL}/service_instance_manager/v1/system-user/status`
export const API_DIAG_USER_ENABLE = `${BASE_URL}/service_instance_manager/v1/system-user/enable`
export const API_DIAG_USER_DISABLE = `${BASE_URL}/service_instance_manager/v1/system-user/disable`

//email-alerts
export const API_EMAIL_ALERTS = () => `${BASE_URL}/admin/v1/alert-configuration`
export const API_EMAIL_ALERTS_SMTP = () =>
  `${BASE_URL}/admin/v1/smtp_configuration`

//api keys
export const API_KEYS = `${BASE_URL}/admin/v1/api-keys`
export const API_KEYS_IMPORT = `${BASE_URL}/admin/v1/api-keys/import`

export const API_NLL = `${BASE_URL}/nll/leasing/v1/borrow`
export const API_LOCKED_LEASES = (org, vg) =>
  `${BASE_URL}/admin/v1/org/${org}/virtual-groups/${vg}/nll/transactions`

//license servers
export const API_LICENSE_SERVER = (orgName, virtualGroupId, serverId) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/license-servers/${serverId}`
export const API_LICENSE_SERVER_INSTALL = `${BASE_URL}/file_installation/v1/license-file`

//license pools
export const API_LICENSE_POOLS = (orgName, virtualGroupId, serverId) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/license-servers/${serverId}/license-pools`
export const API_LICENSE_POOL = (orgName, virtualGroupId, serverId, poolId) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/license-servers/${serverId}/license-pools/${poolId}`

//fulfillment conditions
export const API_FULFILLMENT_CONDITIONS = (orgName, virtualGroupId, serverId) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/license-servers/${serverId}/fulfillment-conditions`
export const API_FULFILLMENT_CONDITION = (
  orgName,
  virtualGroupId,
  serverId,
  fcId
) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/license-servers/${serverId}/fulfillment-conditions/${fcId}`
export const API_FULFILLMENT_CONDITION_MATCH_OPTIONS = (
  orgName,
  virtualGroupId,
  serverId
) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/license-servers/${serverId}/selection-rules`

//return features
export const API_LICENSE_SERVER_RETURN_FEATURES = (
  orgName,
  virtualGroupId,
  serverId
) =>
  `${BASE_URL}/file_installation/v1/org/${orgName}/virtual-groups/${virtualGroupId}/license-servers/${serverId}/return-file/pending-returns`
export const API_RETURN_FEATURES_EXPORT = (orgName, virtualGroupId, serverId) =>
  `${BASE_URL}/file_installation/v1/org/${orgName}/virtual-groups/${virtualGroupId}/license-servers/${serverId}/return-file/export`

//lease service
export const API_LEASES = (orgName, virtualGroupId) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/leases/all`
export const API_LEASES_RELEASE = (orgName, virtualGroupId) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/leases/force-release`
export const API_NLL_LEASES_RELEASE = `${BASE_URL}/nll/leasing/v1/release`

export const API_METRICS = `${BASE_URL}/admin/v1/lease_report`
export const API_USAGE_METRICS = `${BASE_URL}/service_instance_manager/v1/metrics`
export const API_USAGE_METRICS_CONFIG = `${BASE_URL}/service_instance_manager/v1/metrics/config`

//events
export const API_EVENTS = (orgName, virtualGroupId) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/report`
export const API_EVENTS_EXPORT = (orgName, virtualGroupId) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/export`
export const API_EVENTS_EXPORT_JOB = (orgName, virtualGroupId) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/report-export/job`
/*
export const API_EVENTS_EXPORT_DOWNLOAD_JSON = (orgName, virtualGroupId) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/export/download`

export const API_EVENTS_EXPORT_DOWNLOAD_URL = (
  orgName,
  virtualGroupId,
  jobId
) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/report-export/job/${jobId}/download`
*/

export const API_EVENTS_EXPORT_DOWNLOAD = `${BASE_URL}/download/unenc/`
export const API_EVENTS_EXPORT_DOWNLOAD_ENCRYPTED = `${BASE_URL}/download/enc/`

//settings
export const API_GET_SETTINGS = (orgName, virtualGroupId, scope, scopeId) =>
  `${BASE_URL}/admin/v1/org/${orgName}/configuration-settings?setting_scope=${scope}&setting_scope_instance_xid=${scopeId}&vgroup=${virtualGroupId}`
export const API_PUT_SETTINGS = orgName =>
  `${BASE_URL}/admin/v1/org/${orgName}/configuration-settings`

//service instances
export const API_SERVICE_INSTANCE_UPGRADE = `${BASE_URL}/service_instance_manager/v1/service-instance/upgrade-file`
export const API_UPLOAD_UPGRADE_FILE = `${BASE_URL}/upload/upgrade-file`
export const API_SERVICE_INSTANCES = `${BASE_URL}/service_instance_manager/v1/service-instance`
export const API_SERVICE_INSTANCES_PRE_REGISTER = `${BASE_URL}/service_instance_manager/v1/service-instance/pre-register`
export const API_SERVICE_INSTANCE_IDENTITY_TOKEN = `${BASE_URL}/service_instance_manager/v1/service-instance/identity-token`
export const API_SERVICE_INSTANCE_IDENTITY_TOKEN_UNAUTH = `${BASE_URL}/service_instance_manager/v1/service-instance/unauth/identity-token`
export const API_SERVICE_INSTANCE_TOKEN_CONFIG = (orgName, virtualGroupId) =>
  `${BASE_URL}/admin/v1/org/${orgName}/virtual-groups/${virtualGroupId}/license-allocation-config`
export const API_SERVICE_INSTANCE_TOKEN_DOWNLOAD = `${BASE_URL}/service_instance_manager/v1/service-instance/compose-messenger-token`
export const API_SERVICE_INSTANCE_PING = `${BASE_URL}/service_instance_manager/v1/service-instance/ping`
export const API_MIGRATION_TARGET_PING = `${BASE_URL}/service_instance_manager/v1/service-instance/migration/ping-target`
export const API_SERVICE_INSTANCE_CONFIGURE_HA = `${BASE_URL}/service_instance_manager/v1/service-instance/high-availability-config`
export const API_SERVICE_INSTANCE_NW_SHARE = `${BASE_URL}/service_instance_manager/v1/filesystem`
export const API_SERVICE_INSTANCE_NTP_SERVER = `${BASE_URL}/service_instance_manager/v1/ntp`
export const API_SERVICE_INSTANCE_NTP_SERVER_PING = `${BASE_URL}/service_instance_manager/v1/ntp/ping`
export const API_SERVICE_INSTANCE_SSL_CERT = `${BASE_URL}/service_instance_manager/v1/ssl-certs`
export const API_SERVICE_INSTANCE_NODE_IP = `${BASE_URL}/service_instance_manager/v1/ip-address`
export const API_SERVICE_INSTANCE_NODE_RESTART = `${BASE_URL}/service_instance_manager/v1/restart-services`
export const API_UNHEALTHY_CLIENTS = serviceInstanceId =>
  `${BASE_URL}/admin/v1/service-instance/${serviceInstanceId}/clients/invalid`

//remove node new API
export const API_CLUSTER_NODE_REMOVE = `${BASE_URL}/service_instance_manager/v1/service-instance/cluster/node/remove`

//DLS UPGRADE inplace/offline
export const API_UPGRADE_STATUS = `${BASE_URL}/service_instance_manager/v1/upgrade`
export const API_ACK_UPGRADE = `${BASE_URL}/service_instance_manager/v1/upgrade/ack`
export const API_UPGRADE_SYNC_CHANGES = `${BASE_URL}/service_instance_manager/v1/upgrade/sync`
export const API_NODE_ROLE = `${BASE_URL}/service_instance_manager/v1/service-instance/high-availability/node-role`

//online migration old
export const API_ONLINE_MIGRATION_STATUS = `${BASE_URL}/service_instance_manager/v1/service-instance/migration-job?mode=online`

export const API_SERVICE_INSTANCE_MIGRATION_START = `${BASE_URL}/service_instance_manager/v1/service-instance/migration-job`
export const API_SERVICE_INSTANCE_MIGRATION_COMPLETE = `${BASE_URL}/service_instance_manager/v1/service-instance/migration-job/ack`

//old upgrade
export const API_MIGRATION_OFFLINE_GENERATE = `${BASE_URL}/service_instance_manager/v1/service-instance/migration-file/generate`
export const API_MIGRATION_FILE_GEN_STATUS = `${BASE_URL}/service_instance_manager/v1/service-instance/migration-file/generate/status`
export const API_MIGRATION_OFFLINE_DOWNLOAD = `${BASE_URL}/download/migration/`
export const API_MIGRATION_OFFLINE_DECOMMISSION_SI = `${BASE_URL}/service_instance_manager/v1/service-instance/migration/decommission`

export const API_UPGRADE_FILE_INSTALL_STATUS = `${BASE_URL}/service_instance_manager/v1/service-instance/unauth/upgrade-file/installation/status`

//formatters

const METRICS = {
  CPU_USAGE: 'CPU_USAGE',
  MEMORY_USAGE: 'MEMORY_USAGE',
  DISK_USAGE: 'DISK_USAGE'
}

//usage metrics
export function formatUsageMetrics(data, filter) {
  const formatted = (data?.metric || []).map(m => {
    const { ts, ...rest } = m

    return {
      ts: moment.unix(ts),
      //.format(),
      ...rest
    }
  })

  //sort by descending date, then use first as current capacity
  const compareFn = (left, right) => {
    return moment.utc(right.ts).diff(moment.utc(left.ts)) //reverse sort, latest date first
  }

  //remove entries with invalid dates, or dates 10000 years from now.
  const onlyValid = formatted
    .filter(
      m =>
        m.ts.format() !== 'Invalid date' &&
        m.ts.format().substring(4, 5) === '-'
    )
    .sort(compareFn)

  //filter to date range if passed
  const zoomed = filter?.start
    ? onlyValid.filter(
        m =>
          moment(m.ts).isSameOrAfter(filter?.start) &&
          moment(m.ts).isSameOrBefore(filter?.end)
      )
    : onlyValid

  return {
    memoryUsage: zoomed
      .filter(m => m.metricType === METRICS.MEMORY_USAGE)
      .map(x => {
        return [x.ts.format(), x.metricValue / 100]
      }),
    cpuUsage: zoomed
      .filter(m => m.metricType === METRICS.CPU_USAGE)
      .map(x => {
        return [x.ts.format(), x.metricValue / 100]
      }),
    diskUsage: zoomed
      .filter(m => m.metricType === METRICS.DISK_USAGE)
      .map(x => {
        return [x.ts.format(), x.metricValue / 100]
      }),
    memoryCapacity: zoomed
      .filter(m => m.metricType === METRICS.MEMORY_USAGE)
      .map(x => {
        return [x.ts.format(), x.metricTotalValue]
      }),
    cpuCapacity: zoomed
      .filter(m => m.metricType === METRICS.CPU_USAGE)
      .map(x => {
        return [x.ts.format(), x.metricTotalValue]
      }),
    diskCapacity: zoomed
      .filter(m => m.metricType === METRICS.DISK_USAGE)
      .map(x => {
        return [x.ts.format(), x.metricTotalValue]
      }),
    memoryUsageMetricTotalValue: zoomed.filter(
      m => m.metricType === METRICS.MEMORY_USAGE
    )?.[0]?.metricTotalValue,
    cpuUsageMetricTotalValue: zoomed.filter(
      m => m.metricType === METRICS.CPU_USAGE
    )?.[0]?.metricTotalValue,
    diskUsageMetricTotalValue: zoomed.filter(
      m => m.metricType === METRICS.DISK_USAGE
    )?.[0]?.metricTotalValue,

    /* memoryUsageMetricTotalValue: zoomed
      .filter(m => m.metricType === 'MEMORY_USAGE')
      .map(x => {
        return [x.ts.format(),x.metricTotalValue]
      }),
    cpuUsageMetricTotalValue: zoomed
      .filter(m => m.metricType === 'CPU_USAGE')
      .map(x => {
        return [x.ts.format(),x.metricTotalValue]
      }),
    diskUsageMetricTotalValue: zoomed
      .filter(m => m.metricType === 'DISK_USAGE')
      .map(x => {
        return [x.ts.format(),x.metricTotalValue]
      }),*/
    start: moment.min(zoomed.map(ov => ov.ts)).format(),
    end: moment.max(zoomed.map(ov => ov.ts)).format()
  }
}

//nll transactions
export function formatNllTransactions(data) {
  if (!data) return []

  return data.transactions?.map(transaction => {
    const { created_at, ...rest } = transaction
    return {
      ...rest,
      created_at,
      created_at_local: formatDateTime(created_at)
    }
  })
}

export function formatEntitlementsForServer(data) {
  const results = []

  if (!data || !data.entitlements) return results

  data.entitlements
    // .filter(
    //   entitlement =>
    //     (entitlement.isCardinal && entitlement.unassignedQuantity > 0) ||
    //     !entitlement.isCardinal
    // )
    .map(entitlement =>
      entitlement.entitlementProductKeys.map(pk =>
        (pk.entitlementFeatures || [])
          .filter(
            feature =>
              (feature.isCardinal && feature.unassignedQuantity > 0) ||
              !feature.isCardinal
          )
          .map(feature => {
            const { featureName, featureVersion } = feature

            return results.push({
              ...feature,
              displayLabel: featureVersion
                ? `${featureName}-${featureVersion}`
                : featureName,
              emsEntitlementId: entitlement.emsEntitlementId,
              emsProductKeyId: pk.emsProductKeyId,
              sortValue: featureVersion
                ? `${featureName}-${featureVersion}`
                : featureName + pk.emsProductKeyId
            })
          })
      )
    )

  return {
    entitlements: results.sort((a, b) =>
      a.sortValue ? a.sortValue.localeCompare(b.sortValue) : -1
    )
  }
}

export function formatLeasesFlat(data) {
  if (!data || !data.clients) return null

  const allLeases = []
  data.clients.map(client => {
    const { leases, ...rest } = client

    return leases.map(lease => allLeases.push({ ...lease, ...rest }))
  })

  return { leases: allLeases, isBulkReleaseAllowed: data.isBulkReleaseAllowed }
}

const isFeatureUsedInAPool = (featId, licensePools = []) => {
  if (licensePools && licensePools.length) {
    return licensePools.some(lp =>
      lp?.licensePoolFeatures?.some(
        poolFeat => poolFeat.licenseServerFeatureId === featId
      )
    )
  } else {
    return false
  }
}

export function formatLicenseServerWithFeatures(data) {
  if (!data || !data.licenseServer) return null

  const {
    licenseServerFeatures,
    licensePools,
    fulfillmentConditions = [],
    ...rest
  } = data.licenseServer

  const boundPoolIds = []
  fulfillmentConditions?.forEach(c => {
    const { bindings } = c
    bindings.forEach(b => {
      const { licensePoolId } = b
      boundPoolIds.push(licensePoolId)
    })
  })
  const unbound = (licensePools || [])
    .filter(p => !boundPoolIds.includes(p.id))
    .map(p => p.id)

  return {
    ...rest,
    licenseServerFeatures: licenseServerFeatures.map(feature => {
      const {
        featureName,
        featureVersion,
        overageCount,
        inUseQuantity,
        id
      } = feature

      const displayLabel = featureVersion
        ? `${featureName}-${featureVersion}`
        : featureName

      return {
        ...feature,
        displayLabel,
        isOverage: overageCount > 0 ? 'overage' : '',
        isUnallocated:
          inUseQuantity > 0 || isFeatureUsedInAPool(id, licensePools)
            ? ''
            : 'unallocated'
      }
    }),
    licensePools: (licensePools || []).map(lp => {
      const { licensePoolFeatures, ...rest } = lp

      return {
        ...rest,
        isUnbound: unbound.includes(lp.id) ? 'True' : 'False',
        licensePoolFeatures: (licensePoolFeatures || []).map(pf => {
          const match = (licenseServerFeatures || []).find(
            lsf => lsf.id === pf.licenseServerFeatureId
          )
          const { featureName, featureVersion } = match
          const displayLabel = featureVersion
            ? `${featureName}-${featureVersion}`
            : featureName

          return {
            ...pf,
            feature: {
              ...match,
              displayLabel
            }
          }
        })
      }
    }),
    fulfillmentConditions: (fulfillmentConditions || []).map(fc => {
      const { bindings, ...rest } = fc

      return {
        ...rest,
        bindings: (bindings || []).map(boundPool => {
          const match = (licensePools || []).find(
            pool => pool.id === boundPool.licensePoolId
          )

          return {
            ...boundPool,
            id: boundPool.licensePoolId,
            pool: match
          }
        })
      }
    })
  }
}

export function formatServiceInstanceTokenConfig(data, serverId) {
  if (!data || !data.licenseAllocationList) return { scopes: [], classRefs: [] }

  const { licenseAllocationList } = data
  const classRefs = []
  const scopes = []
  const source = serverId
    ? licenseAllocationList.filter(lal => lal.id === serverId)
    : licenseAllocationList

  source.forEach(lal => {
    const {
      id,
      displayName,
      fulfillmentConditionList,
      scopeReferenceList
    } = lal

    scopeReferenceList.forEach(scopeRef => {
      scopes.push({
        id,
        displayName,
        value: scopeRef
      })
    })

    fulfillmentConditionList.forEach(fc => {
      const { fulfillmentClassReferenceList, id, displayName } = fc

      fulfillmentClassReferenceList.forEach(fcRef => {
        classRefs.push({
          id,
          //displayName: `${lal.displayName} / ${displayName}`,
          serverName: lal.displayName,
          displayName,
          value: fcRef
        })
      })
    })
  })

  return { scopes, classRefs }
}

export function formatEventsForList(data) {
  if (!data || !data.events) return null

  const { events, ...rest } = data

  return {
    ...rest,
    events: events.map(event => {
      const { metadata, detail, ...rest } = event

      return {
        ...rest,
        isCritical:
          metadata?.tag_list?.length &&
          metadata.tag_list.some(tag => !!tag['priority']) &&
          metadata.tag_list.filter(tag => tag['priority'])[0].priority ===
            'CRITICAL'
            ? 'CRITICAL'
            : '',
        detail,
        operation_id: detail?.operation_id || ''
      }
    })
  }
}

export function formatEventsExportGenStatus(data) {
  if (!data) return null
  const { state, ...rest } = data
  //normalize "triggered", "running" into "running" for the end user
  return {
    ...rest,
    state:
      state === EVENTS_EXPORT_STATUS.TRIGGERED
        ? EVENTS_EXPORT_STATUS.RUNNING
        : state
    /* //mock
    state: EVENTS_EXPORT_STATUS.FAILED*/
  }
}

//consts
export const SCOPE_NAMES = {
  SOFTWAREDOWNLOAD: 'Software Downloads',
  RETRIEVERFORALLRESOURCES: 'Licensing State',
  ENTERPRISE: 'Enterprise'
}
export const API_KEY_STATUS = {
  ENABLED: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  PENDING: 'ROTATED',
  REVOKED: 'REVOKED'
}
export const EMAIL_ALERT_TYPES = {
  API_KEY_EXPIRATION: 'API_KEY_EXPIRY',
  FEATURE_EXPIRATION: 'FEATURE_EXPIRY',
  LEASE_CONSUMPTION: 'LEASE_CONSUMPTION',
  FAILOVER: 'FAILOVER',
  SERVICE_STATE_MONITOR: 'SERVICE_STATE_MONITOR',
  SYSTEM_RESOURCE_MONITOR: 'SYSTEM_RESOURCE_MONITOR'
}
export const SMTP_SECURITY_TYPES = {
  FORCED: 'FORCED_SSL',
  OPPORTUNISTIC: 'OPPORTUNISTIC_SSL',
  NONE: 'NO_SSL'
}
export const USER_PERMISSIONS = {
  ORGANIZATION_CREATE: 'ORGANIZATION_CREATE',
  ORGANIZATION_RETRIEVE: 'ORGANIZATION_RETRIEVE',
  USER_CREATE: 'USER_CREATE',
  USER_RETRIEVE: 'USER_RETRIEVE',
  USER_DELETE: 'USER_DELETE',
  ENTITLEMENT_CREATE: 'ENTITLEMENT_CREATE',
  ENTITLEMENT_RETRIEVE: 'ENTITLEMENT_RETRIEVE',
  VIRTUAL_GROUP_CREATE: 'VIRTUAL_GROUP_CREATE',
  VIRTUAL_GROUP_RETRIEVE: 'VIRTUAL_GROUP_RETRIEVE',
  VIRTUAL_GROUP_UPDATE: 'VIRTUAL_GROUP_UPDATE',
  VIRTUAL_GROUP_DELETE: 'VIRTUAL_GROUP_DELETE',
  LICENSE_SERVER_CREATE: 'LICENSE_SERVER_CREATE',
  LICENSE_SERVER_RETRIEVE: 'LICENSE_SERVER_RETRIEVE',
  LICENSE_SERVER_UPDATE: 'LICENSE_SERVER_UPDATE',
  LICENSE_SERVER_DELETE: 'LICENSE_SERVER_DELETE',
  LICENSE_POOL_CREATE: 'LICENSE_POOL_CREATE',
  LICENSE_POOL_RETRIEVE: 'LICENSE_POOL_RETRIEVE',
  LICENSE_POOL_UPDATE: 'LICENSE_POOL_UPDATE',
  LICENSE_POOL_DELETE: 'LICENSE_POOL_DELETE',
  ACCESS_GROUP_CREATE: 'ACCESS_GROUP_CREATE',
  ACCESS_GROUP_RETRIEVE: 'ACCESS_GROUP_RETRIEVE',
  ACCESS_GROUP_UPDATE: 'ACCESS_GROUP_UPDATE',
  ACCESS_GROUP_DELETE: 'ACCESS_GROUP_DELETE',
  LICENSE_DOWNLOAD: 'LICENSE_DOWNLOAD',
  SOFTWARE_DOWNLOAD: 'SOFTWARE_DOWNLOAD'
}
export const SETTINGS_DATA_TYPES = {
  BOOLEAN: 'boolean',
  INTEGER: 'integer',
  TIMERANGE: 'iso8601duration'
}
export const SETTINGS_SCOPES = {
  LICENSE_SERVER: 'license_server',
  SERVICE_INSTANCE: 'service_instance'
}
export const FC_TYPES = {
  UNIVERSAL: 'Universal Match',
  REFERENCE: 'Reference Match'
}
export const SERVER_DEPLOY_STATUS = {
  DLS: 'ON_PREM',
  CLOUD: 'CLOUD',
  NA: 'NOT_APPLICABLE',
  NOT_DEPLOYED: 'NOT_DEPLOYED'
}
export const SI_STATES = {
  REGISTERED: 'setup_registered',
  PENDING: 'setup_uninitialized'
}
export const SI_TYPES = {
  CLOUD: '83cc294f-671f-4823-8c94-0d95f9b01904',
  ONPREM: 'c92a973e-424a-463a-b48f-24b9f1441272'
}
export const SI_STATE_DISPLAY_NAME = {
  setup_registered: 'Registered',
  setup_uninitialized: 'Registration pending',
  running_waiting_for_license_allotment: 'Registered, waiting for server',
  running_serving: 'Running, serving licenses',
  running_not_serving: 'Running, not serving licenses'
}
export const HIGH_AVAILABILITY_STATE = {
  UNCONFIGURED: 'UNCONFIGURED',
  STANDALONE: 'STANDALONE',
  CLUSTERED: 'HA',
  IN_PROGRESS: 'HA_IN_PROGRESS'
}
export const HIGH_AVAILABILITY_STATE_DISPLAY_NAME = {
  UNCONFIGURED: 'Unconfigured',
  STANDALONE: 'Standalone',
  HA: 'Clustered',
  HA_IN_PROGRESS: 'In Progress'
}
export const HIGH_AVAILABILITY_NODE_ROLE = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  UNKNOWN: 'Unknown'
}

export const NODE_SERVICE_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}
export const HIGH_AVAILABILITY_STATUS = {
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED'
}

//NODE ROLE
export const NODE_ROLE = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  UNKNOWN: 'Unknown'
}
//DLS UPGRADE
export const UPGRADE_MODE = {
  OFFLINE: 'offline',
  INPLACE: 'inplace'
}
export const UPGRADE_STATUS = {
  INITIATED: 'initiated',
  IN_PROGRESS: 'in_progress',
  FAILED: 'failed',
  COMPLETED: 'completed'
}
export const UPGRADE_STATUS_DISPLAY = {
  initiated: 'Upgrade initiated',
  in_progress: 'Upgrade is in progress',
  failed: 'Last upgrade attempt failed, please try again later.',
  completed: 'Upgrade has completed successfully'
}
//eo DLS UPGRADE

export const MIGRATION_STEP = {
  validated: 1,
  data_transfer_initiated: 2,
  data_transfer_completed: 3,
  completed: 4,
  failed: 4
}
export const UPGRADE_FILE_INSTALL_STATUS = {
  STARTED: 'data_transfer_initiated',
  COMPLETED: 'completed',
  FAILED: 'failed'
}
export const MIGRATION_FILE_GEN_STATUS = {
  INITIATED: 'initiated',
  COMPLETED: 'completed',
  FAILED: 'failed'
}

//LEGACY - online migration status
export const ONLINE_MIGRATION_STATUS = {
  VALIDATED: 'validated',
  DATA_TRANSFER_INITIATED: 'data_transfer_initiated',
  DATA_TRANSFER_COMPLETED: 'data_transfer_completed',
  FAILED: 'failed',
  COMPLETED: 'completed'
}
export const ONLINE_MIGRATION_STATUS_DISPLAY = {
  validated: 'Validating data to be transferred',
  data_transfer_initiated: 'Transferring data to the new appliance',
  data_transfer_completed: 'Data transferred successfully',
  completed: 'Virtual appliance upgrade has completed successfully',
  failed: 'Last migration attempt failed, please try again later.'
}

//normalize "triggered", "running" into "running" for the end user
export const EVENTS_EXPORT_STATUS = {
  TRIGGERED: 'TRIGGERED',
  RUNNING: 'RUNNING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  ARCHIVED: 'ARCHIVED'
}

export const IP_ADDRESS_TYPE = {
  IPV4: 'IPV4',
  IPV6: 'IPV6'
}
export const IpHostnameRegex = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/
export const DomainNameRegex = /(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/
//update these to objects
export const LS_FEATURE_STATUS_EXPIRING = 'ImpendingExpiry'
export const LS_FEATURE_STATUS_EXPIRED = 'Expired'
export const LS_FEATURE_STATUS_ACTIVE = 'Active'
export const LS_FEATURE_STATUS_FUTURE_START = 'FutureStart'
export const LS_STATUS_ENABLED = 'ENABLED'
export const LS_STATUS_DISABLED = 'DISABLED'
export const LS_STATUS_OPERATION_ENABLE = 'ENABLE'
export const LS_STATUS_OPERATION_DISABLE = 'DISABLE'
export const LS_TYPE_NVIDIA = 'NVIDIA'
export const LS_TYPE_FLEXERA = 'FLEXERA'

export const LS_LEASING_MODES = {
  Standard: {
    label: 'Standard Networked Licensing',
    value: 'STANDARD'
  },
  Advanced: {
    label: 'Advanced Networked Licensing',
    value: 'ADVANCE'
  },
  Node_Locked: {
    label: 'Node-Locked Licensing',
    value: 'NLL'
  }
}
