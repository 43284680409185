import React from 'react'
import Result from '@kaizen-ui/result'

export const ServerErrorPage = ({ error }) => {
  return (
    <div style={{ marginTop: '16px' }}>
      <Result
        status={'error'}
        title={'An unexpected error occured'}
        subTitle={
          'Try reloading the page. In case the issue persist, contact NVIDIA Enterprise Support for further assistance.'
        }
        actions={[
          {
            children: 'Reload Page',
            onClick: () => {
              window.location.reload()
            },
            key: 0
            /* variant: 'link',*/
            /* type: 'primary'*/
          },
          {
            children: 'Contact NVIDIA Enterprise Support',
            onClick: () => {
              window.location.href =
                'https://www.nvidia.com/en-us/support/enterprise/'
            },
            key: 1
            /* variant: 'link',*/
            /* type: 'secondary'*/
          }
        ]}
      >
        {error ? (
          <div style={{ textAlign: 'center' }}>
            <label>Error : </label>
            {error}
          </div>
        ) : null}
      </Result>
    </div>
  )
}
