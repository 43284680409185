"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FeatureExpiration", {
  enumerable: true,
  get: function get() {
    return _FeatureExpiration.FeatureExpiration;
  }
});

var _FeatureExpiration = require("./FeatureExpiration");