import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Tabs, { Tab } from '@kaizen-ui/tabs'
import Button from '@kaizen-ui/button'
import Spinner from '@kaizen-ui/spinner'
import { ModalButtons, useAppNotifications, useFetch } from 'common'
import { ModifyServerFeatures } from './ModifyServerFeatures'
import { PreviewManageFeatures } from './PreviewManageFeatures'
import { API_LICENSE_SERVER_RETURN_FEATURES } from '../../api'
import { SessionExpired } from '../SessionExpired'
import { useGlobal } from 'reactn'
import {
  GLOBAL_ORG,
  GLOBAL_PENDING_RETURNS,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'

const StyledTabs = styled.div`
  overflow: auto;
  margin: 0.5rem;

  .content {
    display: block;
    padding: 1rem 0.5rem 0 0;
  }
`

const validationSchema = Yup.object().shape(
  {
    entitlements: Yup.array().when(['removals', 'adjustments'], {
      is: (removals, adjustments) =>
        (removals && removals.length) || (adjustments && adjustments.length),
      then: Yup.array(),
      otherwise: Yup.array().min(
        1,
        'You must remove or decrease at least 1 feature'
      )
    }),
    removals: Yup.array().when(['entitlements', 'adjustments'], {
      is: (entitlements, adjustments) =>
        (entitlements && entitlements.length) ||
        (adjustments && adjustments.length),
      then: Yup.array(),
      otherwise: Yup.array().min(
        1,
        'You must remove or decrease at least 1 feature'
      )
    }),
    adjustments: Yup.array()
  },
  [
    ['entitlements', 'removals'],
    ['entitlements', 'adjustments'],
    ['removals', 'adjustments']
  ]
)
const initialValues = {
  entitlements: [],
  removals: [],
  adjustments: []
}

export const ManageFeatures = ({ server, onUpdate }) => {
  const { notify } = useAppNotifications()
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const [, setHasPending] = useGlobal(GLOBAL_PENDING_RETURNS)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id

  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_LICENSE_SERVER_RETURN_FEATURES(orgId, vgId, server.id),
    method: 'PATCH',
    actionLabel: 'Update license server details',
    SessionExpired: SessionExpired
  })

  const submit = async values => {
    const { adjustments, removals } = values
    const featureCountsToReturn = adjustments?.length
      ? adjustments
          .filter(adj => !adj.isAddCount)
          .map(feat => ({
            featureId: feat.id,
            count: feat.totalQuantity - feat.addCount
          }))
      : []

    const featuresToReturn = removals?.length
      ? removals.map(ad => ({
          featureId: ad.id
        }))
      : []

    const body = {
      featureCountsToReturn,
      featuresToReturn
    }

    const result = await sendRequest({ body })
    if (result) {
      const msg = `License server ${server.name} was successfully updated!`
      notify(msg, null, null, 'Updated license server')
      setHasPending(true)
    }

    onUpdate && onUpdate()
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: submit
  })
  if (submitting)
    return (
      <span style={{ margin: '0 0.5rem' }}>
        <Spinner />
      </span>
    )

  return (
    <>
      <StyledTabs>
        <Tabs>
          <Tab id={1} title='Modify existing'>
            <ModifyServerFeatures server={server} formik={formik} />
          </Tab>

          <Tab id={3} title='Preview changes' disabled={!formik.dirty}>
            <PreviewManageFeatures formik={formik} />
          </Tab>
        </Tabs>
      </StyledTabs>
      <ModalButtons>
        <Button onClick={formik.handleSubmit}>Update Server Features</Button>
      </ModalButtons>
    </>
  )
}
