import React, { useGlobal } from 'reactn'
import * as Yup from 'yup'
import styled from 'styled-components'
import Textbox from '@kaizen-ui/textbox'
import Button from '@kaizen-ui/button'
import Spinner from '@kaizen-ui/spinner'
import { useFormik } from 'formik'
import { useFetch, useAppNotifications } from 'common'
import { GLOBAL_SERVICE_INSTANCE_ID } from '../../globalState'
import { API_SERVICE_INSTANCES } from '../../api'

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 0.5rem;
  }
`

const nameRegExp = /^[a-zA-Z]/
const allowedChars = /^[-_A-Za-z0-9\s]+$/

const validationSchema = Yup.object({
  name: Yup.string()
    .min(4, 'Must have more than 4 characters')
    .matches(nameRegExp, 'Must start with an alphabet')
    .max(32, 'Must have fewer than 32 characters')
    .matches(allowedChars, 'Cannot include special characters')
    .required(),
  description: Yup.string()
    .max(250, 'Must have fewer than 250 characters')
    .required()
})

export const EditServiceInstance = ({ serviceInstance, onUpdate }) => {
  const { notify } = useAppNotifications()
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)

  const initialValues = {
    name: serviceInstance ? serviceInstance.name : '',
    description: serviceInstance ? serviceInstance.description : ''
  }

  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_SERVICE_INSTANCES,
    actionLabel: 'Edit service instance',
    method: 'PATCH'
  })
  const submit = async values => {
    const { name, description } = values

    const body = {
      name,
      description
    }
    const headers = { 'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId }
    const result = await sendRequest({ body, headers })
    if (result) {
      const msg = `Service Instance ${name} updated successfully!`
      notify(msg, null, null, 'Edited service instance')
      onUpdate && onUpdate()
    }
  }
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: submit
  })

  return (
    <div>
      <Textbox
        id='name'
        name='name'
        label='Name'
        className='nvl'
        placeholder='Enter a name for this service instance'
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={formik.touched.name && formik.errors.name}
      />
      <Textbox
        id='description'
        name='description'
        label='Description'
        inputType='multiLine'
        className='nvl'
        placeholder='Enter a description for this service instance'
        value={formik.values.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={
          formik.touched.description && formik.errors.description
        }
      />
      <Buttons>
        {submitting && (
          <span style={{ marginRight: '0.5rem' }}>
            <Spinner size='tiny' />
          </span>
        )}
        <Button
          icon={{ name: 'CustomNgcServer' }}
          onClick={formik.handleSubmit}
          disabled={submitting}
        >
          Edit Service Instance
        </Button>
      </Buttons>
    </div>
  )
}
