"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.colorizeExpirationDate = colorizeExpirationDate;
exports.formatDate = formatDate;
exports.formatDateTime = formatDateTime;
exports.formatExpirationDate = formatExpirationDate;
exports.formatLoginDate = formatLoginDate;
exports.toDaysMinutesSeconds = toDaysMinutesSeconds;

var _moment = _interopRequireDefault(require("moment"));

function formatExpirationDate(dateUTC) {
  if (!dateUTC) return '';
  if (dateUTC === '3000-12-31' || dateUTC === '3000-12-30') return 'never expires';
  if ((0, _moment["default"])(dateUTC).year() === 3000) return 'never expires';
  return (0, _moment["default"])(dateUTC, 'YYYY-MM-DD hh:mm').format('ll');
}

function formatLoginDate(dateUTC) {
  if (!dateUTC) return 'Never';
  return _moment["default"].utc(dateUTC, 'YYYY-MM-DD hh:mm').local().format('lll');
}

function formatDate(dateUTC) {
  if (!dateUTC) return '';
  return (0, _moment["default"])(dateUTC, 'YYYY-MM-DD hh:mm').format('ll');
}

function formatDateTime(dateUTC) {
  if (!dateUTC) return '';
  return _moment["default"].utc(dateUTC, 'YYYY-MM-DD hh:mm').local().format('lll');
}

function colorizeExpirationDate(date) {
  if (formatExpirationDate(date) === 'never expires') return '#c2c2c2';
  return (0, _moment["default"])(date).isBefore(new Date(), 'day') ? 'rgb(196, 0, 25)' : '#000';
}

function toDaysMinutesSeconds(totalSeconds) {
  var seconds = Math.floor(totalSeconds % 60);
  var minutes = Math.floor(totalSeconds % 3600 / 60);
  var hours = Math.floor(totalSeconds % (3600 * 24) / 3600);
  var days = Math.floor(totalSeconds / (3600 * 24));
  var secondsStr = makeHumanReadable(seconds, 'second');
  var minutesStr = makeHumanReadable(minutes, 'minute');
  var hoursStr = makeHumanReadable(hours, 'hour');
  var daysStr = makeHumanReadable(days, 'day');
  return "".concat(daysStr).concat(hoursStr).concat(minutesStr).concat(secondsStr).replace(/,\s*$/, '');
}

function makeHumanReadable(num, singular) {
  return num > 0 ? num + (num === 1 ? " ".concat(singular, ", ") : " ".concat(singular, "s, ")) : '';
}