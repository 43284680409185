import React, { useGlobal } from 'reactn'
import { useFetch, useAppNotifications, StatusToggle } from 'common'
import { API_FULFILLMENT_CONDITION, LS_STATUS_DISABLED } from '../../api'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'

export const FulfillmentConditionStatus = ({
  server = {},
  onUpdate,
  fulfillmentCondition
}) => {
  const { notify } = useAppNotifications()
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const { id: serverId, name: serverName } = server
  const { status, id, name } = fulfillmentCondition
  const isEnabling = status === LS_STATUS_DISABLED

  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_FULFILLMENT_CONDITION(orgId, vgId, serverId, id),
    actionLabel: `${
      isEnabling ? 'Enable' : 'Disable'
    } fulfillment condition on server ${serverName}`,
    method: 'PATCH',
    responseOnly: true
  })
  const submit = async () => {
    const body = {
      enabled: isEnabling
    }

    const result = await sendRequest({ body })
    if (result) {
      const msg = `Fulfillment condition ${name} was successfully ${
        isEnabling ? 'enabled' : 'disabled'
      } on server ${serverName}!`
      notify(
        msg,
        null,
        null,
        `${isEnabling ? 'Enabled' : 'Disabled'} fulfillment condtion`
      )
      onUpdate && onUpdate()
    }
  }

  return (
    <StatusToggle
      submitting={submitting}
      onSubmit={submit}
      isEnabling={isEnabling}
      label='fulfillment condition'
    />
  )
}
