import React, {
  useState,
  useGlobal,
  useContext,
  useEffect,
  useMemo,
  useCallback
} from 'reactn'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Tabs, { Tab } from '@kaizen-ui/tabs'
import Block from '@kaizen-ui/block'
import Button, { ButtonGroup } from '@kaizen-ui/button'
import queryString from 'query-string'
import {
  GLOBAL_ORG,
  GLOBAL_SERVER_ID,
  GLOBAL_VA_VERSION,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'
import {
  useFetch,
  PageHeader,
  FloatingSpinner,
  Table,
  formatDateTime,
  SelectColumnFilter,
  ContextNotifications
  //NullMacCheck
} from 'common'
import {
  LeaseActions,
  LicenseServerActions,
  ROUTE_DASHBOARD,
  SessionExpired
} from '../../Components'
import {
  API_LEASES,
  API_LICENSE_SERVER,
  API_LOCKED_LEASES,
  //API_UNHEALTHY_CLIENTS,
  DOCS_LICENSE_SERVER_MANAGE,
  DOCS_LICENSE_SERVER_MANAGE_CONDITIONS,
  DOCS_LICENSE_SERVER_MANAGE_LEASES,
  DOCS_LICENSE_SERVER_MANAGE_NLL,
  DOCS_LICENSE_SERVER_MANAGE_POOLS,
  formatLeasesFlat,
  formatLicenseServerWithFeatures,
  formatNllTransactions,
  LS_LEASING_MODES,
  LS_STATUS_ENABLED,
  LS_TYPE_FLEXERA,
  LS_TYPE_NVIDIA
} from '../../api'
import ReactTooltip from 'react-tooltip'
import { NvidiaProperties } from './NvidiaProperties'
import { FlexeraProperties } from './FlexeraProperties'
import { ServerFeatures } from './ServerFeatures'
import { LicensePools } from './LicensePools'
import { FullfillmentConditions } from './FulfillmentConditions'
import { LicenseServerOverview } from './LicenseServerOverview'
import { Icon, Text } from '@kaizen-ui/complete'
import { LeaseActionsNLL } from '../../Components'

const StyledTabs = styled.div`
  //   overflow: hidden;
  //   display: flex;
  //   flex-direction: column;
  //   min-height: 0;
  margin: 0.5rem;

  button:empty {
    display: none;
  }

  .content {
    //display: flex;
    //flex-direction: column;
    //min-height: 0;
    //overflow: hidden;
    //flex: 1;
    padding: 1rem 0 0 0;

    > div {
      //   overflow: hidden;
      //   display: flex;
      //   flex-direction: column;
      //   min-height: 0;
      width: 100%;
    }
  }
`
const StyledBlock = styled.div`
  > div[elevation] {
    overflow: auto;

    > div[title] {
      :hover {
        > span {
          color: ${({ theme }) =>
            theme.darkMode
              ? theme.colors.lightGray000
              : theme.colors.lightGray500};
        }
      }
    }

    button {
      z-index: 0;
    }
  }
`
const StyledHeader = styled.span`
  display: flex;
  white-space: normal;
  align-items: center;

  > span {
    margin-left: 0.5rem;
  }
`
export const LicenseServerDetails = ({ serviceInstance }) => {
  const history = useHistory()
  const [serverId] = useGlobal(GLOBAL_SERVER_ID)
  const [version] = useGlobal(GLOBAL_VA_VERSION)
  const [refresh, setRefresh] = useState(false)
  const [refreshLeases, setRefreshLeases] = useState(false)
  const [refreshNllLeases, setRefreshNllLeases] = useState(false)
  const theme = useContext(KaizenThemeContext)
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const [propsCollapsed, setPropsCollapsed] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [server, setServer] = useState({})
  const [leases, setLeases] = useState([])
  const [nllLeases, setNllLeases] = useState([])
  const { getData, abort, loading, lastUpdate } = useFetch({
    endpoint: API_LICENSE_SERVER(orgId, vgId, serverId),
    actionLabel: 'Get license server details',
    SessionExpired: SessionExpired,
    normalizer: formatLicenseServerWithFeatures
  })
  //const { tab, search } = useParams()
  const { tab, search } = queryString.parse(window.location.search)
  const tabMax = 5
  const tabMin = 1
  const tabNumber = Number(tab)
  const [selectedTab, setSelectedTab] = useState(
    tabNumber <= tabMax && tabNumber >= tabMin ? tabNumber : tabMin
  )
  //const [filter, setFilter] = useState(search)
  const { type, name, status, serviceInstanceId, leasingMode } = server

  const isFlex = type === LS_TYPE_FLEXERA
  const isNvidia = type === LS_TYPE_NVIDIA
  const isEnabled = status === LS_STATUS_ENABLED

  const helpRef1 = React.useRef(null)
  const helpRefTabs = React.useRef(null)
  const [step, setStep] = React.useState(-1)
  const help = [
    {
      title: 'Managing Licenses and Licensed Products on a License Server',
      text: `You can add or remove individual licenses for a specific product on the license server in Actions - Manage Features.`,
      ref: helpRef1,
      offsetX: -250,
      offsetY: 40
    },
    ...(server?.leasingMode !== LS_LEASING_MODES.Standard.value
      ? [
          {
            title: 'Managing License Pools',
            text: `You can subdivide the licenses on license server into any number of license pools. However, if you want to serve all licenses on 
      a server from a single pool, you can use the default license pool without creating any additional license pools. To make changes to license pools, 
      the server/pool must be disabled. This can be performed in Actions - Disable.`,
            ref: helpRefTabs,
            link: DOCS_LICENSE_SERVER_MANAGE_POOLS(version),
            linkText: 'More about license pools',
            offsetY: 40,
            offsetX: 50
          },
          {
            title: 'Managing Fulfillment Conditions',
            text: `A fulfillment condition selects the license pools from which a license requested by a licensed client is served. It is a test 
      that is applied to any request from a licensed client to determine if the request may be fulfilled from a specified set of license pools. To make changes to 
      the fulfillment conditions, the server/fulfillment condition must be disabled. This can be performed in Actions - Disable.`,
            ref: helpRefTabs,
            link: DOCS_LICENSE_SERVER_MANAGE_CONDITIONS(version),
            linkText: 'More about fulfillment conditions',
            offsetX: 150,
            offsetY: 40
          }
        ]
      : []),
    {
      title: 'Managing Leases',
      text: `A leases represents a feature that was successfully requested from a client. In the example where a License Client VM has been 
      un-gracefully stopped and deleted from existence, the license will remain in-use on the server and will not be freed until the lease has reached 
      expiration. You can forcibly release a lease to make that resource available again in the table on the Leases tab`,
      ref: helpRefTabs,
      link: DOCS_LICENSE_SERVER_MANAGE_LEASES(version),
      linkText: 'More about leases',
      offsetX: 250,
      offsetY: 40
    },
    {
      title: 'Generating Node-Locked Licenses',
      text: `In Actions - Generate Node-Locked Licensing Lease, provide the MAC address of the client(s), and from the Available features drop-down list, 
      select each product for which you want to generate a license.`,
      ref: helpRef1,
      link: DOCS_LICENSE_SERVER_MANAGE_NLL(version),
      linkText: 'More about node-locked licensing',
      offsetX: -250,
      offsetY: 40
    },
    {
      title:
        'Returning Licenses from a License Server on a DLS Instance to the NVIDIA Licensing Portal',
      text: `After removing individual licenses or licensed products from a license server installed on a DLS instance, you must return 
      them to the entitlement on the NVIDIA Licensing Portal. Returning them ensures that the licenses and products on your license server and on the 
      NVIDIA Licensing Portal are consistent. The returned licenses and products are then available for use by other license servers. You can generate the
       feature return file on the Maintenance page.`,
      ref: helpRef1,
      link: DOCS_LICENSE_SERVER_MANAGE(version),
      offsetX: -250,
      offsetY: 40
    }
  ]

  const {
    getData: getLeaseData,
    abort: abortLeases,
    error: leaseError,
    loading: loadingLeases,
    lastUpdate: lastUpdateLeases
  } = useFetch({
    endpoint: API_LEASES(orgId, vgId),
    actionLabel: `Get leases for server ${name}`,
    SessionExpired: SessionExpired,
    normalizer: formatLeasesFlat
  })
  const {
    getData: getNllLeaseData,
    abort: abortNllLeases,
    error: leaseNllError,
    loading: loadingNllLeases,
    lastUpdate: lastUpdateNllLeases
  } = useFetch({
    endpoint: API_LOCKED_LEASES(orgId, vgId),
    actionLabel: `Get Node-Locked Licensing leases for server ${name}`,
    SessionExpired: SessionExpired,
    normalizer: formatNllTransactions
  })

  const leaseColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'leaseId'
      },
      {
        Header: 'Feature Name (PKID)',
        accessor: 'featureName',
        Cell: ({ row }) => {
          const { featureName, emsProductKeyId } = row.original
          return (
            <div>
              <div>{featureName}</div>
              <small>{emsProductKeyId}</small>
            </div>
          )
        },
        Filter: SelectColumnFilter
      },
      {
        Header: 'Issued On',
        accessor: 'leaseBegin',
        Cell: ({ row }) => <Text>{formatDateTime(row.values.leaseBegin)}</Text>,
        disableFilters: true
      },
      {
        Header: 'Expires',
        accessor: 'leaseExpires',
        Cell: ({ row }) => (
          <Text>
            {row.values.expireAt === 'never'
              ? row.values.expireAt
              : formatDateTime(row.values.leaseExpires)}
          </Text>
        ),
        disableFilters: true
      },
      { Header: 'VGPU ID', accessor: 'gpu_id', hideOnLoad: true },
      {
        Header: () => (
          <StyledHeader>
            Client Origin Ref{' '}
            <span data-tip='Unique token to identify a licensed client'>
              <Icon name='StatusCircleInformation' />
            </span>
          </StyledHeader>
        ),
        label: 'Client Origin Ref',
        accessor: 'originRef'
      },
      {
        Header: 'Client Hostname',
        accessor: 'hostname',
        Cell: ({ row }) => {
          const { hostname } = row.original
          return hostname ? hostname : 'unavailable'
        }
      },
      {
        Header: 'Client MAC Addresses',
        accessor: 'macAddresses',
        Cell: ({ row }) => {
          const { macAddresses } = row.original

          return macAddresses && macAddresses.length
            ? macAddresses.join(', ')
            : 'unavailable'
        }
      },
      {
        Header: 'Client IP Addresses',
        accessor: 'ipAddresses',
        Cell: ({ row }) => {
          const { ipAddresses } = row.original

          return ipAddresses && ipAddresses.length
            ? ipAddresses.join(', ')
            : 'unavailable'
        }
      }
    ],
    []
  )

  const nllLeaseColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id'
      },
      {
        Header: 'Created',
        accessor: 'created_at_local'
      }
    ],
    []
  )

  useEffect(() => {
    const getServer = async () => {
      const data = await getData()

      if (data) {
        setServer(data)
        setPageLoading(false)
      } else {
        history.goBack()
      }
    }
    getServer()

    return () => {
      abort()
    }
  }, [vgId, refresh]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //if (!server.id) return
    //if (server.deployedOn !== SERVER_DEPLOY_STATUS.CLOUD) return

    if (
      !serviceInstanceId ||
      !serverId ||
      leasingMode === LS_LEASING_MODES.Node_Locked.value
    )
      return

    const instanceHeader = serviceInstanceId
      ? {
          'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId
        }
      : {}
    const getLeases = async () => {
      const data = await getLeaseData({
        headers: instanceHeader,
        parameters: { license_server_ids: [serverId] }
      })

      if (data) {
        setLeases(data.leases)
      } else {
        setLeases([])
      }
    }
    getLeases()

    return () => {
      abortLeases()
    }
  }, [serverId, refresh, refreshLeases, leasingMode, serviceInstanceId]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !serviceInstanceId ||
      !serverId ||
      leasingMode !== LS_LEASING_MODES.Node_Locked.value
    )
      return

    const instanceHeader = serviceInstanceId
      ? {
          'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId
        }
      : {}
    const getNllLeases = async () => {
      const data = await getNllLeaseData({
        headers: instanceHeader,
        parameters: { license_server_ids: [serverId] }
      })
      if (data) {
        setNllLeases(data)
      } else {
        setNllLeases([])
      }
    }
    getNllLeases()

    return () => {
      abortNllLeases()
    }
  }, [serverId, refreshNllLeases, refresh, leasingMode, serviceInstanceId]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  React.useEffect(() => {
    document.title = 'DLS - Server Details'
  }, [])

  useEffect(() => {
    setSelectedTab(
      tabNumber <= tabMax && tabNumber >= tabMin ? tabNumber : tabMin
    )
  }, [history.location]) //eslint-disable-line react-hooks/exhaustive-deps

  const rowActions = useCallback((row, related) => {
    const { serviceInstanceId } = related

    return (
      <LeaseActions
        lease={row.original}
        onUpdate={() => setRefresh(val => !val)}
        serviceInstanceId={serviceInstanceId}
      />
    )
  }, [])

  const rowActionsNLL = useCallback(
    row => {
      return (
        <LeaseActionsNLL
          lease={row.original}
          onUpdate={() => setRefresh(val => !val)}
          server={server}
        />
      )
    },
    [server]
  )

  const nllFileView = useCallback(
    ({ row }) => {
      const files = row.original.nll_lic_files

      return files.map(file => {
        return (
          <div key={file.id} style={{ margin: '0.5rem' }}>
            <Block
            //titleIcon={{ name: 'FileBase', color: '#76b900' }}
            //title={file.name}
            //collapsible
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name='FileBase' color='#76b900' size='large' />
                <span style={{ marginLeft: '0.5rem' }}>{file.name}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  color: theme.colors.textbox.placeholder
                }}
              >
                <div>
                  {file.platform_evidence?.map((pe, index) => {
                    const { id, val } = pe
                    return (
                      <div key={index} style={{ margin: '0.25rem' }}>
                        <Text textStyle='label'>{id}: </Text>
                        <Text textStyle='code'>{val}</Text>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div>
                {file.leases?.map(l => {
                  const {
                    lease_ref,
                    product_name,
                    feature_version,
                    lease_expire
                  } = l
                  return (
                    <div key={lease_ref} style={{ margin: '0.25rem' }}>
                      {product_name}-{feature_version} {'-'} lease expires:{' '}
                      {formatDateTime(lease_expire)}
                    </div>
                  )
                })}
              </div>
            </Block>
          </div>
        )
      })
    },
    [theme]
  )

  if (pageLoading) return <FloatingSpinner visible />

  return (
    <>
      <ContextNotifications list={help} step={step} setStep={setStep} />
      <FloatingSpinner visible={loading} />
      <PageHeader
        title='License Server Details'
        //helpUrl={DOCS_ROOT(version)}
        showHelp={() => setStep(0)}
        tip='Get help with license servers'
        subTitle={'View details and manage the installed license server'}
      >
        <div ref={helpRef1}>
          <ButtonGroup>
            <Button
              type='secondary'
              onClick={() => setRefresh(val => !val)}
              icon={{ name: 'CloudRefresh' }}
            >
              Refresh
            </Button>
            <LicenseServerActions
              detailed
              server={server}
              serviceInstance={serviceInstance}
              onUpdate={() => setRefresh(val => !val)}
              isOnLSDetailsPage
            />
          </ButtonGroup>
        </div>
      </PageHeader>
      {/* <NullMacCheck
        alertVisible={server?.hasInvalidClient}
        serviceInstanceName={server?.serviceInstanceName}
        serviceInstanceId={server?.serviceInstanceId}
        sessionExpired={SessionExpired}
        apiEndpoint={API_UNHEALTHY_CLIENTS}
      /> */}
      <StyledBlock theme={theme}>
        <Block
          collapsible
          collapsed={propsCollapsed}
          onToggle={() => setPropsCollapsed(val => !val)}
          title={isNvidia ? `${name} is ${status}` : name}
          titleIcon={{
            name: 'ConnectionServer',
            color: isEnabled ? theme.colors.brand : theme.colors.red500,
            size: 36
          }}
        >
          <>
            {isNvidia && <NvidiaProperties server={server} />}
            {isFlex && <FlexeraProperties server={server} />}
          </>
        </Block>
      </StyledBlock>

      {/* {isNvidia && !isEnabled && (
        <div style={{ margin: '1rem -1rem' }}>
          <Banner status='critical' elevation='lowest'>
            {name} is currently in edit mode. No feature licenses will be
            served, but you can make changes to the allocated features, pools,
            conditions
          </Banner>
        </div>
      )} */}
      {isNvidia && (
        <StyledTabs ref={helpRefTabs}>
          <Tabs
            onTabChange={val => {
              setSelectedTab(val)
              history.replace(`${ROUTE_DASHBOARD}?tab=${val}`)
            }}
            selectedTabId={selectedTab}
          >
            <Tab id={1} title='Overview'>
              <LicenseServerOverview
                server={server}
                onTabChange={setSelectedTab}
                onUpdate={() => setRefresh(val => !val)}
              />
            </Tab>
            <Tab id={2} title='Server Features'>
              <div>
                <ServerFeatures
                  server={server}
                  //onFilter={setFilter}
                  search={search}
                  refresh={() => setRefresh(val => !val)}
                  lastUpdate={lastUpdate}
                />
              </div>
            </Tab>
            <Tab
              id={3}
              title={
                server?.leasingMode !== LS_LEASING_MODES.Standard.value
                  ? 'License Pools'
                  : ''
              }
            >
              <div>
                <LicensePools
                  server={server}
                  //onFilter={setFilter}
                  search={search}
                  refresh={() => setRefresh(val => !val)}
                  lastUpdate={lastUpdate}
                />
              </div>
            </Tab>
            <Tab
              id={4}
              title={
                server?.leasingMode !== LS_LEASING_MODES.Standard.value
                  ? 'Fulfillment Conditions'
                  : ''
              }
            >
              <FullfillmentConditions
                server={server}
                //onFilter={setFilter}
                search={search}
                refresh={() => setRefresh(val => !val)}
                lastUpdate={lastUpdate}
                onTabChange={setSelectedTab}
              />
            </Tab>
            <Tab id={5} title='Leases'>
              {leasingMode === LS_LEASING_MODES.Node_Locked.value ? (
                <Table
                  columns={nllLeaseColumns}
                  data={nllLeases}
                  fetching={loadingNllLeases}
                  loading={pageLoading}
                  error={leaseNllError}
                  label={'lease files'}
                  refresh={() => setRefreshNllLeases(val => !val)}
                  lastUpdate={lastUpdateNllLeases}
                  renderRowSubComponent={nllFileView}
                  relatedData={{ serviceInstanceId }}
                  globalSearch={search}
                  rowActions={rowActionsNLL}
                />
              ) : (
                <Table
                  dataId='leaseId'
                  columns={leaseColumns}
                  data={leases}
                  fetching={loadingLeases}
                  loading={pageLoading}
                  error={leaseError}
                  label={'leases'}
                  refresh={() => setRefreshLeases(val => !val)}
                  lastUpdate={lastUpdateLeases}
                  rowActions={rowActions}
                  //onFilter={setFilter}
                  relatedData={{ serviceInstanceId }}
                  globalSearch={search}
                />
              )}
            </Tab>
          </Tabs>
        </StyledTabs>
      )}
    </>
  )
}
