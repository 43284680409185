import React, { useContext, useState, useMemo, useGlobal } from 'reactn'
import ReactTooltip from 'react-tooltip'
import { Pill, toDaysMinutesSeconds } from 'common'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Icon from '@kaizen-ui/icon'
import Button from '@kaizen-ui/button'
import Modal from '@kaizen-ui/modal'
import {
  HIGH_AVAILABILITY_NODE_ROLE,
  NODE_ROLE,
  NODE_SERVICE_STATUS
} from '../../../api'
import { RestartNodeService } from './RestartNodeService'
import { Banner, Text } from '@kaizen-ui/complete'
import { AssignStaticIP } from './AssignStaticIP'
import { RemoveNode } from './RemoveNode'
import { GLOBAL_NODE_ROLE } from '../../../globalState'

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 1rem;

  svg {
    margin-right: 0.5rem;
  }
  .kaizen-button {
    padding: 0 0 0 0.5rem;
  }
`
const Capitalize = styled.div`
  text-transform: capitalize;
`

export const NodeDetails = ({
  ind,
  showInd,
  node,
  isPrimary,
  isClustered,
  isPureContainer,
  isCurrentlyLoggedInHere,
  atleastOneNodeDown,
  existingNodeAddresses,
  onUpdate,
  restartRef
}) => {
  const theme = useContext(KaizenThemeContext)
  const {
    role,
    fqdn,
    ip_v6_address,
    network_location,
    status,
    down_time,
    vapp_options_used
  } = node || {}

  const [restarCriticalOpen, setRestartCriticalOpen] = useState(false)
  const [restarOtherOpen, setRestartOtherOpen] = useState(false)
  const [removeNodeOpen, setRemoveNodeOpen] = useState(false)
  const [configureIPOpen, setConfigureIPOpen] = useState(false)

  const [nodeRole] = useGlobal(GLOBAL_NODE_ROLE) //note - role of currently logged in node
  const isLoggedIntoPrimary = useMemo(
    () => nodeRole?.toLowerCase() === NODE_ROLE.PRIMARY?.toLowerCase(),
    [nodeRole]
  )

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <>
      <NodeTitle>
        {showInd && <Text textStyle={'h2'}>{`${ind + 1}.`}</Text>}

        <div>
          <Icon
            name={'ComputerScreen2'}
            size={22}
            color={
              down_time
                ? theme.colors.red500
                : isPrimary
                ? theme.colors.brand
                : ''
            }
          />
        </div>
        <Text textStyle={'h3'}>{`${role} Node`}</Text>
        {down_time ? (
          <StyledBanner>
            <Banner
              icon={{
                name: 'StatusWarning',
                size: 18,
                variant: 'solid'
              }}
              rounded
              status='critical'
            >
              <span style={{ fontSize: '14px' }}>
                Inactive since last {toDaysMinutesSeconds(down_time)}
              </span>
            </Banner>
          </StyledBanner>
        ) : null}
      </NodeTitle>
      <Row>
        <Pill
          label='Fully qualified domain name'
          value={fqdn || 'not available'}
        />
        <Pill
          label='IPv6 address'
          value={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {typeof ip_v6_address === 'string'
                ? ip_v6_address
                : ip_v6_address?.length > 0
                ? ip_v6_address?.join(', ')
                : 'not assigned'}
            </div>
          }
        />
        <Pill label='IPv4 address' value={network_location || 'not assigned'} />
      </Row>
      <Row ref={restartRef}>
        <Pill
          label='Critical services'
          value={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                name={
                  status['Critical Services'] === NODE_SERVICE_STATUS.ACTIVE
                    ? 'StatusCircleCheck1'
                    : 'StatusCircleError'
                }
                color={
                  status['Critical Services'] === NODE_SERVICE_STATUS.ACTIVE
                    ? theme.colors.brand
                    : theme.colors.textbox.validationMessage
                }
                variant='solid'
              />
              <Capitalize
                style={{
                  color:
                    status['Critical Services'] === NODE_SERVICE_STATUS.ACTIVE
                      ? theme.colors.brand
                      : theme.colors.textbox.validationMessage
                }}
              >
                {status['Critical Services'] ||
                  HIGH_AVAILABILITY_NODE_ROLE.UNKNOWN}
              </Capitalize>
              <span
                data-tip={
                  isCurrentlyLoggedInHere
                    ? `Restarts 'Critical services' for currently logged-in node.`
                    : `Restart option is available only for the services of the currently logged-in node. Please login to the respective node to be able to restart its services.`
                }
              >
                <Button
                  disabled={!isCurrentlyLoggedInHere}
                  variant='link'
                  icon={{ name: 'ActionsSync' }}
                  type='secondary'
                  onClick={() => setRestartCriticalOpen(true)}
                >
                  Restart
                </Button>
              </span>
            </div>
          }
        />
        <Pill
          label='Other services'
          value={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                name={
                  status['Other Services'] === NODE_SERVICE_STATUS.ACTIVE
                    ? 'StatusCircleCheck1'
                    : 'StatusCircleError'
                }
                color={
                  status['Other Services'] === NODE_SERVICE_STATUS.ACTIVE
                    ? theme.colors.brand
                    : theme.colors.textbox.validationMessage
                }
                variant='solid'
              />
              <Capitalize
                style={{
                  color:
                    status['Other Services'] === NODE_SERVICE_STATUS.ACTIVE
                      ? theme.colors.brand
                      : theme.colors.textbox.validationMessage
                }}
              >
                {status['Other Services'] ||
                  HIGH_AVAILABILITY_NODE_ROLE.UNKNOWN}
              </Capitalize>
              <span
                data-tip={
                  isCurrentlyLoggedInHere
                    ? `Restarts 'Other services' for currently logged-in node.`
                    : `Restart option is available only for the services of the currently logged-in node. Please login to the respective node to be able to restart its services.`
                }
              >
                <Button
                  disabled={!isCurrentlyLoggedInHere}
                  variant='link'
                  icon={{ name: 'ActionsSync' }}
                  type='secondary'
                  onClick={() => setRestartOtherOpen(true)}
                >
                  Restart
                </Button>
              </span>
            </div>
          }
        />
      </Row>
      <ActionButtons>
        {!isPureContainer ? (
          <div
            data-tip={
              atleastOneNodeDown
                ? 'In order to use this feature, it is necessary for all nodes to be operational'
                : vapp_options_used
                ? 'IP address has been configured in vApp options'
                : ''
            }
          >
            <Button
              variant='solid'
              icon={{ name: 'ConnectionServerNetwork1' }}
              type='secondary'
              disabled={atleastOneNodeDown || vapp_options_used}
              onClick={() => {
                setConfigureIPOpen(true)
              }}
            >
              Configure IP address
            </Button>
          </div>
        ) : null}

        {isLoggedIntoPrimary && !isPrimary ? (
          <div data-tip='Remove this node from the high availability cluster'>
            <Button
              variant='solid'
              type='secondary'
              icon={{ name: 'ActionsTrash' }}
              onClick={() => {
                setRemoveNodeOpen(true)
              }}
            >
              Remove Node
            </Button>
          </div>
        ) : null}
      </ActionButtons>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Restart Critical Services'
          //subtitle=
          open={restarCriticalOpen}
          onClose={() => setRestartCriticalOpen(false)}
        >
          {restarCriticalOpen && (
            <RestartNodeService
              isCritical
              isPrimary={isPrimary}
              isClustered={isClustered}
              onUpdate={() => {
                setRestartCriticalOpen(false)
                onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Restart Other Services'
          //subtitle=
          open={restarOtherOpen}
          onClose={() => setRestartOtherOpen(false)}
        >
          {restarOtherOpen && (
            <RestartNodeService
              isPrimary={isPrimary}
              onUpdate={() => {
                setRestartOtherOpen(false)
                onUpdate()
              }}
            />
          )}
        </Modal>
      </div>

      <div onClick={e => e.stopPropagation()}>
        <Modal
          title={`Remove Node from Cluster`}
          subtitle={`Remove the secondary node ${fqdn ||
            ip_v6_address ||
            network_location} from the high availability cluster`}
          open={removeNodeOpen}
          onClose={() => setRemoveNodeOpen(false)}
        >
          {removeNodeOpen && (
            <>
              <RemoveNode
                node={node}
                onUpdate={() => {
                  setRemoveNodeOpen(false)
                  onUpdate()
                }}
              />
            </>
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Configure Node IP Address'
          subtitle="The IP address must be in the subnet range of the node's virtual network"
          open={configureIPOpen}
          onClose={() => setConfigureIPOpen(false)}
        >
          {configureIPOpen && (
            <AssignStaticIP
              node={node}
              isPrimary={isPrimary}
              existingNodeAddresses={existingNodeAddresses}
              onUpdate={() => {
                setConfigureIPOpen(false)
                onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
    </>
  )
}

export const StyledBanner = styled.div`
  align-self: center;
  margin-left: 1rem;
  > div {
    display: inline-flex;
    padding: 0.4rem 0.8rem;
  }

  .banner-icon {
    margin-right: 0.4rem;
  }
`

export const NodeTitle = styled.div`
  margin: 0.5rem 0 0.5rem 1.5rem;
  display: flex;
  align-items: center;

  > div {
    display: flex;
    margin-right: 0.5rem;
  }
  > span {
    margin-right: 1rem;
  }
`

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 0.5rem;
  }
`
