import React, { setGlobal } from 'reactn'
import ReactDOM from 'react-dom'
import App from './App'
import { initialState } from './globalState'

setGlobal({
  ...initialState
})

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root')
)
