import React, { useState, useEffect } from 'react'
import { useAppNotifications, useFetch } from 'common'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import styled from 'styled-components'
import Textbox from '@kaizen-ui/textbox'
import Text from '@kaizen-ui/text'
import Button from '@kaizen-ui/button'
import Result from '@kaizen-ui/result'
import Radio, { RadioGroup } from '@kaizen-ui/radio'
import {
  API_SERVICE_INSTANCE_CONFIGURE_HA,
  API_SERVICE_INSTANCE_PING,
  DomainNameRegex,
  HIGH_AVAILABILITY_STATE,
  IP_ADDRESS_TYPE,
  IpHostnameRegex
} from '../../../api'

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Radios = styled.div`
  > div {
    justify-content: center;
  }
`

export const AddNode = ({
  existingNodeAddresses = [],
  onUpdate,
  isClustered
}) => {
  const { notify } = useAppNotifications()
  const [pingResult, setPingResult] = useState(undefined)
  const [submitting, setSubmitting] = useState(false)
  const initialValues = {
    networkLocation: '',
    addressType: IP_ADDRESS_TYPE.IPV4
  }
  const { getData: sendPing, loading: pinging } = useFetch({
    endpoint: API_SERVICE_INSTANCE_PING,
    actionLabel: 'Pinging node for HA setup',
    method: 'POST'
    /*normalizer: () => {
      return { reachable: true }
    }*/
  })
  const { getData: sendHaConfig, error, resetError } = useFetch({
    endpoint: API_SERVICE_INSTANCE_CONFIGURE_HA,
    actionLabel: 'Configuring high availability',
    method: 'POST'
  })

  const validationSchema = Yup.object({
    networkLocation: Yup.string()
      .notOneOf(
        existingNodeAddresses,
        "Value can't be same as existing node's IP address or FQDN"
      )
      .matches(IpHostnameRegex, 'Must be a valid IP address or FQDN')
      .required('Network location of the node to be added is required')
  })

  const onSubmit = async values => {
    const { networkLocation, addressType } = values
    setPingResult(undefined)
    const body = {
      networkLocation: networkLocation?.trim(),
      useHa: true,
      ...(DomainNameRegex.test(networkLocation) ? { addressType } : {})
    }
    const result = await sendPing({ body })
    if (result) {
      setPingResult(result)
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit
  })

  const submitConfig = async () => {
    setSubmitting(true)
    const { networkLocation, addressType } = formik.values
    const body = {
      mode: HIGH_AVAILABILITY_STATE.CLUSTERED,
      config: {
        nodeList: [
          {
            network_location: networkLocation
          }
        ]
      },
      op: 'dynamic',
      ...(DomainNameRegex.test(networkLocation) ? { addressType } : {})
    }

    const result = await sendHaConfig({ body })
    if (result) {
      const msg = isClustered
        ? 'Triggered node addition to the cluster'
        : 'High availability configuration triggered successfully'
      notify(
        msg,
        null,
        null,
        isClustered
          ? 'Triggered node addition'
          : 'High availability configuration triggered'
      )
      onUpdate && onUpdate()
    } else {
      setSubmitting(false)
    }
  }
  useEffect(() => {
    if (pingResult) {
      setPingResult(undefined)
    }
    if (error) {
      resetError()
    }
  }, [formik?.values?.networkLocation, formik?.values?.addressType]) //eslint-disable-line react-hooks/exhaustive-deps

  const isDomainEntered = formik?.values?.networkLocation
    ? DomainNameRegex.test(formik?.values?.networkLocation)
    : false

  return (
    <>
      <>
        <div style={{ marginBottom: '-1rem' }}>
          <Text textStyle='optionLabel'>Network location</Text>
        </div>
        <Row>
          <Textbox
            id='networkLocation'
            name='networkLocation'
            className='nvl'
            placeholder='IP address or FQDN of the node to be added'
            value={formik.values.networkLocation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            validationMessage={
              formik.touched.networkLocation && formik.errors.networkLocation
            }
          />
        </Row>

        {isDomainEntered ? (
          <>
            <Row>
              <div>
                <Text textStyle='optionLabel'>
                  FQDN resolution address type: &#160;
                </Text>
              </div>
              <Radios>
                <RadioGroup
                  inline={true}
                  id={'addressType'}
                  name='addressType'
                  onChange={e =>
                    formik.setFieldValue('addressType', e.target.id)
                  }
                  selected={formik?.values?.addressType}
                >
                  <Radio id={IP_ADDRESS_TYPE.IPV4} label='IPv4' />
                  <Radio id={IP_ADDRESS_TYPE.IPV6} label='IPv6' />
                </RadioGroup>
              </Radios>
            </Row>
            <br />
          </>
        ) : null}

        <Row>
          <Button
            variant='outline'
            type={'primary'}
            icon={{ name: 'ObjectsNetwork' }}
            onClick={formik.handleSubmit}
            disabled={pinging || !formik.values.networkLocation}
          >
            Ping
          </Button>
        </Row>
        <br />
        <div style={{ textAlign: 'center' }}>
          <Result
            status={
              submitting
                ? 'loading'
                : !!error
                ? 'error'
                : pinging
                ? 'loading'
                : !pingResult
                ? 'info'
                : pingResult?.reachable
                ? 'success'
                : 'error'
            }
            title={
              submitting
                ? isClustered
                  ? 'Adding Node to the Cluster'
                  : 'Configuring High Availability'
                : !!error
                ? undefined
                : pinging
                ? 'Pinging node'
                : !pingResult
                ? ''
                : pingResult?.reachable
                ? 'Success!'
                : undefined
            }
            subTitle={
              submitting
                ? isClustered
                  ? 'Adding Node to the Cluster'
                  : 'Configuring High Availability'
                : !!error
                ? error
                : pinging
                ? 'Checking node status'
                : !pingResult
                ? 'Awaiting node information'
                : pingResult?.reachable
                ? "Click 'Add Node' to add the node to the Cluster"
                : pingResult?.failure_detail || 'Unable to reach the node'
            }
            actions={
              pingResult?.reachable
                ? [
                    {
                      children: isClustered ? 'Add Node' : 'Create Cluster',
                      onClick: submitConfig,
                      disabled: submitting,
                      key: 0
                    }
                  ]
                : undefined
            }
          />
        </div>
      </>
    </>
  )
}
