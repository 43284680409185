import React, {
  useContext,
  useGlobal,
  useEffect,
  useState,
  useMemo
} from 'reactn'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import styled from 'styled-components'
import Progress from '@kaizen-ui/progress'
import Icon from '@kaizen-ui/icon'
import Note from '@kaizen-ui/note'
import Text from '@kaizen-ui/text'
import Button, { ButtonGroup } from '@kaizen-ui/button'
import Modal from '@kaizen-ui/modal'
import {
  GLOBAL_NODE_ROLE,
  GLOBAL_OFFLINE_UPGRADE_TRIGGERED,
  GLOBAL_UPGRADE_STATUS,
  GLOBAL_UPGRADE_STATUS_REFRESH
} from '../../globalState'
import { BlockContents, BlockSection, BlockTitle, Step } from './Upgrade'
import { useAppNotifications, useFetch, Table, ModalSpinner } from 'common'
import {
  API_MIGRATION_OFFLINE_DOWNLOAD,
  API_MIGRATION_OFFLINE_GENERATE,
  API_MIGRATION_FILE_GEN_STATUS,
  MIGRATION_FILE_GEN_STATUS,
  NODE_ROLE,
  API_UPGRADE_SYNC_CHANGES,
  API_ACK_UPGRADE,
  API_MIGRATION_OFFLINE_DECOMMISSION_SI
} from '../../api'
import { SessionExpired } from '../../Components'
import { useAuth } from '../../hooks'
import { LiteSpinner } from 'common'
import { TriggerOfflineUpgradeOnSecondary } from './TriggerOfflineUpgradeOnSecondary'

const Buttons = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  /*  button {
    margin-left: 1rem;
  }*/
`
const Capitalize = styled.div`
  text-transform: capitalize;
`
const Warning = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
`
const StyledNote = styled.div`
  display: flex;
  align-items: center;

  > div {
    align-items: center;
    padding: 0;

    .note-command-container {
      padding-left: 0;
    }
  }
`
const SiDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 1.75rem;

  > div {
    flex-basis: 50%;
  }
`

const NodeList = ({ nodes, setRefreshFileGenStatus, loadingSI, refreshSI }) => {
  const [, setUpgradeStatusRefresh] = useGlobal(GLOBAL_UPGRADE_STATUS_REFRESH)
  const [nodeRole] = useGlobal(GLOBAL_NODE_ROLE)
  const [triggerUpgradeOpen, setTriggerUpgradeOpen] = useState(false)
  const [
    selectedSecondaryNodeAddress,
    setSelectedSecondaryNodeAddress
  ] = useState(false)

  const nodeListColumns = useMemo(
    () => [
      {
        Header: 'Role',
        accessor: 'role',
        disableFilters: true
      },
      {
        Header: 'Fully qualified domain name',
        accessor: 'fqdn',
        disableFilters: true
      },
      {
        Header: 'IPv6 address',
        /*  accessor: 'ip_v6_address',*/
        disableFilters: true,
        Cell: ({ row }) => {
          const { ip_v6_address } = row.original || {}
          return typeof ip_v6_address === 'string'
            ? ip_v6_address
            : ip_v6_address?.length > 0
            ? ip_v6_address?.join(', ')
            : ''
        }
      },
      {
        Header: 'IPv4 address',
        accessor: 'network_location',
        disableFilters: true
      },
      {
        Header: 'Node Version',
        accessor: 'version',
        disableFilters: true
      },
      ...(nodeRole === NODE_ROLE.PRIMARY
        ? [
            {
              Header: 'Upgrade',
              disableFilters: true,
              Cell: ({ row }) => {
                const { role, fqdn, ip_v6_address, network_location } =
                  row.original || {}

                return role === 'Secondary' ? (
                  <div data-tip={``}>
                    <Button
                      onClick={() => {
                        setSelectedSecondaryNodeAddress(
                          fqdn || ip_v6_address || network_location
                        )
                        setTriggerUpgradeOpen(true)
                      }}
                    >
                      Upgrade
                    </Button>
                  </div>
                ) : (
                  <Text textStyle={'p2'}>
                    Upgrade can be started only for secondary node
                  </Text>
                )
              }
            }
          ]
        : [])
    ],
    [nodeRole]
  )
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: '0.5rem'
        }}
      >
        <Text textStyle={'h3'}>Nodes in the DLS Cluster</Text>
        <div style={{ marginLeft: '1rem' }}>
          <Button
            type='secondary'
            variant={'link'}
            onClick={() => {
              refreshSI(val => !val)
            }}
            icon={{ name: 'CloudRefresh' }}
            disabled={loadingSI}
          >
            Refresh Node List
          </Button>
        </div>
      </div>
      <TableOverflow>
        <Table
          columns={nodeListColumns}
          data={nodes || []}
          minHeight={'50px'}
          dataId={'id'}
          label={'Nodes'}
          initialPageSize={10}
          disablePagination
          disablePageSizeSelection
          disableFiltering
          disableSorting
          disableExporting
          disableSearch
          disableColumnHiding
        />
      </TableOverflow>
      <div onClick={e => e.stopPropagation()}>
        {triggerUpgradeOpen && (
          <Modal
            title={`Trigger portal assisted upgrade`}
            open={triggerUpgradeOpen}
            size={'large'}
            onClose={() => {
              setTriggerUpgradeOpen(false)
            }}
          >
            <TriggerOfflineUpgradeOnSecondary
              secondaryNodeAddress={selectedSecondaryNodeAddress}
              onUpdate={() => {
                setTriggerUpgradeOpen(false)
                setRefreshFileGenStatus(val => !val)
                setUpgradeStatusRefresh(val => !val)
              }}
            />
          </Modal>
        )}
      </div>
    </div>
  )
}

export const OfflineUpgrade = ({
  serviceInstance = {},
  isPortalAssistedUpgradeInProgress,
  loadingSI,
  refreshSI,
  isStandaloneUpgrade
}) => {
  const theme = useContext(KaizenThemeContext)
  const { notify } = useAppNotifications()
  const { user, checkAuth } = useAuth()
  const [upgradeStatus] = useGlobal(GLOBAL_UPGRADE_STATUS)
  const [, setRefresh] = useGlobal(GLOBAL_UPGRADE_STATUS_REFRESH)
  const [nodeRole] = useGlobal(GLOBAL_NODE_ROLE)
  const [startOpen, setStartOpen] = useState(false)
  const [ackOpen, setAckOpen] = useState(false)
  const [ackDownloadOpen, setAckDownloadOpen] = useState(false)
  const [fileGenerationStatus, setFileGenerationStatus] = useState(undefined)
  const [refreshFileGenStatus, setRefreshFileGenStatus] = useState(false)
  const { secondaryNode, isAckRequired } = upgradeStatus || {}
  const [siNodeList, setSiNodeList] = useState([])
  const upgradeNodeList = useMemo(() => {
    return upgradeStatus?.cluster?.nodes?.map((node, index) => ({
      ...node,
      id: `node-${index}`
    }))
  }, [upgradeStatus?.cluster?.nodes])
  const isPrimary = useMemo(
    () => nodeRole?.toLowerCase() === NODE_ROLE.PRIMARY?.toLowerCase(),
    [nodeRole]
  )
  //this flag is from /me from old upgrade flow
  const [isOfflineUpgradeTriggered] = useGlobal(
    GLOBAL_OFFLINE_UPGRADE_TRIGGERED
  )

  useEffect(() => {
    if (serviceInstance?.high_availability_config?.config?.nodeList) {
      setSiNodeList(
        serviceInstance?.high_availability_config?.config?.nodeList
          ?.sort((a, b) => {
            if (a.role < b.role) {
              return -1
            }
            if (a.role > b.role) {
              return 1
            }
            return 0
          })
          .map((n, index) => ({ ...n, id: `node-${index}` }))
      )
    }
  }, [serviceInstance?.high_availability_config?.config?.nodeList])

  const { getData, abort } = useFetch({
    endpoint: API_MIGRATION_FILE_GEN_STATUS,
    actionLabel: 'Check migration file generation status',
    SessionExpired: SessionExpired,
    supressToast: true
    /* normalizer: () => {
      //return { status: MIGRATION_FILE_GEN_STATUS.FAILED }
      return { status: null }
    }*/
  })

  const { getData: sendGenerate, loading: triggeringGenerate } = useFetch({
    endpoint: API_MIGRATION_OFFLINE_GENERATE,
    actionLabel: 'Generate offline migration file',
    method: 'PUT',
    SessionExpired: SessionExpired
  })

  const { getData: getDownloadUrl, loading: gettingDownloadUrl } = useFetch({
    endpoint: API_MIGRATION_OFFLINE_DOWNLOAD,
    actionLabel: 'Get offline migration file download url',
    method: 'GET',
    SessionExpired: SessionExpired
  })

  const { getData: sendAckUpgrade, loading: sendingAckUpgrade } = useFetch({
    endpoint: API_ACK_UPGRADE,
    actionLabel: 'Acknowledge upgrade',
    method: 'POST',
    SessionExpired: SessionExpired
  })

  const { getData: sendDecom, loading: decomming } = useFetch({
    endpoint: API_MIGRATION_OFFLINE_DECOMMISSION_SI,
    actionLabel: 'Acknowledge offline migration file downloaded',
    method: 'DELETE',
    SessionExpired: SessionExpired
  })

  useEffect(() => {
    //file generation status polling
    if (!isPortalAssistedUpgradeInProgress && !isOfflineUpgradeTriggered) return

    const interval = setInterval(() => {
      getFileGenerationStatus()
    }, 5000)
    const getFileGenerationStatus = async () => {
      if (!user) {
        clearInterval(interval)
        return
      }
      const data = await getData()

      if (!data || !data.status) {
        setFileGenerationStatus(undefined)
        clearInterval(interval)
        return
      }

      const { status } = data
      setFileGenerationStatus(data)
      if (
        status === MIGRATION_FILE_GEN_STATUS.FAILED ||
        status === MIGRATION_FILE_GEN_STATUS.COMPLETED
      ) {
        clearInterval(interval)
      }
    }
    getFileGenerationStatus()

    return () => {
      clearInterval(interval)
      abort()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    refreshFileGenStatus,
    isPortalAssistedUpgradeInProgress,
    isOfflineUpgradeTriggered
  ])

  const generateFile = async () => {
    const data = await sendGenerate()
    if (data) {
      notify(
        'Migration file generation triggered successfully',
        null,
        null,
        'Triggered migration file generation'
      )
      isStandaloneUpgrade && checkAuth(() => {})
    }
    setRefreshFileGenStatus(val => !val)
  }

  const downloadFile = async () => {
    const headers = {
      'x-nv-service-instance-id': serviceInstance?.xid
    }
    const data = await getDownloadUrl({ headers })
    if (data?.length) {
      /*[
        {
          name: 'on_prem_migration_file_04-14-2022-12-04-35.bin',
          type: 'file',
          mtime: 'Thu, 14 Apr 2022 12:04:35 GMT',
          size: 669
        }
      ]*/
      const fileName = data[0]?.name
      if (fileName) {
        window.location.href = `${API_MIGRATION_OFFLINE_DOWNLOAD}${fileName}`
      } else {
        const msg =
          'Received invalid response while fetching download-url for the migration file.'
        notify(
          msg,
          null,
          null,
          'Failed to get the download-url for the migration file'
        )
      }
    }
  }

  const ackUpgrade = async () => {
    const data = await sendAckUpgrade()

    if (data) {
      notify(
        'Acknowledged the portal assisted upgrade successfully.',
        null,
        null,
        'Acknowledged portal assisted upgrade'
      )
      setAckOpen(false)
      setRefresh(val => !val)
    }
  }

  const ackMigrationFileDownload = async () => {
    const data = await sendDecom()

    if (data) {
      notify(
        'Acknowledged the download of migration file successfully. This virtual appliance will be switched off in few minutes',
        null,
        null,
        'Acknowledged offline migration file downloaded'
      )
    }
  }

  return (
    <>
      {isStandaloneUpgrade && !isOfflineUpgradeTriggered ? (
        <Warning>
          <Icon
            name='StatusWarning'
            size='larger'
            color={theme.colors.orange500}
          />
          <span style={{ color: theme.colors.textbox.placeholder }}>
            This virtual appliance will be unusable once the offline upgrade
            process has started!
          </span>
        </Warning>
      ) : null}

      <BlockContents>
        {isStandaloneUpgrade ? (
          <>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 1 -</Step>
                Download Migration file
              </BlockTitle>
              <div>
                Generate and download the migration file. You won&apos;t be able
                to modify this virtual appliance or perform any lease operations
                once you start the migration file generation.
              </div>

              {fileGenerationStatus?.status ===
              MIGRATION_FILE_GEN_STATUS.FAILED ? (
                <>
                  <h4 style={{ color: theme.colors.red500, marginBottom: 0 }}>
                    Failed to generate the migration file, please try again
                  </h4>
                  <Progress
                    size='small'
                    progress={1}
                    total={1}
                    color={theme.colors.red500}
                  />
                </>
              ) : null}

              {(!isOfflineUpgradeTriggered ||
                fileGenerationStatus?.status ===
                  MIGRATION_FILE_GEN_STATUS.FAILED) && (
                <>
                  <Button
                    icon={{ name: 'DatabaseEdit' }}
                    onClick={() => setStartOpen(true)}
                  >
                    Generate Migration File
                  </Button>
                </>
              )}

              {fileGenerationStatus?.status ===
                MIGRATION_FILE_GEN_STATUS.INITIATED && (
                <>
                  <h4 style={{ marginBottom: 0 }}>
                    Migration file generation is in progress. You&#39;ll be able
                    to download the file once the migration file is available
                  </h4>
                  <Progress indeterminate size='small' />
                </>
              )}
              {fileGenerationStatus?.status ===
                MIGRATION_FILE_GEN_STATUS.COMPLETED && (
                <>
                  <h4 style={{ marginBottom: 0 }}>
                    Migration file has been generated
                  </h4>
                  <Progress size='small' progress={1} total={1} />
                  <SiDetails>
                    <Text textStyle='label'>Service instance details:</Text>
                    <StyledNote>
                      <Note copyValue={serviceInstance?.name}>
                        {serviceInstance?.name}
                      </Note>
                    </StyledNote>
                    <StyledNote>
                      <Note copyValue={serviceInstance?.xid}>
                        {serviceInstance?.xid}
                      </Note>
                    </StyledNote>
                    <div style={{ display: 'flex' }}>
                      <Text truncate color={theme.colors.textbox.placeholder}>
                        {serviceInstance?.description}
                      </Text>
                    </div>
                  </SiDetails>
                  <Warning style={{ margin: '1rem' }}>
                    <Icon
                      name='StatusWarning'
                      size='larger'
                      color={theme.colors.red500}
                    />
                    <span style={{ color: theme.colors.textbox.placeholder }}>
                      Clicking &apos;Acknowledge Download&apos; acknowledges
                      that you have successfully downloaded the migration file
                      and shuts down this virtual appliance!
                    </span>
                  </Warning>

                  <ButtonGroup>
                    <Button
                      icon={{ name: 'ActionsDownload' }}
                      onClick={downloadFile}
                      disabled={gettingDownloadUrl}
                    >
                      Download Migration File
                    </Button>
                    <Button
                      icon={{ name: 'ActionsDownload' }}
                      onClick={() => setAckDownloadOpen(true)}
                      type='critical'
                      disabled={decomming}
                    >
                      Acknowledge Download
                    </Button>
                  </ButtonGroup>
                </>
              )}
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 2 -</Step>
                Upload the migration file on NVIDIA Licensing Portal
              </BlockTitle>
              In the NVIDIA Licensing Portal, go to the <b>
                Service Instances
              </b>{' '}
              page. Find the service instance which needs an upgrade, then click{' '}
              <b>Upload Migration File</b>. In the <b>Upload Migration File</b>{' '}
              pop-up window that opens, select the file generated in step one,
              and then upload.
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 3 -</Step>
                Set up a new virtual appliance
              </BlockTitle>
              Install a new version of the virtual appliance on a virtual
              machine. Once you are able to see the web interface for the
              virtual appliance, click on <b>Upgrade</b>. From the{' '}
              <b>Upgrade</b> page, download the DLS instance token for this new
              virtual appliance.
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 4 -</Step>
                Download the Upgrade file from NVIDIA Licensing Portal
              </BlockTitle>
              In the NVIDIA Licensing Portal, go to the <b>
                Service Instances
              </b>{' '}
              page. Click on <b>Register DLS Instance</b>. In the{' '}
              <b>Register DLS Instance</b> pop-up window that opens, select the{' '}
              <b>For upgrade</b> checkbox, select the service instance which
              needs an upgrade, and then submit. After uploading the token,
              download the respective upgrade file for the service instance by
              clicking on <b>Download Upgrade File</b>.
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 5 -</Step>
                Upload the Upgrade file on the new virtual appliance setup
              </BlockTitle>
              Open the web interface of the new virtual appliance setup. Click{' '}
              <b>Upgrade</b>. Upload the upgrade file obtained in step four to
              complete the upgrade. You&#39;ll be able to use your old virtual
              appliance&#39;s credentials to login after the upgrade completion.
            </BlockSection>
          </>
        ) : (
          <>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 1 -</Step>
                Choose a secondary node to upgrade and download the migration
                file
              </BlockTitle>
              <div>
                Choose a secondary node to upgrade, it'll convert the cluster to
                standalone mode and put primary node in maintenance mode. All
                the secondary nodes including the selected secondary node will
                be wiped clean. Migration file will be generated to perform the
                next steps.
              </div>

              {isPortalAssistedUpgradeInProgress ? (
                <SiDetails>
                  <div>
                    <Text textStyle='label'>Service instance details:</Text>
                    <StyledNote>
                      <Note copyValue={serviceInstance?.name}>
                        {serviceInstance?.name}
                      </Note>
                    </StyledNote>
                    {secondaryNode ? (
                      <StyledNote>
                        <Note copyValue={serviceInstance?.xid}>
                          {serviceInstance?.xid}
                        </Note>
                      </StyledNote>
                    ) : null}
                  </div>
                  <div>
                    <Text textStyle='label'>Selected secondary node :</Text>
                    <StyledNote>
                      <Note copyValue={secondaryNode}>{secondaryNode}</Note>
                    </StyledNote>
                  </div>
                </SiDetails>
              ) : null}

              {!isPortalAssistedUpgradeInProgress && siNodeList?.length ? (
                <>
                  <br />
                  <NodeList
                    nodes={siNodeList}
                    refreshSI={refreshSI}
                    loadingSI={loadingSI}
                    setRefreshFileGenStatus={setRefreshFileGenStatus}
                  />
                </>
              ) : null}

              {isPrimary &&
              isPortalAssistedUpgradeInProgress &&
              fileGenerationStatus?.status ===
                MIGRATION_FILE_GEN_STATUS.FAILED ? (
                <>
                  <h4 style={{ color: theme.colors.red500, marginBottom: 0 }}>
                    Failed to generate the migration file, please try again.
                  </h4>
                  <Progress
                    size='small'
                    progress={1}
                    total={1}
                    color={theme.colors.red500}
                  />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      icon={{ name: 'DatabaseEdit' }}
                      onClick={generateFile}
                      disabled={triggeringGenerate}
                    >
                      Retry Migration File Generation
                    </Button>
                    {triggeringGenerate && (
                      <div style={{ marginLeft: '1rem' }}>
                        <LiteSpinner />
                      </div>
                    )}
                  </div>
                </>
              ) : null}

              {isPrimary &&
              isPortalAssistedUpgradeInProgress &&
              !isAckRequired &&
              !fileGenerationStatus?.status ? (
                <div>
                  <h4 style={{ color: theme.colors.red500, marginBottom: 0 }}>
                    Portal assisted upgrade is in progress but migration file is
                    not available. Please try re-generating the file.
                  </h4>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      icon={{ name: 'DatabaseEdit' }}
                      onClick={generateFile}
                      disabled={triggeringGenerate}
                    >
                      Trigger Migration File Generation
                    </Button>
                    {triggeringGenerate && (
                      <div style={{ marginLeft: '1rem' }}>
                        <LiteSpinner />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              {isPrimary &&
                isPortalAssistedUpgradeInProgress &&
                fileGenerationStatus?.status ===
                  MIGRATION_FILE_GEN_STATUS.INITIATED && (
                  <>
                    <h4 style={{ marginBottom: 0 }}>
                      Migration file generation is in progress. You&#39;ll be
                      able to download the file once the migration file is
                      available
                    </h4>
                    <Progress indeterminate size='small' />
                  </>
                )}
              {isPrimary &&
                isPortalAssistedUpgradeInProgress &&
                fileGenerationStatus?.status ===
                  MIGRATION_FILE_GEN_STATUS.COMPLETED && (
                  <>
                    <h4 style={{ marginBottom: 0 }}>
                      Migration file has been generated
                    </h4>
                    <Progress size='small' progress={1} total={1} />

                    {/*    <Warning style={{ margin: '1rem' }}>
                <Icon
                  name='StatusWarning'
                  size='larger'
                  color={theme.colors.red500}
                />
                <span style={{ color: theme.colors.textbox.placeholder }}>
                  Clicking &apos;Acknowledge Download&apos; acknowledges that
                  you have successfully downloaded the migration file and shuts
                  down this virtual appliance!
                </span>
              </Warning>*/}

                    <ButtonGroup>
                      <Button
                        icon={{ name: 'ActionsDownload' }}
                        onClick={downloadFile}
                        disabled={gettingDownloadUrl}
                      >
                        Download Migration File
                      </Button>
                      {/*<div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      icon={{ name: 'DatabaseEdit' }}
                      onClick={generateFile}
                      disabled={triggeringGenerate}
                    >
                      Re-generate Migration File
                    </Button>
                    {triggeringGenerate && (
                      <div style={{ marginLeft: '1rem' }}>
                        <LiteSpinner />
                      </div>
                    )}
                  </div>*/}
                    </ButtonGroup>
                  </>
                )}
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 2 -</Step>
                Upload the migration file on NVIDIA Licensing Portal
              </BlockTitle>
              In the NVIDIA Licensing Portal, go to the <b>
                Service Instances
              </b>{' '}
              page. Find the service instance which needs an upgrade, then click{' '}
              <b>Upload Migration File</b>. In the <b>Upload Migration File</b>{' '}
              pop-up window that opens, select the file generated in step one,
              and then upload.
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 3 -</Step>
                Download latest DLS appliance artifact from NVIDIA Licensing
                Portal
              </BlockTitle>
              <div style={{ marginBottom: '1rem' }}>
                <Text textStyle={'p1'}>
                  Login to NVIDIA Licensing Portal and download latest DLS
                  appliance artifact from Software Downloads page.
                </Text>
              </div>
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 4 -</Step>
                Install the downloaded DLS appliance artifact on the secondary
                node selected in step one
              </BlockTitle>
              Follow the below steps to install a new version of DLS appliance
              on the selected secondary node.
              <ol>
                <li>
                  If you want to upgrade the secondary node by spinning up a new
                  version of DLS VM, delete your old VM. Spin up the new version
                  DLS VM, and assign the same network properties as of secondary
                  node(FQDN or IP Address)
                </li>
                <li>
                  If you want to upgrade the secondary node by replacing the
                  containers on VM, or by upgrading the containers on a
                  container host follow the below steps -
                </li>
                <ol type='a'>
                  <li>Start the VM if it is down, in case of DLS VM</li>
                  <li>
                    Login to VM/Container console with user rsu_admin and run
                    command : &nbsp;
                    <Text textStyle='code'>
                      sudo nls-X.X.X-bios/upgrade/clean_dls_upgrade.sh
                    </Text>
                  </li>
                </ol>
              </ol>
              Once you are able to see the web interface for the node, click on{' '}
              <b>Upgrade</b>. From the <b>Upgrade</b> page, download the DLS
              instance token.
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 5 -</Step>
                Download the Upgrade file from NVIDIA Licensing Portal
              </BlockTitle>
              In the NVIDIA Licensing Portal, go to the <b>
                Service Instances
              </b>{' '}
              page. Click on <b>Register DLS Instance</b>. In the{' '}
              <b>Register DLS Instance</b> pop-up window that opens, select the{' '}
              <b>For upgrade</b> checkbox, select the service instance which
              needs an upgrade, select the DLS instance token downloaded from
              step four and then submit. After uploading the token, download the
              respective upgrade file for the service instance by clicking on{' '}
              <b>Download Upgrade File</b>.
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 6 -</Step>
                Upload the Upgrade file on the selected secondary node
              </BlockTitle>
              Access the web interface of the node that you have selected for
              upgrade. Click on the <b>Upgrade</b> button. Upload the upgrade
              file that you obtained in step five. Once the upgrade file is
              installed, you can log in using the same credentials as your old
              virtual appliance.
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 7 -</Step>
                Sync delta changes (if any) from primary node to upgraded node
              </BlockTitle>
              If there are delta changes present between the Primary node and
              the upgraded node, you'd see an addition sync button in the
              cluster details table which would allow you to sync delta changes
              to the upgraded node.
              {isPortalAssistedUpgradeInProgress && upgradeNodeList?.length ? (
                <>
                  <br />
                  <UpgradeNodeList nodes={upgradeNodeList} />
                </>
              ) : null}
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 8 -</Step>
                Acknowledge upgrade from upgraded node
              </BlockTitle>
              Once all the delta changes are in sync, you can acknowledge the
              upgrade from the upgraded node. Once acknowledged, the upgraded
              node will start serving the leases, maintenance mode will be
              exited and old Primary node will be wiped clean. At this point,
              the cluster will have only one upgraded node with all the latest
              data.
              {isAckRequired ? (
                <Buttons>
                  <Button
                    icon={{ name: 'ServerCheck' }}
                    onClick={() => setAckOpen(true)}
                    type='primary'
                  >
                    Acknowledge Upgrade
                  </Button>
                </Buttons>
              ) : null}
            </BlockSection>
            <BlockSection>
              <BlockTitle theme={theme}>
                <Step>Step 9 -</Step>
                Restore nodes in the cluster(if any)
              </BlockTitle>
              The details of removed nodes will persist on Maintenance page for
              next 7 days. To restore these nodes, install the latest DLS
              artifact on all the removed nodes from the cluster. Use the
              "Configure High Availability/Add Node" feature from Service
              Instance page of the Primary node to add these nodes back to the
              cluster.
            </BlockSection>
          </>
        )}
      </BlockContents>

      <Modal
        title='Generate Migration File'
        subtitle='This will start the offline virtual appliance upgrade process'
        open={startOpen}
        onClose={() => setStartOpen(false)}
        primaryButtonText='Generate Migration File'
        onPrimaryButtonClick={() => generateFile()}
        size={'large'}
      >
        <ul>
          <li>
            The license configuration of current virtual appliance will be
            exported to a migration file, events will not be exported.
          </li>
          <li>
            The modifications to this virtual appliance will be blocked until
            the portal assisted process is complete.
          </li>
        </ul>
      </Modal>

      <Modal
        title='Acknowledge Upgrade'
        open={ackOpen}
        onClose={() => setAckOpen(false)}
      >
        {sendingAckUpgrade ? <ModalSpinner /> : null}
        <StyledList>
          <ol>
            <li>
              The upgraded node (i.e., the current node) will receive any
              remaining delta messages from the existing primary node. Once this
              is done, the existing primary node will also be removed from the
              cluster and wiped clean.
            </li>
            <li>
              After the upgrade, the upgraded node (i.e. current node) will
              start serving leases and exit maintenance mode.
            </li>
          </ol>
        </StyledList>
        <AckButtons>
          <Button
            icon={{ name: 'ServerCheck' }}
            onClick={ackUpgrade}
            type='primary'
            disabled={sendingAckUpgrade}
          >
            Acknowledge Upgrade
          </Button>
        </AckButtons>
      </Modal>

      <Modal
        title='Acknowledge Download'
        open={ackDownloadOpen}
        onClose={() => setAckDownloadOpen(false)}
        primaryButtonText='Acknowledge Download'
        onPrimaryButtonClick={ackMigrationFileDownload}
      >
        <p>
          Acknowledges that you have successfully downloaded the migration file
          and decommissions this virtual appliance to free up the resources.
          Decommissioning the virtual appliance may take few minutes.
        </p>
      </Modal>
    </>
  )
}

const UpgradeNodeList = ({ nodes }) => {
  const theme = useContext(KaizenThemeContext)
  const [, setRefresh] = useGlobal(GLOBAL_UPGRADE_STATUS_REFRESH)
  const [nodeRole] = useGlobal(GLOBAL_NODE_ROLE)
  const [upgradeStatus] = useGlobal(GLOBAL_UPGRADE_STATUS)
  const { message } = upgradeStatus || {}
  const isPrimary = useMemo(
    () => nodeRole?.toLowerCase() === NODE_ROLE.PRIMARY?.toLowerCase(),
    [nodeRole]
  )
  const isSyncInProgress = useMemo(() => {
    return message === 'SYNC_INITIATED'
  }, [message])

  const {
    getData: startSyncingDeltaChanges,
    loading: triggeringSync
  } = useFetch({
    endpoint: API_UPGRADE_SYNC_CHANGES,
    actionLabel: `Trigger sync to the upgraded node`,
    SessionExpired: SessionExpired,
    method: 'POST',
    responseOnly: true
  })

  const { notify } = useAppNotifications()

  const startSyncProcess = async ({ nodeAddress }) => {
    const body = {
      nodeAddress
    }
    const result = await startSyncingDeltaChanges({ body })
    if (result) {
      const msg = `Started syncing delta load from this node to upgraded node - ${nodeAddress}!`
      notify(msg, null, null, 'Start syncing delta changes to upgraded node')
    }
  }

  const nodeListColumns = useMemo(
    () => [
      {
        Header: 'Fully qualified domain name',
        accessor: 'fqdn',
        disableFilters: true
      },
      {
        Header: 'IPv6 address',
        accessor: 'ipV6Adress',
        disableFilters: true
      },
      {
        Header: 'IPv4 address',
        accessor: 'networkLocation',
        disableFilters: true
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        Cell: ({ row }) => {
          const { status } = row.original
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                name={
                  status === 'up' ? 'StatusCircleCheck1' : 'StatusCircleError'
                }
                color={
                  status === 'up'
                    ? theme.colors.brand
                    : theme.colors.textbox.validationMessage
                }
                variant='solid'
              />
              <div style={{ width: '0.25rem' }} />
              <Capitalize
                style={{
                  color:
                    status === 'up'
                      ? theme.colors.brand
                      : theme.colors.textbox.validationMessage
                }}
              >
                {status === 'up' ? 'Active' : 'Down'}
              </Capitalize>
            </div>
          )
        }
      },
      {
        Header: 'Node Version',
        accessor: 'version',
        disableFilters: true
      },
      ...(isPrimary
        ? [
            {
              Header: 'Sync',
              disableFilters: true,
              Cell: ({ row }) => {
                const { isSyncRequired, fqdn, ipV6Adress, networkLocation } =
                  row.original || {}

                return isSyncRequired ? (
                  <div
                    data-tip={
                      isSyncInProgress
                        ? `Upgraded node is in sync with current node. Please acknowledge the upgrade from node ${fqdn ||
                            ipV6Adress ||
                            networkLocation} to complete the upgrade process`
                        : `Changes done to this node post migration file generation will be pushed to the upgraded node ${fqdn ||
                            ipV6Adress ||
                            networkLocation}`
                    }
                  >
                    <Button
                      onClick={() => {
                        startSyncProcess({
                          nodeAddress: fqdn || ipV6Adress || networkLocation
                        })
                      }}
                      disabled={triggeringSync || isSyncInProgress}
                    >
                      Sync Changes
                    </Button>
                    {triggeringSync && (
                      <div style={{ marginLeft: '1rem' }}>
                        <LiteSpinner />
                      </div>
                    )}
                  </div>
                ) : null
              }
            }
          ]
        : [])
    ], //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSyncInProgress,
      isPrimary,
      theme.colors.brand,
      theme.colors.textbox.validationMessage,
      triggeringSync
    ]
  )
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: '0.5rem'
        }}
      >
        <Text textStyle={'h3'}>
          Snapshot of list of nodes in the DLS Cluster before upgrade was
          triggered
        </Text>
        <div style={{ marginLeft: '1rem' }}>
          <Button
            type='secondary'
            variant={'link'}
            onClick={() => setRefresh(val => !val)}
            icon={{ name: 'CloudRefresh' }}
          >
            Refresh Node List
          </Button>
        </div>
      </div>
      <TableOverflow>
        <Table
          columns={nodeListColumns}
          data={nodes || []}
          minHeight={'50px'}
          dataId={'id'}
          label={'Nodes'}
          initialPageSize={10}
          disablePagination
          disablePageSizeSelection
          disableFiltering
          disableSorting
          disableExporting
          disableSearch
          disableColumnHiding
        />
      </TableOverflow>
    </>
  )
}

const TableOverflow = styled.div`
  width: 100%;
  overflow: auto;
`

const StyledList = styled.div`
  ol {
    padding-inline-start: 20px;
  }
`

const AckButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
