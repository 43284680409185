import { useState, useEffect } from 'react'
import { useAppNotifications, useFetch } from 'common'
import {
  API_SERVICE_INSTANCES,
  API_SERVICE_INSTANCE_IDENTITY_TOKEN
} from '../../api'
import { SessionExpired } from '../../Components'
import moment from 'moment'
import { saveAs } from 'file-saver'

export const useServiceInstance = ({
  serviceInstance: propServiceInstance,
  autoLoad
} = {}) => {
  const [serviceInstance, setSi] = useState(propServiceInstance)
  const [refresh, setRefresh] = useState(false)
  const { notify } = useAppNotifications()

  useEffect(() => {
    setSi(propServiceInstance)
  }, [propServiceInstance])

  const { getData, loading, error } = useFetch({
    endpoint: API_SERVICE_INSTANCES,
    actionLabel: 'Get service instance',
    SessionExpired: SessionExpired
  })

  const {
    getData: sendDownloadToken,
    loading: downloading,
    error: downloadError
  } = useFetch({
    endpoint: API_SERVICE_INSTANCE_IDENTITY_TOKEN,
    actionLabel: `Download DLS identity token`,
    SessionExpired: SessionExpired
  })

  const get = async () => {
    const data = await getData()
    if (data) {
      setSi(data)
    } else {
      setSi(undefined)
    }

    return data
  }

  const edit = async (values, onUpdate) => {
    const { name, description } = values

    const body = {
      name,
      description
    }
    const headers = { 'X-NV-SERVICE-INSTANCE-ID': serviceInstance?.xid }
    const data = await getData({
      method: 'PATCH',
      actionLabel: 'Edit service instance',
      body,
      headers
    })
    if (data) {
      const msg = `Service Instance ${name} updated successfully!`
      notify(msg, null, null, 'Edited service instance')
      onUpdate && onUpdate()
    }

    return data
  }

  const downloadToken = async () => {
    const data = await sendDownloadToken({
      headers: { 'X-NV-SERVICE-INSTANCE-ID': serviceInstance?.xid }
    })
    if (data?.identityToken) {
      const blob = new Blob([JSON.stringify(data)], {
        type: 'text/plain'
      })
      saveAs(
        blob,
        `dls_instance_token_${moment(Date.now()).format(
          'MM-DD-YYYY-HH-mm-ss'
        )}.tok`
      )
    } else {
      notify('Unable to parse token', null, true)
    }
  }

  useEffect(() => {
    autoLoad && get()
  }, [autoLoad, refresh]) //eslint-disable-line react-hooks/exhaustive-deps

  return {
    get,
    edit,
    downloadToken,
    loading: loading || downloading,
    error: error || downloadError,
    serviceInstance,
    setRefresh
  }
}
