import {
  Button,
  Text,
  Textbox,
  KaizenThemeContext,
  Icon,
  Block
} from '@kaizen-ui/complete'
import {
  //DurationSelector,
  FileUpload,
  formatDate,
  formatExpirationDate,
  InlineButton,
  ModalButtons,
  ModalSelect,
  ModalSpinner,
  Numberbox,
  Table,
  UsageIndicator,
  useAppNotifications,
  useFetch
} from 'common'
import React from 'react'
import {
  API_NLL,
  LS_FEATURE_STATUS_EXPIRED,
  LS_FEATURE_STATUS_EXPIRING
} from '../../api'
import { saveAs } from 'file-saver'
import styled from 'styled-components'
import { useEffect } from 'reactn'
import ReactTooltip from 'react-tooltip'
import { FormikError } from 'common'

const AddContent = styled.div`
  display: flex;
  align-items: center;

  .kaizen-button {
    padding-right: 0;
  }

  max-width: 600px;
  //margin-bottom: -1rem;
`

const validMACaddress = /^(([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}.[0-9a-fA-F]{4}.[0-9a-fA-F]{4}))$/

export const GenerateNll = ({ serviceInstanceId, server, onUpdate }) => {
  const theme = React.useContext(KaizenThemeContext)
  const { name, licensePools, scopeReference } = server
  const featureOptions = React.useMemo(() => {
    return [
      ...new Set(
        licensePools?.[0]?.licensePoolFeatures?.map(
          pf => pf?.feature?.displayLabel
        ) || []
      )
    ]?.map(label => {
      return {
        label,
        value: licensePools[0]?.licensePoolFeatures?.find(
          pf => pf?.feature?.displayLabel === label
        )?.feature
      }
    })
  }, [licensePools])

  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_NLL,
    actionLabel: `Generating node-locked licensing lease for server ${name}`,
    method: 'POST',
    returnBlob: true
  })
  const submit = async () => {
    const body = {
      nll_lease_request: clients.map((client, index) => {
        return {
          id: `VM${index}`,
          gpu_count: client.numGpus,
          fulfillment_context: {
            fulfillment_class_ref_list: []
          },
          platform_evidence: [{ id: 'MAC', val: client.id }],
          lease_proposal_list: client.features.map(option => {
            return {
              product: {
                name: option.value.featureName,
                version: option.value.featureVersion
              },
              /*...expire,*/
              license_type_qualifiers: {
                count: 1
              }
            }
          }),
          scope_ref_list: [scopeReference]
        }
      })
    }

    const result = await sendRequest({
      body,
      headers: {
        'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId
      }
    })
    if (result) {
      saveAs(result, `nll.zip`)
      onUpdate && onUpdate()
    }
  }

  const [addSerial, setAddSerial] = React.useState('')
  const [macs, setMacs] = React.useState([])
  const [clients, setClients] = React.useState([])
  const [selectedClient, setSelectedClient] = React.useState(undefined)
  const [clear, setClear] = React.useState(false)
  const { notify } = useAppNotifications()
  const [showPool, setShowPool] = React.useState(false)

  const invalidSerial = React.useMemo(() => {
    return addSerial
      ? addSerial
          ?.split(/[\s,]+/)
          .map(val => val.trim())
          .find(val => !validMACaddress.test(val))
      : false
  }, [addSerial])

  const invalidCountOfSerial = React.useMemo(() => {
    return macs?.length > 100
  }, [macs])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const add = values => {
    const added = [
      ...new Set([
        ...(addSerial || values)
          .split(/[\s,]+/)
          .filter(txt => !!txt.trim() && validMACaddress.test(txt)),
        ...macs
      ])
    ]
    setMacs(added)
    setAddSerial('')

    const missing = added
      .filter(
        unique =>
          !clients.some(
            client => client.id.toLowerCase() === unique.toLowerCase()
          )
      )
      .map(mac => {
        return {
          id: mac.toLowerCase(),
          numGpus: 1,
          features: []
        }
      })

    setClients([...clients, ...missing])
  }

  const readCsv = file => {
    if (!file) {
      return
    }
    const reader = new FileReader()
    reader.onload = function(r) {
      let result = r.target.result
      if (result) {
        add(result)
        setClear(val => !val)
      } else {
        const msg =
          'Something went wrong while processing the CSV file. Please try again.'
        notify(msg, null, null, 'Processing CSV networking file failed')
      }
    }
    reader.onerror = function(r) {
      setClear(val => !val)
      const msg =
        'Something went wrong while processing the CSV file. Please try again.'
      notify(msg, null, null, 'Processing CSV networking file failed')
    }
    reader.readAsText(file)
  }

  React.useEffect(() => {
    if (clients.length && !selectedClient) setSelectedClient(clients[0])
  }, [selectedClient, clients])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Feature',
        accessor: 'displayLabel',
        Cell: ({ row }) => {
          const { displayLabel, status } = row.original.feature

          const isExpiring = status === LS_FEATURE_STATUS_EXPIRING
          const isExpired = status === LS_FEATURE_STATUS_EXPIRED

          const dateStyle = isExpired
            ? {
                style: {
                  borderLeft: `5px solid ${theme.colors.danger}`,
                  padding: '0.75rem',
                  margin: '-0.75rem'
                }
              }
            : isExpiring
            ? {
                style: {
                  borderLeft: `5px solid ${theme.colors.warning}`,
                  padding: '0.75rem',
                  margin: '-0.75rem'
                }
              }
            : {
                style: {
                  borderLeft: `5px solid transparent`,
                  padding: '0.75rem',
                  margin: '-0.75rem'
                }
              }

          return (
            <div {...dateStyle}>
              <div>{displayLabel}</div>
            </div>
          )
        }
      },
      {
        Header: 'In Use / Allocated',
        accessor: 'totalAllotment',
        align: 'right',
        Cell: ({ row }) => {
          const { inUse, totalAllotment } = row.original
          const { isCardinal } = row.original.feature

          return (
            <>
              {isCardinal ? (
                <div
                  style={{
                    flex: 1
                  }}
                  data-tip={`${totalAllotment} licenses have been assigned to this pool, of which ${inUse} leases have been issued`}
                >
                  <UsageIndicator
                    assignedQuantity={inUse}
                    totalQuantity={totalAllotment}
                    short
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flex: 1
                  }}
                >
                  n/a
                </div>
              )}
            </>
          )
          //return <RightAlign>{isCardinal ? totalQuantity : 'n/a'}</RightAlign>
        }
      },
      {
        Header: 'Effective',
        accessor: 'startDate',
        width: 100,
        Cell: ({ row }) => {
          const { startDate } = row.original.feature

          return <small>{formatDate(startDate)}</small>
        }
      },
      {
        Header: 'Expiration',
        accessor: 'endDate',
        width: 100,
        Cell: ({ row }) => {
          const { endDate, status } = row.original.feature

          const isExpiring = status === LS_FEATURE_STATUS_EXPIRING
          const isExpired = status === LS_FEATURE_STATUS_EXPIRED

          return isExpired || isExpiring ? (
            <div
              data-tip={
                isExpired
                  ? 'This feature has expired'
                  : isExpiring
                  ? 'This feature will expire soon'
                  : null
              }
            >
              <Icon
                name='StatusWarning'
                color={isExpired ? theme.colors.danger : theme.colors.warning}
              />
              {formatExpirationDate(endDate)}
            </div>
          ) : (
            <small>{formatExpirationDate(endDate)}</small>
          )
        }
      }
    ],
    [theme]
  )

  return (
    <>
      {submitting && <ModalSpinner />}

      <AddContent>
        <StyledContainerForTooltip>
          <span
            data-for='modal-tooltip'
            data-tip={`Supported MAC address representations - XX:XX:XX:XX:XX:XX ,
            XX-XX-XX-XX-XX-XX , XXXX.XXXX.XXXX<br/>System will consider only unique MAC addresses upto 100.<br/>Each MAC address represents a unique license file with one lease count granted for the selected feature(s).`}
          >
            <Icon name='StatusCircleInformation' color={theme.colors?.brand} />
          </span>
        </StyledContainerForTooltip>
        <Textbox
          className='nvl'
          value={addSerial}
          onChange={({ target: { value } }) => setAddSerial(value)}
          placeholder='Comma separated MAC addresses'
          onKeyUp={({ key }) => key === 'Enter' && add()}
          valid={!invalidSerial || !invalidCountOfSerial}
          validationMessage={''}
        />
        <Button
          icon={{ name: 'ActionsAdd' }}
          variant='link'
          onClick={() => add()}
          disabled={!addSerial || invalidSerial}
        >
          Add MAC Address(es)
        </Button>

        <FileUpload
          label='Select file'
          variant='link'
          clearFile={clear}
          onChange={readCsv}
        />
      </AddContent>
      {invalidCountOfSerial || invalidSerial ? (
        <FormikError>
          {invalidCountOfSerial
            ? 'Count of MAC addresses must be less than or equal to 100'
            : invalidSerial
            ? `Values must be a valid MAC address`
            : ''}
        </FormikError>
      ) : null}
      {clients.length ? (
        <>
          <div>
            <Text textStyle='h3'>Clients</Text>
          </div>
          <Row>
            <Left>
              <div>
                {clients.map(client => {
                  return (
                    <ClientRow
                      key={client.id}
                      onClick={() => setSelectedClient(client)}
                      selected={client.id === selectedClient?.id}
                    >
                      <div>{client.id}</div>
                      <div>{client.numGpus}</div>
                    </ClientRow>
                  )
                })}
              </div>
            </Left>
            <Right>
              {selectedClient ? (
                <div>
                  <Block>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Text textStyle='h3'>
                        Configure client {selectedClient.id}
                      </Text>
                      <span style={{ marginLeft: '0.5rem' }}>
                        <Button
                          variant='link'
                          type='critical'
                          icon={{ name: 'ActionsTrash' }}
                          onClick={() => {
                            const filtered = clients.filter(
                              client => client.id !== selectedClient.id
                            )
                            setClients(
                              filtered.sort(function(a, b) {
                                return a.id > b.id ? 1 : a === b ? 0 : -1
                              })
                            )
                            setMacs(filtered.map(f => f.id))
                            setSelectedClient(undefined)
                          }}
                        >
                          Remove client
                        </Button>
                      </span>
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                      <Text textStyle='label'>Number of GPUs</Text>
                      <Numberbox
                        id={selectedClient}
                        className='nvl'
                        value={selectedClient?.numGpus}
                        min={1}
                        max={1000}
                        onChange={value => {
                          const numGpus = isNaN(value) || value < 1 ? 1 : value
                          const filtered = clients.filter(
                            client => client.id !== selectedClient.id
                          )
                          const newSelected = { ...selectedClient, numGpus }
                          const update = [...filtered, newSelected]
                          setClients(
                            update.sort(function(a, b) {
                              return a.id > b.id ? 1 : a === b ? 0 : -1
                            })
                          )
                          setSelectedClient(newSelected)
                        }}
                      />
                    </div>
                    <div>
                      <Text textStyle='label'>Available features</Text>
                      <ModalSelect
                        multiSelect
                        options={featureOptions}
                        //onChange={val => setFeatures(val || [])}
                        onChange={value => {
                          const features = value || []
                          const filtered = clients.filter(
                            client => client.id !== selectedClient.id
                          )
                          const newSelected = { ...selectedClient, features }
                          const update = [...filtered, newSelected]
                          setClients(
                            update.sort(function(a, b) {
                              return a.id > b.id ? 1 : a === b ? 0 : -1
                            })
                          )
                          setSelectedClient(newSelected)
                        }}
                        value={selectedClient?.features}
                        placeholder='Select one or more features'
                      />
                    </div>
                  </Block>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1,
                    marginTop: '1.85rem'
                  }}
                >
                  <Text textStyle='p2' color={theme.colors.textbox.placeholder}>
                    Click on a client to the left to configure it
                  </Text>
                </div>
              )}
            </Right>
          </Row>
        </>
      ) : (
        <Text textStyle='p2' color={theme.colors.textbox.placeholder}>
          Enter a MAC address to start configuring a client, or import a from a
          file above.
        </Text>
      )}
      <div style={{ marginTop: '1rem' }}>
        <InlineButton variant='link' onClick={() => setShowPool(val => !val)}>
          {showPool ? 'Hide' : 'View'} detailed license pool information
        </InlineButton>
        {showPool && (
          <Block>
            <Table
              columns={columns}
              data={licensePools[0]?.licensePoolFeatures}
              disableSearch
              disableColumnHiding
              disablePagination
              disableFiltering
              disableSorting
              minHeight={1}
            />
          </Block>
        )}
      </div>
      <div style={{ marginTop: '2rem' }}>
        <ModalButtons>
          <Button
            onClick={submit}
            disabled={
              clients.length === 0 ||
              invalidCountOfSerial ||
              clients.some(client => client.features?.length === 0) ||
              submitting
            }
          >
            generate
          </Button>
        </ModalButtons>
      </div>
    </>
  )
}

const ClientRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.colors.actionMenu.item.disabled.normal.icon
      : theme.colors.actionMenu.item.default.hover.background};

  :hover {
    cursor: pointer;
    background-color: ${({ theme, selected }) =>
      selected
        ? theme.colors.actionMenu.item.disabled.normal.icon
        : theme.colors.componentBackground};
  }
`

const Row = styled.div`
  display: flex;
`

const Left = styled.div`
  width: 200px;
  background-color: ${({ theme }) => theme.colors.actionMenu.menu.background};
  overflow: auto;
  max-height: 200px;
  flex-shrink: 0;
`

const Right = styled.div`
  display: flex;
  flex: 1;
  margin-left: 1rem;
`
const StyledContainerForTooltip = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  span {
    vertical-align: middle;
    align-self: flex-end;
  }
`
