import { FloatingSpinner, useFetch } from 'common'
import React, { useGlobal } from 'reactn'
import {
  API_EMAIL_ALERTS,
  API_EMAIL_ALERTS_SMTP, DOCS_DISK_SPACE_THRESHOLD_CONFIG,
  EMAIL_ALERT_TYPES
} from '../../../api'
import { SessionExpired } from '../../../Components'
import { GLOBAL_ORG, GLOBAL_VA_VERSION } from '../../../globalState'
import styled from 'styled-components'
import { AlertDisplay } from './AlertDisplay'
import Modal from '@kaizen-ui/modal'
import { ConfigureAlert } from './ConfigureAlert'
import { Block, Button, Result } from '@kaizen-ui/complete'
import { ConfigureSmtp } from './ConfigureSmtp'

const Row = styled.div`
  display: flex;
  margin: -1rem -1rem 0 -1rem;
  flex-wrap: wrap;

  > div {
    margin: 1rem;
    flex-shrink: 0;
    //flex: 1;
    width: 30%;
    min-width: 300px;
  }

  hr {
    margin-bottom: 1.5rem;
  }
`
const StyledModalSubTitle = styled.div`
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  font-family: 'Open Sans',sans-serif;
  font-size: 1rem;
`

export const EmailAlerts = () => {
  const [org] = useGlobal(GLOBAL_ORG)
  const [version] = useGlobal(GLOBAL_VA_VERSION)
  const orgId = org && org.id
  const [refresh, setRefresh] = React.useState(false)
  const [pageLoading, setPageLoading] = React.useState(true)
  //const [apiKeyAlert, setApiKeyAlert] = React.useState(undefined)
  // const [featureExpiredAlert, setFeatureExpiredAlert] = React.useState(
  //   undefined
  // )
  const [leaseConsumptionAlert, setLeaseConsumptionAlert] = React.useState(
    undefined
  )
  const [failoverAlert, setFailoverAlert] = React.useState(undefined)
  const [serviceStateAlert, setServiceStateAlert] = React.useState(undefined)
  const [systemResourceAlert, setSystemResourceAlert] = React.useState(
    undefined
  )
  const [selectedAlert, setSelectedAlert] = React.useState(undefined)
  const [smtp, setSmtp] = React.useState(undefined)
  const [reconfig, setReconfig] = React.useState(false)

  const { getData, loading, error, abort } = useFetch({
    endpoint: API_EMAIL_ALERTS(),
    actionLabel: 'Get e-mail alerts',
    SessionExpired: SessionExpired,
    normalizer: data => {
      return {
        alertConfigs: data.alertConfigs.map(ac => {
          return { ...ac, type: ac.alertType }
        })
      }
    }
  })
  const {
    getData: getConfig,
    error: errorConfig,
    abort: abortConfig
  } = useFetch({
    endpoint: API_EMAIL_ALERTS_SMTP(),
    actionLabel: 'Get e-mail alert configuration',
    SessionExpired: SessionExpired
  })

  React.useEffect(() => {
    const getAlerts = async () => {
      const data = await getData()

      if (data?.alertConfigs) {
        const { alertConfigs } = data

        setFailoverAlert(undefined)
        setServiceStateAlert(undefined)
        setSystemResourceAlert(undefined)
        setLeaseConsumptionAlert(undefined)
        alertConfigs.forEach(alert => {
          const { type } = alert

          if (type === EMAIL_ALERT_TYPES.FAILOVER) {
            setFailoverAlert(alert)
          } else if (type === EMAIL_ALERT_TYPES.SERVICE_STATE_MONITOR) {
            setServiceStateAlert(alert)
          } else if (type === EMAIL_ALERT_TYPES.SYSTEM_RESOURCE_MONITOR) {
            setSystemResourceAlert(alert)
          } else if (type === EMAIL_ALERT_TYPES.LEASE_CONSUMPTION) {
            setLeaseConsumptionAlert(alert)
          }
        })
      }
    }
    getAlerts()
    const getSmtp = async () => {
      const data = await getConfig()

      if (data) {
        setSmtp(data)
      }

      setPageLoading(false)
    }
    getSmtp()

    return () => {
      abort()
      abortConfig()
    }
  }, [orgId, refresh]) //eslint-disable-line react-hooks/exhaustive-deps

  const openModal = (title, type, alert, subtitle='', subtitleLink='') => {
    setSelectedAlert({ title, type, alert, subtitle, subtitleLink })
  }

  if ((!pageLoading && !smtp?.host) || reconfig)
    return (
      <ConfigureSmtp
        smtp={smtp}
        onUpdate={() => {
          setRefresh(val => !val)
          setReconfig(false)
        }}
        onCancel={() => setReconfig(false)}
      />
    )

  if (error || errorConfig)
    return <Result status='error' title={error || errorConfig} />

  return (
    <>
      {/* <PageHeader
        title='E-Mail Alerts'
        //helpUrl={DOCS_ROOT}
        //tip='Get help with the NVIDIA licensing portal'
        subTitle={
          'Configure alerts to notify one or more e-mail addresses when an event occurs in '
        }
        org={org}
      /> */}
      {pageLoading || loading ? (
        <FloatingSpinner visible />
      ) : (
        <>
          <div style={{ marginBottom: '1rem' }}>
            <Block>
              <Button variant='link' onClick={() => setReconfig(val => !val)}>
                Update SMTP configuration
              </Button>
            </Block>
          </div>
          <Row>
            <div>
              <AlertDisplay
                alert={failoverAlert}
                title='Failover Event'
                icon='ObjectsKey2'
                onClick={() => {
                  openModal(
                    'Failover Event',
                    EMAIL_ALERT_TYPES.FAILOVER,
                    failoverAlert
                  )
                }}
              />
            </div>
            <div>
              <AlertDisplay
                alert={systemResourceAlert}
                title='System Resources'
                icon='CustomNgcSummary'
                onClick={() => {
                  openModal(
                    'System Resources',
                    EMAIL_ALERT_TYPES.SYSTEM_RESOURCE_MONITOR,
                    systemResourceAlert,
                    "The mentioned resource usage percentage will be applicable for CPU and Memory. The applicable percentage for Disk will be retrieved from the system configuration file.",
                    DOCS_DISK_SPACE_THRESHOLD_CONFIG(version)
                  )
                }}
              />
            </div>
            <div>
              <AlertDisplay
                alert={serviceStateAlert}
                title='Service Availability'
                icon='CustomNgcSummary'
                onClick={() => {
                  openModal(
                    'Service Availability',
                    EMAIL_ALERT_TYPES.SERVICE_STATE_MONITOR,
                    serviceStateAlert
                  )
                }}
              />
            </div>
            <div>
              <AlertDisplay
                alert={leaseConsumptionAlert}
                title='Lease Consumption'
                icon='CustomNgcWorkstation'
                onClick={() => {
                  openModal(
                    'Lease Consumption',
                    EMAIL_ALERT_TYPES.LEASE_CONSUMPTION,
                    leaseConsumptionAlert
                  )
                }}
              />
            </div>
          </Row>
        </>
      )}
      <div onClick={e => e.stopPropagation()}>
        <Modal
          open={!!selectedAlert}
          onClose={() => setSelectedAlert(undefined)}
          title={selectedAlert?.title}
        >
          {selectedAlert?.subtitle &&
            <StyledModalSubTitle>
              {selectedAlert?.subtitle} &nbsp;
              {selectedAlert?.subtitleLink && (
                /*<span
                  style={{
                    display: 'flex'
                  }}
                >*/
                <a href={selectedAlert?.subtitleLink} target='_blank' rel='noreferrer'>
                  {/*<Button variant='link' icon={{ name: 'StatusCircleHelp' }}>*/}
                  View documentation
                  {/*  </Button>*/}
                </a>
                /*</span>*/
              )}
            </StyledModalSubTitle>
          }

          {selectedAlert && (
            <ConfigureAlert
              selectedAlert={selectedAlert}
              onUpdate={() => {
                setSelectedAlert(undefined)
                setRefresh(val => !val)
              }}
            />
          )}
        </Modal>
      </div>
    </>
  )
}
