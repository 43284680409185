import React, { useEffect, useGlobal } from 'reactn'
import styled from 'styled-components'
import Banner from '@kaizen-ui/banner'
import Button from '@kaizen-ui/button'
import { UPGRADE_MODE, UPGRADE_STATUS } from '../../api'
import { useAuth, useUpgradeNotifier } from '../../hooks'
import {
  GLOBAL_UPGRADE_MODE,
  GLOBAL_UPGRADE_STATUS_LOADING,
  GLOBAL_UPGRADE_STATUS_REFRESH
} from '../../globalState'
import { LiteSpinner } from 'common'
import { Text } from '@kaizen-ui/complete'

const Outer = styled.div`
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px 0;

  .content {
    flex: 1;
  }
`
const StyledRow = styled.div`
  display: flex;
  align-items: center;
`
export const UpgradeNotifier = () => {
  const { upgradeStatus, gettingUpgradeStatus } = useUpgradeNotifier()
  const { user } = useAuth()
  /* const theme = useContext(KaizenThemeContext)*/
  const [, setRefresh] = useGlobal(GLOBAL_UPGRADE_STATUS_REFRESH)
  const [, setUpgradeStatusLoading] = useGlobal(GLOBAL_UPGRADE_STATUS_LOADING)
  const [upgradeMode] = useGlobal(GLOBAL_UPGRADE_MODE)

  useEffect(() => {
    setUpgradeStatusLoading(gettingUpgradeStatus)
  }, [gettingUpgradeStatus]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {user && upgradeStatus ? (
        <Outer>
          <Banner
            icon={{
              name: 'SettingsCogDouble2',
              size: 56,
              color: 'success',
              variant: 'solid'
            }}
            status={'passive'}
            title={
              upgradeStatus?.state === UPGRADE_STATUS.INITIATED ||
              upgradeStatus?.state === UPGRADE_STATUS.IN_PROGRESS
                ? 'Virtual appliance is currently undergoing an upgrade process'
                : upgradeStatus?.state === UPGRADE_STATUS.COMPLETED
                ? 'Virtual appliance upgrade completed successfully'
                : upgradeStatus?.state === UPGRADE_STATUS.FAILED
                ? 'Last virtual appliance upgrade attempt failed, please try again later.'
                : 'Virtual appliance upgrade status'
            }
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              <div style={{ display: 'block' }}>
                <StyledRow>
                  <Text textStyle={'h3'}>Mode : </Text>
                  <Text textStyle={'h3'}>
                    &nbsp;{' '}
                    {upgradeMode === UPGRADE_MODE.OFFLINE
                      ? 'Portal Assisted Upgrade'
                      : 'In-Place Upgrade'}
                  </Text>
                </StyledRow>{' '}
                <StyledRow>
                  <Text textStyle={'h3'}> Status : </Text>
                  <div style={{ textTransform: 'uppercase' }}>
                    <Text textStyle={'h3'}>&nbsp; {upgradeStatus?.state}</Text>
                  </div>
                </StyledRow>
              </div>
              {(upgradeStatus?.state === UPGRADE_STATUS.INITIATED ||
                upgradeStatus?.state === UPGRADE_STATUS.IN_PROGRESS) && (
                <div style={{ display: 'block' }}>
                  <Button
                    type='secondary'
                    variant={'link'}
                    onClick={() => setRefresh(val => !val)}
                    icon={{ name: 'CloudRefresh' }}
                    style={{ marginLeft: '1rem' }}
                    disabled={gettingUpgradeStatus}
                  >
                    Refresh Status
                  </Button>
                </div>
              )}
              {gettingUpgradeStatus && <LiteSpinner />}
            </div>
          </Banner>
        </Outer>
      ) : null}
    </>
  )
}
